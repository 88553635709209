"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var sharePageLoginDetails = {
  name: {
    first: '',
    last: ''
  },
  image: '',
  email: '',
  phone: '',
  code: ''
};
var addTextMemory = {
  message: ''
};
var addFamUpdate = {
  updateText: ''
};
var mobileModalComment = {
  commentText: ''
};
var galleryComment = {
  commentText: ''
};
var mediaComments = [{
  commentText: ''
}];
var famMsgComments = [{
  commentText: ''
}];
var shareWelcomeModal = {
  email: '',
  password: ''
};
var journeyHomeReview = {
  name: '',
  email: '',
  rating: -1,
  message: '',
  adjectives: [],
  everdaysRating: null,
  everdaysFeedback: '',
  userUpdated: false
}; // no longer in use

var chatSearch = {
  searchText: ''
};
var chatSort = {
  sortValue: ''
};
var pastEventComment = [{
  commentText: ''
}];
var tbdEventComment = [{
  commentText: ''
}];
var futureEventComment = [{
  commentText: ''
}];
var _default = {
  sharePageLoginDetails: sharePageLoginDetails,
  addTextMemory: addTextMemory,
  addFamUpdate: addFamUpdate,
  mobileModalComment: mobileModalComment,
  galleryComment: galleryComment,
  mediaComments: mediaComments,
  famMsgComments: famMsgComments,
  shareWelcomeModal: shareWelcomeModal,
  journeyHomeReview: journeyHomeReview,
  // forms below are no longer used
  chatSearch: chatSearch,
  chatSort: chatSort,
  pastEventComment: pastEventComment,
  tbdEventComment: tbdEventComment,
  futureEventComment: futureEventComment
};
exports.default = _default;