"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.array.from.js");

require("core-js/modules/es6.array.slice.js");

require("core-js/modules/es6.weak-map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.regexp.to-string.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.array.map.js");

require("core-js/modules/es6.function.name.js");

require("core-js/modules/es6.array.find-index.js");

var actions = _interopRequireWildcard(require("../../constants/dash/fetchCompanyMemorials"));

var editMemorialActions = _interopRequireWildcard(require("../../constants/dash/editMemorial"));

var demoActions = _interopRequireWildcard(require("../../lib/demoMode/constants"));

var _demoModeData = require("@evdy/web-etc/lib/demoModeData");

var _libphonenumberJs = require("libphonenumber-js");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var parsePhone = function parsePhone(phone) {
  return new _libphonenumberJs.asYouType('US').input(phone.toString());
};

var initialState = {
  memorials: null,
  isFetching: false,
  lastUpdated: null,
  error: {}
};

var transformAccessors = function transformAccessors(memorial) {
  return memorial.accessors.map(function (accessor) {
    if (accessor.phone) {
      try {
        accessor.phone = parsePhone(accessor.phone);
      } catch (e) {
        console.error(e);
      }
    }

    if (!accessor.name) {
      accessor.name = accessor.userRef && accessor.userRef.name;
    }

    if (!accessor.email) {
      accessor.email = accessor.userRef && accessor.userRef.email || '';
    }

    return accessor;
  }).filter(function (a) {
    return a && a.name && a.name.first && a.name.last;
  });
};

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actions.FETCH_COMPANY_MEMORIALS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true,
        error: {}
      });

    case actions.FETCH_COMPANY_MEMORIALS_SUCCESS:
      if (action.payload && action.payload.data) {
        action.payload.data.forEach(function (memorial) {
          memorial.accessors = transformAccessors(memorial);
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        memorials: action.payload,
        error: {}
      });

    case actions.LOAD_MORE_MEMORIALS_SUCCESS:
      if (action.payload && action.payload.data) {
        action.payload.data.forEach(function (memorial) {
          memorial.accessors = transformAccessors(memorial);
        });
      }

      var additionalMemorials = [].concat(_toConsumableArray(state.memorials.data), _toConsumableArray(action.payload.data));
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        memorials: _objectSpread(_objectSpread({}, action.payload), {}, {
          data: additionalMemorials
        }),
        error: {}
      });

    case editMemorialActions.EDIT_MEMORIAL_SUCCESS:
      if (!state.memorials || !state.memorials.data || !state.memorials.data.length) {
        return state;
      }

      var newMemorial = action.data;

      var data = _toConsumableArray(state.memorials.data);

      var updateIdx = data.findIndex(function (m) {
        return m._id === newMemorial._id;
      });

      if (updateIdx < 0) {
        return state;
      }

      newMemorial.accessors = transformAccessors(newMemorial);
      data[updateIdx] = newMemorial;
      return _objectSpread(_objectSpread({}, state), {}, {
        memorials: _objectSpread(_objectSpread({}, state.memorials), {}, {
          data: data
        })
      });

    case actions.FETCH_COMPANY_MEMORIALS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: action.error
      });

    case demoActions.FETCH_DEMO_ANNOUNCEMENTS:
      return _objectSpread(_objectSpread({}, initialState), {}, {
        memorials: {
          data: _demoModeData.demoAnnouncements
        },
        lastUpdated: Date.now()
      });

    case demoActions.CREATE_DEMO_MEMORIAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        memorials: {
          data: [action.memorial].concat(_toConsumableArray(_demoModeData.demoAnnouncements))
        },
        lastUpdated: Date.now()
      });

    default:
      return state;
  }
};

exports.default = _default;