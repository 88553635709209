"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SET_SHELL_PASSWORD_FAILURE = exports.SET_SHELL_PASSWORD_SUCCESS = exports.SET_SHELL_PASSWORD_REQUEST = void 0;
var SET_SHELL_PASSWORD_REQUEST = 'SET_SHELL_PASSWORD_REQUEST';
exports.SET_SHELL_PASSWORD_REQUEST = SET_SHELL_PASSWORD_REQUEST;
var SET_SHELL_PASSWORD_SUCCESS = 'SET_SHELL_PASSWORD_SUCCESS';
exports.SET_SHELL_PASSWORD_SUCCESS = SET_SHELL_PASSWORD_SUCCESS;
var SET_SHELL_PASSWORD_FAILURE = 'SET_SHELL_PASSWORD_FAILURE';
exports.SET_SHELL_PASSWORD_FAILURE = SET_SHELL_PASSWORD_FAILURE;