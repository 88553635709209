"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: {
    first: '',
    last: ''
  },
  title: '',
  phone: '',
  email: '',
  image: '',
  bio: '',
  isCompanyAdmin: false,
  isHomeAFP: false,
  locationSettings: [{
    createView: false,
    display: false,
    reportsAccess: false,
    crmAccess: false
  }]
};
exports.default = _default;