"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.array.from.js");

require("core-js/modules/es6.function.name.js");

require("core-js/modules/es6.weak-map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find-index.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.regexp.to-string.js");

require("core-js/modules/es6.array.map.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.array.slice.js");

require("core-js/modules/es6.array.find.js");

var actions = _interopRequireWildcard(require("../constants/types"));

var followActions = _interopRequireWildcard(require("../constants/dash/followMemorial"));

var editActions = _interopRequireWildcard(require("../constants/dash/editMemorial"));

var demoActions = _interopRequireWildcard(require("../lib/demoMode/constants"));

var _annc = require("../constants/dash/annc");

var _nullPrune = _interopRequireDefault(require("null-prune"));

var _rsvp = require("../constants/announcements/rsvp");

var _mediaComments = require("../constants/announcements/mediaComments");

var _mediaLikes = require("../constants/announcements/mediaLikes");

var _deleteMedia = require("../constants/announcements/deleteMedia");

var _famMsgComments = require("../constants/announcements/famMsgComments");

var _famMsgReportComment = require("../constants/announcements/famMsgReportComment");

var _eventReportComment = require("../constants/announcements/eventReportComment");

var _mediaReportComments = require("../constants/announcements/mediaReportComments");

var _famMsgLikes = require("../constants/announcements/famMsgLikes");

var _eventComments = require("../constants/announcements/eventComments");

var _eventLikes = require("../constants/announcements/eventLikes");

var _familyUpdates = require("../constants/webAnnouncement/familyUpdates");

var _demoModeData = require("@evdy/web-etc/lib/demoModeData");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialState = {
  notice: {
    data: {}
  },
  isFetching: true,
  lastUpdated: null,
  error: {}
};

function getItemIdx(array, itemId) {
  return array.findIndex(function (item) {
    return item._id === itemId;
  });
}

function getLikeIdx(array, itemId, userId, type) {
  var itemIdx = getItemIdx(array, itemId);
  var likeCommentIdx = array[itemIdx][type].findIndex(function (x) {
    return (x.poster && x.poster._id || x.poster).toString() === userId.toString();
  });
  return [itemIdx, likeCommentIdx];
}

var _default = function _default() {
  var _action$data, _action$data$data;

  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var accessors = state.notice && state.notice.data && state.notice.data.accessors || [];
  var userId = action.userId;
  var splitIndex = accessors.findIndex(function (a) {
    return (a.userRef && a.userRef._id || a.userRef) === userId;
  });
  var accessorIsFollowing = splitIndex > -1;

  switch (action.type) {
    case actions.FETCH_MEMORIAL_NOTICE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true,
        error: {}
      });

    case actions.FETCH_MEMORIAL_NOTICE_SUCCESS:
    case editActions.EDIT_MEMORIAL_SUCCESS:
      var _action$payload = action.payload,
          data = _action$payload.data,
          rest = _objectWithoutProperties(_action$payload, ["data"]);

      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        notice: _objectSpread({
          data: (0, _nullPrune.default)(data)
        }, rest),
        error: {}
      });

    case actions.SUBMIT_INVITE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            accessors: (action === null || action === void 0 ? void 0 : (_action$data = action.data) === null || _action$data === void 0 ? void 0 : (_action$data$data = _action$data.data) === null || _action$data$data === void 0 ? void 0 : _action$data$data.accessors) || []
          })
        })
      });

    case _rsvp.RSVP_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            accessors: action.data.data.accessors
          })
        })
      });

    case _mediaComments.CREATE_MEDIA_COMMENT_SUCCESS:
      var mediaIdxC = action.data.meta.mediaIdx;

      var mediaC = _toConsumableArray(state.notice.data.media);

      var newMediaComment = _objectSpread(_objectSpread({}, action.data), {}, {
        new: true
      });

      var checkMediaComments = mediaC[mediaIdxC].comments || [];
      var mediaComments = [].concat(_toConsumableArray(checkMediaComments), [newMediaComment]);

      var newMediaC = _objectSpread(_objectSpread({}, mediaC[mediaIdxC]), {}, {
        comments: mediaComments
      });

      var newMediaArrayC = mediaC.map(function (x, idx) {
        return idx === mediaIdxC ? newMediaC : x;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            media: newMediaArrayC
          })
        })
      });

    case _mediaReportComments.CREATE_MEDIA_REPORT_COMMENT_SUCCESS:
      var mediaIdCR = action.mediaId,
          mediaCommentIdCR = action.commentId;

      var mediaCR = _toConsumableArray(state.notice.data.media);

      var mediaIdxCR = getItemIdx(mediaCR, mediaIdCR);
      var mediaCommentsCR = mediaCR[mediaIdxCR].comments || [];

      var newMediaCommentsCR = _toConsumableArray(mediaCommentsCR).filter(function (_ref) {
        var _id = _ref._id;
        return _id !== mediaCommentIdCR;
      });

      var newMediaCR = _objectSpread(_objectSpread({}, mediaCR[mediaIdxCR]), {}, {
        comments: newMediaCommentsCR
      });

      var newMediaArrayCR = mediaCR.map(function (x, idx) {
        return idx === mediaIdxCR ? newMediaCR : x;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            media: newMediaArrayCR
          })
        })
      });

    case _mediaLikes.MEDIA_LIKE_SUCCESS:
      var mediaIdxL = action.data.meta.mediaIdx;

      var mediaL = _toConsumableArray(state.notice.data.media);

      var newMediaLike = _objectSpread(_objectSpread({}, action.data), {}, {
        new: true
      });

      var checkMediaLikes = mediaL[mediaIdxL].likes || [];
      var mediaLikes = [].concat(_toConsumableArray(checkMediaLikes), [newMediaLike]);

      var newMediaL = _objectSpread(_objectSpread({}, mediaL[mediaIdxL]), {}, {
        likes: mediaLikes
      });

      var newMediaArrayL = mediaL.map(function (x, idx) {
        return idx === mediaIdxL ? newMediaL : x;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            media: newMediaArrayL
          })
        })
      });

    case _mediaLikes.MEDIA_UNLIKE_SUCCESS:
      var mediaIdU = action.mediaId,
          userIdMU = action.userId;

      var mediaU = _toConsumableArray(state.notice.data.media);

      var _getLikeIdx = getLikeIdx(mediaU, mediaIdU, userIdMU, 'likes'),
          _getLikeIdx2 = _slicedToArray(_getLikeIdx, 2),
          mediaIdxU = _getLikeIdx2[0],
          likeIdxMU = _getLikeIdx2[1];

      var newLikesMU = [].concat(_toConsumableArray(mediaU[mediaIdxU].likes.slice(0, likeIdxMU)), _toConsumableArray(mediaU[mediaIdxU].likes.slice(likeIdxMU + 1, mediaU[mediaIdxU].likes.length)));

      var newMediaU = _objectSpread(_objectSpread({}, mediaU[mediaIdxU]), {}, {
        likes: newLikesMU
      });

      var newMediaArrayU = mediaU.map(function (x, idx) {
        return idx === mediaIdxU ? newMediaU : x;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            media: newMediaArrayU
          })
        })
      });

    case _deleteMedia.DELETE_MEDIA_SUCCESS:
      var mediaIdD = action.mediaId;

      var mediaD = _toConsumableArray(state.notice.data.media);

      var mediaIdxD = getItemIdx(mediaD, mediaIdD);
      var newMediaArrayD = [].concat(_toConsumableArray(mediaD.slice(0, mediaIdxD)), _toConsumableArray(mediaD.slice(mediaIdxD + 1, mediaD.length)));
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            media: newMediaArrayD
          })
        })
      });

    case _famMsgComments.FAMILY_MESSAGE_COMMENT_SUCCESS:
      var famMsgIdC = action.famMsgId;

      var familyMsgsC = _toConsumableArray(state.notice.data.familyMessages);

      var newFamMsgComment = _objectSpread(_objectSpread({}, action.data), {}, {
        new: true
      });

      var familyMsgIdxC = getItemIdx(familyMsgsC, famMsgIdC);
      var checkFamMsgComments = familyMsgsC[familyMsgIdxC].comments || [];
      var famMsgComments = [].concat(_toConsumableArray(checkFamMsgComments), [newFamMsgComment]);

      var newFamMsgC = _objectSpread(_objectSpread({}, familyMsgsC[familyMsgIdxC]), {}, {
        comments: famMsgComments
      });

      var newFamMsgsC = familyMsgsC.map(function (x, idx) {
        return idx === familyMsgIdxC ? newFamMsgC : x;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            familyMessages: newFamMsgsC
          })
        })
      });

    case _famMsgReportComment.FAMILY_MESSAGE_COMMENT_REPORT_SUCCESS:
      var famMsgIdCR = action.famMsgId,
          famCommentIdCR = action.commentId;

      var familyMsgsCR = _toConsumableArray(state.notice.data.familyMessages);

      var familyMsgIdxCR = getItemIdx(familyMsgsCR, famMsgIdCR);
      var familyMsgCommentsCR = familyMsgsCR[familyMsgIdxCR].comments || [];

      var newFamMsgsCommentsCR = _toConsumableArray(familyMsgCommentsCR).filter(function (_ref2) {
        var _id = _ref2._id;
        return _id !== famCommentIdCR;
      });

      var newFamMsgCR = _objectSpread(_objectSpread({}, familyMsgsCR[familyMsgIdxCR]), {}, {
        comments: newFamMsgsCommentsCR
      });

      var newFamMsgsCR = familyMsgsCR.map(function (x, idx) {
        return idx === familyMsgIdxCR ? newFamMsgCR : x;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            familyMessages: newFamMsgsCR
          })
        })
      });

    case _famMsgLikes.FAMILY_MESSAGE_LIKE_SUCCESS:
      var famMsgIdL = action.famMsgId;

      var familyMsgsL = _toConsumableArray(state.notice.data.familyMessages);

      var newFamMsgLike = _objectSpread(_objectSpread({}, action.data), {}, {
        new: true
      });

      var famMsgIdxL = getItemIdx(familyMsgsL, famMsgIdL);
      var checkFamMsgLikes = familyMsgsL[famMsgIdxL].likes || [];
      var famMsgLikes = [].concat(_toConsumableArray(checkFamMsgLikes), [newFamMsgLike]);

      var newFamMsgL = _objectSpread(_objectSpread({}, familyMsgsL[famMsgIdxL]), {}, {
        likes: famMsgLikes
      });

      var newFamMsgsL = familyMsgsL.map(function (x, idx) {
        return idx === famMsgIdxL ? newFamMsgL : x;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            familyMessages: newFamMsgsL
          })
        })
      });

    case _famMsgLikes.FAMILY_MESSAGE_UNLIKE_SUCCESS:
      var famMsgIdU = action.famMsgId,
          userIdFMU = action.userId;

      var familyMsgsU = _toConsumableArray(state.notice.data.familyMessages);

      var _getLikeIdx3 = getLikeIdx(familyMsgsU, famMsgIdU, userIdFMU, 'likes'),
          _getLikeIdx4 = _slicedToArray(_getLikeIdx3, 2),
          famMsgIdxU = _getLikeIdx4[0],
          likeIdxFMU = _getLikeIdx4[1];

      var newLikesFMU = [].concat(_toConsumableArray(familyMsgsU[famMsgIdxU].likes.slice(0, likeIdxFMU)), _toConsumableArray(familyMsgsU[famMsgIdxU].likes.slice(likeIdxFMU + 1, familyMsgsU[famMsgIdxU].likes.length)));

      var newFamMsgU = _objectSpread(_objectSpread({}, familyMsgsU[famMsgIdxU]), {}, {
        likes: newLikesFMU
      });

      var newFamMsgsU = familyMsgsU.map(function (x, idx) {
        return idx === famMsgIdxU ? newFamMsgU : x;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            familyMessages: newFamMsgsU
          })
        })
      });

    case _eventComments.EVENT_COMMENT_SUCCESS:
      var eventIdC = action.eventId;

      var servicesEC = _toConsumableArray(state.notice.data.services);

      var newEventComment = _objectSpread(_objectSpread({}, action.data), {}, {
        new: true
      });

      var eventIdxC = getItemIdx(servicesEC, eventIdC);
      var checkEventComments = servicesEC[eventIdxC].comments || [];
      var eventComments = [].concat(_toConsumableArray(checkEventComments), [newEventComment]);

      var newEventC = _objectSpread(_objectSpread({}, servicesEC[eventIdxC]), {}, {
        comments: eventComments
      });

      var newEventsC = servicesEC.map(function (x, idx) {
        return idx === eventIdxC ? newEventC : x;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            services: newEventsC
          })
        })
      });

    case _eventReportComment.EVENT_REPORT_COMMENT_SUCCESS:
      var eventIdCR = action.eventId,
          eventCommentIdCR = action.commentId;

      var servicesECR = _toConsumableArray(state.notice.data.services);

      var eventIdxCR = getItemIdx(servicesECR, eventIdCR);
      var eventCommentsCR = servicesECR[eventIdxCR].comments || [];

      var newEventCommentsCR = _toConsumableArray(eventCommentsCR).filter(function (_ref3) {
        var _id = _ref3._id;
        return _id !== eventCommentIdCR;
      });

      var newEventCR = _objectSpread(_objectSpread({}, servicesECR[eventIdxCR]), {}, {
        comments: newEventCommentsCR
      });

      var newEventsCR = servicesECR.map(function (x, idx) {
        return idx === eventIdxCR ? newEventCR : x;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            services: newEventsCR
          })
        })
      });

    case _eventLikes.EVENT_LIKE_SUCCESS:
      var eventIdL = action.eventId;

      var servicesEL = _toConsumableArray(state.notice.data.services);

      var newEventLike = _objectSpread(_objectSpread({}, action.data), {}, {
        new: true
      });

      var eventIdxL = getItemIdx(servicesEL, eventIdL);
      var checkEventLikes = servicesEL[eventIdxL].likes || [];
      var eventLikes = [].concat(_toConsumableArray(checkEventLikes), [newEventLike]);

      var newEventL = _objectSpread(_objectSpread({}, servicesEL[eventIdxL]), {}, {
        likes: eventLikes
      });

      var newEventsL = servicesEL.map(function (x, idx) {
        return idx === eventIdxL ? newEventL : x;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            services: newEventsL
          })
        })
      });

    case _eventLikes.EVENT_UNLIKE_SUCCESS:
      var eventIdU = action.eventId,
          userIdEU = action.userId;

      var servicesEU = _toConsumableArray(state.notice.data.services);

      var _getLikeIdx5 = getLikeIdx(servicesEU, eventIdU, userIdEU, 'likes'),
          _getLikeIdx6 = _slicedToArray(_getLikeIdx5, 2),
          eventIdxU = _getLikeIdx6[0],
          likeIdxEU = _getLikeIdx6[1];

      var newLikesEU = [].concat(_toConsumableArray(servicesEU[eventIdxU].likes.slice(0, likeIdxEU)), _toConsumableArray(servicesEU[eventIdxU].likes.slice(likeIdxEU + 1, servicesEU[eventIdxU].likes.length)));

      var newEventU = _objectSpread(_objectSpread({}, servicesEU[eventIdxU]), {}, {
        likes: newLikesEU
      });

      var newEventsU = servicesEU.map(function (x, idx) {
        return idx === eventIdxU ? newEventU : x;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            services: newEventsU
          })
        })
      });

    case followActions.FOLLOW_MEMORIAL_SUCCESS:
      var userIdFollow = action.userId;

      var nextDataF = _objectSpread({}, state.notice.data);

      nextDataF.accessors.push({
        userRef: {
          _id: userIdFollow
        },
        type: 'viewer'
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: nextDataF
        })
      });

    case actions.FETCH_MEMORIAL_NOTICE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: action.error
      });

    case followActions.FOLLOW_MEMORIAL_SUCCESS:
      var followAccessors = accessorIsFollowing ? _toConsumableArray(accessors) : [].concat(_toConsumableArray(accessors), [{
        userRef: userId,
        type: 'viewer'
      }]);
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            accessors: followAccessors
          })
        })
      });

    case followActions.UNFOLLOW_MEMORIAL_SUCCESS:
      var unfollowAccessors = accessorIsFollowing ? [].concat(_toConsumableArray(accessors.slice(0, splitIndex)), _toConsumableArray(accessors.slice(splitIndex + 1))) : _toConsumableArray(accessors);
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            accessors: unfollowAccessors
          })
        })
      });

    case editActions.EDIT_MEMORIAL_NEW_IMAGES:
      var mediaArray = state.notice.data.media || [];
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            media: [].concat(_toConsumableArray(action.data), _toConsumableArray(mediaArray))
          })
        })
      });

    case _familyUpdates.ADD_FAMILY_UPDATE_SUCCESS:
      var famMsgs = state.notice.data.familyMessages || [];
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            familyMessages: [action.payload.data].concat(_toConsumableArray(famMsgs))
          })
        })
      });

    case _familyUpdates.EDIT_FAMILY_UPDATE_SUCCESS:
      var famMsgsE = _toConsumableArray(state.notice.data.familyMessages) || [];
      var editedIdx = famMsgsE.findIndex(function (fm) {
        return fm._id === action.payload.data._id;
      });
      var newFamMsgArrayE = [].concat(_toConsumableArray(state.notice.data.familyMessages.slice(0, editedIdx)), [action.payload.data], _toConsumableArray(state.notice.data.familyMessages.slice(editedIdx + 1)));
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            familyMessages: newFamMsgArrayE
          })
        })
      });

    case _familyUpdates.DELETE_FAMILY_UPDATE_SUCCESS:
      var famMsgsD = _toConsumableArray(state.notice.data.familyMessages) || [];
      var deletedIdx = famMsgsD.findIndex(function (fm) {
        return fm._id === action.famMsgId;
      });
      var newFamMsgArrayD = [].concat(_toConsumableArray(state.notice.data.familyMessages.slice(0, deletedIdx)), _toConsumableArray(state.notice.data.familyMessages.slice(deletedIdx + 1)));
      return _objectSpread(_objectSpread({}, state), {}, {
        notice: _objectSpread(_objectSpread({}, state.notice), {}, {
          data: _objectSpread(_objectSpread({}, state.notice.data), {}, {
            familyMessages: newFamMsgArrayD
          })
        })
      });

    case _annc.anncReset:
      return initialState;

    case demoActions.FETCH_DEMO_ANNOUNCEMENT:
      return _objectSpread(_objectSpread({}, initialState), {}, {
        notice: {
          data: _demoModeData.demoAnnouncements.find(function (annc) {
            return annc._id === action.id;
          })
        },
        lastUpdated: Date.now(),
        isFetching: false
      });

    default:
      return state;
  }
};

exports.default = _default;