"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EVENT_REPORT_COMMENT_FAILURE = exports.EVENT_REPORT_COMMENT_SUCCESS = exports.EVENT_REPORT_COMMENT_REQUEST = void 0;
var EVENT_REPORT_COMMENT_REQUEST = 'EVENT_REPORT_COMMENT_REQUEST';
exports.EVENT_REPORT_COMMENT_REQUEST = EVENT_REPORT_COMMENT_REQUEST;
var EVENT_REPORT_COMMENT_SUCCESS = 'EVENT_REPORT_COMMENT_SUCCESS';
exports.EVENT_REPORT_COMMENT_SUCCESS = EVENT_REPORT_COMMENT_SUCCESS;
var EVENT_REPORT_COMMENT_FAILURE = 'EVENT_REPORT_COMMENT_FAILURE';
exports.EVENT_REPORT_COMMENT_FAILURE = EVENT_REPORT_COMMENT_FAILURE;