"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetProviders = exports.fetchProviders = void 0;

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

var _actions = require("./actions");

var _constants = require("../../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var instance = _index.default.create({
  baseURL: _constants.API_URL
});

var fetchProviders = function fetchProviders(_ref) {
  var isAuthedUser = _ref.isAuthedUser,
      searchTerm = _ref.searchTerm;
  return function (dispatch) {
    dispatch((0, _actions.fetchProvidersRequest)());
    return instance.get("/v2/".concat(isAuthedUser ? 'private' : 'public', "/preneed/providers?search=").concat(searchTerm)).then(function (res) {
      return dispatch((0, _actions.fetchProvidersSuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.fetchProvidersFailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.fetchProviders = fetchProviders;

var resetProviders = function resetProviders() {
  return function (dispatch) {
    dispatch((0, _actions.resetProvidersAction)());
  };
};

exports.resetProviders = resetProviders;