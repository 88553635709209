import React from 'react';

import { useVerifyCookie } from '../../../customHooks';

import priceIcon from '../../../public/images/icons/get-price-icon.svg';
import quizIcon from '../../../public/images/icons/quiz-icon.svg';
import diagramIcon from '../../../public/images/icons/packages-icon.svg';

import styles from './TripleCTA.module.scss';

const {
  'triple-cta': tripleCTAClass,
  'card-section': cardSectionClass,
  card: cardClass,
  'card-copy': cardCopyClass
} = styles;

const TripleCTA = ({ cardArray }) => {
  const ctaLink = useVerifyCookie('rq-sid') ? '/get-my-price' : 'https://try.everdays.com/plan';

  const cards = [
    {
      img: quizIcon,
      title: 'Find Your Match',
      body: 'Answer 5 easy questions to instantly see the right package for you.',
      cta: {
        text: 'Take Quiz',
        url: 'https://everdays.typeform.com/to/CSHJdReI'
      }
    },
    {
      img: priceIcon,
      title: 'Get Your Price',
      body: 'Enter your age and zip and we’ll instantly show your personalized package pricing.',
      cta: {
        text: 'Get My Price',
        url: ctaLink
      }
    },
    {
      img: diagramIcon,
      title: 'See Our Packages',
      body: 'Check out all of our packages side by side and see all of your options in one place.',
      cta: {
        text: 'See Packages',
        url: '/packages'
      }
    }
  ];
  const renderCard = ({ img, title, body, cta }) => {
    return (
      <div className={cardClass} key={title}>
        <div className={cardCopyClass}>
          <img src={img} alt="illustation" />
          <div>
            <h4>{title}</h4>
            <p>{body}</p>
          </div>
        </div>
        <a href={cta.url}>
          <button>{cta.text}</button>
        </a>
      </div>
    );
  };

  return (
    <div className={tripleCTAClass}>
      <h2>Get started however it’s right for you</h2>
      <div className={cardSectionClass}>{(cardArray || cards).map(card => renderCard(card))}</div>
    </div>
  );
};

export default TripleCTA;
