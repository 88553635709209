"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var editRecipient = [{
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  role: ''
}];
var _default = editRecipient;
exports.default = _default;