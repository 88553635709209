import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NavBar from '../NavBar';

import SiteHero from '../shared/SiteHero';
import ComparePackages from '../ComparePackages';
import FooterSection from '../shared/Footer/FooterSection';
import TermsFooter from '../shared/Footer/TermsFooter';
import Breadcrumbs from '../shared/Breadcrumbs/Breadcrumbs';
import Evelyn from '../shared/Evelyn';

import { useScrollToTop } from '@evdy/web-core/dist/customHooks';
import { preneedPackagesOperations } from '@evdy/web-redux/dist/lib/webPurchase/preneedPackages';
import { useHideIntercom, useVerifyCookie } from '../../customHooks';

import styles from './AllPackages.module.scss';

import curvedArrow from '../../public/images/large-grey-curved-arrow-down-right.svg';
import navyCircleCheck from '../../public/images/icons/navy-circle-check.svg';
import priceIcon from '../../public/images/price.svg';
import quizIcon from '../../public/images/quiz.svg';
import phoneIcon from '../../public/images/phone-contact-us.svg';
import surveyPreview from '../../public/images/evelyn-survey-preview.png';
import packageSummary from '../../public/images/personal-package-summary.png';
import flowerAccent from '../../public/images/all-packages-pdf-accent-flower.svg';
import accentOval from '../../public/images/all-packages-pdf-accent.svg';

const {
  'packages-page': packagesPageClass,
  breadcrumb: breadcrumbClass,
  questionnaire: questionnaireClass,
  evelyn: evelynClass,
  'mobile-hidden': mobileHiddenClass,
  cta: ctaClass,
  'package-summary-pdf': packageSummaryPDFClass,
  'card-content': cardContentClass,
  accent: accentClass,
  'flower-accent': flowerAccentClass,
  'button-link': buttonLinkClass
} = styles;

const { getPreneedPackages } = preneedPackagesOperations;

const AllPackages = ({ location }) => {
  const { preneedPackages = [] } = useSelector(({ webPurchase }) => ({
    preneedPackages: webPurchase.preneedPackages.data?.packages
  }));

  const dispatch = useDispatch();

  //get preneed packages
  useEffect(() => {
    if (!preneedPackages?.length) {
      dispatch(getPreneedPackages());
    }
  }, [preneedPackages.length]);

  //scroll to top
  useScrollToTop();

  //hide intercom
  useHideIntercom();

  const sectionInfoInclusiveServices = [
    {
      title: 'Professional guidance',
      subtitle:
        'Funeral home professionals will support your family, guide them through the services, and assist with administrative and other necessary tasks.',
      imgSrc: navyCircleCheck
    },
    {
      title: 'Digital services',
      subtitle:
        'The Everdays digital invitations and virtual guest book give everyone a way to connect and share support from anywhere.',
      imgSrc: navyCircleCheck
    },
    {
      title: 'Cremation & urn',
      subtitle:
        'All of our packages include cremation, with compassionate care provided from the time of retrieval through return of remains in an urn chosen by your family.*',
      imgSrc: navyCircleCheck
    }
  ];

  const crumbTrail = [
    { pageName: 'Home', href: '/' },
    { pageName: 'Packages', href: '/packages' }
  ];

  const ctaCards = [
    {
      img: priceIcon,
      title: 'Get A Price Estimate',
      body: 'Enter your age and zip and we’ll instantly email you a personalized quote.',
      cta: {
        text: 'Get Estimate',
        url: '/get-my-price'
      }
    },
    {
      img: quizIcon,
      title: 'Discover What’s for You',
      body: "Answer five simple questions and find the experience that's right for you.",
      cta: {
        text: 'Get Started',
        url: 'https://everdays.typeform.com/to/CSHJdReI'
      }
    },
    {
      img: phoneIcon,
      title: 'Schedule A Consultation',
      body: 'Our dedicated planning experts are here to provide one-on-one guidance and support.',
      cta: {
        text: 'Set Up Call',
        url: '/schedule-call?UTM_SOURCE=fb_ad'
      }
    }
  ];

  const ctaLink = useVerifyCookie('rq-sid') ? '/get-my-price' : 'https://try.everdays.com/plan';

  return (
    <div className={packagesPageClass}>
      <NavBar {...{ location, ctaLink }} />
      <SiteHero
        heroTitle="We’ve got the funeral or cremation package that’s right for you"
        heroSubtitle="Everything you need. Easy to understand. Delivered by the funeral home of your choice."
        textAlign="left"
        currentPage="packages"
        renderBreadcrumbs={<Breadcrumbs {...{ crumbTrail }} customClass={breadcrumbClass} />}
      />
      <ComparePackages
        {...{ preneedPackages }}
        title="Turnkey packages. Designed for you."
        downloadPdfUrl="https://try.everdays.com/packages/"
      />
      <div className={questionnaireClass}>
        <div className={mobileHiddenClass}>
          <img src={surveyPreview} alt="questionnaire preview" />
        </div>
        <div>
          <Evelyn
            alignBubbles
            customClass={evelynClass}
            speechBubbleArray={[
              'Not sure which of our packages is for you?',
              'Answer 5 easy questions to find your match.'
            ]}
          />
          <a href="https://everdays.typeform.com/to/CSHJdReI" className={buttonLinkClass}>
            <button className={ctaClass}>Take Our Quiz</button>
          </a>
        </div>
      </div>
      <div className={packageSummaryPDFClass}>
        <div className={cardContentClass}>
          <img className={accentClass} src={accentOval} alt="accent" />
          <img src={packageSummary} alt="list of offered packages" />
          <h3>Print out the package details for easy sharing with your family</h3>
          <a href="https://downloads.everdays.com/packages/" className={buttonLinkClass}>
            <button className={ctaClass}>Get the Printable PDF</button>
          </a>
          <img className={flowerAccentClass} src={flowerAccent} alt="flower accent" />
        </div>
      </div>
      <FooterSection />
    </div>
  );
};

export default AllPackages;
