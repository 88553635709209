"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESET_INVITE_LINK = exports.GET_INVITE_LINK_FAILURE = exports.GET_INVITE_LINK_SUCCESS = exports.GET_INVITE_LINK_REQUEST = exports.TRACK_ACCESSOR_APP_FAILURE = exports.TRACK_ACCESSOR_APP_SUCCESS = exports.TRACK_ACCESSOR_APP_REQUEST = exports.LOG_ADS_TRACKING_FAILURE = exports.LOG_ADS_TRACKING_SUCCESS = exports.LOG_ADS_TRACKING_REQUEST = exports.REMOVE_COMMENT_SUCCESS = exports.REMOVE_COMMENT_FAILURE = exports.REMOVE_COMMENT_REQUEST = exports.RESET_COMMENT = exports.POST_COMMUNITY_COMMENT_SUCCESS = exports.POST_COMMUNITY_COMMENT_FAILURE = exports.POST_COMMUNITY_COMMENT_REQUEST = exports.SET_SHOW_MORE_MESSAGES = exports.RESET_MESSAGE_STAGING = exports.STAGE_MESSAGE_VALUES = exports.REMOVE_MESSAGE_SUCCESS = exports.REMOVE_MESSAGE_FAILURE = exports.REMOVE_MESSAGE_REQUEST = exports.RESET_MESSAGE = exports.LEAVE_MESSAGE_SUCCESS = exports.LEAVE_MESSAGE_FAILURE = exports.LEAVE_MESSAGE_REQUEST = exports.FETCH_WEB_COMMUNITY_SUCCESS = exports.FETCH_WEB_COMMUNITY_FAILURE = exports.FETCH_WEB_COMMUNITY_REQUEST = void 0;
// Community
var FETCH_WEB_COMMUNITY_REQUEST = 'FETCH_WEB_COMMUNITY_REQUEST';
exports.FETCH_WEB_COMMUNITY_REQUEST = FETCH_WEB_COMMUNITY_REQUEST;
var FETCH_WEB_COMMUNITY_FAILURE = 'FETCH_WEB_COMMUNITY_FAILURE';
exports.FETCH_WEB_COMMUNITY_FAILURE = FETCH_WEB_COMMUNITY_FAILURE;
var FETCH_WEB_COMMUNITY_SUCCESS = 'FETCH_WEB_COMMUNITY_SUCCESS'; // Messages

exports.FETCH_WEB_COMMUNITY_SUCCESS = FETCH_WEB_COMMUNITY_SUCCESS;
var LEAVE_MESSAGE_REQUEST = 'LEAVE_MESSAGE_REQUEST';
exports.LEAVE_MESSAGE_REQUEST = LEAVE_MESSAGE_REQUEST;
var LEAVE_MESSAGE_FAILURE = 'LEAVE_MESSAGE_FAILURE';
exports.LEAVE_MESSAGE_FAILURE = LEAVE_MESSAGE_FAILURE;
var LEAVE_MESSAGE_SUCCESS = 'LEAVE_MESSAGE_SUCCESS';
exports.LEAVE_MESSAGE_SUCCESS = LEAVE_MESSAGE_SUCCESS;
var RESET_MESSAGE = 'RESET_MESSAGE';
exports.RESET_MESSAGE = RESET_MESSAGE;
var REMOVE_MESSAGE_REQUEST = 'REMOVE_MESSAGE_REQUEST';
exports.REMOVE_MESSAGE_REQUEST = REMOVE_MESSAGE_REQUEST;
var REMOVE_MESSAGE_FAILURE = 'REMOVE_MESSAGE_FAILURE';
exports.REMOVE_MESSAGE_FAILURE = REMOVE_MESSAGE_FAILURE;
var REMOVE_MESSAGE_SUCCESS = 'REMOVE_MESSAGE_SUCCESS';
exports.REMOVE_MESSAGE_SUCCESS = REMOVE_MESSAGE_SUCCESS;
var STAGE_MESSAGE_VALUES = 'STAGE_MESSAGE_VALUES';
exports.STAGE_MESSAGE_VALUES = STAGE_MESSAGE_VALUES;
var RESET_MESSAGE_STAGING = 'RESET_MESSAGE_STAGING';
exports.RESET_MESSAGE_STAGING = RESET_MESSAGE_STAGING;
var SET_SHOW_MORE_MESSAGES = 'SET_SHOW_MORE_MESSAGES'; // Comments

exports.SET_SHOW_MORE_MESSAGES = SET_SHOW_MORE_MESSAGES;
var POST_COMMUNITY_COMMENT_REQUEST = 'POST_COMMUNITY_COMMENT_REQUEST';
exports.POST_COMMUNITY_COMMENT_REQUEST = POST_COMMUNITY_COMMENT_REQUEST;
var POST_COMMUNITY_COMMENT_FAILURE = 'POST_COMMUNITY_COMMENT_FAILURE';
exports.POST_COMMUNITY_COMMENT_FAILURE = POST_COMMUNITY_COMMENT_FAILURE;
var POST_COMMUNITY_COMMENT_SUCCESS = 'POST_COMMUNITY_COMMENT_SUCCESS';
exports.POST_COMMUNITY_COMMENT_SUCCESS = POST_COMMUNITY_COMMENT_SUCCESS;
var RESET_COMMENT = 'RESET_COMMENT';
exports.RESET_COMMENT = RESET_COMMENT;
var REMOVE_COMMENT_REQUEST = 'REMOVE_COMMENT_REQUEST';
exports.REMOVE_COMMENT_REQUEST = REMOVE_COMMENT_REQUEST;
var REMOVE_COMMENT_FAILURE = 'REMOVE_COMMENT_FAILURE';
exports.REMOVE_COMMENT_FAILURE = REMOVE_COMMENT_FAILURE;
var REMOVE_COMMENT_SUCCESS = 'REMOVE_COMMENT_SUCCESS'; // Ads

exports.REMOVE_COMMENT_SUCCESS = REMOVE_COMMENT_SUCCESS;
var LOG_ADS_TRACKING_REQUEST = 'LOG_ADS_TRACKING_REQUEST';
exports.LOG_ADS_TRACKING_REQUEST = LOG_ADS_TRACKING_REQUEST;
var LOG_ADS_TRACKING_SUCCESS = 'LOG_ADS_TRACKING_SUCCESS';
exports.LOG_ADS_TRACKING_SUCCESS = LOG_ADS_TRACKING_SUCCESS;
var LOG_ADS_TRACKING_FAILURE = 'LOG_ADS_TRACKING_FAILURE'; // Tracking

exports.LOG_ADS_TRACKING_FAILURE = LOG_ADS_TRACKING_FAILURE;
var TRACK_ACCESSOR_APP_REQUEST = 'TRACK_ACCESSOR_APP_REQUEST';
exports.TRACK_ACCESSOR_APP_REQUEST = TRACK_ACCESSOR_APP_REQUEST;
var TRACK_ACCESSOR_APP_SUCCESS = 'TRACK_ACCESSOR_APP_SUCCESS';
exports.TRACK_ACCESSOR_APP_SUCCESS = TRACK_ACCESSOR_APP_SUCCESS;
var TRACK_ACCESSOR_APP_FAILURE = 'TRACK_ACCESSOR_APP_FAILURE'; // Invite Link

exports.TRACK_ACCESSOR_APP_FAILURE = TRACK_ACCESSOR_APP_FAILURE;
var GET_INVITE_LINK_REQUEST = 'GET_INVITE_LINK_REQUEST';
exports.GET_INVITE_LINK_REQUEST = GET_INVITE_LINK_REQUEST;
var GET_INVITE_LINK_SUCCESS = 'GET_INVITE_LINK_SUCCESS';
exports.GET_INVITE_LINK_SUCCESS = GET_INVITE_LINK_SUCCESS;
var GET_INVITE_LINK_FAILURE = 'GET_INVITE_LINK_FAILURE';
exports.GET_INVITE_LINK_FAILURE = GET_INVITE_LINK_FAILURE;
var RESET_INVITE_LINK = 'RESET_INVITE_LINK';
exports.RESET_INVITE_LINK = RESET_INVITE_LINK;