import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/browser';

import ErrorPage from '@evdy/web-core/dist/components/ErrorPage';

import '@evdy/web-core/dist/sass/main.css';
import 'react-toastify/dist/ReactToastify.min.css'; // base toast styling
import '@evdy/web-share/src/styles/Toast.scss'; // custom toast styling

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidMount() {
    // TODO: remove this later / test w/ new Sentry SDK
    global.window &&
      window.addEventListener('unhandledrejection', function(e) {
        window.Sentry && window.Sentry.captureException(e && e.detail && e.detail.reason);
      });
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    console.error({ error, errorInfo });

    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    });

    Sentry.captureException(error);
  }

  render() {
    if (this.state.error) {
      return <ErrorPage />;
    } else {
      return (
        <div className="full">
          {this.props.children}
          <ToastContainer />
        </div>
      );
    }
  }
}

export default App;
