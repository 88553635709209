"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _redux = require("redux");

var _reactRouterRedux = require("react-router-redux");

var _fetchMemorialNotice = _interopRequireDefault(require("./fetchMemorialNotice"));

var _fetchUser = _interopRequireDefault(require("./internalDash/fetchUser"));

var _fetchClientConfig = _interopRequireDefault(require("./fetchClientConfig"));

var _fetchFuneralHomes = _interopRequireDefault(require("./fetchFuneralHomes"));

var _updateConventionForm = _interopRequireDefault(require("./updateConventionForm"));

var _submitSignup = _interopRequireDefault(require("./submitSignup"));

var _submitInvite = _interopRequireDefault(require("./invite/submitInvite"));

var _newsletterSignup = _interopRequireDefault(require("./pages/newsletterSignup"));

var _reducers = _interopRequireDefault(require("../lib/inspirationSignup/reducers"));

var _searchMemorials = _interopRequireDefault(require("./pages/searchMemorials"));

var _sendMessage = _interopRequireDefault(require("./sendMessage"));

var _messages = _interopRequireDefault(require("./messages"));

var _sentMessages = _interopRequireDefault(require("./sentMessages"));

var _mostRecentBotMsg = _interopRequireDefault(require("./mostRecentBotMsg"));

var _dash = _interopRequireDefault(require("./dash"));

var _internalDash = _interopRequireDefault(require("./internalDash"));

var _track = _interopRequireDefault(require("./track"));

var _rsvp = _interopRequireDefault(require("./rsvp"));

var _recordMobileOS = _interopRequireDefault(require("./recordMobileOS"));

var _announcement = _interopRequireDefault(require("./announcement"));

var _fetchInbox = _interopRequireDefault(require("./webAnnouncement/fetchInbox"));

var _chat = _interopRequireDefault(require("./webAnnouncement/chat"));

var _decedentData = _interopRequireDefault(require("./webAnnouncement/decedentData"));

var _familyUpdates = _interopRequireDefault(require("./webAnnouncement/familyUpdates"));

var _userImages = _interopRequireDefault(require("./webAnnouncement/userImages"));

var _webAnnouncement = _interopRequireDefault(require("./webAnnouncement/webAnnouncement"));

var _checkShellUser = _interopRequireDefault(require("./webAnnouncement/checkShellUser"));

var _reducers2 = _interopRequireDefault(require("../lib/webAnnouncement/setShellPassword/reducers"));

var _jobs = _interopRequireDefault(require("./pages/jobs"));

var _talentNetworkSignup = _interopRequireDefault(require("./pages/talentNetworkSignup"));

var _HomeDirectory = _interopRequireDefault(require("./HomeDirectory"));

var _reducers3 = require("../lib/googleAddress/reducers");

var _reducers4 = require("../lib/downloadMedia/reducers");

var _reducers5 = require("../lib/journeyLog/reducers");

var _demoInvitePage = _interopRequireDefault(require("../lib/demoInvitePage"));

var _reducers6 = _interopRequireDefault(require("../lib/authUser/reducers"));

var _invitePage = _interopRequireDefault(require("../lib/invitePage"));

var _reducer = _interopRequireDefault(require("../lib/photoUpload/reducer"));

var _inviteCreation = _interopRequireDefault(require("../lib/inviteCreation"));

var _inviteDetails = _interopRequireDefault(require("../lib/inviteDetails"));

var _reducers7 = require("../lib/pendingAction/reducers");

var _requestDemoEmail = _interopRequireDefault(require("./requestDemoEmail"));

var _pageSize = _interopRequireDefault(require("../lib/pageSize"));

var _requestApp = _interopRequireDefault(require("../lib/requestApp"));

var _funeralHome = _interopRequireDefault(require("../lib/funeralHome"));

var _demoLiveStream = _interopRequireDefault(require("../lib/demoLiveStream"));

var _requiredFields = _interopRequireDefault(require("../lib/requiredFields"));

var _socketioHandler = _interopRequireDefault(require("../lib/socketioHandler"));

var _webPurchase = _interopRequireDefault(require("../lib/webPurchase"));

var _webCommunity = _interopRequireDefault(require("../lib/webCommunity"));

var _klaviyoNewsletter = _interopRequireDefault(require("../lib/klaviyoNewsletter"));

var _shareEstimates = _interopRequireDefault(require("../lib/shareEstimates"));

var _forms = _interopRequireDefault(require("./forms"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Combine reducers with routeReducer which keeps track of
// router state
var rootReducer = (0, _redux.combineReducers)({
  routing: _reactRouterRedux.routerReducer,
  memorial: _fetchMemorialNotice.default,
  user: _fetchUser.default,
  client: _fetchClientConfig.default,
  funeral: _fetchFuneralHomes.default,
  conForm: _updateConventionForm.default,
  submitSignup: _submitSignup.default,
  submitInvite: _submitInvite.default,
  newsletterSignup: _newsletterSignup.default,
  inspirationSignup: _reducers.default,
  searchedMemorials: _searchMemorials.default,
  sendMessage: _sendMessage.default,
  messages: _messages.default,
  sentMessages: _sentMessages.default,
  mostRecentBotMsg: _mostRecentBotMsg.default,
  dash: _dash.default,
  internalDash: _internalDash.default,
  track: _track.default,
  rsvp: _rsvp.default,
  mobileOS: _recordMobileOS.default,
  chat: _chat.default,
  decedentData: _decedentData.default,
  familyUpdates: _familyUpdates.default,
  userImages: _userImages.default,
  webAnnouncement: _webAnnouncement.default,
  checkShellUser: _checkShellUser.default,
  setShellPassword: _reducers2.default,
  announcement: _announcement.default,
  fetchInbox: _fetchInbox.default,
  jobs: _jobs.default,
  talentNetworkSignup: _talentNetworkSignup.default,
  homeDirectory: _HomeDirectory.default,
  fetchedGoogleAddress: _reducers3.fetchedGoogleAddress,
  downloadMedia: _reducers4.downloadMedia,
  fetchedJourneyLog: _reducers5.fetchedJourneyLog,
  demoInvitePage: _demoInvitePage.default,
  authUser: _reducers6.default,
  invitePage: _invitePage.default,
  photoUpload: _reducer.default,
  inviteCreation: _inviteCreation.default,
  inviteDetails: _inviteDetails.default,
  pendingAction: _reducers7.pendingAction,
  requestDemoEmail: _requestDemoEmail.default,
  pageSize: _pageSize.default,
  requestAppLink: _requestApp.default,
  funeralHome: _funeralHome.default,
  demoLiveStream: _demoLiveStream.default,
  requiredPreneedFields: _requiredFields.default,
  socketioHandler: _socketioHandler.default,
  webPurchase: _webPurchase.default,
  webCommunity: _webCommunity.default,
  klaviyoNewsletter: _klaviyoNewsletter.default,
  shareEstimates: _shareEstimates.default,
  forms: _forms.default
});
var _default = rootReducer;
exports.default = _default;