"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EDIT_RESOURCE_FAILURE = exports.EDIT_RESOURCE_SUCCESS = exports.EDIT_RESOURCE_REQUEST = exports.ADD_RESOURCE_FAILURE = exports.ADD_RESOURCE_SUCCESS = exports.ADD_RESOURCE_REQUEST = exports.FETCH_RESOURCES_FAILURE = exports.FETCH_RESOURCES_SUCCESS = exports.FETCH_RESOURCES_REQUEST = void 0;
var FETCH_RESOURCES_REQUEST = 'FETCH_RESOURCES_REQUEST';
exports.FETCH_RESOURCES_REQUEST = FETCH_RESOURCES_REQUEST;
var FETCH_RESOURCES_SUCCESS = 'FETCH_RESOURCES_SUCCESS';
exports.FETCH_RESOURCES_SUCCESS = FETCH_RESOURCES_SUCCESS;
var FETCH_RESOURCES_FAILURE = 'FETCH_RESOURCES_FAILURE';
exports.FETCH_RESOURCES_FAILURE = FETCH_RESOURCES_FAILURE;
var ADD_RESOURCE_REQUEST = 'ADD_RESOURCE_REQUEST';
exports.ADD_RESOURCE_REQUEST = ADD_RESOURCE_REQUEST;
var ADD_RESOURCE_SUCCESS = 'ADD_RESOURCE_SUCCESS';
exports.ADD_RESOURCE_SUCCESS = ADD_RESOURCE_SUCCESS;
var ADD_RESOURCE_FAILURE = 'ADD_RESOURCE_FAILURE';
exports.ADD_RESOURCE_FAILURE = ADD_RESOURCE_FAILURE;
var EDIT_RESOURCE_REQUEST = 'EDIT_RESOURCE_REQUEST';
exports.EDIT_RESOURCE_REQUEST = EDIT_RESOURCE_REQUEST;
var EDIT_RESOURCE_SUCCESS = 'EDIT_RESOURCE_SUCCESS';
exports.EDIT_RESOURCE_SUCCESS = EDIT_RESOURCE_SUCCESS;
var EDIT_RESOURCE_FAILURE = 'EDIT_RESOURCE_FAILURE';
exports.EDIT_RESOURCE_FAILURE = EDIT_RESOURCE_FAILURE;