"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFocusInput = void 0;

var _react = require("react");

var _reactRedux = require("react-redux");

var _Input = require("@evdy/web-core/dist/components/shared/Input");

/**
 * focus input of model/inputModel
 * used when wanting to focus a certain form input
 * if form is on page, use the effect in form, if it's open in modal the focus needs to be called
 * after any RRF reset()
 * @param {String} model RRF form model to use
 * @param {String} inputModel input field model to use ig: 'name.first'
 * @param {Boolean} modalVisible comes from the current state of the modal - optional - for when focusing forms that are mounted but open in modal
 */
var useFocusInput = function useFocusInput(_ref) {
  var model = _ref.model,
      inputModel = _ref.inputModel,
      modalVisible = _ref.modalVisible;
  var dispatch = (0, _reactRedux.useDispatch)();
  (0, _react.useEffect)(function () {
    dispatch(_Input.formActions.focus("".concat(model, ".").concat(inputModel)));
  }, [dispatch, inputModel, model, modalVisible]);
};

exports.useFocusInput = useFocusInput;