"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.anncReset = exports.REMOVE_DONATION = exports.EDIT_DONATION = exports.PUSH_DONATION = exports.SET_DONATIONS = exports.DEMOTE_MEMBER_BY_ID = exports.DEMOTE_MEMBER = exports.REMOVE_MEMBER = exports.EDIT_MEMBER = exports.PUSH_MEMBER = exports.SET_MEMBERS = exports.REMOVE_EVENT = exports.EDIT_EVENT = exports.PUSH_EVENT = exports.SET_EVENTS = void 0;
var SET_EVENTS = 'SET_EVENTS';
exports.SET_EVENTS = SET_EVENTS;
var PUSH_EVENT = 'PUSH_EVENT';
exports.PUSH_EVENT = PUSH_EVENT;
var EDIT_EVENT = 'EDIT_EVENT';
exports.EDIT_EVENT = EDIT_EVENT;
var REMOVE_EVENT = 'REMOVE_EVENT';
exports.REMOVE_EVENT = REMOVE_EVENT;
var SET_MEMBERS = 'SET_MEMBERS';
exports.SET_MEMBERS = SET_MEMBERS;
var PUSH_MEMBER = 'PUSH_MEMBER';
exports.PUSH_MEMBER = PUSH_MEMBER;
var EDIT_MEMBER = 'EDIT_MEMBER';
exports.EDIT_MEMBER = EDIT_MEMBER;
var REMOVE_MEMBER = 'REMOVE_MEMBER';
exports.REMOVE_MEMBER = REMOVE_MEMBER;
var DEMOTE_MEMBER = 'DEMOTE_MEMBER';
exports.DEMOTE_MEMBER = DEMOTE_MEMBER;
var DEMOTE_MEMBER_BY_ID = 'DEMOTE_MEMBER_BY_ID';
exports.DEMOTE_MEMBER_BY_ID = DEMOTE_MEMBER_BY_ID;
var SET_DONATIONS = 'SET_DONATIONS';
exports.SET_DONATIONS = SET_DONATIONS;
var PUSH_DONATION = 'PUSH_DONATION';
exports.PUSH_DONATION = PUSH_DONATION;
var EDIT_DONATION = 'EDIT_DONATION';
exports.EDIT_DONATION = EDIT_DONATION;
var REMOVE_DONATION = 'REMOVE_DONATION';
exports.REMOVE_DONATION = REMOVE_DONATION;
var anncReset = 'ANNC_RESET';
exports.anncReset = anncReset;