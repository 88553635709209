"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.signOut = exports.resetError = exports.resetMeta = exports.updatePassword = exports.resetPassword = exports.resetPasswordFailure = exports.resetPasswordSuccess = exports.resetPasswordRequest = exports.appSignup = exports.appSignupFailure = exports.appSignupSuccess = exports.appSignupRequest = exports.fbLogin = exports.fbLoginFailure = exports.fbLoginSuccess = exports.fbLoginRequest = exports.simpleSignupWithPassword = exports.simpleSignupWithPasswordFailure = exports.simpleSignupWithPasswordSuccess = exports.simpleSignupWithPasswordRequest = exports.simpleSignup = exports.simpleSignupFailure = exports.simpleSignupSuccess = exports.simpleSignupRequest = exports.appLogin = exports.appLoginFailure = exports.appLoginSuccess = exports.appLoginRequest = exports.dashLogin = exports.dashLoginFailure = exports.dashLoginSuccess = exports.dashLoginRequest = void 0;

require("core-js/modules/es6.function.name.js");

var types = _interopRequireWildcard(require("../../constants/dash/user"));

var _constants = require("../../constants");

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var instance = _index.default.create({
  baseURL: _constants.API_URL,
  headers: {
    Accept: 'application/vnd.everdays+json; v=20171228'
  }
});
/**
 * Dash Login
 */


var dashLoginRequest = function dashLoginRequest() {
  return {
    type: types.DASH_LOGIN_REQUEST
  };
};

exports.dashLoginRequest = dashLoginRequest;

var dashLoginSuccess = function dashLoginSuccess(_ref) {
  var data = _ref.data;
  return {
    type: types.DASH_LOGIN_SUCCESS,
    payload: data.data,
    receivedAt: Date.now()
  };
};

exports.dashLoginSuccess = dashLoginSuccess;

var dashLoginFailure = function dashLoginFailure(error) {
  return {
    type: types.DASH_LOGIN_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.dashLoginFailure = dashLoginFailure;

var dashLogin = function dashLogin(data) {
  return function (dispatch) {
    dispatch(dashLoginRequest());
    return instance.post('/auth/dash-login', data).then(function (res) {
      return dispatch(dashLoginSuccess(res));
    }).catch(function (error) {
      return dispatch(dashLoginFailure((0, _transformAxiosError.default)(error)));
    });
  };
};
/**
 * App-Style Login
 */


exports.dashLogin = dashLogin;

var appLoginRequest = function appLoginRequest() {
  return {
    type: types.APP_LOGIN_REQUEST
  };
};

exports.appLoginRequest = appLoginRequest;

var appLoginSuccess = function appLoginSuccess(_ref2) {
  var data = _ref2.data;
  return {
    type: types.APP_LOGIN_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.appLoginSuccess = appLoginSuccess;

var appLoginFailure = function appLoginFailure(error) {
  return {
    type: types.APP_LOGIN_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.appLoginFailure = appLoginFailure;

var appLogin = function appLogin(_ref3) {
  var email = _ref3.email,
      password = _ref3.password;
  return function (dispatch) {
    dispatch(appLoginRequest());
    return instance.post('/auth/login', {
      email: email,
      password: password,
      fromWeb: true
    }).then(function (res) {
      return dispatch(appLoginSuccess(res.data));
    }).catch(function (error) {
      return dispatch(appLoginFailure((0, _transformAxiosError.default)(error)));
    });
  };
};
/**
 * Name + Email Sign Up
 */


exports.appLogin = appLogin;

var simpleSignupRequest = function simpleSignupRequest() {
  return {
    type: types.SIMPLE_SIGNUP_REQUEST
  };
};

exports.simpleSignupRequest = simpleSignupRequest;

var simpleSignupSuccess = function simpleSignupSuccess(_ref4) {
  var data = _ref4.data;
  return {
    type: types.SIMPLE_SIGNUP_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.simpleSignupSuccess = simpleSignupSuccess;

var simpleSignupFailure = function simpleSignupFailure(error) {
  return {
    type: types.SIMPLE_SIGNUP_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.simpleSignupFailure = simpleSignupFailure;

var simpleSignup = function simpleSignup(email, name, mfollow) {
  var shouldFollow = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  var tosAgree = arguments.length > 4 ? arguments[4] : undefined;
  return function (dispatch) {
    dispatch(simpleSignupRequest());
    return instance.post('/auth/signup-email-name', {
      name: {
        full: name
      },
      email: email,
      mfollow: mfollow,
      shouldFollow: shouldFollow,
      tosAgree: tosAgree
    }).then(function (res) {
      return dispatch(simpleSignupSuccess(res.data));
    }).catch(function (error) {
      return dispatch(simpleSignupFailure((0, _transformAxiosError.default)(error)));
    });
  };
};
/**
 * Name + Email + Password Sign Up
 */


exports.simpleSignup = simpleSignup;

var simpleSignupWithPasswordRequest = function simpleSignupWithPasswordRequest() {
  return {
    type: types.SIMPLE_SIGNUP_WITH_PASSWORD_REQUEST
  };
};

exports.simpleSignupWithPasswordRequest = simpleSignupWithPasswordRequest;

var simpleSignupWithPasswordSuccess = function simpleSignupWithPasswordSuccess(_ref5) {
  var data = _ref5.data;
  return {
    type: types.SIMPLE_SIGNUP_WITH_PASSWORD_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.simpleSignupWithPasswordSuccess = simpleSignupWithPasswordSuccess;

var simpleSignupWithPasswordFailure = function simpleSignupWithPasswordFailure(error) {
  return {
    type: types.SIMPLE_SIGNUP_WITH_PASSWORD_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.simpleSignupWithPasswordFailure = simpleSignupWithPasswordFailure;

var simpleSignupWithPassword = function simpleSignupWithPassword(email, name, password, mfollow) {
  var shouldFollow = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
  var tosAgree = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : true;
  return function (dispatch) {
    dispatch(simpleSignupWithPasswordRequest());
    return instance.post('/auth/signup-email-name-password', {
      name: {
        full: name
      },
      email: email,
      password: password,
      mfollow: mfollow,
      shouldFollow: shouldFollow,
      tosAgree: tosAgree
    }).then(function (res) {
      dispatch(simpleSignupWithPasswordSuccess(res.data));
    }).catch(function (error) {
      return dispatch(simpleSignupWithPasswordFailure((0, _transformAxiosError.default)(error)));
    });
  };
};
/**
 * FB Login / Signup
 */


exports.simpleSignupWithPassword = simpleSignupWithPassword;

var fbLoginRequest = function fbLoginRequest() {
  return {
    type: types.FB_LOGIN_REQUEST
  };
};

exports.fbLoginRequest = fbLoginRequest;

var fbLoginSuccess = function fbLoginSuccess(_ref6) {
  var data = _ref6.data;
  return {
    type: types.FB_LOGIN_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.fbLoginSuccess = fbLoginSuccess;

var fbLoginFailure = function fbLoginFailure(error) {
  return {
    type: types.FB_LOGIN_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.fbLoginFailure = fbLoginFailure;

var fbLogin = function fbLogin(access_token, fbId) {
  var userKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var fromDash = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  return function (dispatch) {
    dispatch(fbLoginRequest());
    return instance.post('/auth/fb', {
      access_token: access_token,
      user: {
        fbId: fbId
      },
      fromWeb: true,
      userKey: userKey,
      fromDash: fromDash
    }).then(function (res) {
      return dispatch(fbLoginSuccess(res.data));
    }).catch(function (error) {
      return dispatch(fbLoginFailure((0, _transformAxiosError.default)(error)));
    });
  };
};
/**
 * App-Style Signup
 */


exports.fbLogin = fbLogin;

var appSignupRequest = function appSignupRequest() {
  return {
    type: types.APP_SIGNUP_REQUEST
  };
};

exports.appSignupRequest = appSignupRequest;

var appSignupSuccess = function appSignupSuccess(_ref7) {
  var data = _ref7.data;
  return {
    type: types.APP_SIGNUP_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.appSignupSuccess = appSignupSuccess;

var appSignupFailure = function appSignupFailure(error) {
  return {
    type: types.APP_SIGNUP_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.appSignupFailure = appSignupFailure;

var appSignup = function appSignup(_ref8) {
  var name = _ref8.name,
      phone = _ref8.phone,
      email = _ref8.email,
      password = _ref8.password;
  return function (dispatch) {
    dispatch(appSignupRequest());
    return instance.post('/auth/signup', {
      name: name,
      phone: phone,
      email: email,
      password: password
    }).then(function (res) {
      return dispatch(appSignupSuccess(res.data));
    }).catch(function (error) {
      return dispatch(appSignupFailure((0, _transformAxiosError.default)(error)));
    });
  };
};
/**
 * Password Reset
 */


exports.appSignup = appSignup;

var resetPasswordRequest = function resetPasswordRequest() {
  return {
    type: types.RESET_PASSWORD_REQUEST
  };
};

exports.resetPasswordRequest = resetPasswordRequest;

var resetPasswordSuccess = function resetPasswordSuccess(_ref9) {
  var data = _ref9.data;
  return {
    type: types.RESET_PASSWORD_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.resetPasswordSuccess = resetPasswordSuccess;

var resetPasswordFailure = function resetPasswordFailure(error) {
  return {
    type: types.RESET_PASSWORD_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.resetPasswordFailure = resetPasswordFailure;

var resetPassword = function resetPassword(data) {
  return function (dispatch) {
    dispatch(resetPasswordRequest());
    return instance.post('v2/public/user/reset-password', data).then(function (res) {
      return dispatch(resetPasswordSuccess(res));
    }).catch(function (error) {
      return dispatch(resetPasswordFailure((0, _transformAxiosError.default)(error)));
    });
  };
};
/**
 * Password Update
 */


exports.resetPassword = resetPassword;

var updatePassword = function updatePassword(password, s_token) {
  return function (dispatch) {
    dispatch(resetPasswordRequest());
    return instance.put('/v2/private/user', {
      dashPassword: password
    }, {
      params: {
        s_token: s_token
      }
    }).then(function (res) {
      return dispatch(resetPasswordSuccess(res));
    }).catch(function (error) {
      return dispatch(resetPasswordFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.updatePassword = updatePassword;

var resetMeta = function resetMeta() {
  return {
    type: types.RESET_META
  };
};

exports.resetMeta = resetMeta;

var resetError = function resetError() {
  return {
    type: types.RESET_ERROR
  };
};
/**
 * Sign Out
 */


exports.resetError = resetError;
var staleCookie = 'rq-sid=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';

var signOut = function signOut(redirect) {
  return function (dispatch) {
    document.cookie = process.env.NODE_ENV === 'production' ? staleCookie + '; domain=.everdays.com' : staleCookie;
    _index.default.defaults.headers.common['Cookie'] = '';
    window.Sentry && window.Sentry.configureScope(function (scope) {
      return scope.setUser({});
    });
    dispatch({
      type: 'RESET'
    });
    setTimeout(function () {
      return window.location.href = redirect || '/dash/login';
    }, 50);
  };
};

exports.signOut = signOut;