"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.array.from.js");

require("core-js/modules/es6.array.slice.js");

require("core-js/modules/es6.function.name.js");

require("core-js/modules/es6.weak-map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _redux = require("redux");

var _pick = _interopRequireDefault(require("lodash-es/pick"));

var actions = _interopRequireWildcard(require("./constants"));

var _helpers = require("./helpers");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialInviteMetaState = {
  data: {},
  isFetching: false,
  lastUpdated: null,
  error: {}
};
var inviteMetaKeys = ['_id', 'createdByFuneralHome', 'creationDate', 'isFinished', 'renderInvite', 'role', // TODO: not sure where this goes
'shareUrl', 'themeIdx', 'accessors', 'ads', 'donations', 'obituary'];

var inviteMetaReducer = function inviteMetaReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialInviteMetaState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actions.FETCH_WEB_COMMUNITY_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true,
        error: {}
      });

    case actions.FETCH_WEB_COMMUNITY_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: action.error
      });

    case actions.FETCH_WEB_COMMUNITY_SUCCESS:
      var data = (0, _pick.default)(action.payload.data, inviteMetaKeys);
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        data: data,
        error: {}
      });

    default:
      return state;
  }
};

var initialDecedentDetailsState = {
  data: {},
  isFetching: false,
  lastUpdated: null,
  error: {}
};
var decedentDetailsKeys = ['cityOfBirth', 'cityOfDeath', 'dateOfBirth', 'dateOfDeath', 'decedentPhone', 'displayName', 'image', 'name', 'funeralHomeInfo'];

var decedentDetailsReducer = function decedentDetailsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialDecedentDetailsState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actions.FETCH_WEB_COMMUNITY_SUCCESS:
      var data = (0, _pick.default)(action.payload.data, decedentDetailsKeys);
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        data: data,
        error: {}
      });

    default:
      return state;
  }
};

var initialEventsState = {
  array: [],
  serviceStatus: null,
  isFetching: false,
  lastUpdated: null,
  error: {}
};

var eventsReducer = function eventsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialEventsState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actions.FETCH_WEB_COMMUNITY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        array: action.payload.data.services,
        serviceStatus: action.payload.data.serviceStatus,
        error: {}
      });

    default:
      return state;
  }
};

var initialPhotosState = {
  array: [],
  isFetching: false,
  lastUpdated: null,
  error: {},
  commentLeft: false
};

var photosReducer = function photosReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialPhotosState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var _ref = action || {},
      _ref$entityId = _ref.entityId,
      entityId = _ref$entityId === void 0 ? undefined : _ref$entityId,
      _ref$mediaId = _ref.mediaId,
      mediaId = _ref$mediaId === void 0 ? undefined : _ref$mediaId;

  var mediaIdx = (0, _helpers.findItemIdx)(state.array, entityId || mediaId);

  switch (action.type) {
    // Init
    case actions.FETCH_WEB_COMMUNITY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        array: action.payload.data.photos,
        error: {}
      });
    // Reset

    case actions.RESET_COMMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        commentLeft: false
      });

    default:
      return state;
  }
};

var initialMessagesState = {
  array: [],
  messageLeft: false,
  isFetching: false,
  lastUpdated: null,
  error: {},
  commentLeft: false,
  messagesShown: 5
};

var messagesReducer = function messagesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialMessagesState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var _ref2 = action || {},
      _ref2$entityId = _ref2.entityId,
      entityId = _ref2$entityId === void 0 ? undefined : _ref2$entityId,
      _ref2$mediaId = _ref2.mediaId,
      mediaId = _ref2$mediaId === void 0 ? undefined : _ref2$mediaId;

  var mediaIdx = (0, _helpers.findItemIdx)(state.array, entityId || mediaId);

  switch (action.type) {
    // Init
    case actions.FETCH_WEB_COMMUNITY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        array: action.payload.data.condolences,
        error: {}
      });
    // Requests

    case actions.LEAVE_MESSAGE_REQUEST:
    case actions.POST_COMMUNITY_COMMENT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true
      });
    // Failures

    case actions.LEAVE_MESSAGE_FAILURE:
    case actions.POST_COMMUNITY_COMMENT_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: action.error,
        lastUpdated: action.receivedAt
      });
    // Successes

    case actions.LEAVE_MESSAGE_SUCCESS:
      var newMessagesArray = action.payload; // if newMessagesArray is an array - public ep was used and end returned memorial
      // if not array - private ep and just condolence was returned, concat

      var newArray = Array.isArray(newMessagesArray) ? newMessagesArray : [].concat(_toConsumableArray(state.array), [newMessagesArray]);
      return _objectSpread(_objectSpread({}, state), {}, {
        array: newArray,
        isFetching: false,
        lastUpdated: action.receivedAt,
        messageLeft: true
      });

    case actions.POST_COMMUNITY_COMMENT_SUCCESS:
      if (action.mediaType === 'message') {
        var array = (0, _helpers.getNewMediaCommentsArray)({
          mediaData: action.payload,
          stateArray: state.array,
          mediaIdx: mediaIdx
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          isFetching: false,
          lastUpdated: action.receivedAt,
          array: array,
          commentLeft: true
        });
      } else {
        return state;
      }

    // Reset

    case actions.RESET_MESSAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        messageLeft: false
      });

    case actions.SET_SHOW_MORE_MESSAGES:
      return _objectSpread(_objectSpread({}, state), {}, {
        messagesShown: state.messagesShown + action.payload
      });

    case actions.RESET_COMMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        commentLeft: false
      });

    default:
      return state;
  }
};

var initialMessageStagingState = {
  name: '',
  email: '',
  phone: '',
  message: '',
  comment: '',
  userCreated: false,
  sentPin: false,
  verifiedPin: false,
  loginById: false,
  messageSaved: false
};

var messageStagingReducer = function messageStagingReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialMessageStagingState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actions.STAGE_MESSAGE_VALUES:
      return _objectSpread(_objectSpread({}, state), action.payload);

    case actions.RESET_MESSAGE_STAGING:
      return initialMessageStagingState;

    default:
      return state;
  }
};

var initialInviteLinkState = {
  success: false,
  isFetching: false,
  lastUpdated: null,
  error: {}
};

var inviteLinkReducer = function inviteLinkReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialInviteLinkState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actions.GET_INVITE_LINK_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true
      });

    case actions.GET_INVITE_LINK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: action.payload,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: {}
      });

    case actions.GET_INVITE_LINK_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: action.error
      });

    case actions.RESET_INVITE_LINK:
      return _objectSpread({}, initialInviteLinkState);

    default:
      return state;
  }
}; // TODO: maybe split the reducers into separate files?


var _default = (0, _redux.combineReducers)({
  inviteMeta: inviteMetaReducer,
  decedentDetails: decedentDetailsReducer,
  events: eventsReducer,
  photos: photosReducer,
  messages: messagesReducer,
  messageStaging: messageStagingReducer,
  inviteLink: inviteLinkReducer
});

exports.default = _default;