import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { browserHistory } from 'react-router';
import classNames from 'classnames';

import CharacterProfileImage from '@evdy/web-core/dist/components/shared/elements/CharacterProfileImage';
import { nameString } from '@evdy/web-core/dist/lib/utils';

import { signOut } from '@evdy/web-redux/dist/actions/dash/user';

import styles from './SlideOutMenu.module.scss';

import everdaysLogo from '../../../../public/images/logos/everdays-logo-white.svg';
import closeIcon from '../../../../public/images/icons/close-button-icon-white.svg';

const {
  'slide-out-menu': slideOutMenuClass,
  'menu-open': menuOpenClass,
  'menu-header': menuHeaderClass,
  'menu-links': menuLinksClass,
  'menu-link': menuLinkClass,
  'menu-footer': menuFooterClass,
  'user-row': userRowClass,
  'user-img': userImgClass,
  'no-img-profile': noImgProfileClass,
  'user-name': userNameClass
} = styles;

const nonAuthLinks = [
  {
    linkCopy: 'Get help / support',
    href: 'https://help.everdays.com/'
  },
  {
    linkCopy: 'Go to everdays.com',
    href: '/'
  },
  {
    linkCopy: 'Terms of service',
    href: '/terms'
  },
  {
    linkCopy: 'Privacy policy',
    href: '/privacy'
  }
];

const SlideOutMenu = ({ closeMenu, isMenuOpen, baseRoute }) => {
  const { user } = useSelector(({ authUser }) => ({ user: authUser.data }));
  const { _id: userId, name: userName, image: userImg } = user || {};

  const dispatch = useDispatch();

  const menuClasses = classNames({
    [slideOutMenuClass]: slideOutMenuClass,
    [menuOpenClass]: isMenuOpen
  });

  const profileImageClasses = classNames(userImgClass, {
    [noImgProfileClass]: !userImg
  });

  const handleSignOut = () => dispatch(signOut(window.location.href));

  const handleLogInClick = () => browserHistory.push(`${baseRoute}/condolence/login`);

  const renderAuthSection = () => {
    return !!userId ? (
      <div className={userRowClass}>
        <CharacterProfileImage
          image={userImg}
          name={userName}
          customClasses={[profileImageClasses]}
        />
        <div className={userNameClass}>
          <p>{nameString(userName)}</p>
          {/* hide edit profile until future sprint */}
          {/* <span>Edit Profile</span> */}
        </div>
      </div>
    ) : (
      ''
    );
  };

  return (
    <div className={menuClasses}>
      <div className={menuHeaderClass}>
        <img className="logo" src={everdaysLogo} alt="everdays logo" />
        <button onClick={closeMenu}>
          <img className="close" src={closeIcon} alt="close icon" />
        </button>
      </div>
      <div className={menuLinksClass}>
        {renderAuthSection()}
        {nonAuthLinks.map((link, idx) => (
          <a target="_blank" rel="noreferrer" href={link.href} className={menuLinkClass} key={idx}>
            {link.linkCopy}
          </a>
        ))}
        {!!userId && (
          <div onClick={handleSignOut} className={menuLinkClass}>
            Log out
          </div>
        )}
      </div>
      <div className={menuFooterClass}>
        <p>Where people take care of their families, long before they're gone</p>
        <button>
          <a target="_blank" href="/">
            Learn more
          </a>
        </button>
      </div>
    </div>
  );
};

export default SlideOutMenu;
