"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FETCH_DECEDENT_DATA_FAILURE = exports.FETCH_DECEDENT_DATA_SUCCESS = exports.FETCH_DECEDENT_DATA_REQUEST = void 0;
var FETCH_DECEDENT_DATA_REQUEST = 'FETCH_DECEDENT_DATA_REQUEST';
exports.FETCH_DECEDENT_DATA_REQUEST = FETCH_DECEDENT_DATA_REQUEST;
var FETCH_DECEDENT_DATA_SUCCESS = 'FETCH_DECEDENT_DATA_SUCCESS';
exports.FETCH_DECEDENT_DATA_SUCCESS = FETCH_DECEDENT_DATA_SUCCESS;
var FETCH_DECEDENT_DATA_FAILURE = 'FETCH_DECEDENT_DATA_FAILURE';
exports.FETCH_DECEDENT_DATA_FAILURE = FETCH_DECEDENT_DATA_FAILURE;