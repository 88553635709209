"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CREATE_MEDIA_REPORT_COMMENT_FAILURE = exports.CREATE_MEDIA_REPORT_COMMENT_SUCCESS = exports.CREATE_MEDIA_REPORT_COMMENT_REQUEST = void 0;
var CREATE_MEDIA_REPORT_COMMENT_REQUEST = 'CREATE_MEDIA_REPORT_COMMENT_REQUEST';
exports.CREATE_MEDIA_REPORT_COMMENT_REQUEST = CREATE_MEDIA_REPORT_COMMENT_REQUEST;
var CREATE_MEDIA_REPORT_COMMENT_SUCCESS = 'CREATE_MEDIA_REPORT_COMMENT_SUCCESS';
exports.CREATE_MEDIA_REPORT_COMMENT_SUCCESS = CREATE_MEDIA_REPORT_COMMENT_SUCCESS;
var CREATE_MEDIA_REPORT_COMMENT_FAILURE = 'CREATE_MEDIA_REPORT_COMMENT_FAILURE';
exports.CREATE_MEDIA_REPORT_COMMENT_FAILURE = CREATE_MEDIA_REPORT_COMMENT_FAILURE;