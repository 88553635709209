import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { useVerifyCookie } from '../../../../customHooks';

import styles from './PackageHero.module.scss';

import blueCheckMark from '../../../../public/images/blue-check-mark.svg';
// will get jpgs from woody
import cFOneBig from '../../../../public/images/CF1big.jpg';
import cFOneThumb from '../../../../public/images/CF1thumb.jpg';
import cFTwoBig from '../../../../public/images/CF2big.jpg';
import cFTwoThumb from '../../../../public/images/CF2thumb.jpg';
import cFThreeBig from '../../../../public/images/CF3big.jpg';
import cFThreeThumb from '../../../../public/images/CF3thumb.jpg';
import cFFourBig from '../../../../public/images/CF4big.jpg';
import cFFourThumb from '../../../../public/images/CF4thumb.jpg';
import cLOneBig from '../../../../public/images/CL1big.jpg';
import cLOneThumb from '../../../../public/images/CL1thumb.jpg';
import cLTwoBig from '../../../../public/images/CL2big.jpg';
import cLTwoThumb from '../../../../public/images/CL2thumb.jpg';
import cLThreeBig from '../../../../public/images/CL3big.jpg';
import cLThreeThumb from '../../../../public/images/CL3thumb.jpg';
import cLFourBig from '../../../../public/images/CL4big.jpg';
import cLFourThumb from '../../../../public/images/CL4thumb.jpg';
import hMOneBig from '../../../../public/images/HM01-cutbig.jpg';
import hMOneThumb from '../../../../public/images/HM01-cutthumb.jpg';
import hMTwoBig from '../../../../public/images/HM02-flybig.jpg';
import hMTwoThumb from '../../../../public/images/HM02-flythumb.jpg';
import hMThreeBig from '../../../../public/images/HM03-surfbig.jpg';
import hMThreeThumb from '../../../../public/images/HM03-surfthumb.jpg';
import hMFourBig from '../../../../public/images/HM04-strengthbig.jpg';
import hMFourThumb from '../../../../public/images/HM04-strengththumb.jpg';
import lockGuarantee from '../../../../public/images/lock-guarantee.svg';
import homeGuarantee from '../../../../public/images/home-guarantee.svg';

const {
  'package-hero': packageHeroClass,
  'hero-info': heroInfoClass,
  'bullet-points': bulletPointsClass,
  'bullet-point': bulletPointClass,
  'bullet-copy': bulletCopyClass,
  'thumb-nails': thumbNailsClass,
  'thumb-nail': thumbNailClass,
  'hero-images': heroImagesClass,
  'main-image': mainImageClass,
  'my-price-cta': myPriceCtaClass,
  'cta-guarantee': ctaGuaranteeClass,
  'hero-images-info': heroImagesAndInfoClass,
  guarantees: guaranteesClass,
  active: activeClass
} = styles;

const HeroInfo = ({ packageName }) => {
  // package bullet points are hardcoded - doesn't exist on the back end - current bullet points are slightly different copy
  // package name keys will need to be updated every time a package name is updated
  const packageBulletPointsCopy = {
    'Complete Funeral': [
      { Viewing: 'gives family & friends a chance to pay their final respects' },
      { 'Funeral Service': 'gathers loved ones to share support and pay tribute together' },
      { 'Cremation Services': 'compassionate care provided by certified professionals' }
    ],
    'Heartfelt Memorial': [
      { Visitation: 'gives family & friends a way to share support and memories' },
      { 'Memorial Service': 'gathers loved ones to honor your life together' },
      { 'Cremation Services': 'compassionate care provided by certified professionals' }
    ],
    'Celebration of Life': [
      { 'Professional Family Support': 'for difficult decisions, tasks & paperwork' },
      { 'Event Consultation': 'guidance as family decides how to honor your life' },
      { 'Cremation Services': 'compassionate care provided by certified professionals' }
    ]
  };

  const packageTitleCopy = `A ${packageName}`;

  return (
    <div className={heroInfoClass}>
      <p>A Popular plan Starter For</p>
      <h1>{packageTitleCopy}</h1>
      <div className={bulletPointsClass}>
        {packageBulletPointsCopy[packageName]?.map(bulletPoint => {
          const [point, copy] = Object.entries(bulletPoint)[0];
          return (
            <div key={`${point}_${copy}`} className={bulletPointClass}>
              <img src={blueCheckMark} alt="" />
              <div className={bulletCopyClass}>
                <p>
                  <strong>{point}</strong> - {copy}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const HeroImages = ({ packageName = 'Complete Funeral' }) => {
  // package name keys will need to be updated every time a package name is updated
  const activeImagesToCycle = {
    'Complete Funeral': [
      { thumb: cFOneThumb, big: cFOneBig },
      { thumb: cFTwoThumb, big: cFTwoBig },
      { thumb: cFThreeThumb, big: cFThreeBig },
      { thumb: cFFourThumb, big: cFFourBig }
    ],
    'Heartfelt Memorial': [
      { thumb: hMOneThumb, big: hMOneBig },
      { thumb: hMTwoThumb, big: hMTwoBig },
      { thumb: hMThreeThumb, big: hMThreeBig },
      { thumb: hMFourThumb, big: hMFourBig }
    ],
    'Celebration of Life': [
      { thumb: cLOneThumb, big: cLOneBig },
      { thumb: cLTwoThumb, big: cLTwoBig },
      { thumb: cLThreeThumb, big: cLThreeBig },
      { thumb: cLFourThumb, big: cLFourBig }
    ]
  };
  const [currentImageIdx, setCurrentImgIndex] = useState(0);
  const currentImage = activeImagesToCycle[packageName]?.length
    ? activeImagesToCycle[packageName][currentImageIdx]
    : '';

  useEffect(() => {
    const updateImgFn = e => {
      const imgNode = e.target;
      if (imgNode.src) setCurrentImgIndex(+imgNode.dataset.idx);
    };
    document.getElementsByClassName(thumbNailsClass)[0].addEventListener('mouseover', updateImgFn);

    return () => {
      document
        .getElementsByClassName(thumbNailsClass)[0]
        .removeEventListener('mouseover', updateImgFn);
    };
  }, []);

  return (
    <div className={heroImagesClass}>
      <div className={thumbNailsClass}>
        {activeImagesToCycle[packageName]?.map(({ thumb }, i) => {
          // const updateImg
          const thumbNailClasses = classNames(thumbNailClass, {
            [activeClass]: i === currentImageIdx
          });
          return (
            <img src={thumb} alt="" data-idx={`${i}`} className={thumbNailClasses} key={thumb} />
          );
        })}
      </div>
      <img className={mainImageClass} src={currentImage?.big} alt="" />
    </div>
  );
};

const guarantees = [
  { imgUrl: lockGuarantee, copy: 'Quality guaranteed' },
  { imgUrl: homeGuarantee, copy: 'Delivered by the funeral home of your choice' }
];

const PackageHero = ({ name: packageName = '', selectedPackage }) => {
  const ctaLink = useVerifyCookie('rq-sid') ? '/get-my-price' : 'https://try.everdays.com/plan';

  return (
    <div className={packageHeroClass}>
      <div className={heroImagesAndInfoClass}>
        <HeroImages {...{ packageName }} />
        <HeroInfo {...{ packageName }} />
      </div>
      <div className={ctaGuaranteeClass}>
        <a className={myPriceCtaClass} href={ctaLink}>
          <button>This is for me</button>
        </a>
        <div className={guaranteesClass}>
          {guarantees.map(({ imgUrl, copy }) => (
            <div>
              <img src={imgUrl} alt="" />
              <p>{copy}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PackageHero;
