import React, { useEffect } from 'react';

import NavBar from '../../NavBar';
import WhyHero from '../components/WhyHero';
import WhyContent from '../components/WhyContent';
import FooterSection from '../../shared/Footer/FooterSection';
import TripleCTA from '../../shared/TripleCTA';

import OtherPagesGrid from '../components/OtherPagesGrid';

import { useHideIntercom, useVerifyCookie } from '../../../customHooks';

import pageData from './pageData';

import styles from './pages.module.scss';

const { 'why-page': whyPageClass } = styles;

// one of the Why pages has more content than the others, and in a format that is unique (lists)
// so use the extraContent prop to render the extra content
const WhyPageTemplate = ({
  currentPageName = 'love',
  extraContent,
  footerDisclaimers = {},
  location
}) => {
  const currentPageData = pageData.find(page => page.name === currentPageName);
  const otherPagesArray = pageData.filter(page => page.name !== currentPageName);

  // for scrolling to the top of the page on mount
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  //hide intercom
  useHideIntercom();

  const ctaLink = useVerifyCookie('rq-sid') ? '/get-my-price' : 'https://try.everdays.com/plan';

  return (
    <div className={whyPageClass}>
      <NavBar {...{ location, ctaLink }} />
      <WhyHero {...currentPageData} currentPageName={currentPageName} />
      <WhyContent {...currentPageData} renderExtraContent={extraContent} />
      <TripleCTA />
      <OtherPagesGrid pagesArray={otherPagesArray} />
      <FooterSection {...footerDisclaimers} />
    </div>
  );
};

export default WhyPageTemplate;
