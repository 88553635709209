import React, { useEffect } from 'react';

import NavBar from '../NavBar';
import SiteHero from '../shared/SiteHero';
import Breadcrumbs from '../shared/Breadcrumbs';
import StepCard from '../StepCard';
import FlowerCTA from '../FlowerCTA';
import FooterSection from '../shared/Footer/FooterSection';

import { useHideIntercom, useVerifyCookie } from '../../customHooks';

import stepCardImage1 from '../../public/images/step-card-01.png';
import stepCardImage2 from '../../public/images/step-card-02.png';
import stepCardImage3 from '../../public/images/step-card-03.png';
import stepCardImage4 from '../../public/images/step-card-04.png';
import navyCheckIcon from '../../public/images/icons/navy-check.svg';
import questionMarkCircle from '../../public/images/icons/question-mark-circle-icon.svg';

import styles from './HowItWorks.module.scss';

const {
  'how-it-works': howItWorksClass,
  'how-it-works-hero': howItWorksHeroClass,
  'step-section': stepSectionClass,
  'faq-section': faqSectionClass,
  'hero-content': heroContent,
  'hero-list': heroListClass,
  'hero-text': heroTextClass,
  'hide-arrow-mobile': hideArrowMobile,
  'how-step-card': howStepCard,
  breadcrumbs: breadcrumbsClass,
  questions: questionsClass,
  question: questionClass
} = styles;

const HowItWorks = ({ location }) => {
  const breadcrumbArray = [
    { pageName: 'Home', href: '/' },
    { pageName: 'How It Works', href: '/how-it-works/' }
  ];

  //hide intercom
  useHideIntercom();

  const ctaLink = useVerifyCookie('rq-sid') ? '/get-my-price' : 'https://try.everdays.com/plan';

  return (
    <div className={howItWorksClass}>
      <NavBar {...{ location, ctaLink }} />
      <SiteHero
        currentPage="how-it-works"
        customClass={howItWorksHeroClass}
        customTextClass={heroTextClass}
        heroTitle="We make buying your funeral a breeze"
        renderBreadcrumbs={
          <Breadcrumbs crumbTrail={breadcrumbArray} customClass={breadcrumbsClass} />
        }
        renderMarkup={
          <div className={heroContent}>
            <ul className={heroListClass}>
              <li>
                <img src={navyCheckIcon} alt="checkmark" />
                <span>Easy</span>
              </li>
              <li>
                <img src={navyCheckIcon} alt="checkmark" />
                <span>Simple</span>
              </li>
              <li>
                <img src={navyCheckIcon} alt="checkmark" />
                <span>Straightforward</span>
              </li>
            </ul>
          </div>
        }
      />
      <div className={stepSectionClass}>
        <StepCard
          textAlign="right"
          cardTitle="First, choose your package & funeral home"
          cardText="Compare the packages, see your pricing options, take our quiz, or talk to our care team. We’ve got lots of ways for you to find the right package and funeral home provider."
          cardImage={stepCardImage1}
          customArrowClass={hideArrowMobile}
          customClass={howStepCard}
        />
        <StepCard
          cardTitle="Everyone gets the details they need"
          cardText="The funeral home you’ve chosen automatically receives all the paperwork, and it’s easy to digitally share your verified package purchase with whoever you’d like – it’s seamless."
          cardImage={stepCardImage2}
          customArrowClass={hideArrowMobile}
          customClass={howStepCard}
        />
        <StepCard
          textAlign="right"
          cardTitle="Then you’re done, and everything is set!"
          cardText="You get to live with peace of mind knowing everything is taken care of and saved for you and your family just a tap away, and loved ones will have what they’ll need."
          cardImage={stepCardImage3}
          customArrowClass={hideArrowMobile}
          customClass={howStepCard}
        />
        <StepCard
          hideArrow
          cardTitle="The funeral home will guide your family"
          cardText="Beginning the moment the funeral home is notified of your passing, funeral professionals will support and guide your family and fulfill the package you’ve chosen for them."
          cardImage={stepCardImage4}
          customArrowClass={hideArrowMobile}
          customClass={howStepCard}
        />
      </div>
      <div className={faqSectionClass}>
        <h2>Have a specific question in mind?</h2>
        <div className={questionsClass}>
          <div className={questionClass}>
            <img src={questionMarkCircle} />
            <h5>Can I change my funeral home after purchase?</h5>
            <p>
              Yes. If you decide you’d like to work with another funeral home down the road, our
              care team can help you switch.
            </p>
          </div>
          <div className={questionClass}>
            <img src={questionMarkCircle} />
            <h5>What are the different payment options available?</h5>
            <p>
              We offer two different options: you can purchase your package directly or buy
              insurance with low monthly payments.
            </p>
          </div>
          <div className={questionClass}>
            <img src={questionMarkCircle} />
            <h5>Can I return my package if I change my mind?</h5>
            <p>
              Yes. If you’re not fully satisfied with your purchase within 30 days we’ll issue a
              full refund, no questions asked.
            </p>
          </div>
        </div>
        <a
          href="https://help.everdays.com/en/collections/2674330-funeral-package-faqs#package-questions"
          target="_blank"
          rel="noreferrer"
        >
          View All FAQs
        </a>
      </div>
      <FlowerCTA ctaAction={() => (window.location.href = ctaLink)} />
      <FooterSection
        firstAsteriskCopy="*Based on historical inflation rates and typical life expectancy."
        secondAsteriskCopy="†If you choose our insurance plan, full coverage goes into effect no later than 24 months from your first payment. Until then, your family is protected but at a lesser amount."
      />
    </div>
  );
};

export default HowItWorks;
