"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ONBOARD_RESET_CHECK_PHONE = exports.ONBOARD_FINISH_FAILURE = exports.ONBOARD_FINISH_SUCCESS = exports.ONBOARD_FINISH_REQUEST = exports.ONBOARD_ADD_COMPANY_INFO_FAILURE = exports.ONBOARD_ADD_COMPANY_INFO_SUCCESS = exports.ONBOARD_ADD_COMPANY_INFO_REQUEST = exports.ONBOARD_CHECK_COMPANY_FAILURE = exports.ONBOARD_CHECK_COMPANY_SUCCESS = exports.ONBOARD_CHECK_COMPANY_REQUEST = exports.ONBOARD_UPDATE_EMAIL_FAILURE = exports.ONBOARD_UPDATE_EMAIL_SUCCESS = exports.ONBOARD_UPDATE_EMAIL_REQUEST = exports.ONBOARD_ADD_EMAIL_FAILURE = exports.ONBOARD_ADD_EMAIL_SUCCESS = exports.ONBOARD_ADD_EMAIL_REQUEST = exports.ONBOARD_VERIFY_PHONE_FAILURE = exports.ONBOARD_VERIFY_PHONE_SUCCESS = exports.ONBOARD_VERIFY_PHONE_REQUEST = exports.ONBOARD_CHECK_PHONE_FAILURE = exports.ONBOARD_CHECK_PHONE_SUCCESS = exports.ONBOARD_CHECK_PHONE_REQUEST = exports.ONBOARD_INIT_USER_FAILURE = exports.ONBOARD_INIT_USER_SUCCESS = exports.ONBOARD_INIT_USER_REQUEST = void 0;
var ONBOARD_INIT_USER_REQUEST = 'ONBOARD_INIT_USER_REQUEST';
exports.ONBOARD_INIT_USER_REQUEST = ONBOARD_INIT_USER_REQUEST;
var ONBOARD_INIT_USER_SUCCESS = 'ONBOARD_INIT_USER_SUCCESS';
exports.ONBOARD_INIT_USER_SUCCESS = ONBOARD_INIT_USER_SUCCESS;
var ONBOARD_INIT_USER_FAILURE = 'ONBOARD_INIT_USER_FAILURE';
exports.ONBOARD_INIT_USER_FAILURE = ONBOARD_INIT_USER_FAILURE;
var ONBOARD_CHECK_PHONE_REQUEST = 'ONBOARD_CHECK_PHONE_REQUEST';
exports.ONBOARD_CHECK_PHONE_REQUEST = ONBOARD_CHECK_PHONE_REQUEST;
var ONBOARD_CHECK_PHONE_SUCCESS = 'ONBOARD_CHECK_PHONE_SUCCESS';
exports.ONBOARD_CHECK_PHONE_SUCCESS = ONBOARD_CHECK_PHONE_SUCCESS;
var ONBOARD_CHECK_PHONE_FAILURE = 'ONBOARD_CHECK_PHONE_FAILURE';
exports.ONBOARD_CHECK_PHONE_FAILURE = ONBOARD_CHECK_PHONE_FAILURE;
var ONBOARD_VERIFY_PHONE_REQUEST = 'ONBOARD_VERIFY_PHONE_REQUEST';
exports.ONBOARD_VERIFY_PHONE_REQUEST = ONBOARD_VERIFY_PHONE_REQUEST;
var ONBOARD_VERIFY_PHONE_SUCCESS = 'ONBOARD_VERIFY_PHONE_SUCCESS';
exports.ONBOARD_VERIFY_PHONE_SUCCESS = ONBOARD_VERIFY_PHONE_SUCCESS;
var ONBOARD_VERIFY_PHONE_FAILURE = 'ONBOARD_VERIFY_PHONE_FAILURE';
exports.ONBOARD_VERIFY_PHONE_FAILURE = ONBOARD_VERIFY_PHONE_FAILURE;
var ONBOARD_ADD_EMAIL_REQUEST = 'ONBOARD_ADD_EMAIL_REQUEST';
exports.ONBOARD_ADD_EMAIL_REQUEST = ONBOARD_ADD_EMAIL_REQUEST;
var ONBOARD_ADD_EMAIL_SUCCESS = 'ONBOARD_ADD_EMAIL_SUCCESS';
exports.ONBOARD_ADD_EMAIL_SUCCESS = ONBOARD_ADD_EMAIL_SUCCESS;
var ONBOARD_ADD_EMAIL_FAILURE = 'ONBOARD_ADD_EMAIL_FAILURE';
exports.ONBOARD_ADD_EMAIL_FAILURE = ONBOARD_ADD_EMAIL_FAILURE;
var ONBOARD_UPDATE_EMAIL_REQUEST = 'ONBOARD_UPDATE_EMAIL_REQUEST';
exports.ONBOARD_UPDATE_EMAIL_REQUEST = ONBOARD_UPDATE_EMAIL_REQUEST;
var ONBOARD_UPDATE_EMAIL_SUCCESS = 'ONBOARD_UPDATE_EMAIL_SUCCESS';
exports.ONBOARD_UPDATE_EMAIL_SUCCESS = ONBOARD_UPDATE_EMAIL_SUCCESS;
var ONBOARD_UPDATE_EMAIL_FAILURE = 'ONBOARD_UPDATE_EMAIL_FAILURE';
exports.ONBOARD_UPDATE_EMAIL_FAILURE = ONBOARD_UPDATE_EMAIL_FAILURE;
var ONBOARD_CHECK_COMPANY_REQUEST = 'ONBOARD_CHECK_COMPANY_REQUEST';
exports.ONBOARD_CHECK_COMPANY_REQUEST = ONBOARD_CHECK_COMPANY_REQUEST;
var ONBOARD_CHECK_COMPANY_SUCCESS = 'ONBOARD_CHECK_COMPANY_SUCCESS';
exports.ONBOARD_CHECK_COMPANY_SUCCESS = ONBOARD_CHECK_COMPANY_SUCCESS;
var ONBOARD_CHECK_COMPANY_FAILURE = 'ONBOARD_CHECK_COMPANY_FAILURE';
exports.ONBOARD_CHECK_COMPANY_FAILURE = ONBOARD_CHECK_COMPANY_FAILURE;
var ONBOARD_ADD_COMPANY_INFO_REQUEST = 'ONBOARD_ADD_COMPANY_INFO_REQUEST';
exports.ONBOARD_ADD_COMPANY_INFO_REQUEST = ONBOARD_ADD_COMPANY_INFO_REQUEST;
var ONBOARD_ADD_COMPANY_INFO_SUCCESS = 'ONBOARD_ADD_COMPANY_INFO_SUCCESS';
exports.ONBOARD_ADD_COMPANY_INFO_SUCCESS = ONBOARD_ADD_COMPANY_INFO_SUCCESS;
var ONBOARD_ADD_COMPANY_INFO_FAILURE = 'ONBOARD_ADD_COMPANY_INFO_FAILURE';
exports.ONBOARD_ADD_COMPANY_INFO_FAILURE = ONBOARD_ADD_COMPANY_INFO_FAILURE;
var ONBOARD_FINISH_REQUEST = 'ONBOARD_FINISH_REQUEST';
exports.ONBOARD_FINISH_REQUEST = ONBOARD_FINISH_REQUEST;
var ONBOARD_FINISH_SUCCESS = 'ONBOARD_FINISH_SUCCESS';
exports.ONBOARD_FINISH_SUCCESS = ONBOARD_FINISH_SUCCESS;
var ONBOARD_FINISH_FAILURE = 'ONBOARD_FINISH_FAILURE';
exports.ONBOARD_FINISH_FAILURE = ONBOARD_FINISH_FAILURE;
var ONBOARD_RESET_CHECK_PHONE = 'ONBOARD_RESET_CHECK_PHONE';
exports.ONBOARD_RESET_CHECK_PHONE = ONBOARD_RESET_CHECK_PHONE;