"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _utils = require("./utils");

var _getNextWeekDate = (0, _utils.getNextWeekDate)(),
    nextWeekMonth = _getNextWeekDate.nextWeekMonth,
    nextWeekDate = _getNextWeekDate.nextWeekDate,
    nextWeekYear = _getNextWeekDate.nextWeekYear;

var _default = {
  selectedTask: 'success',
  retry: {
    date: {
      month: nextWeekMonth,
      day: nextWeekDate,
      year: nextWeekYear
    },
    time: '',
    additionalNotes: ''
  },
  success: {
    date: {
      month: nextWeekMonth,
      day: nextWeekDate,
      year: nextWeekYear
    },
    time: '',
    additionalNotes: ''
  },
  close: {
    reason: '',
    additionalNotes: ''
  }
};
exports.default = _default;