"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CREATE_MEMORIAL_RESET = exports.CREATE_MEMORIAL_FAILURE = exports.CREATE_MEMORIAL_SUCCESS = exports.CREATE_MEMORIAL_REQUEST = void 0;
var CREATE_MEMORIAL_REQUEST = 'CREATE_MEMORIAL_REQUEST';
exports.CREATE_MEMORIAL_REQUEST = CREATE_MEMORIAL_REQUEST;
var CREATE_MEMORIAL_SUCCESS = 'CREATE_MEMORIAL_SUCCESS';
exports.CREATE_MEMORIAL_SUCCESS = CREATE_MEMORIAL_SUCCESS;
var CREATE_MEMORIAL_FAILURE = 'CREATE_MEMORIAL_FAILURE';
exports.CREATE_MEMORIAL_FAILURE = CREATE_MEMORIAL_FAILURE;
var CREATE_MEMORIAL_RESET = 'CREATE_MEMORIAL_RESET';
exports.CREATE_MEMORIAL_RESET = CREATE_MEMORIAL_RESET;