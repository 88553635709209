"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FETCH_USER_IMAGES_FAILURE = exports.FETCH_USER_IMAGES_SUCCESS = exports.FETCH_USER_IMAGES_REQUEST = void 0;
var FETCH_USER_IMAGES_REQUEST = 'FETCH_USER_IMAGES_REQUEST';
exports.FETCH_USER_IMAGES_REQUEST = FETCH_USER_IMAGES_REQUEST;
var FETCH_USER_IMAGES_SUCCESS = 'FETCH_USER_IMAGES_SUCCESS';
exports.FETCH_USER_IMAGES_SUCCESS = FETCH_USER_IMAGES_SUCCESS;
var FETCH_USER_IMAGES_FAILURE = 'FETCH_USER_IMAGES_FAILURE';
exports.FETCH_USER_IMAGES_FAILURE = FETCH_USER_IMAGES_FAILURE;