"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_JOBS_FAILURE = exports.GET_JOBS_SUCCESS = exports.GET_JOBS_REQUEST = void 0;
var GET_JOBS_REQUEST = 'GET_JOBS_REQUEST';
exports.GET_JOBS_REQUEST = GET_JOBS_REQUEST;
var GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS';
exports.GET_JOBS_SUCCESS = GET_JOBS_SUCCESS;
var GET_JOBS_FAILURE = 'GET_JOBS_FAILURE';
exports.GET_JOBS_FAILURE = GET_JOBS_FAILURE;