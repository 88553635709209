"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var inviteDetailsForm = {
  name: {
    first: '',
    last: ''
  },
  phone: '',
  notes: {
    funeralHomeName: '',
    other: '',
    leadEmail: '',
    insuranceCarrier: '',
    title: '',
    leadTemp: '',
    cpy: ''
  }
};
var showInvite = {
  search: ''
};
var _default = {
  inviteDetailsForm: inviteDetailsForm,
  showInvite: showInvite
};
exports.default = _default;