"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EDIT_JOURNEYLOG_FAILURE = exports.EDIT_JOURNEYLOG_SUCCESS = exports.EDIT_JOURNEYLOG_REQUEST = exports.FETCH_JOURNEYLOG_FAILURE = exports.FETCH_JOURNEYLOG_SUCCESS = exports.FETCH_JOURNEYLOG_REQUEST = void 0;
var FETCH_JOURNEYLOG_REQUEST = 'FETCH_JOURNEYLOG_REQUEST';
exports.FETCH_JOURNEYLOG_REQUEST = FETCH_JOURNEYLOG_REQUEST;
var FETCH_JOURNEYLOG_SUCCESS = 'FETCH_JOURNEYLOG_SUCCESS';
exports.FETCH_JOURNEYLOG_SUCCESS = FETCH_JOURNEYLOG_SUCCESS;
var FETCH_JOURNEYLOG_FAILURE = 'FETCH_JOURNEYLOG_FAILURE';
exports.FETCH_JOURNEYLOG_FAILURE = FETCH_JOURNEYLOG_FAILURE;
var EDIT_JOURNEYLOG_REQUEST = 'EDIT_JOURNEYLOG_REQUEST';
exports.EDIT_JOURNEYLOG_REQUEST = EDIT_JOURNEYLOG_REQUEST;
var EDIT_JOURNEYLOG_SUCCESS = 'EDIT_JOURNEYLOG_SUCCESS';
exports.EDIT_JOURNEYLOG_SUCCESS = EDIT_JOURNEYLOG_SUCCESS;
var EDIT_JOURNEYLOG_FAILURE = 'EDIT_JOURNEYLOG_FAILURE';
exports.EDIT_JOURNEYLOG_FAILURE = EDIT_JOURNEYLOG_FAILURE;