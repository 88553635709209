"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _redux = require("redux");

var _reactReduxForm = require("react-redux-form");

var _photos = _interopRequireDefault(require("./photos"));

var _decedent = _interopRequireDefault(require("./decedent"));

var _homeInfo = _interopRequireDefault(require("./homeInfo"));

var _misc = _interopRequireDefault(require("./misc"));

var _meta = _interopRequireDefault(require("./meta"));

var _serviceStatus = _interopRequireDefault(require("./serviceStatus"));

var _companyInfo = _interopRequireDefault(require("./companyInfo"));

var _informant = _interopRequireDefault(require("./informant"));

var _event = require("./event");

var _member = require("./member");

var _donations = require("./donations");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var annc = (0, _redux.combineReducers)({
  photos: _photos.default,
  events: _event.events,
  members: _member.members,
  donations: _donations.donations,
  forms: (0, _reactReduxForm.combineForms)({
    homeInfo: _homeInfo.default,
    decedent: _decedent.default,
    misc: _misc.default,
    meta: _meta.default,
    event: _event.event,
    member: _member.member,
    donation: _donations.donation,
    serviceStatus: _serviceStatus.default,
    companyInfo: _companyInfo.default,
    informant: _informant.default
  }, 'dash.annc.forms', {
    key: 'formsMeta'
  })
});
var _default = annc;
exports.default = _default;