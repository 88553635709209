"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EDIT_COMPANY_RESET = exports.EDIT_COMPANY_FAILURE = exports.EDIT_COMPANY_SUCCESS = exports.EDIT_COMPANY_REQUEST = void 0;
var EDIT_COMPANY_REQUEST = 'EDIT_COMPANY_REQUEST';
exports.EDIT_COMPANY_REQUEST = EDIT_COMPANY_REQUEST;
var EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';
exports.EDIT_COMPANY_SUCCESS = EDIT_COMPANY_SUCCESS;
var EDIT_COMPANY_FAILURE = 'EDIT_COMPANY_FAILURE';
exports.EDIT_COMPANY_FAILURE = EDIT_COMPANY_FAILURE;
var EDIT_COMPANY_RESET = 'EDIT_COMPANY_RESET';
exports.EDIT_COMPANY_RESET = EDIT_COMPANY_RESET;