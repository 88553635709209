"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: {
    first: '',
    last: '',
    middle: ''
  },
  integrationMeta: {
    precoa: {
      inSteppingStones: false
    }
  },
  location: {
    isNearby: false,
    zip: ''
  },
  ageRangeTxt: '',
  phone: '',
  email: '',
  relation: '',
  type: 'manager',
  locationNearby: '',
  primarySurvivor: false,
  hideInCondolences: false
};
exports.default = _default;