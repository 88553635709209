"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: '',
  websiteUrl: '',
  type: '',
  address: {
    street: '',
    street2: '',
    city: '',
    state: '',
    zip: ''
  },
  phone: '',
  email: '',
  casesPerYear: '',
  flowers: {
    url: ''
  },
  facebookUrl: '',
  placeId: '',
  yelpUrl: '',
  description: '',
  profileImage: '',
  photos: [],
  displayPublicly: true,
  specialStateLicenseInfo: '',
  specialStateLicenseInfo2: '',
  funeralDirectorNumber: '',
  insuranceLicenseNumber: '',
  representativeIsNotMe: false,
  representative: {
    name: {
      first: '',
      last: ''
    },
    phone: '',
    email: ''
  },
  homeHasOwnTaxInfo: false,
  taxID: '',
  taxClassification: ''
};
exports.default = _default;