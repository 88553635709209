"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESET_SCROLL_Y = exports.SUBTRACT_WELCOME_MAT_HEIGHT = exports.STORE_SCROLL_Y = void 0;
var STORE_SCROLL_Y = 'STORE_SCROLL_Y';
exports.STORE_SCROLL_Y = STORE_SCROLL_Y;
var SUBTRACT_WELCOME_MAT_HEIGHT = 'SUBTRACT_WELCOME_MAT_HEIGHT';
exports.SUBTRACT_WELCOME_MAT_HEIGHT = SUBTRACT_WELCOME_MAT_HEIGHT;
var RESET_SCROLL_Y = 'RESET_SCROLL_Y';
exports.RESET_SCROLL_Y = RESET_SCROLL_Y;