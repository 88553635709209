"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  funeralDirectorId: '',
  funeralHome: '',
  funeralHomeDirector: '',
  afp: ''
};
exports.default = _default;