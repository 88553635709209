"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.closeConnection = exports.openConnection = void 0;

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.promise.js");

var _socket = _interopRequireDefault(require("socket.io-client"));

var _constants = require("../../constants");

var _actions = require("./actions");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var streamProps = {
  dispatch: undefined,
  socketHandler: undefined
};

var handleStreamConnectionChange = function handleStreamConnectionChange() {
  streamProps.dispatch((0, _actions.socketConnectionChange)(streamProps));

  if (!streamProps.socketHandler.connected) {
    streamProps.socketHandler.removeEventListener('connect', handleStreamConnectionChange);
    streamProps.socketHandler.removeEventListener('reconnect', handleStreamConnectionChange);
    streamProps.socketHandler.removeEventListener('disconnect', handleStreamConnectionChange);
    streamProps = {
      dispatch: undefined,
      socketHandler: undefined
    };
  }
};

var openConnection = function openConnection() {
  return function (dispatch) {
    var socketHandler = (0, _socket.default)(_constants.API_URL);
    streamProps = {
      dispatch: dispatch,
      socketHandler: socketHandler
    };
    socketHandler.on('connect', handleStreamConnectionChange);
    socketHandler.on('reconnect', handleStreamConnectionChange);
    socketHandler.on('disconnect', handleStreamConnectionChange);
    return Promise.resolve(socketHandler);
  };
};

exports.openConnection = openConnection;

var closeConnection = function closeConnection() {
  return function (dispatch) {
    streamProps.socketHandler.disconnect();
  };
};

exports.closeConnection = closeConnection;