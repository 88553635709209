"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SUBMIT_APP_LINK_FAILURE = exports.SUBMIT_APP_LINK_SUCCESS = exports.SUBMIT_APP_LINK_REQUEST = void 0;
var SUBMIT_APP_LINK_REQUEST = 'SUBMIT_APP_LINK_REQUEST';
exports.SUBMIT_APP_LINK_REQUEST = SUBMIT_APP_LINK_REQUEST;
var SUBMIT_APP_LINK_SUCCESS = 'SUBMIT_APP_LINK_SUCCESS';
exports.SUBMIT_APP_LINK_SUCCESS = SUBMIT_APP_LINK_SUCCESS;
var SUBMIT_APP_LINK_FAILURE = 'SUBMIT_APP_LINK_FAILURE';
exports.SUBMIT_APP_LINK_FAILURE = SUBMIT_APP_LINK_FAILURE;