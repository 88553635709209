import React from 'react';

import styles from './FlowerCTA.module.scss';

const {
  'flower-cta': flowerCTAClass,
  content: contentClass,
  'copy-section': copySectionClass
} = styles;

const FlowerCTA = ({
  titleCopy = 'Instant, personal pricing',
  subtitleCopy = 'See your personalized prices with all your options and find exactly what you’re looking for.',
  ctaCopy = 'Get Started',
  ctaAction = () => (window.location.href = '/get-my-price')
}) => {
  return (
    <div className={flowerCTAClass}>
      <div className={contentClass}>
        <div className={copySectionClass}>
          <h2>{titleCopy}</h2>
          <p>{subtitleCopy}</p>
        </div>
        <button onClick={ctaAction}>{ctaCopy}</button>
      </div>
    </div>
  );
};

export default FlowerCTA;
