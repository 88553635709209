"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TALENT_NETWORK_SIGNUP_FAILURE = exports.TALENT_NETWORK_SIGNUP_SUCCESS = exports.TALENT_NETWORK_SIGNUP_REQUEST = void 0;
var TALENT_NETWORK_SIGNUP_REQUEST = 'TALENT_NETWORK_SIGNUP_REQUEST';
exports.TALENT_NETWORK_SIGNUP_REQUEST = TALENT_NETWORK_SIGNUP_REQUEST;
var TALENT_NETWORK_SIGNUP_SUCCESS = 'TALENT_NETWORK_SIGNUP_SUCCESS';
exports.TALENT_NETWORK_SIGNUP_SUCCESS = TALENT_NETWORK_SIGNUP_SUCCESS;
var TALENT_NETWORK_SIGNUP_FAILURE = 'TALENT_NETWORK_SIGNUP_FAILURE';
exports.TALENT_NETWORK_SIGNUP_FAILURE = TALENT_NETWORK_SIGNUP_FAILURE;