"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FETCH_MAT_FAILURE = exports.FETCH_MAT_SUCCESS = exports.FETCH_MAT_REQUEST = void 0;
var FETCH_MAT_REQUEST = 'FETCH_MAT_REQUEST';
exports.FETCH_MAT_REQUEST = FETCH_MAT_REQUEST;
var FETCH_MAT_SUCCESS = 'FETCH_MAT_SUCCESS';
exports.FETCH_MAT_SUCCESS = FETCH_MAT_SUCCESS;
var FETCH_MAT_FAILURE = 'FETCH_MAT_FAILURE';
exports.FETCH_MAT_FAILURE = FETCH_MAT_FAILURE;