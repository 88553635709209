"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESET_CONTACT_HOME = exports.CONTACT_HOME_FAILURE = exports.CONTACT_HOME_SUCCESS = exports.CONTACT_HOME_REQUEST = void 0;
var CONTACT_HOME_REQUEST = 'CONTACT_HOME_REQUEST';
exports.CONTACT_HOME_REQUEST = CONTACT_HOME_REQUEST;
var CONTACT_HOME_SUCCESS = 'CONTACT_HOME_SUCCESS';
exports.CONTACT_HOME_SUCCESS = CONTACT_HOME_SUCCESS;
var CONTACT_HOME_FAILURE = 'CONTACT_HOME_FAILURE';
exports.CONTACT_HOME_FAILURE = CONTACT_HOME_FAILURE;
var RESET_CONTACT_HOME = 'RESET_CONTACT_HOME';
exports.RESET_CONTACT_HOME = RESET_CONTACT_HOME;