"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UNFOLLOW_INVITE_SUCCESS = exports.UNFOLLOW_INVITE_FAILURE = exports.UNFOLLOW_INVITE_REQUEST = exports.FOLLOW_INVITE_SUCCESS = exports.FOLLOW_INVITE_FAILURE = exports.FOLLOW_INVITE_REQUEST = exports.RESET_VIDEO_MODAL_CLOSED = exports.SET_VIDEO_MODAL_CLOSED = exports.CLEAR_VIDEO_PROGRESS = exports.SAVE_VIDEO_PROGRESS = exports.UNLOVE_COMMENT_SUCCESS = exports.UNLOVE_COMMENT_FAILURE = exports.UNLOVE_COMMENT_REQUEST = exports.LOVE_COMMENT_SUCCESS = exports.LOVE_COMMENT_FAILURE = exports.LOVE_COMMENT_REQUEST = exports.REMOVE_COMMENT_SUCCESS = exports.REMOVE_COMMENT_FAILURE = exports.REMOVE_COMMENT_REQUEST = exports.RESET_COMMENT = exports.LEAVE_COMMENT_SUCCESS = exports.LEAVE_COMMENT_FAILURE = exports.LEAVE_COMMENT_REQUEST = exports.UNLOVE_MEDIA_SUCCESS = exports.UNLOVE_MEDIA_FAILURE = exports.UNLOVE_MEDIA_REQUEST = exports.LOVE_MEDIA_SUCCESS = exports.LOVE_MEDIA_FAILURE = exports.LOVE_MEDIA_REQUEST = exports.REMOVE_CONDOLENCE_SUCCESS = exports.REMOVE_CONDOLENCE_FAILURE = exports.REMOVE_CONDOLENCE_REQUEST = exports.RESET_CONDOLENCE = exports.LEAVE_CONDOLENCE_SUCCESS = exports.LEAVE_CONDOLENCE_FAILURE = exports.LEAVE_CONDOLENCE_REQUEST = exports.PENDING_VIDEO_PROGRESS_UPDATE = exports.VIDEO_ENCODED_RESET = exports.VIDEO_UPLOADED_RESET = exports.ENCODE_VIDEO_FAILURE = exports.ENCODE_VIDEO_SUCCESS = exports.ENCODE_VIDEO_REQUEST = exports.UPLOAD_VIDEO_FAILURE = exports.UPLOAD_VIDEO_SUCCESS = exports.UPLOAD_VIDEO_REQUEST = exports.PENDING_PHOTO_PROGRESS_UPDATE = exports.UPLOAD_PHOTOS_FAILURE = exports.UPLOAD_PHOTOS_SUCCESS = exports.UPLOAD_PHOTOS_REQUEST = exports.FETCH_ANNOUNCEMENT_SUCCESS = exports.FETCH_ANNOUNCEMENT_FAILURE = exports.FETCH_ANNOUNCEMENT_REQUEST = void 0;
// Invite Page
var FETCH_ANNOUNCEMENT_REQUEST = 'FETCH_ANNOUNCEMENT_REQUEST';
exports.FETCH_ANNOUNCEMENT_REQUEST = FETCH_ANNOUNCEMENT_REQUEST;
var FETCH_ANNOUNCEMENT_FAILURE = 'FETCH_ANNOUNCEMENT_FAILURE';
exports.FETCH_ANNOUNCEMENT_FAILURE = FETCH_ANNOUNCEMENT_FAILURE;
var FETCH_ANNOUNCEMENT_SUCCESS = 'FETCH_ANNOUNCEMENT_SUCCESS'; // Photos

exports.FETCH_ANNOUNCEMENT_SUCCESS = FETCH_ANNOUNCEMENT_SUCCESS;
var UPLOAD_PHOTOS_REQUEST = 'UPLOAD_PHOTOS_REQUEST';
exports.UPLOAD_PHOTOS_REQUEST = UPLOAD_PHOTOS_REQUEST;
var UPLOAD_PHOTOS_SUCCESS = 'UPLOAD_PHOTOS_SUCCESS';
exports.UPLOAD_PHOTOS_SUCCESS = UPLOAD_PHOTOS_SUCCESS;
var UPLOAD_PHOTOS_FAILURE = 'UPLOAD_PHOTOS_FAILURE';
exports.UPLOAD_PHOTOS_FAILURE = UPLOAD_PHOTOS_FAILURE;
var PENDING_PHOTO_PROGRESS_UPDATE = 'PENDING_PHOTO_PROGRESS_UPDATE'; // Upload Videos

exports.PENDING_PHOTO_PROGRESS_UPDATE = PENDING_PHOTO_PROGRESS_UPDATE;
var UPLOAD_VIDEO_REQUEST = 'UPLOAD_VIDEO_REQUEST';
exports.UPLOAD_VIDEO_REQUEST = UPLOAD_VIDEO_REQUEST;
var UPLOAD_VIDEO_SUCCESS = 'UPLOAD_VIDEO_SUCCESS';
exports.UPLOAD_VIDEO_SUCCESS = UPLOAD_VIDEO_SUCCESS;
var UPLOAD_VIDEO_FAILURE = 'UPLOAD_VIDEO_FAILURE';
exports.UPLOAD_VIDEO_FAILURE = UPLOAD_VIDEO_FAILURE;
var ENCODE_VIDEO_REQUEST = 'ENCODE_VIDEO_REQUEST';
exports.ENCODE_VIDEO_REQUEST = ENCODE_VIDEO_REQUEST;
var ENCODE_VIDEO_SUCCESS = 'ENCODE_VIDEO_SUCCESS';
exports.ENCODE_VIDEO_SUCCESS = ENCODE_VIDEO_SUCCESS;
var ENCODE_VIDEO_FAILURE = 'ENCODE_VIDEO_FAILURE';
exports.ENCODE_VIDEO_FAILURE = ENCODE_VIDEO_FAILURE;
var VIDEO_UPLOADED_RESET = 'VIDEO_UPLOADED_RESET';
exports.VIDEO_UPLOADED_RESET = VIDEO_UPLOADED_RESET;
var VIDEO_ENCODED_RESET = 'VIDEO_ENCODED_RESET';
exports.VIDEO_ENCODED_RESET = VIDEO_ENCODED_RESET;
var PENDING_VIDEO_PROGRESS_UPDATE = 'PENDING_VIDEO_PROGRESS_UPDATE'; // Condolences

exports.PENDING_VIDEO_PROGRESS_UPDATE = PENDING_VIDEO_PROGRESS_UPDATE;
var LEAVE_CONDOLENCE_REQUEST = 'LEAVE_CONDOLENCE_REQUEST';
exports.LEAVE_CONDOLENCE_REQUEST = LEAVE_CONDOLENCE_REQUEST;
var LEAVE_CONDOLENCE_FAILURE = 'LEAVE_CONDOLENCE_FAILURE';
exports.LEAVE_CONDOLENCE_FAILURE = LEAVE_CONDOLENCE_FAILURE;
var LEAVE_CONDOLENCE_SUCCESS = 'LEAVE_CONDOLENCE_SUCCESS';
exports.LEAVE_CONDOLENCE_SUCCESS = LEAVE_CONDOLENCE_SUCCESS;
var RESET_CONDOLENCE = 'RESET_CONDOLENCE';
exports.RESET_CONDOLENCE = RESET_CONDOLENCE;
var REMOVE_CONDOLENCE_REQUEST = 'REMOVE_CONDOLENCE_REQUEST';
exports.REMOVE_CONDOLENCE_REQUEST = REMOVE_CONDOLENCE_REQUEST;
var REMOVE_CONDOLENCE_FAILURE = 'REMOVE_CONDOLENCE_FAILURE';
exports.REMOVE_CONDOLENCE_FAILURE = REMOVE_CONDOLENCE_FAILURE;
var REMOVE_CONDOLENCE_SUCCESS = 'REMOVE_CONDOLENCE_SUCCESS'; // Love/Unlove Media

exports.REMOVE_CONDOLENCE_SUCCESS = REMOVE_CONDOLENCE_SUCCESS;
var LOVE_MEDIA_REQUEST = 'LOVE_MEDIA_REQUEST';
exports.LOVE_MEDIA_REQUEST = LOVE_MEDIA_REQUEST;
var LOVE_MEDIA_FAILURE = 'LOVE_MEDIA_FAILURE';
exports.LOVE_MEDIA_FAILURE = LOVE_MEDIA_FAILURE;
var LOVE_MEDIA_SUCCESS = 'LOVE_MEDIA_SUCCESS';
exports.LOVE_MEDIA_SUCCESS = LOVE_MEDIA_SUCCESS;
var UNLOVE_MEDIA_REQUEST = 'UNLOVE_MEDIA_REQUEST';
exports.UNLOVE_MEDIA_REQUEST = UNLOVE_MEDIA_REQUEST;
var UNLOVE_MEDIA_FAILURE = 'UNLOVE_MEDIA_FAILURE';
exports.UNLOVE_MEDIA_FAILURE = UNLOVE_MEDIA_FAILURE;
var UNLOVE_MEDIA_SUCCESS = 'UNLOVE_MEDIA_SUCCESS'; // Comments

exports.UNLOVE_MEDIA_SUCCESS = UNLOVE_MEDIA_SUCCESS;
var LEAVE_COMMENT_REQUEST = 'LEAVE_COMMENT_REQUEST';
exports.LEAVE_COMMENT_REQUEST = LEAVE_COMMENT_REQUEST;
var LEAVE_COMMENT_FAILURE = 'LEAVE_COMMENT_FAILURE';
exports.LEAVE_COMMENT_FAILURE = LEAVE_COMMENT_FAILURE;
var LEAVE_COMMENT_SUCCESS = 'LEAVE_COMMENT_SUCCESS';
exports.LEAVE_COMMENT_SUCCESS = LEAVE_COMMENT_SUCCESS;
var RESET_COMMENT = 'RESET_COMMENT';
exports.RESET_COMMENT = RESET_COMMENT;
var REMOVE_COMMENT_REQUEST = 'REMOVE_COMMENT_REQUEST';
exports.REMOVE_COMMENT_REQUEST = REMOVE_COMMENT_REQUEST;
var REMOVE_COMMENT_FAILURE = 'REMOVE_COMMENT_FAILURE';
exports.REMOVE_COMMENT_FAILURE = REMOVE_COMMENT_FAILURE;
var REMOVE_COMMENT_SUCCESS = 'REMOVE_COMMENT_SUCCESS'; // Love/Unlove Comments

exports.REMOVE_COMMENT_SUCCESS = REMOVE_COMMENT_SUCCESS;
var LOVE_COMMENT_REQUEST = 'LOVE_COMMENT_REQUEST';
exports.LOVE_COMMENT_REQUEST = LOVE_COMMENT_REQUEST;
var LOVE_COMMENT_FAILURE = 'LOVE_COMMENT_FAILURE';
exports.LOVE_COMMENT_FAILURE = LOVE_COMMENT_FAILURE;
var LOVE_COMMENT_SUCCESS = 'LOVE_COMMENT_SUCCESS';
exports.LOVE_COMMENT_SUCCESS = LOVE_COMMENT_SUCCESS;
var UNLOVE_COMMENT_REQUEST = 'UNLOVE_COMMENT_REQUEST';
exports.UNLOVE_COMMENT_REQUEST = UNLOVE_COMMENT_REQUEST;
var UNLOVE_COMMENT_FAILURE = 'UNLOVE_COMMENT_FAILURE';
exports.UNLOVE_COMMENT_FAILURE = UNLOVE_COMMENT_FAILURE;
var UNLOVE_COMMENT_SUCCESS = 'UNLOVE_COMMENT_SUCCESS'; // Video Progress Tracking

exports.UNLOVE_COMMENT_SUCCESS = UNLOVE_COMMENT_SUCCESS;
var SAVE_VIDEO_PROGRESS = 'SAVE_VIDEO_PROGRESS';
exports.SAVE_VIDEO_PROGRESS = SAVE_VIDEO_PROGRESS;
var CLEAR_VIDEO_PROGRESS = 'CLEAR_VIDEO_PROGRESS';
exports.CLEAR_VIDEO_PROGRESS = CLEAR_VIDEO_PROGRESS;
var SET_VIDEO_MODAL_CLOSED = 'SET_VIDEO_MODAL_CLOSED';
exports.SET_VIDEO_MODAL_CLOSED = SET_VIDEO_MODAL_CLOSED;
var RESET_VIDEO_MODAL_CLOSED = 'RESET_VIDEO_MODAL_CLOSED'; // Follow Invitation

exports.RESET_VIDEO_MODAL_CLOSED = RESET_VIDEO_MODAL_CLOSED;
var FOLLOW_INVITE_REQUEST = 'FOLLOW_INVITE_REQUEST';
exports.FOLLOW_INVITE_REQUEST = FOLLOW_INVITE_REQUEST;
var FOLLOW_INVITE_FAILURE = 'FOLLOW_INVITE_FAILURE';
exports.FOLLOW_INVITE_FAILURE = FOLLOW_INVITE_FAILURE;
var FOLLOW_INVITE_SUCCESS = 'FOLLOW_INVITE_SUCCESS'; // Unfollow Invitation

exports.FOLLOW_INVITE_SUCCESS = FOLLOW_INVITE_SUCCESS;
var UNFOLLOW_INVITE_REQUEST = 'UNFOLLOW_INVITE_REQUEST';
exports.UNFOLLOW_INVITE_REQUEST = UNFOLLOW_INVITE_REQUEST;
var UNFOLLOW_INVITE_FAILURE = 'UNFOLLOW_INVITE_FAILURE';
exports.UNFOLLOW_INVITE_FAILURE = UNFOLLOW_INVITE_FAILURE;
var UNFOLLOW_INVITE_SUCCESS = 'UNFOLLOW_INVITE_SUCCESS';
exports.UNFOLLOW_INVITE_SUCCESS = UNFOLLOW_INVITE_SUCCESS;