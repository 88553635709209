import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NavBar from '../../NavBar';
import PackageHero from '../components/PackageHero';
import CompareServices from '../components/CompareServices';
import FooterSection from '../../shared/Footer/FooterSection';
import FirstStepIndicator from '../components/FirstStepIndicator';
import FamilyProtected from '../components/FamilyProtected';
import EverdaysGuaranteeSection from '../../EverdaysGuaranteeSection';
import Testimonials from '../../Testimonials';
import LowCostTodayCTA from '../components/LowCostTodayCTA';
import FAQ from '../../FAQ';
import ReadyToPlan from '../../ReadyToPlan';
import LoaderComponent from '@evdy/web-core/dist/components/shared/elements/LoaderComponent';

import { useScrollToTop } from '@evdy/web-core/dist/customHooks';
import { useGetWebleadFromCookie } from './utils/customHooks';

import { preneedPackagesOperations } from '@evdy/web-redux/dist/lib/webPurchase/preneedPackages';
import { useHideIntercom, useVerifyCookie } from '../../../customHooks';

import styles from './PackageDetails.module.scss';

import sallyN from '../../../public/images/YA-GIRL-SALLY.jpg';
import doloresH from '../../../public/images/dolores-h-profile.jpg';
import johnC from '../../../public/images/john-c-profile.jpg';

const { getPreneedPackages } = preneedPackagesOperations;

const { 'package-details': packagesClass } = styles;

const PackageDetails = ({ location }) => {
  const dispatch = useDispatch();
  const {
    authUser = {},
    preneedPackages = [],
    purchaseFlow = {},
    isFetchingPackages
  } = useSelector(({ authUser, webPurchase, isFetchingPackages }) => ({
    authUser,
    isFetchingPackages: webPurchase.preneedPackages?.isFetching,
    preneedPackages: webPurchase.preneedPackages?.data?.packages,
    purchaseFlow: webPurchase.purchaseFlow
  }));

  // assign most prioritized user data
  const { data: userData, isFetching: isUserFetching } = useMemo(() => {
    return !!authUser?.data?._id ? authUser : purchaseFlow;
  }, [authUser, purchaseFlow]);
  const { _id: userId } = userData || {};

  const packagePath = location?.pathname.split('/')[2];
  const packageGivenPath = ({ name }) => name.toLowerCase().replace(/\s/g, '-') === packagePath;
  const selectedPackage = preneedPackages.find(packageGivenPath);
  const {
    consumerServices,
    colors,
    name,
    description,
    packageId,
    experiencePrice,
    experienceMonthlyPrice
  } = selectedPackage ?? {};

  const testimonials = [
    {
      name: 'Sally N.',
      image: sallyN,
      stars: 5,
      copy: 'I kept putting it off, but knew I should get these decisions written down. Everdays made it so easy, and I had a nice plan I could email to my kids in less than 10 minutes.'
    },
    {
      name: 'Dolores H.',
      image: doloresH,
      stars: 5,
      copy: 'Everdays made it so easy to take care of my cremation, there was no reason not to do it. Now it’s done and my kids have what they need all ready when they need it.'
    },
    {
      name: 'John C.',
      image: johnC,
      stars: 5,
      copy: 'I knew I needed funeral insurance but just kept putting it off. It only took a few minutes, no health screening, and all online. I don’t know why I waited so long!'
    }
  ];
  // scroll to top on mount
  useScrollToTop();

  // on mount, check if weblead cookie exists and fetch existing weblead
  useGetWebleadFromCookie();

  //get preneed packages
  useEffect(() => {
    if (!preneedPackages?.length) {
      dispatch(getPreneedPackages(!!userId));
    }
  }, [dispatch, preneedPackages.length, userId]);

  //hide intercom
  useHideIntercom();

  const ctaLink = useVerifyCookie('rq-sid') ? '/get-my-price' : 'https://try.everdays.com/plan';

  const shouldRenderLoader = !!isUserFetching || isFetchingPackages;

  return (
    <div className={packagesClass}>
      <NavBar {...{ location, ctaLink }} />
      <FirstStepIndicator />
      <PackageHero {...{ name, selectedPackage }} />
      <FamilyProtected />
      <CompareServices {...{ consumerServices, colors, description, packageId }} />
      <LowCostTodayCTA {...{ experiencePrice, experienceMonthlyPrice }} />
      <EverdaysGuaranteeSection />
      <Testimonials {...{ testimonials }} />
      <FAQ />
      <ReadyToPlan />
      <FooterSection
        firstAsteriskCopy="*Based on historical inflation rates and typical life expectancy."
        secondAsteriskCopy="Texas consumers visit www.prepaidfunerals.texas.gov for more information."
      />
      {shouldRenderLoader && <LoaderComponent />}
    </div>
  );
};

export default PackageDetails;
