import React from 'react';

import { useVerifyCookie } from '../../customHooks';

import laptop from '../../public/images/ready_to_plan_laptop.png';
import goldElipse from '../../public/images/gold_elipse.png';

import styles from './ReadyToPlan.module.scss';

const {
  'ready-to-plan': readyToPlanClass,
  decoration: decorationClass,
  ellipse: ellipseClass,
  laptop: laptopClass,
  body: bodyClass,
  cta: ctaClass
} = styles;

const ReadyToPlan = () => {
  const ctaLink = useVerifyCookie('rq-sid') ? '/get-my-price' : 'https://try.everdays.com/plan';

  return (
    <div className={readyToPlanClass}>
      <div className={decorationClass}>
        <img className={ellipseClass} src={goldElipse} alt="" />
        <img className={laptopClass} src={laptop} alt="" />
      </div>
      <div className={bodyClass}>
        <h2>Ready to make your plan?</h2>
        <p>
          Take the first step to protect your future and your family by getting a plan in place on
          Everdays.
        </p>
        <a className={ctaClass} href={ctaLink}>
          <button>Start My Free Plan</button>
        </a>
      </div>
    </div>
  );
};

export default ReadyToPlan;
