"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = configureStore;

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

var _reduxThunk = _interopRequireDefault(require("redux-thunk"));

var _reduxReset = _interopRequireDefault(require("redux-reset"));

var _reduxLogger = require("redux-logger");

var _redux = require("redux");

var _reactRouterRedux = require("react-router-redux");

var _index = require("@sentry/browser/dist/index");

var _reducers = _interopRequireDefault(require("@evdy/web-redux/dist/reducers"));

var _promiseMiddleware = _interopRequireDefault(require("./promiseMiddleware"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

// Sentry middleware
var sentryReporter = function sentryReporter() {
  return function (next) {
    return function (action) {
      (0, _index.addBreadcrumb)({
        message: action.type,
        category: 'redux action',
        level: 'info',
        data: {}
      });
      return next(action);
    };
  };
};
/*
 * @param {Object} initial state to bootstrap our stores with for server-side rendering
 * @param {History Object} a history object. We use `createMemoryHistory` for server-side rendering,
 *                          while using browserHistory for client-side
 *                          rendering.
 */


function configureStore(initialState, history) {
  var middleware = [_reduxThunk.default, _promiseMiddleware.default, (0, _reactRouterRedux.routerMiddleware)(history)];
  var store;

  if (global.__DEVCLIENT__) {
    middleware.push((0, _reduxLogger.createLogger)());
    store = (0, _redux.createStore)(_reducers.default, initialState, (0, _redux.compose)(_redux.applyMiddleware.apply(void 0, middleware), (0, _reduxReset.default)(), (typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' ? window.__REDUX_DEVTOOLS_EXTENSION__() : function (f) {
      return f;
    }));
  } else {
    global.window && window.Sentry && middleware.push(sentryReporter);
    store = (0, _redux.createStore)(_reducers.default, initialState, (0, _redux.compose)(_redux.applyMiddleware.apply(void 0, middleware), (0, _reduxReset.default)(), function (f) {
      return f;
    }));
  }

  return store;
}