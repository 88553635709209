import React from 'react';

import styles from './PrintPackage.module.scss';

import printPackage from '../../../../public/images/print-package-printer.svg';

const { 'print-package': printPackageClass, 'copy-container': copyClass, image: imgClass } = styles;

const PrintPackage = ({
  titleCopy = 'Print this Package',
  bodyCopy = 'It’s always great to review important decisions with your family. You can share this URL or download an easy to print PDF by clicking here.'
}) => {
  return (
    <div className={printPackageClass}>
      <div className={imgClass}>
        <img src={printPackage} alt="" />
      </div>
      <div className={copyClass}>
        <h2>{titleCopy}</h2>
        <p>{bodyCopy}</p>
      </div>
      <a href="https://try.everdays.com/packages" target="_blank" rel="noopener noreferrer">
        <button>Print Package</button>
      </a>
    </div>
  );
};

export default PrintPackage;
