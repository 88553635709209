"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CALCULATE_PRICE_FAILURE = exports.CALCULATE_PRICE_SUCCESS = exports.CALCULATE_PRICE_REQUEST = void 0;
var CALCULATE_PRICE_REQUEST = 'CALCULATE_PRICE_REQUEST';
exports.CALCULATE_PRICE_REQUEST = CALCULATE_PRICE_REQUEST;
var CALCULATE_PRICE_SUCCESS = 'CALCULATE_PRICE_SUCCESS';
exports.CALCULATE_PRICE_SUCCESS = CALCULATE_PRICE_SUCCESS;
var CALCULATE_PRICE_FAILURE = 'CALCULATE_PRICE_FAILURE';
exports.CALCULATE_PRICE_FAILURE = CALCULATE_PRICE_FAILURE;