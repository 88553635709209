"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EDIT_ACCOUNT_RESET = exports.EDIT_ACCOUNT_FAILURE = exports.EDIT_ACCOUNT_SUCCESS = exports.EDIT_ACCOUNT_REQUEST = void 0;
var EDIT_ACCOUNT_REQUEST = 'EDIT_ACCOUNT_REQUEST';
exports.EDIT_ACCOUNT_REQUEST = EDIT_ACCOUNT_REQUEST;
var EDIT_ACCOUNT_SUCCESS = 'EDIT_ACCOUNT_SUCCESS';
exports.EDIT_ACCOUNT_SUCCESS = EDIT_ACCOUNT_SUCCESS;
var EDIT_ACCOUNT_FAILURE = 'EDIT_ACCOUNT_FAILURE';
exports.EDIT_ACCOUNT_FAILURE = EDIT_ACCOUNT_FAILURE;
var EDIT_ACCOUNT_RESET = 'EDIT_ACCOUNT_RESET';
exports.EDIT_ACCOUNT_RESET = EDIT_ACCOUNT_RESET;