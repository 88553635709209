"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requiredPreneedFields = void 0;

var types = _interopRequireWildcard(require("./constants"));

var editCompanyActions = _interopRequireWildcard(require("../../constants/dash/editCompany"));

var editHomeActions = _interopRequireWildcard(require("../funeralHome/constants"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialState = {
  data: {},
  error: {},
  isFetching: false,
  missingFieldsSubmitted: false,
  getFieldsLastUpdated: null,
  missingFieldsLastUpdated: null
};

var requiredPreneedFields = function requiredPreneedFields() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.GET_REQUIRED_FIELDS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true
      });

    case types.GET_REQUIRED_FIELDS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        data: action.payload,
        isFetching: false,
        getFieldsLastUpdated: Date.now()
      });

    case types.GET_REQUIRED_FIELDS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        isFetching: false,
        getFieldsLastUpdated: Date.now()
      });

    case editCompanyActions.EDIT_COMPANY_REQUEST:
    case editHomeActions.EDIT_FUNERAL_HOME_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        missingFieldsSubmitted: true
      });

    case editCompanyActions.EDIT_COMPANY_SUCCESS:
    case editHomeActions.EDIT_FUNERAL_HOME_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        missingFieldsSubmitted: false,
        missingFieldsLastUpdated: Date.now()
      });

    case editCompanyActions.EDIT_COMPANY_FAILURE:
    case editHomeActions.EDIT_FUNERAL_HOME_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        missingFieldsSubmitted: false,
        missingFieldsLastUpdated: Date.now()
      });

    default:
      return state;
  }
};

exports.requiredPreneedFields = requiredPreneedFields;