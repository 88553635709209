"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CHECK_SHELL_USER_FAILURE = exports.CHECK_SHELL_USER_SUCCESS = exports.CHECK_SHELL_USER_REQUEST = void 0;
var CHECK_SHELL_USER_REQUEST = 'CHECK_SHELL_USER_REQUEST';
exports.CHECK_SHELL_USER_REQUEST = CHECK_SHELL_USER_REQUEST;
var CHECK_SHELL_USER_SUCCESS = 'CHECK_SHELL_USER_SUCCESS';
exports.CHECK_SHELL_USER_SUCCESS = CHECK_SHELL_USER_SUCCESS;
var CHECK_SHELL_USER_FAILURE = 'CHECK_SHELL_USER_FAILURE';
exports.CHECK_SHELL_USER_FAILURE = CHECK_SHELL_USER_FAILURE;