"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.archiveInviteFailure = exports.archiveInviteSuccess = exports.archiveInviteRequest = exports.resetInviteDetailsAction = exports.fetchInviteDetailsFailure = exports.fetchInviteDetailsSuccess = exports.fetchInviteDetailsRequest = void 0;

var types = _interopRequireWildcard(require("./constants"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var fetchInviteDetailsRequest = function fetchInviteDetailsRequest() {
  return {
    type: types.FETCH_INVITE_DETAILS_REQUEST
  };
};

exports.fetchInviteDetailsRequest = fetchInviteDetailsRequest;

var fetchInviteDetailsSuccess = function fetchInviteDetailsSuccess(_ref) {
  var data = _ref.data;
  return {
    type: types.FETCH_INVITE_DETAILS_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.fetchInviteDetailsSuccess = fetchInviteDetailsSuccess;

var fetchInviteDetailsFailure = function fetchInviteDetailsFailure(error) {
  return {
    type: types.FETCH_INVITE_DETAILS_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.fetchInviteDetailsFailure = fetchInviteDetailsFailure;

var resetInviteDetailsAction = function resetInviteDetailsAction() {
  return {
    type: types.INVITE_DETAILS_RESET
  };
}; // Archive Invite


exports.resetInviteDetailsAction = resetInviteDetailsAction;

var archiveInviteRequest = function archiveInviteRequest() {
  return {
    type: types.ARCHIVE_INVITE_REQUEST
  };
};

exports.archiveInviteRequest = archiveInviteRequest;

var archiveInviteSuccess = function archiveInviteSuccess(_ref2) {
  var data = _ref2.data;
  return {
    type: types.ARCHIVE_INVITE_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.archiveInviteSuccess = archiveInviteSuccess;

var archiveInviteFailure = function archiveInviteFailure(error) {
  return {
    type: types.FETCH_INVITE_DETAILS_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.archiveInviteFailure = archiveInviteFailure;