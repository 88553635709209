"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DELETE_MEDIA_RESET = exports.DELETE_MEDIA_FAILURE = exports.DELETE_MEDIA_SUCCESS = exports.DELETE_MEDIA_REQUEST = void 0;
var DELETE_MEDIA_REQUEST = 'DELETE_MEDIA_REQUEST';
exports.DELETE_MEDIA_REQUEST = DELETE_MEDIA_REQUEST;
var DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
exports.DELETE_MEDIA_SUCCESS = DELETE_MEDIA_SUCCESS;
var DELETE_MEDIA_FAILURE = 'DELETE_MEDIA_FAILURE';
exports.DELETE_MEDIA_FAILURE = DELETE_MEDIA_FAILURE;
var DELETE_MEDIA_RESET = 'DELETE_MEDIA_RESET';
exports.DELETE_MEDIA_RESET = DELETE_MEDIA_RESET;