import React from 'react';

import MinimalNav from '../MinimalNav';
import FooterSection from '../shared/Footer/FooterSection';
import TermsFooter from '../shared/Footer/TermsFooter';

import { useHideIntercom } from '../../customHooks';

import whiteChevron from '../../public/images/icons/white-chevron-right.svg';

import styles from './ScheduleSuccess.module.scss';

const { 'schedule-success': scheduleSuccessClass, 'thank-you-hero': thankYouHeroClass } = styles;

const ScheduleSuccess = () => {
  // Hide Intercom
  useHideIntercom();

  return (
    <div className={scheduleSuccessClass}>
      <MinimalNav />
      <div className={thankYouHeroClass}>
        <h1>Thank you!</h1>
        <h6>We’ve sent you an email confirmation and look forward to speaking with you soon.</h6>
        <a href="/">
          Go to Homepage <img src={whiteChevron} />
        </a>
      </div>
      <FooterSection />
      <TermsFooter />
    </div>
  );
};

export default ScheduleSuccess;
