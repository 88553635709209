import React from 'react';
import { connect } from 'react-redux';

import PagesHeader from '@evdy/web-core/dist/components/PagesHeader2';
import PagesFooter from '@evdy/web-core/dist/components/PagesFooter';
import LegalTextHeader from '../../shared/LegalTextHeader';

import { renderAuthModal } from '@evdy/web-core/dist/lib/utils';

import './TOS.scss';

const TOS = ({ user, location, children }) => {
  return (
    <>
      <PagesHeader currentPage="terms" pathname={location.pathname} queryString={location.search} />
      <div className="terms-of-service">
        <LegalTextHeader />
        <div className="terms">
          <h1>User Terms of Service</h1>
          <p className="date-updated">
            Last Modified: <span>July 6, 2020</span>
          </p>
          <p>
            Welcome to <a href="/">Everdays.com</a>, the website of Everdays, Inc. (“<b>Everdays</b>
            ,” “<b>we</b>,” “<b>our</b>,” or “<b>us</b>”). This page explains the terms by which you
            may use our website, software and any online or mobile services (including, without
            limitation, our Mobile Applications) provided on or in connection with the service
            (collectively, the “<b>Service</b>”). By downloading, accessing or using the Service, or
            by clicking a button or checking a box marked “I Agree” (or something similar), you
            signify that you have read, understood, and agree to be bound by these User Terms of
            Service (this “Agreement”) and to the collection and use of your information as set
            forth in the <a href="privacy">User Privacy Policy</a> whether or not you are a
            registered user of our Service. Everdays reserves the right to modify these terms and
            will provide notice of these changes as described below. This Agreement applies to all
            visitors, users, and others who access the Service (“<b>Users</b>”).
          </p>
          <h5>
            PLEASE READ THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. THIS
            AGREEMENT CONTAINS A MANDATORY INDIVIDUAL ARBITRATION AND CLASS ACTION/JURY TRIAL WAIVER
            PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE
            DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS. YOU MUST READ AND AGREE TO THIS
            AGREEMENT BEFORE USING THE SERVICE. IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS
            CONTAINED HEREIN, YOU DO NOT HAVE OUR PERMISSION TO ACCESS OR USE THE SERVICE.
          </h5>
          <section>
            <h3>1. EVERDAYS SERVICE</h3>
            <h4>
              <b>1.1 Eligibility</b>
            </h4>
            <p>
              You may use the Service only if you can form a binding contract with Everdays, and
              only in compliance with this Agreement and all applicable local, state, national, and
              international laws, rules and regulations. Any use or access to the Service by anyone
              under eighteen (18) years is strictly prohibited and in violation of this Agreement.
              The Service is not available to any Users previously removed from the Service by
              Everdays.
            </p>
            <h4>
              <b>1.2 Limited License</b>
            </h4>
            <p>
              Subject to the terms and conditions of this Agreement, you are hereby granted a
              non-exclusive, limited, non-transferable, freely revocable license to use the Service
              as permitted by the features of the Service. Everdays reserves all rights not
              expressly granted herein in the Service and the Everdays Content (as defined below).
              Everdays may terminate this license at any time for any reason or no reason.
            </p>
            <h4>
              <b>1.3 User Accounts</b>
            </h4>
            <p>
              Your account on the Service (your “<b>User Account</b>”) gives you access to the
              services and functionality that we may establish and maintain from time to time and in
              our sole discretion. We may maintain different types of User Accounts for different
              types of Users. If you open a User Account on behalf of a company, organization, or
              other entity, then (i) “you” includes you and that entity; (ii) you represent and
              warrant that you are an authorized representative of the entity with the authority to
              bind the entity to this Agreement; and (iii) you agree to this Agreement on the
              entity’s behalf. By connecting to Everdays with a third-party service, you give us
              permission to access and use your information from that service as permitted by that
              service, and to store your log-in credentials for that service.
            </p>
            <p>
              You may never use another User’s User Account without our permission. When creating
              your User Account, you must provide accurate and complete information, and you must
              keep this information up to date. You are solely responsible for the activity that
              occurs on your User Account, and you must keep your User Account password secure. We
              encourage you to use “strong” passwords (passwords that use a combination of upper-
              and lower-case letters, numbers and symbols) with your User Account. You must notify
              Everdays immediately of any breach of security or unauthorized use of your User
              Account. Everdays will not be liable for any losses caused by any unauthorized use of
              your User Account.
            </p>
            <p>
              You may control your User profile and how you interact with the Service by changing
              the settings in your settings page. By providing Everdays your email address you
              consent to our using the email address to send you Service-related notices, including
              any notices required by law, in lieu of communication by postal mail. We may also use
              your email address to send you other messages, such as changes to features of the
              Service and special offers. If you do not want to receive such email messages, you may
              opt out or change your preferences in your settings page. Opting out may prevent you
              from receiving email messages regarding updates, improvements, or offers.
            </p>
            <h4>
              <b>1.4 Changes to the Service</b>
            </h4>
            <p>
              We may, without prior notice, change the Service; stop providing the Service or
              features of the Service, to you or to Users generally; or create usage limits for the
              Service. We may permanently or temporarily terminate or suspend your access to the
              Service without notice and liability for any reason, including if in our sole
              determination you violate any provision of this Agreement, or for no reason. Upon
              termination for any reason or no reason, you continue to be bound by this Agreement.
            </p>
            <h4>
              <b>1.5 Disputes with Other Users</b>
            </h4>
            <p>
              You are solely responsible for your interactions with other Users. We reserve the
              right, but have no obligation, to monitor disputes between you and other Users.
              Everdays shall have no liability for your interactions with other Users, or for any
              User’s action or inaction.
            </p>
            <h4>
              <b>1.6 Your Consent to This and Other Agreements</b>
            </h4>
            <p>
              When you sign up to use a special feature of this Service, or when we notify you of
              updates to the Service, you may be asked to agree to special terms governing your use
              of the special feature. In such cases, you may be asked to expressly consent to the
              special terms, for example, by checking a box or clicking on a button marked “I
              agree”. This type of agreement is known as a “click-through” agreement. If any of the
              terms of the click-through agreement are different than the terms of these User Terms
              of Service, the terms of the click-through agreement will supplement or amend these
              User Terms of Service, but only with respect to the matters governed by the
              click-through agreement.
            </p>
          </section>
          <section>
            <h3>2. PRODUCTS AND SERVICES</h3>
            <p>
              Everdays does not provide, own or control any of the funeral-related products and
              services that you can access through our Service (the “Funeral Products”). The Funeral
              Products are owned, controlled or made available by third parties, including, but not
              limited to, funeral homes (the “Funeral Homes”). The Funeral Homes are responsible for
              the Funeral Products. The Funeral Homes’ terms and privacy policies apply to your
              purchases so you should make yourself aware of and agree to those terms. Your
              interaction with any Funeral Home through our Service is at your own risk; Everdays
              does not bear any responsibility should anything go wrong with your Funeral Products.
              The display on our Service of a funeral product, funeral service and/or funeral home
              does not – in any way – imply, suggest or constitute a recommendation by Everdays of
              that funeral product, funeral service or funeral home, or any sponsorship or approval
              of the funeral home by Everdays, or any affiliation between such funeral home and
              Everdays.
            </p>
            <p>
              Everdays hosts content, including descriptions and pricing of Funeral Products and
              Services, made available by or obtained from Funeral Homes. Everdays is in no way
              responsible for the accuracy, timeliness or completeness of such content. Pricing of
              Funeral Products and Services will vary between Funeral Homes. Further, since Everdays
              has no control over the Funeral Products and Services and does not verify the content
              uploaded by the Funeral Homes, it is not possible for us to guarantee the prices
              displayed on our Service until you have completed your purchase.
            </p>
            <p>
              Everdays also does not provide, own or control any of the preneed funeral insurance
              that you can access through our Service (the “Preneed Insurance”), which is made
              available by Homesteaders Life Company or any other insurance providers we make
              available (“Insurance Carriers”). Insurance Carriers are responsible for the Preneed
              Insurance. Insurance Carriers’ terms and privacy policies apply to your purchases so
              you should make yourself aware of and agree to those terms. Your interaction with
              Insurance Carriers through our Service is at your own risk; Everdays does not bear any
              responsibility should anything go wrong with your Preneed Insurance. Everdays is an
              authorized insurance agent for the Insurance Carriers and receives compensation from
              them for most policies sold through the Service.
            </p>
            <p>
              Everdays hosts content, including descriptions and pricing of Preneed Insurance, made
              available by or obtained from Insurance Carriers. Everdays is in no way responsible
              for the accuracy, timeliness or completeness of such content. Everdays has no control
              over the Preneed Insurance and does not verify the content uploaded by Insurance
              Carriers. Further, the prices change based on your answers to our questions and
              information collected during your application, your choices for different products, or
              due to any changes or elapsed time that may occur before your purchase is completed.
              Your final price isn’t final and set until you purchase the policy.
            </p>
          </section>
          <section>
            <h3>3. USER CONTENT</h3>
            <p>
              Some areas of the Service allow Users to submit, post, display, provide, or otherwise
              make available content such as profile information, images, photographs, videos,
              illustrations, graphics, sound recordings, posts, comments, questions, data, works,
              texts, streaming video and/or audio, and other content or information (any such
              materials a User submits, posts, displays, provides, or otherwise makes available on
              the Service is referred to as “<b>User Content</b>”).
            </p>
            <p>
              WE CLAIM NO OWNERSHIP RIGHTS OVER USER CONTENT CREATED BY YOU. THE USER CONTENT YOU
              CREATE REMAINS YOURS. However, you understand that certain portions of the Service may
              allow other Users to view, edit, share, and/or otherwise interact with your User
              Content. By providing or sharing User Content through the Service, you agree to allow
              others to view, edit, share, and/or interact with your User Content in accordance with
              your settings and this Agreement. Further, you allow us to record audio and/or video
              that you stream through the Service, and to make it available to others to view, edit,
              share and/or interact with in accordance you’re your settings and this Agreement.
              Everdays has the right (but not the obligation) in its sole discretion to remove any
              User Content that is shared via the Service.
            </p>
            <p>
              By submitting, posting, displaying, providing, or otherwise making available any User
              Content on or through the Service, you expressly grant, and you represent and warrant
              that you have all rights necessary to grant, to Everdays a royalty-free,
              sublicensable, transferable, perpetual, irrevocable, non-exclusive, worldwide license
              to use, reproduce, modify, publish, list information regarding, edit, translate,
              distribute, syndicate, publicly perform, publicly display, and make derivative works
              of all such User Content and your name, voice, and/or likeness as contained in your
              User Content, in whole or in part, and in any form, media or technology, whether now
              known or hereafter developed, for use in connection with the Service and Everdays’
              (and its successors’ and affiliates’) business, including without limitation for
              promoting and redistributing part or all of the Service (and derivative works thereof)
              in any media formats and through any media channels. You also hereby grant each User
              of the Service a non-exclusive license to access your User Content through the
              Service, and to use, reproduce, distribute, display and perform such User Content as
              permitted through the functionality of the Service and under this Agreement.
            </p>
            <p>
              For the purposes of this Agreement, “<b>Intellectual Property Rights</b>” means all
              patent rights, copyright rights, mask work rights, moral rights, rights of publicity,
              trademark, trade dress and service mark rights, goodwill, trade secret rights and
              other intellectual property rights as may now exist or hereafter come into existence,
              and all applications therefore and registrations, renewals and extensions thereof,
              under the laws of any state, country, territory or other jurisdiction.
            </p>
            <p>
              In connection with your User Content, you affirm, represent and warrant the following:
            </p>
            <ul>
              <li>
                You have the written consent of each and every identifiable natural person in the
                User Content, if any, to use such person’s name or likeness in the manner
                contemplated by the Service and this Agreement, and each such person has released
                you from any liability that may arise in relation to such use.
              </li>
              <li>
                You have obtained and are solely responsible for obtaining all consents as may be
                required by law to post any User Content relating to third parties.
              </li>
              <li>
                Your User Content and Everdays’ use thereof as contemplated by this Agreement and
                the Service will not violate any law or infringe any rights of any third party,
                including but not limited to any Intellectual Property Rights and privacy rights.
              </li>
              <li>
                Everdays may exercise the rights to your User Content granted under this Agreement
                without liability for payment of any guild fees, residuals, payments, fees, or
                royalties payable under any collective bargaining agreement or otherwise.
              </li>
              <li>
                To the best of your knowledge, all your User Content and other information that you
                provide to us is truthful and accurate.
              </li>
            </ul>
            <p>
              Everdays takes no responsibility and assumes no liability for any User Content that
              you or any other User or third party posts, sends, or otherwise makes available over
              the Service. You shall be solely responsible for your User Content and the
              consequences of posting, publishing it, sharing it, or otherwise making it available
              on the Service, and you agree that we are only acting as a passive conduit for your
              online distribution and publication of your User Content. You understand and agree
              that you may be exposed to User Content that is inaccurate, objectionable,
              inappropriate for children, or otherwise unsuited to your purpose, and you agree that
              Everdays shall not be liable for any damages you allege to incur as a result of or
              relating to any User Content.
            </p>
          </section>
          <section>
            <h3>4. PROHIBITED USES</h3>
            <p>
              You may use the Services only for lawful purposes and in accordance with these Terms
              of Use and the Privacy Notice. To the extent you create an account through this
              Service, you understand and agree that any account you create, including your username
              and password, are personal to you and may not be used by anyone else. You are
              responsible for maintaining the confidentiality of your username and password and are
              fully responsible for all activities that occur under your username and password by
              you or by anyone else using your username and password, whether or not authorized by
              you. You agree to change your password immediately if you believe your password may
              have been compromised or used without authorization. You also agree to immediately
              inform us of any apparent breaches of security such as loss, theft or unauthorized
              disclosure or use of your username or password by contacting us using the information
              provided below. Until we are so notified, you will remain liable for any unauthorized
              use of your account. You agree not to use the Services in any of the following ways:
            </p>
            <ul>
              <li>
                In any way that intentionally or unintentionally violates any applicable federal,
                state, local or international law or regulation (including, without limitation, any
                laws regarding the export of data or software to and from the United States or other
                countries), or encouraging others to do so.
              </li>
              <li>
                For the purpose of exploiting, harming or attempting to exploit or harm minors in
                any way by exposing them to inappropriate content, asking for Personally
                Identifiable Information or otherwise.
              </li>
              <li>
                To transmit, or procure the sending of, any advertising or promotional material,
                including any “junk mail,” “chain letter,” or “spam” or any other similar
                solicitation.
              </li>
              <li>
                To impersonate or attempt to impersonate Everdays, an Everdays employee, another
                user, or any other person or entity (including, without limitation, by using e-mail
                associated with any of the foregoing).
              </li>
              <li>
                To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment
                of the Website, or which, as determined by us, may harm Everdays or users of the
                Service or expose them to liability.
              </li>
              <li>Additionally, you agree not to do any of the following:</li>
              <ul>
                <li>
                  Circumvent, disable, or otherwise interfere with security-related features of the
                  Service or features that prevent or restrict use or copying of any content or
                  enforce limitations on the use of our Service or any content on the Service.
                </li>
                <li>Publicly disparage anyone or any User Content.</li>
                <li>
                  Use the Service in any manner that could disable, overburden, damage, or impair
                  the Service or interfere with any other party’s use of the Service, including its
                  ability to engage in real time activities through the Service.
                </li>
                <li>
                  Use any robot, spider, “scraper,” “crawler,” or other automatic device, process,
                  or means to access the Service for any purpose, including monitoring or copying
                  any of the material on the Service.
                </li>
                <li>
                  Use any manual process to monitor or copy any of the material on the Service or
                  for any other unauthorized purpose without our prior written consent.
                </li>
                <li>
                  Use any device, software, or routine that interferes with the proper working of
                  the Service.
                </li>
                <li>
                  Introduce any viruses, trojan horses, worms, logic bombs, or other material that
                  is malicious or technologically harmful.
                </li>
                <li>
                  Attempt to gain unauthorized access to, interfere with, damage, or disrupt any
                  parts of the Service, the servers on which the Service is stored, or any server,
                  computer, or database connected to the Service.
                </li>
                <li>
                  Attack the Service via a denial-of-service attack or a distributed
                  denial-of-service attack.
                </li>
                <li>Otherwise attempt to interfere with the proper working of the Service.</li>
                <li>Modify copies of any materials from the Service.</li>
                <li>
                  Use any illustrations, photographs, video or audio sequences or any graphics from
                  the Service or any text.
                </li>
                <li>
                  Attempt to reverse engineer, decompile, hack, disable, interfere with,
                  disassemble, modify, copy, translate, or disrupt the features, functionality,
                  integrity, or performance of the Service, any third-party use of the Service, or
                  any third-party data contained therein.
                </li>
                <li>
                  Send altered, deceptive or false source-identifying information, including
                  “spoofing” or “phishing”.
                </li>
                <li>
                  Use contact or other user information obtained from the Service (including email
                  addresses) to contact Users outside the Service without their express permission
                  or authority or to create or distribute mailing lists or other collections of
                  contact or user profile information of Users for use outside the Service.
                </li>
                <li>
                  Delete or alter any copyright, trademark, or other proprietary rights notices from
                  copies of materials from the Service.
                </li>
                <li>
                  Authorize, permit, enable, induce or encourage any third party to do any of the
                  above.
                </li>
              </ul>
            </ul>
          </section>
          <section>
            <h3>5. OUR PROPRIETARY RIGHTS</h3>
            <p>
              Except for your User Content, the Service and all materials therein or transferred
              thereby, including, without limitation, software, images, text, graphics,
              illustrations, logos, patents, trademarks, service marks, copyrights, photographs,
              audio, videos, music, and User Content belonging to other Users (the “Everdays
              Content”), and all Intellectual Property Rights related thereto, are the exclusive
              property of Everdays and its licensors (including other Users who post User Content to
              the Service). Except as explicitly provided herein, nothing in this Agreement shall be
              deemed to create a license in or under any such Intellectual Property Rights, and you
              agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit,
              publicly display, publicly perform, publish, adapt, edit or create derivative works
              from any Everdays Content. Use of the Everdays Content for any purpose not expressly
              permitted by this Agreement is strictly prohibited. You are not authorized to display
              or use trademarks, product names, company names, logos, service marks and/or trade
              dress of other owners featured within this Service without the prior written
              permission of such owners.
            </p>
            <p>
              You may choose to, or we may invite you to, submit comments or ideas about the
              Service, including without limitation about how to improve the Service or our products
              (“<b>Ideas</b>”). By submitting any Idea, you agree that your disclosure is
              gratuitous, unsolicited and without restriction and will not place Everdays under any
              fiduciary or other obligation, and that we are free to use the Idea without any
              additional compensation to you, and/or to disclose the Idea on a non-confidential
              basis or otherwise to anyone. You further acknowledge that, by acceptance of your
              submission, Everdays does not waive any rights to use similar or related ideas
              previously known to Everdays, or developed by its employees, or obtained from sources
              other than you.
            </p>
          </section>
          <section>
            <h3>6. CALIFORNIA RESIDENTS</h3>
            <p>
              The provider of services is Everdays, Inc. If you are a California resident, in
              accordance with Cal. Civ. Code §1789.3, you may report complaints to the Complaint
              Assistance Unit of the Division of Consumer Services of the California Department of
              Consumer Affairs by contacting them in writing at 1625 North Market Blvd., Suite N 112
              Sacramento, CA 95834, or by telephone at (800) 952-5210 or (916) 445-1254.
            </p>
          </section>
          <section>
            <h3>7. ELECTRONIC COMMUNICATIONS</h3>
            <h4>
              <b>7.1 Text Messaging</b>
            </h4>
            <p>
              We offer you the chance to enroll to receive SMS/text messages from Everdays. You may
              enroll to receive text messages about account-related news and alerts. By enrolling in
              Everdays’ SMS/text messaging service, you agree to receive text messages from Everdays
              to your mobile phone number provided, and you certify that your mobile number provided
              is true and accurate and that you are authorized to enroll the designated mobile
              number to receive such text messages. You are solely responsible for keeping us
              updated with your current phone number, respectively updating to the latest version of
              the mobile app, and for any charges incurred by receiving such messages. We will not
              be liable for information sent to a device that is associated with your outdated
              mobile phone number or using an outdated app. You acknowledge and agree that the text
              messages may be sent using an automatic telephone dialing system and that standard
              message and data rates apply. Consent is not required as a condition of purchase.
            </p>
            <p>
              To unsubscribe from text messages at any time, reply STOP to any text message you
              receive from Everdays. You consent that following such a request to unsubscribe, you
              may receive one final text message from Everdays confirming your request. For help
              contact us at <a href="mailto:support@everdays.com">support@everdays.com</a>.
            </p>
            <h4>
              <b>7.2 Electronic Signature Agreement</b>
            </h4>
            <p>
              By selecting the “I Accept” or “I Agree” button (or something similar), you are
              signing the Agreement electronically. You agree your electronic signature is the legal
              equivalent of your manual signature on this Agreement. You further agree that your use
              of a key pad, mouse or other device to select an item, button, icon or similar
              act/action, in accessing or making any transaction regarding any agreement,
              acknowledgement, consent terms, disclosures or conditions constitutes your signature
              (hereafter referred to as “E-Signature”), acceptance and agreement as if actually
              signed by you in writing. You also agree that no certification authority or other
              third-party verification is necessary to validate your E-Signature and that the lack
              of such certification or third-party verification will not in any way affect the
              enforceability of your E-Signature or any resulting contract between you and Everdays.
              You also represent that you are authorized to enter into this Agreement for all
              persons who own or are authorized to access any of your accounts and that such persons
              will be bound by the terms of this Agreement. You further agree that each use of your
              E-Signature in connection with the Service constitutes your agreement to be bound by
              the terms and conditions of the Agreement.
            </p>
            <h4>
              <b>7.3 Electronic Delivery</b>
            </h4>
            <p>
              When you use the Service or send emails or texts to us, you are communicating with us
              electronically. You consent to receive communications from us electronically. We will
              communicate with you by email or by posting notices on our website. You agree that all
              agreements, notices, disclosures and other communications that we provide to you
              electronically satisfy any legal requirement that such communication be in writing.
              You are responsible for providing, at your expense, any access to the internet and any
              required equipment.
            </p>
          </section>
          <section>
            <h3>8. PRIVACY</h3>
            <p>
              We care about the privacy of our Users. You understand that by using the Services you
              consent to the collection, use and disclosure of your personally identifiable
              information and aggregate and/or anonymized data as set forth in our{' '}
              <a href="/privacy">User Privacy Policy</a>.
            </p>
          </section>
          <section>
            <h3>9. SECURITY</h3>
            <p>
              Everdays uses commercially reasonable physical, managerial, and technical safeguards
              to preserve the integrity and security of your personal information and implement your
              privacy settings. However, we cannot guarantee that unauthorized third parties will
              never be able to defeat our security measures or use your personal information for
              improper purposes. You acknowledge that you provide your personal information at your
              own risk.
            </p>
          </section>
          <section>
            <h3>10. DMCA NOTICE</h3>
            <p>
              Since we respect artist and content owner rights, it is Everdays’ policy to respond to
              alleged infringement notices that comply with the Digital Millennium Copyright Act of
              1998 (“<b>DMCA</b>”).
            </p>
            <p>
              If you believe that your copyrighted work has been copied in a way that constitutes
              copyright infringement and is accessible via the Service, please notify Everdays’
              copyright agent as set forth in the DMCA. For your complaint to be valid under the
              DMCA, you must provide the following information in writing:
            </p>
            <ol>
              <li>
                An electronic or physical signature of a person authorized to act on behalf of the
                copyright owner;
              </li>
              <li>Identification of the copyrighted work that you claim has been infringed;</li>
              <li>
                Identification of the material that is claimed to be infringing and where it is
                located on the Service;
              </li>
              <li>
                Information reasonably sufficient to permit Everdays to contact you, such as your
                address, telephone number, and, e-mail address;
              </li>
              <li>
                A statement that you have a good faith belief that use of the material in the manner
                complained of is not authorized by the copyright owner, its agent, or law; and
              </li>
              <li>
                A statement, made under penalty of perjury, that the above information is accurate,
                and that you are the copyright owner or are authorized to act on behalf of the
                owner.
              </li>
            </ol>
            <p>The above information must be submitted to the following DMCA Agent:</p>
            <p>
              Attn: DMCA Notice
              <br />
              Everdays, Inc.
              <br />
              Address: 320 Martin St
              <br />
              Ste. 140
              <br />
              Birmingham MI, 48009
              <br />
              Tel.: 248-480-2444
              <br />
              Fax: 248-480-2402
              <br />
              Email: <a href="mailto:copyright@everdays.com">copyright@everdays.com</a>
            </p>
            <p>
              UNDER FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT THAT ONLINE MATERIAL IS INFRINGING,
              YOU MAY BE SUBJECT TO CRIMINAL PROSECUTION FOR PERJURY AND CIVIL PENALTIES, INCLUDING
              MONETARY DAMAGES, COURT COSTS, AND ATTORNEYS’ FEES.
            </p>
            <p>
              Please note that this procedure is exclusively for notifying Everdays and its
              affiliates that your copyrighted material has been infringed. The preceding
              requirements are intended to comply with Everdays’ rights and obligations under the
              DMCA, including 17 U.S.C. §512(c), but do not constitute legal advice. It may be
              advisable to contact an attorney regarding your rights and obligations under the DMCA
              and other applicable laws.
            </p>
            <p>
              In accordance with the DMCA and other applicable law, Everdays has adopted a policy of
              terminating, in appropriate circumstances, Users who are deemed to be repeat
              infringers. Everdays may also at its sole discretion limit access to the Service
              and/or terminate the User Accounts of any Users who infringe any intellectual property
              rights of others, whether or not there is any repeat infringement.
            </p>
          </section>
          <section>
            <h3>11. THIRD-PARTY LINKS AND INFORMATION</h3>
            <p>
              The Service may contain links to third-party materials that are not owned or
              controlled by Everdays. Everdays does not endorse or assume any responsibility for any
              such third-party sites, information, materials, products, or services. If you access a
              third-party website or service from the Service or share your User Content on or
              through any third-party website or service, you do so at your own risk, and you
              understand that this Agreement and the User Privacy Policy do not apply to your use of
              such sites. You expressly relieve Everdays from any and all liability arising from
              your use of any third-party website, service, or content, including without limitation
              User Content submitted by other Users. Additionally, your dealings with or
              participation in promotions of advertisers found on the Service, including payment and
              delivery of goods, and any other terms (such as warranties) are solely between you and
              such advertisers. You agree that Everdays shall not be responsible for any loss or
              damage of any sort relating to your dealings with such advertisers.
            </p>
            <p>
              We have engaged with third-party companies to enable users to complete the purchase of
              at-need and/or preneed funeral goods and services, funded by preneed insurance
              policies. These providers include (i) one or more agents who may assist with insurance
              agency, document processing, payment processing and/or compliance services, among
              other things; (ii) one or more life insurance companies, who will underwrite the
              insurance used to fund prearranged funeral products and services; and (iii) certain
              funeral homes, who will sell and provide the Funeral Products and Services you
              purchase. It may be necessary for you to accept and operate under the terms of service
              and/or privacy policy with those companies in order to complete Transactions.
            </p>
          </section>
          <section>
            <h3>12. INDEMNITY</h3>
            <p>
              You agree to defend, indemnify and hold harmless Everdays and its subsidiaries,
              agents, licensors, managers, and other affiliated companies, and their employees,
              contractors, agents, officers and directors, from and against any and all claims,
              damages, obligations, losses, liabilities, costs or debt, and expenses (including but
              not limited to attorney’s fees) arising from: (i) your use of and access to the
              Service, including any data or content transmitted or received by you; (ii) your
              violation of any term of this Agreement, including without limitation your breach of
              any of the representations and warranties above; (iii) your violation of any
              third-party right, including without limitation any right of privacy or Intellectual
              Property Rights; (iv) your violation of any applicable law, rule or regulation; (v)
              User Content or any content that is submitted via your User Account including without
              limitation misleading, false, or inaccurate information; (vi) your willful misconduct;
              or (vii) any other party’s access and use of the Service with your unique username,
              password or other appropriate security code.
            </p>
          </section>
          <section>
            <h3>13. NO WARRANTY</h3>
            <p>
              THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. USE OF THE SERVICE IS
              AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE IS
              PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT
              NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, OR NON-INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
              OBTAINED BY YOU FROM EVERDAYS OR THROUGH THE SERVICE WILL CREATE ANY WARRANTY NOT
              EXPRESSLY STATED HEREIN. WITHOUT LIMITING THE FOREGOING, EVERDAYS, ITS SUBSIDIARIES,
              ITS AFFILIATES, AND ITS LICENSORS DO NOT WARRANT THAT THE CONTENT IS ACCURATE,
              RELIABLE OR CORRECT; THAT THE SERVICE WILL MEET YOUR REQUIREMENTS; THAT THE SERVICE
              WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE; THAT
              ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE SERVICE IS FREE OF VIRUSES OR
              OTHER HARMFUL COMPONENTS. ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE
              OF THE SERVICE IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR
              ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM
              SUCH DOWNLOAD OR YOUR USE OF THE SERVICE.
            </p>
            <p>
              FURTHER, EVERDAYS DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR
              ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICE OR
              ANY HYPERLINKED WEBSITE OR SERVICE, AND EVERDAYS WILL NOT BE A PARTY TO OR IN ANY WAY
              MONITOR ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
            </p>
            <p>
              FEDERAL LAW, SOME STATES, PROVINCES AND OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION
              AND LIMITATIONS OF CERTAIN IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY
              TO YOU. THIS AGREEMENT GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER
              RIGHTS WHICH VARY FROM STATE TO STATE. THE DISCLAIMERS AND EXCLUSIONS UNDER THIS
              AGREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
            </p>
          </section>
          <section>
            <h3>14. LIMITATION OF LIABILITY</h3>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL EVERDAYS, ITS
              AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS OR LICENSORS BE LIABLE FOR ANY
              INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING
              WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
              INTANGIBLE LOSSES, ARISING OUT OF OR RELATING TO THE USE OF, OR INABILITY TO USE, THE
              SERVICE. UNDER NO CIRCUMSTANCES WILL EVERDAYS BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR
              INJURY RESULTING FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF THE
              SERVICE OR YOUR ACCOUNT OR THE INFORMATION CONTAINED THEREIN.
            </p>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EVERDAYS ASSUMES NO LIABILITY OR
              RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (II) PERSONAL
              INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR
              USE OF OUR SERVICE; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
              AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY INTERRUPTION OR
              CESSATION OF TRANSMISSION TO OR FROM THE SERVICE; (V) ANY BUGS, VIRUSES, TROJAN
              HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD
              PARTY; (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED
              AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE
              AVAILABLE THROUGH THE SERVICE; AND/OR (VII) USER CONTENT OR THE DEFAMATORY, OFFENSIVE,
              OR ILLEGAL CONDUCT OF ANY THIRD PARTY. IN NO EVENT SHALL EVERDAYS, ITS AFFILIATES,
              AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS, OR LICENSORS BE LIABLE TO YOU FOR ANY CLAIMS,
              PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN AN AMOUNT EXCEEDING
              THE AMOUNT YOU PAID TO EVERDAYS HEREUNDER OR $100.00, WHICHEVER IS GREATER.
            </p>
            <p>
              THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON
              CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF EVERDAYS HAS
              BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
            </p>
            <p>
              SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL
              DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. THIS AGREEMENT
              GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM
              STATE TO STATE. THE DISCLAIMERS, EXCLUSIONS, AND LIMITATIONS OF LIABILITY UNDER THIS
              AGREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
            </p>
          </section>
          <section>
            <h3>15. GOVERNING LAW, ARBITRATION, AND CLASS ACTION/JURY TRIAL WAIVER</h3>
            <h4>
              <b>15.1 Governing Law</b>
            </h4>
            <p>
              This Agreement shall be governed by the internal substantive laws of Michigan, without
              respect to its conflict of laws principles. The parties acknowledge that this
              Agreement evidences a transaction involving interstate commerce. Notwithstanding the
              preceding sentences with respect to the substantive law, any arbitration conducted
              pursuant to the terms of this Agreement shall be governed by the Federal Arbitration
              Act (9 U.S.C. §§ 1-16). The application of the United Nations Convention on Contracts
              for the International Sale of Goods is expressly excluded. You agree to submit to the
              personal jurisdiction of the federal and state courts located in Oakland County,
              Michigan for any actions for which we retain the right to seek injunctive or other
              equitable relief in a court of competent jurisdiction to prevent the actual or
              threatened infringement, misappropriation or violation of a our copyrights,
              trademarks, trade secrets, patents, or other intellectual property or proprietary
              rights, as set forth in the Arbitration provision below, including any provisional
              relief required to prevent irreparable harm. You agree that Oakland County, Michigan
              is the proper forum for any appeals of an arbitration award or for trial court
              proceedings in the event that the arbitration provision below is found to be
              unenforceable.
            </p>
            <h4>
              <b>15.2 Arbitration</b>
            </h4>
            <p>
              READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE PARTIES TO ARBITRATE THEIR
              DISPUTES AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM EVERDAYS. For any
              dispute with Everdays, you agree to first contact us at{' '}
              <a href="mailto:legal@everdays.com">legal@everdays.com</a> and attempt to resolve the
              dispute with us informally. In the unlikely event that Everdays has not been able to
              resolve a dispute it has with you after sixty (60) days, we each agree to resolve any
              claim, dispute, or controversy (excluding any claims for injunctive or other equitable
              relief as provided below) arising out of or in connection with or relating to this
              Agreement, or the breach or alleged breach thereof (collectively, “Claims”), by
              binding arbitration by JAMS, under the Optional Expedited Arbitration Procedures then
              in effect for JAMS, except as provided herein. JAMS may be contacted at
              www.jamsadr.com. The arbitration will be conducted in Oakland County, Michigan, unless
              you and Everdays agree otherwise. If you are using the Service for commercial
              purposes, each party will be responsible for paying any JAMS filing, administrative
              and arbitrator fees in accordance with JAMS rules, and the award rendered by the
              arbitrator shall include costs of arbitration, reasonable attorneys’ fees and
              reasonable costs for expert and other witnesses. If you are an individual using the
              Service for non-commercial purposes: (i) JAMS may require you to pay a fee for the
              initiation of your case, unless you apply for and successfully obtain a fee waiver
              from JAMS; (ii) the award rendered by the arbitrator may include your costs of
              arbitration, your reasonable attorney’s fees, and your reasonable costs for expert and
              other witnesses; and (iii) you may sue in a small claims court of competent
              jurisdiction without first engaging in arbitration, but this does not absolve you of
              your commitment to engage in the informal dispute resolution process. Any judgment on
              the award rendered by the arbitrator may be entered in any court of competent
              jurisdiction. Nothing in this Section shall be deemed as preventing Everdays from
              seeking injunctive or other equitable relief from the courts as necessary to prevent
              the actual or threatened infringement, misappropriation, or violation of our data
              security, Intellectual Property Rights or other proprietary rights.
            </p>
            <h4>
              <b>15.3 CLASS ACTION/JURY TRIAL WAIVER</b>
            </h4>
            <p>
              WITH RESPECT TO ALL PERSONS AND ENTITIES, REGARDLESS OF WHETHER THEY HAVE OBTAINED OR
              USED THE SERVICE FOR PERSONAL, COMMERCIAL OR OTHER PURPOSES, ALL CLAIMS MUST BE
              BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
              ANY PURPORTED CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION OR
              OTHER REPRESENTATIVE PROCEEDING. THIS WAIVER APPLIES TO CLASS ARBITRATION, AND, UNLESS
              WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS.
              YOU AGREE THAT, BY ENTERING INTO THIS AGREEMENT, YOU AND EVERDAYS ARE EACH WAIVING THE
              RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION, COLLECTIVE ACTION,
              PRIVATE ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF ANY KIND.
            </p>
          </section>
          <section>
            <h3>16. ADDITIONAL TERMS FOR MOBILE APPLICATION</h3>
            <h4>
              <b>16.1 Mobile Applications</b>
            </h4>
            <p>
              We may make available software to access the Service via a mobile device (“
              <b>Mobile Applications</b>”). To use any Mobile Applications, you must have a mobile
              device that is compatible with the Mobile Applications. Everdays does not warrant that
              the Mobile Applications will be compatible with your mobile device. You may use mobile
              data in connection with the Mobile Applications and may incur additional charges from
              your wireless provider for these services. You agree that you are solely responsible
              for any such charges. Everdays hereby grants you a non-exclusive, non-transferable,
              revocable license to use a compiled code copy of the Mobile Applications for one
              Everdays User Account on one mobile device owned or leased solely by you, for your
              personal use. You may not: (i) modify, disassemble, decompile or reverse engineer the
              Mobile Applications, except to the extent that such restriction is expressly
              prohibited by law; (ii) rent, lease, loan, resell, sublicense, distribute or otherwise
              transfer the Mobile Applications to any third party or use the Mobile Applications to
              provide time sharing or similar services for any third party; (iii) make any copies of
              the Mobile Applications; (iv) remove, circumvent, disable, damage or otherwise
              interfere with security-related features of the Mobile Applications, features that
              prevent or restrict use or copying of any content accessible through the Mobile
              Applications, or features that enforce limitations on use of the Mobile Applications;
              or (v) delete the copyright and other proprietary rights notices on the Mobile
              Applications. You acknowledge that Everdays may from time to time issue upgraded
              versions of the Mobile Applications and may automatically electronically upgrade the
              version of the Mobile Applications that you are using on your mobile device. You
              consent to such automatic upgrading on your mobile device and agree that the terms and
              conditions of this Agreement will apply to all such upgrades. Any third-party code
              that may be incorporated in the Mobile Applications is covered by the applicable open
              source or third-party license EULA, if any, authorizing use of such code. The
              foregoing license grant is not a sale of the Mobile Applications or any copy thereof,
              and Everdays or its third-party partners or suppliers retain all right, title, and
              interest in the Mobile Applications (and any copy thereof). Any attempt by you to
              transfer any of the rights, duties or obligations hereunder, except as expressly
              provided for in this Agreement, is void. Everdays reserves all rights not expressly
              granted under this Agreement. If the Mobile Applications is being acquired on behalf
              of the United States Government, then the following provision applies. The Mobile
              Applications will be deemed to be “commercial computer software” and “commercial
              computer software documentation,” respectively, pursuant to DFAR Section 227.7202 and
              FAR Section 12.212, as applicable. Any use, reproduction, release, performance,
              display or disclosure of the Service and any accompanying documentation by the U.S.
              Government will be governed solely by this Agreement and is prohibited except to the
              extent expressly permitted by this Agreement. The Mobile Applications originates in
              the United States and is subject to United States export laws and regulations. The
              Mobile Applications may not be exported or re-exported to certain countries or those
              persons or entities prohibited from receiving exports from the United States. In
              addition, the Mobile Applications may be subject to the import and export laws of
              other countries. You agree to comply with all United States and foreign laws related
              to use of the Mobile Applications and the Service.
            </p>
            <h4>
              <b>16.2 Mobile Applications from Apple App Store</b>
            </h4>
            <p>
              The following applies to any Mobile Applications you acquire from the Apple App Store
              (“
              <b>Apple-Sourced Software</b>”): You acknowledge and agree that this Agreement is
              solely between you and Everdays, not Apple, Inc. (“<b>Apple</b>”) and that Apple has
              no responsibility for the Apple-Sourced Software or content thereof. Your use of the
              Apple-Sourced Software must comply with the App Store Terms of Service. You
              acknowledge that Apple has no obligation whatsoever to furnish any maintenance and
              support services with respect to the Apple-Sourced Software. In the event of any
              failure of the Apple-Sourced Software to conform to any applicable warranty, you may
              notify Apple, and Apple will refund the purchase price for the Apple-Sourced Software
              to you; to the maximum extent permitted by applicable law, Apple will have no other
              warranty obligation whatsoever with respect to the Apple-Sourced Software, and any
              other claims, losses, liabilities, damages, costs or expenses attributable to any
              failure to conform to any warranty will be solely governed by this Agreement and any
              law applicable to Everdays as provider of the software. You acknowledge that Apple is
              not responsible for addressing any claims of you or any third party relating to the
              Apple-Sourced Software or your possession and/or use of the Apple-Sourced Software,
              including, but not limited to: (i) product liability claims; (ii) any claim that the
              Apple-Sourced Software fails to conform to any applicable legal or regulatory
              requirement; and (iii) claims arising under consumer protection or similar
              legislation; and all such claims are governed solely by this Agreement and any law
              applicable to Everdays as provider of the software. You acknowledge that, in the event
              of any third-party claim that the Apple-Sourced Software or your possession and use of
              that Apple-Sourced Software infringes that third party’s intellectual property rights,
              Everdays, not Apple, will be solely responsible for the investigation, defense,
              settlement and discharge of any such intellectual property infringement claim to the
              extent required by this Agreement. You and Everdays acknowledge and agree that Apple,
              and Apple’s subsidiaries, are third-party beneficiaries of this Agreement as relates
              to your license of the Apple-Sourced Software, and that, upon your acceptance of the
              terms and conditions of this Agreement, Apple will have the right (and will be deemed
              to have accepted the right) to enforce this Agreement as relates to your license of
              the Apple-Sourced Software against you as a third-party beneficiary thereof.
            </p>
            <h4>
              <b>16.3 Mobile Applications from Google Play Store</b>
            </h4>
            <p>
              The following applies to any Mobile Applications you acquire from the Google Play
              Store (“<b>Google-Sourced Software</b>”): (i) you acknowledge that the Agreement is
              between you and Everdays only, and not with Google, Inc. (“<b>Google</b>”); (ii) your
              use of Google-Sourced Software must comply with Google’s then-current Google Play
              Store Terms of Service; (iii) Google is only a provider of the Google Play Store where
              you obtained the Google-Sourced Software; (iv) Everdays, and not Google, is solely
              responsible for its Google-Sourced Software; (v) Google has no obligation or liability
              to you with respect to Google-Sourced Software or the Agreement; and (vi) you
              acknowledge and agree that Google is a third-party beneficiary to the Agreement as it
              relates to Everdays’s Google-Sourced Software. The following applies to any Mobile
              Applications you acquire from the Google Play Store (“<b>Google-Sourced Software</b>
              ”): (i) you acknowledge that the Agreement is between you and Everdays only, and not
              with Google, Inc. (“<b>Google</b>”); (ii) your use of Google-Sourced Software must
              comply with Google’s then-current Google Play Store Terms of Service; (iii) Google is
              only a provider of the Google Play Store where you obtained the Google-Sourced
              Software; (iv) Everdays, and not Google, is solely responsible for its Google-Sourced
              Software; (v) Google has no obligation or liability to you with respect to
              Google-Sourced Software or the Agreement; and (vi) you acknowledge and agree that
              Google is a third-party beneficiary to the Agreement as it relates to Everdays’
              Google-Sourced Software.
            </p>
          </section>
          <section>
            <h3>17. GENERAL</h3>
            <h4>
              <b>17.1 Assignment</b>
            </h4>
            <p>
              This Agreement, and any rights and licenses granted hereunder, may not be transferred
              or assigned by you, but may be assigned by Everdays without restriction. Any attempted
              transfer or assignment in violation hereof shall be null and void.
            </p>
            <h4>
              <b>17.2 Notification Procedures and Changes to the Agreement</b>
            </h4>
            <p>
              Everdays may provide notifications, whether such notifications are required by law or
              are for marketing or other business-related purposes, to you via email notice, written
              or hard copy notice, or through posting of such notice on our website, as determined
              by Everdays in our sole discretion. Everdays reserves the right to determine the form
              and means of providing notifications to our Users, provided that you may opt out of
              certain means of notification as described in this Agreement. Everdays is not
              responsible for any automatic filtering you or your network provider may apply to
              email notifications we send to the email address you provide us. Everdays may, in its
              sole discretion, modify or update this Agreement from time to time, and so you should
              review this page periodically. When we change the Agreement in a material manner, we
              will update the ‘last modified’ date at the top of this page and notify you that
              material changes have been made to the Agreement. Your continued use of the Service
              after any such change constitutes your acceptance of the new User Terms of Service. If
              you do not agree to any of these terms or any future User Terms of Service, do not use
              or access (or continue to access) the Service.
            </p>
            <h4>
              <b>17.3 Entire Agreement/Severability</b>
            </h4>
            <p>
              This Agreement, together with any amendments and any additional agreements you may
              enter into with Everdays in connection with the Service, shall constitute the entire
              agreement between you and Everdays concerning the Service. If any provision of this
              Agreement is deemed invalid by a court of competent jurisdiction, the invalidity of
              such provision shall not affect the validity of the remaining provisions of this
              Agreement, which shall remain in full force and effect, except that in the event of
              unenforceability of the universal Class Action/Jury Trial Waiver, the entire
              arbitration agreement shall be unenforceable.
            </p>
            <h4>
              <b>17.4 No Waiver</b>
            </h4>
            <p>
              No waiver of any term of this Agreement shall be deemed a further or continuing waiver
              of such term or any other term, and Everdays’ failure to assert any right or provision
              under this Agreement shall not constitute a waiver of such right or provision.
            </p>
            <h4>
              <b>17.5 Contact</b>
            </h4>
            <p>
              Please contact us at <a href="mailto:legal@everdays.com">legal@everdays.com</a> with
              any questions regarding this Agreement.
            </p>
          </section>
        </div>
      </div>
      <PagesFooter
        currentPage={'privacy'}
        pathname={location.pathname}
        queryString={location.search}
      />
      {renderAuthModal(user, location, children, { dlHideAuth: true, hasOptions: true })}
    </>
  );
};

export default connect(({ dash }) => ({ user: dash.user }))(TOS);
