import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash-es/isEmpty';

import ItemSection from '../../../shared/ItemSection';
import PrintPackage from '../PrintPackage';

import styles from './CompareServices.module.scss';

import completeFuneralServices from '../../../../public/images/complete-funeral-services-image.jpg';
import heartfeltMemorialServices from '../../../../public/images/heartfelt-memorial-services-image.jpg';
import cremationEssentialsServices from '../../../../public/images/cremation-essentials-services-image.jpg';

const serviceImageArray = [
  cremationEssentialsServices,
  heartfeltMemorialServices,
  completeFuneralServices
];

const {
  'compare-services': compareServicesClass,
  'unique-items-section': uniqueItemsSectionClass,
  'unique-items-container': uniqueItemsContainerClass,
  'unique-items-header': uniqueItemsHeaderClass,
  'base-items-section': baseItemsSectionClass,
  'item-lists': itemListsClass,
  'item-list': itemListClass
} = styles;

const CompareServices = ({ consumerServices = [], colors = {}, description = '', packageId }) => {
  const basicServices = consumerServices.filter(({ addOn, unique }) => !addOn && !unique);
  const uniqueServices = consumerServices.filter(({ unique, addOn }) => !!unique && !addOn);
  const addOns = consumerServices.filter(({ addOn }) => !!addOn);
  const [activeItems, setActiveItems] = useState({});

  // open first unique item when services load in
  useEffect(() => {
    if (!isEmpty(uniqueServices) && isEmpty(activeItems)) {
      setActiveItems({ Overview: true });
    }
  }, [activeItems, uniqueServices]);

  const handleToggleActive = name => {
    setActiveItems(prev => ({ ...prev, [name]: !prev[name] }));
  };

  return (
    <div className={compareServicesClass}>
      <h2>In this package</h2>
      <div className={uniqueItemsSectionClass}>
        <img src={serviceImageArray[packageId]} alt="" />
        <div className={uniqueItemsContainerClass}>
          <div className={uniqueItemsHeaderClass}>
            <p>{description}</p>
          </div>
          <div className={itemListClass}>
            {uniqueServices.map(({ name, description }) => (
              <ItemSection key={name} {...{ activeItems, handleToggleActive, name, description }} />
            ))}
          </div>
        </div>
      </div>
      <div className={baseItemsSectionClass}>
        <div className={itemListsClass}>
          <div className={itemListClass}>
            <h3>Included in every package</h3>
            {basicServices.map(({ name, description }) => (
              <ItemSection key={name} {...{ activeItems, handleToggleActive, name, description }} />
            ))}
          </div>
          <div className={itemListClass}>
            <h3>personalize your package with</h3>
            {addOns.map(({ name, description }) => (
              <ItemSection key={name} {...{ activeItems, handleToggleActive, name, description }} />
            ))}
          </div>
        </div>
      </div>
      <PrintPackage />
    </div>
  );
};

export default CompareServices;
