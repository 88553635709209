"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOAD_MORE_MEMORIALS_SUCCESS = exports.FETCH_COMPANY_MEMORIALS_FAILURE = exports.FETCH_COMPANY_MEMORIALS_SUCCESS = exports.FETCH_COMPANY_MEMORIALS_REQUEST = void 0;
var FETCH_COMPANY_MEMORIALS_REQUEST = 'FETCH_COMPANY_MEMORIALS_REQUEST';
exports.FETCH_COMPANY_MEMORIALS_REQUEST = FETCH_COMPANY_MEMORIALS_REQUEST;
var FETCH_COMPANY_MEMORIALS_SUCCESS = 'FETCH_COMPANY_MEMORIALS_SUCCESS';
exports.FETCH_COMPANY_MEMORIALS_SUCCESS = FETCH_COMPANY_MEMORIALS_SUCCESS;
var FETCH_COMPANY_MEMORIALS_FAILURE = 'FETCH_COMPANY_MEMORIALS_FAILURE';
exports.FETCH_COMPANY_MEMORIALS_FAILURE = FETCH_COMPANY_MEMORIALS_FAILURE;
var LOAD_MORE_MEMORIALS_SUCCESS = 'LOAD_MORE_MEMORIALS_SUCCESS';
exports.LOAD_MORE_MEMORIALS_SUCCESS = LOAD_MORE_MEMORIALS_SUCCESS;