"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EDIT_MEMORIAL_NEW_IMAGES = exports.EDIT_MEMORIAL_SUBMITTED_RESET = exports.EDIT_MEMORIAL_RESET = exports.EDIT_MEMORIAL_FAILURE = exports.EDIT_MEMORIAL_SUCCESS = exports.EDIT_MEMORIAL_REQUEST = void 0;
var EDIT_MEMORIAL_REQUEST = 'EDIT_MEMORIAL_REQUEST';
exports.EDIT_MEMORIAL_REQUEST = EDIT_MEMORIAL_REQUEST;
var EDIT_MEMORIAL_SUCCESS = 'EDIT_MEMORIAL_SUCCESS';
exports.EDIT_MEMORIAL_SUCCESS = EDIT_MEMORIAL_SUCCESS;
var EDIT_MEMORIAL_FAILURE = 'EDIT_MEMORIAL_FAILURE';
exports.EDIT_MEMORIAL_FAILURE = EDIT_MEMORIAL_FAILURE;
var EDIT_MEMORIAL_RESET = 'EDIT_MEMORIAL_RESET';
exports.EDIT_MEMORIAL_RESET = EDIT_MEMORIAL_RESET;
var EDIT_MEMORIAL_SUBMITTED_RESET = 'EDIT_MEMORIAL_SUBMITTED_RESET';
exports.EDIT_MEMORIAL_SUBMITTED_RESET = EDIT_MEMORIAL_SUBMITTED_RESET;
var EDIT_MEMORIAL_NEW_IMAGES = 'EDIT_MEMORIAL_NEW_IMAGES';
exports.EDIT_MEMORIAL_NEW_IMAGES = EDIT_MEMORIAL_NEW_IMAGES;