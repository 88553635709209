"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESET_CITY_HOMES = exports.FETCH_CITY_HOMES_FAILURE = exports.FETCH_CITY_HOMES_SUCCESS = exports.FETCH_CITY_HOMES_REQUEST = void 0;
var FETCH_CITY_HOMES_REQUEST = 'FETCH_CITY_HOMES_REQUEST';
exports.FETCH_CITY_HOMES_REQUEST = FETCH_CITY_HOMES_REQUEST;
var FETCH_CITY_HOMES_SUCCESS = 'FETCH_CITY_HOMES_SUCCESS';
exports.FETCH_CITY_HOMES_SUCCESS = FETCH_CITY_HOMES_SUCCESS;
var FETCH_CITY_HOMES_FAILURE = 'FETCH_CITY_HOMES_FAILURE';
exports.FETCH_CITY_HOMES_FAILURE = FETCH_CITY_HOMES_FAILURE;
var RESET_CITY_HOMES = 'RESET_CITY_HOMES';
exports.RESET_CITY_HOMES = RESET_CITY_HOMES;