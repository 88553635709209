"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EDIT_HOME_RESET = exports.ADD_HOME_USER_FAILURE = exports.ADD_HOME_USER_SUCCESS = exports.ADD_HOME_USER_REQUEST = exports.EDIT_HOME_FAILURE = exports.EDIT_HOME_SUCCESS = exports.EDIT_HOME_REQUEST = void 0;
var EDIT_HOME_REQUEST = 'EDIT_HOME_REQUEST';
exports.EDIT_HOME_REQUEST = EDIT_HOME_REQUEST;
var EDIT_HOME_SUCCESS = 'EDIT_HOME_SUCCESS';
exports.EDIT_HOME_SUCCESS = EDIT_HOME_SUCCESS;
var EDIT_HOME_FAILURE = 'EDIT_HOME_FAILURE';
exports.EDIT_HOME_FAILURE = EDIT_HOME_FAILURE;
var ADD_HOME_USER_REQUEST = 'ADD_HOME_USER_REQUEST';
exports.ADD_HOME_USER_REQUEST = ADD_HOME_USER_REQUEST;
var ADD_HOME_USER_SUCCESS = 'ADD_HOME_USER_SUCCESS';
exports.ADD_HOME_USER_SUCCESS = ADD_HOME_USER_SUCCESS;
var ADD_HOME_USER_FAILURE = 'ADD_HOME_USER_FAILURE';
exports.ADD_HOME_USER_FAILURE = ADD_HOME_USER_FAILURE;
var EDIT_HOME_RESET = 'EDIT_HOME_RESET';
exports.EDIT_HOME_RESET = EDIT_HOME_RESET;