"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CREATE_DEMO_MEMORIAL = exports.FETCH_DEMO_ACTIVITIES = exports.FETCH_DEMO_MEMORIAL_STATS = exports.FETCH_DEMO_ANNOUNCEMENT = exports.FETCH_DEMO_ANNOUNCEMENTS = exports.FETCH_DEMO_DIRECTORS = exports.FETCH_DEMO_HOMES = exports.FETCH_DEMO_COMPANY = exports.FETCH_DEMO_REPORTS = exports.FETCH_DEMO_LEAD = exports.FETCH_DEMO_CRM_LEADS = void 0;
var FETCH_DEMO_CRM_LEADS = 'FETCH_DEMO_CRM_LEADS';
exports.FETCH_DEMO_CRM_LEADS = FETCH_DEMO_CRM_LEADS;
var FETCH_DEMO_LEAD = 'FETCH_DEMO_LEAD';
exports.FETCH_DEMO_LEAD = FETCH_DEMO_LEAD;
var FETCH_DEMO_REPORTS = 'FETCH_DEMO_REPORTS';
exports.FETCH_DEMO_REPORTS = FETCH_DEMO_REPORTS;
var FETCH_DEMO_COMPANY = 'FETCH_DEMO_COMPANY';
exports.FETCH_DEMO_COMPANY = FETCH_DEMO_COMPANY;
var FETCH_DEMO_HOMES = 'FETCH_DEMO_HOMES';
exports.FETCH_DEMO_HOMES = FETCH_DEMO_HOMES;
var FETCH_DEMO_DIRECTORS = 'FETCH_DEMO_DIRECTORS';
exports.FETCH_DEMO_DIRECTORS = FETCH_DEMO_DIRECTORS;
var FETCH_DEMO_ANNOUNCEMENTS = 'FETCH_DEMO_ANNOUNCEMENTS';
exports.FETCH_DEMO_ANNOUNCEMENTS = FETCH_DEMO_ANNOUNCEMENTS;
var FETCH_DEMO_ANNOUNCEMENT = 'FETCH_DEMO_ANNOUNCEMENT';
exports.FETCH_DEMO_ANNOUNCEMENT = FETCH_DEMO_ANNOUNCEMENT;
var FETCH_DEMO_MEMORIAL_STATS = 'FETCH_DEMO_MEMORIAL_STATS';
exports.FETCH_DEMO_MEMORIAL_STATS = FETCH_DEMO_MEMORIAL_STATS;
var FETCH_DEMO_ACTIVITIES = 'FETCH_DEMO_ACTIVITIES';
exports.FETCH_DEMO_ACTIVITIES = FETCH_DEMO_ACTIVITIES;
var CREATE_DEMO_MEMORIAL = 'CREATE_DEMO_MEMORIAL';
exports.CREATE_DEMO_MEMORIAL = CREATE_DEMO_MEMORIAL;