"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FETCH_COMPANY_REPORT_FAILURE = exports.FETCH_COMPANY_REPORT_SUCCESS = exports.FETCH_COMPANY_REPORT_REQUEST = void 0;
var FETCH_COMPANY_REPORT_REQUEST = 'FETCH_COMPANY_REPORT_REQUEST';
exports.FETCH_COMPANY_REPORT_REQUEST = FETCH_COMPANY_REPORT_REQUEST;
var FETCH_COMPANY_REPORT_SUCCESS = 'FETCH_COMPANY_REPORT_SUCCESS';
exports.FETCH_COMPANY_REPORT_SUCCESS = FETCH_COMPANY_REPORT_SUCCESS;
var FETCH_COMPANY_REPORT_FAILURE = 'FETCH_COMPANY_REPORT_FAILURE';
exports.FETCH_COMPANY_REPORT_FAILURE = FETCH_COMPANY_REPORT_FAILURE;