"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestAppLinkFailure = exports.requestAppLinkSuccess = exports.requestAppLinkRequest = void 0;

var types = _interopRequireWildcard(require("./constants"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//request app link
var requestAppLinkRequest = function requestAppLinkRequest() {
  return {
    type: types.SUBMIT_APP_LINK_REQUEST
  };
};

exports.requestAppLinkRequest = requestAppLinkRequest;

var requestAppLinkSuccess = function requestAppLinkSuccess(_ref) {
  var data = _ref.data;
  return {
    type: types.SUBMIT_APP_LINK_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.requestAppLinkSuccess = requestAppLinkSuccess;

var requestAppLinkFailure = function requestAppLinkFailure(error) {
  return {
    type: types.SUBMIT_APP_LINK_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.requestAppLinkFailure = requestAppLinkFailure;