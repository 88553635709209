import React from 'react';

import ErrorPage from '@evdy/web-core/dist/components/ErrorPage';

export default () => (
  <ErrorPage title="Our apologies" hasButton={false}>
    <p>This isn’t the page you were looking for.</p>
    <p>
      Please try the Help Center below or visit our <a href="https://everdays.com/">home page</a>.
    </p>
  </ErrorPage>
);
