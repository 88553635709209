import React from 'react';

import styles from './FamilyProtected.module.scss';

import allYouNeed from '../../../../public/images/all-you-need.svg';
import relocateAnyTime from '../../../../public/images/relocate-any-time.svg';
import everythingInsured from '../../../../public/images/everything-you-need.svg';

const {
  'family-protection': familyProtectionClass,
  'guarantees-section': guaranteesSectionClass,
  guarantee: guaranteeClass,
  'guarantee-copy': guaranteeCopyClass
} = styles;

const FamilyProtected = ({ titleCopy = 'Protected. Safe. There when your family needs it.' }) => {
  const guarantees = [
    {
      title: 'All You Need',
      description:
        'All our packages include professional guidance from trusted funeral homes to help loved ones navigate a difficult time.',
      imgUrl: allYouNeed
    },
    {
      title: 'Relocate Any Time',
      description:
        'Our team can always help move your plan to a new funeral home if you move or change your mind.',
      imgUrl: relocateAnyTime
    },
    {
      title: 'Everything Is Insured',
      description:
        'Funds are held in an insurance policy to ensure full service delivery when the time comes.',
      imgUrl: everythingInsured
    }
  ];

  return (
    <div className={familyProtectionClass}>
      <h2>{titleCopy}</h2>
      <div className={guaranteesSectionClass}>
        {guarantees.map(({ title, description, imgUrl }) => {
          return (
            <div className={guaranteeClass} key={`${title}_${description}`}>
              <img src={imgUrl} alt="" />
              <div className={guaranteeCopyClass}>
                <h3>{title}</h3>
                <p>{description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FamilyProtected;
