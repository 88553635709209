"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FAMILY_MESSAGE_UNLIKE_FAILURE = exports.FAMILY_MESSAGE_UNLIKE_SUCCESS = exports.FAMILY_MESSAGE_UNLIKE_REQUEST = exports.FAMILY_MESSAGE_LIKE_FAILURE = exports.FAMILY_MESSAGE_LIKE_SUCCESS = exports.FAMILY_MESSAGE_LIKE_REQUEST = void 0;
var FAMILY_MESSAGE_LIKE_REQUEST = 'FAMILY_MESSAGE_LIKE_REQUEST';
exports.FAMILY_MESSAGE_LIKE_REQUEST = FAMILY_MESSAGE_LIKE_REQUEST;
var FAMILY_MESSAGE_LIKE_SUCCESS = 'FAMILY_MESSAGE_LIKE_SUCCESS';
exports.FAMILY_MESSAGE_LIKE_SUCCESS = FAMILY_MESSAGE_LIKE_SUCCESS;
var FAMILY_MESSAGE_LIKE_FAILURE = 'FAMILY_MESSAGE_LIKE_FAILURE';
exports.FAMILY_MESSAGE_LIKE_FAILURE = FAMILY_MESSAGE_LIKE_FAILURE;
var FAMILY_MESSAGE_UNLIKE_REQUEST = 'FAMILY_MESSAGE_UNLIKE_REQUEST';
exports.FAMILY_MESSAGE_UNLIKE_REQUEST = FAMILY_MESSAGE_UNLIKE_REQUEST;
var FAMILY_MESSAGE_UNLIKE_SUCCESS = 'FAMILY_MESSAGE_UNLIKE_SUCCESS';
exports.FAMILY_MESSAGE_UNLIKE_SUCCESS = FAMILY_MESSAGE_UNLIKE_SUCCESS;
var FAMILY_MESSAGE_UNLIKE_FAILURE = 'FAMILY_MESSAGE_UNLIKE_FAILURE';
exports.FAMILY_MESSAGE_UNLIKE_FAILURE = FAMILY_MESSAGE_UNLIKE_FAILURE;