"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _redux = require("redux");

var _reactReduxForm = require("react-redux-form");

var _addNote = _interopRequireDefault(require("./addNote"));

var _meetingSet = _interopRequireDefault(require("./meetingSet"));

var _meetingHeld = _interopRequireDefault(require("./meetingHeld"));

var _closed = _interopRequireDefault(require("./closed"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// form reducers
var crm = (0, _redux.combineReducers)({
  forms: (0, _reactReduxForm.combineForms)({
    addNote: _addNote.default,
    meetingSet: _meetingSet.default,
    meetingHeld: _meetingHeld.default,
    closed: _closed.default
  }, 'dash.crm.forms', {
    key: 'crmMeta'
  })
});
var _default = crm;
exports.default = _default;