"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SEARCH_MEMORIALS_RESET = exports.SEARCH_MEMORIALS_FAILURE = exports.SEARCH_MEMORIALS_SUCCESS = exports.SEARCH_MEMORIALS_REQUEST = void 0;
var SEARCH_MEMORIALS_REQUEST = 'SEARCH_MEMORIALS_REQUEST';
exports.SEARCH_MEMORIALS_REQUEST = SEARCH_MEMORIALS_REQUEST;
var SEARCH_MEMORIALS_SUCCESS = 'SEARCH_MEMORIALS_SUCCESS';
exports.SEARCH_MEMORIALS_SUCCESS = SEARCH_MEMORIALS_SUCCESS;
var SEARCH_MEMORIALS_FAILURE = 'SEARCH_MEMORIALS_FAILURE';
exports.SEARCH_MEMORIALS_FAILURE = SEARCH_MEMORIALS_FAILURE;
var SEARCH_MEMORIALS_RESET = 'SEARCH_MEMORIALS_RESET';
exports.SEARCH_MEMORIALS_RESET = SEARCH_MEMORIALS_RESET;