"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  flowers: {
    isDefault: false,
    active: true,
    url: undefined,
    image: undefined,
    title: undefined,
    button: undefined
  }
};
exports.default = _default;