import React, { useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import SlideOutMenu from '../shared/SlideOutMenu';
import NavBarDropdown from './NavDropdown';

import CharacterProfileImage from '@evdy/web-core/dist/components/shared/elements/CharacterProfileImage';

import { getAPIUrl } from '@evdy/web-redux/dist/constants';

import { useVerifyCookie } from '../../customHooks';

import styles from './NavBar.module.scss';

import contactUsPhone from '../../public/images/phone-without-intercom.svg';

const API_URL_LOCAL_3000 = getAPIUrl('', 3000);

const {
  'nav-bar-wrapper': navBarWrapperClass,
  'everdays-logo': everdaysLogoClass,
  'get-started-cta': getStartedClass,
  'left-nav-wrapper': leftSideNavWrapperClass,
  'right-nav-wrapper': rightSideNavWrapperClass,
  'contact-us-cta': contactUsCtaClass,
  'phone-contact-copy': callUsCopyClass,
  'phone-contact-nums': phoneNumberClass,
  'char-profile': charProfileClass,
  'how-it-works': howItWorksClass,
  'why-everdays': whyEverdaysClass,
  hamburger: hamburgerMenuClass,
  'shop-dropdown': shopDropdownClass,
  'planning-dropdown': planningDropdownClass,
  'desktop-links': desktopLinksClass,
  'package-links': packageLinksClass,
  'desktop-only-link': desktopOnly,
  'section-title': sectionTitleClass,
  'pseudo-border-ignore-margin': borderIgnoreMarginClass,
  'planning-links-column-one': planningLinksColumnOne,
  'planning-links-column-two': planningLinksColumnTwo,
  'planning-dropdown-content': planningDropdownContent,
  'planning-dropdown-custom': planningDropdownCustomClass,
  'package-link': packageLink,
  'extra-links': extraInfoLinks,
  'more-info-link': moreInfoLink,
  'chevron-right': chevRight,
  active: activeClass
} = styles;

const RenderShopDropdown = () => {
  const ctaLink = useVerifyCookie('rq-sid') ? '/get-my-price' : 'https://try.everdays.com/plan';

  const onlyTabletLinks = [
    {
      linkCopy: 'Why Everdays',
      href: '/why',
      target: ''
    }
  ];
  const packageLinks = [
    {
      linkCopy: 'Funerals',
      href: '/packages/complete-funeral',
      target: ''
    },
    {
      linkCopy: 'Memorials',
      href: '/packages/heartfelt-memorial',
      target: ''
    },
    {
      linkCopy: 'Celebrations of Life',
      href: '/packages/celebration-of-life',
      target: ''
    }
  ];
  const moreInfoLinks = [
    {
      linkCopy: 'Get A Price',
      href: ctaLink,
      target: ''
    },
    {
      linkCopy: 'Build My Plan',
      href: 'https://everdays.typeform.com/to/CSHJdReI',
      target: ''
    },
    {
      linkCopy: 'Find A Funeral Home',
      href: '/funeral-homes',
      target: ''
    }
  ];

  return (
    <>
      <div className={desktopLinksClass}>
        {onlyTabletLinks.map(({ linkCopy, href, target }, i) => (
          <a
            href={href}
            rel="noopener noreferrer"
            key={`${linkCopy}_${i}`}
            className={desktopOnly}
            target={target}
          >
            {linkCopy}
          </a>
        ))}
        <p className={sectionTitleClass}>Popular Plan Starters</p>
      </div>
      <div className={packageLinksClass}>
        {packageLinks.map(({ linkCopy, href, target }, i) => (
          <a
            href={href}
            rel="noopener noreferrer"
            key={`${linkCopy}_${i}`}
            className={packageLink}
            target={target}
          >
            {linkCopy}
          </a>
        ))}
        <div className={borderIgnoreMarginClass} />
      </div>
      <div className={extraInfoLinks}>
        {moreInfoLinks.map(({ linkCopy, href, target }, i) => (
          <a
            href={href}
            rel="noopener noreferrer"
            key={`${linkCopy}_${i}`}
            className={moreInfoLink}
            target={target}
          >
            {linkCopy}
            <div className={chevRight} />
          </a>
        ))}
      </div>
    </>
  );
};

const renderPlanningDropdown = () => {
  const planningLinksOne = [
    {
      linkCopy: 'Prepaid Funeral Plans',
      href: `${API_URL_LOCAL_3000}/prepaid-funeral-plans/`,
      target: ''
    },
    {
      linkCopy: 'Prepaid Cremation Services',
      href: `${API_URL_LOCAL_3000}/prepaid-funeral-plans/options/prepaid-cremation/`,
      target: ''
    },
    {
      linkCopy: 'The Pros and Cons',
      href: `${API_URL_LOCAL_3000}/resources/pros-cons-prepaid-funeral-plans/`,
      target: ''
    }
  ];
  const planningLinksTwo = [
    {
      linkCopy: 'Funeral & Burial Insurance',
      href: `${API_URL_LOCAL_3000}/prepaid-funeral-plans/options/funeral-insurance/`,
      target: ''
    },
    {
      linkCopy: 'Average Cremation Costs',
      href: `${API_URL_LOCAL_3000}/resources/cremation-cost/`,
      target: ''
    }
    // waiting for this content page to go in
    // { linkCopy: 'Celebration of Life Planning', href: '', target: '' }
  ];

  return (
    <div className={planningDropdownContent}>
      <div className={planningLinksColumnOne}>
        <p>Prepaid Plans</p>
        {planningLinksOne.map(({ linkCopy, href, target }, i) => (
          <a href={href} rel="noopener noreferrer" key={`${linkCopy}_${i}`} target={target}>
            {linkCopy}
          </a>
        ))}
      </div>
      <div className={planningLinksColumnTwo}>
        <p>Planning Resources</p>
        {planningLinksTwo.map(({ linkCopy, href, target }, i) => (
          <a href={href} rel="noopener noreferrer" key={`${linkCopy}_${i}`}>
            {linkCopy}
          </a>
        ))}
      </div>
    </div>
  );
};

const ContactUs = ({ ctaLink, ctaCopy, userId, name, image }) => (
  <div className={rightSideNavWrapperClass}>
    <a href="tel:8774003297" className={contactUsCtaClass}>
      <img src={contactUsPhone} alt="" />
      <div>
        <p className={callUsCopyClass}>Call Us For Help</p>
        <p className={phoneNumberClass}>(877) 400-3297</p>
      </div>
    </a>
    <a href={ctaLink}>
      <button className={getStartedClass}>{ctaCopy}</button>
    </a>
    {!!userId && <CharacterProfileImage {...{ name, image }} customClasses={[charProfileClass]} />}
  </div>
);

const NavBar = ({
  ctaLink = 'https://try.everdays.com/plan',
  ctaCopy = 'Get Started',
  location
}) => {
  const { userData } = useSelector(({ authUser }) => ({
    userData: authUser.data
  }));
  const { _id: userId, image, name = {} } = userData || {};

  const [isSlideOutOpen, setSlideOutOpen] = useState(false);
  const [isShopDropdownOpen, setIsShopDropdownOpen] = useState(false);
  const [isPlanningDropdownOpen, setIsPlanningDropdownOpen] = useState(false);

  //mobile slideout
  const closeSlideOut = () => setSlideOutOpen(false);
  const openSlideOut = () => setSlideOutOpen(true);

  const getPriceAction = () => (window.location.href = ctaLink);

  const { pathname } = location || {};

  const whyEverdaysClasses = classNames(whyEverdaysClass, {
    [activeClass]: /\/why\/?/.test(pathname)
  });

  // const howItWorksClasses = classNames(howItWorksClass, {
  //   [activeClass]: /\/how-it-works\/?/.test(pathname)
  // });

  return (
    <div className={navBarWrapperClass}>
      <div className={leftSideNavWrapperClass}>
        <div className={hamburgerMenuClass} onClick={openSlideOut} />
        <a href="/">
          <div className={everdaysLogoClass} />
        </a>
        <NavBarDropdown
          tabletCopy="Learn"
          desktopCopy="Learn"
          customDropdownClass={planningDropdownClass}
          renderDropdown={renderPlanningDropdown}
          isDropdownOpen={isPlanningDropdownOpen}
          setIsDropdownOpen={setIsPlanningDropdownOpen}
          customClass={planningDropdownCustomClass}
        />
        <a href="/why" className={whyEverdaysClasses}>
          Features
        </a>
        <a href="https://learn.everdays.com/about/" className={whyEverdaysClass}>
          Company
        </a>
      </div>
      <ContactUs {...{ ctaLink, ctaCopy, userId, name, image }} />
      <SlideOutMenu
        {...{ getPriceAction, name, image, userId }}
        isMenuOpen={isSlideOutOpen}
        closeMenu={closeSlideOut}
      />
    </div>
  );
};

export default NavBar;
