import React from 'react';
import classNames from 'classnames';

import NavBar from '../NavBar';
import Breadcrumbs from '../shared/Breadcrumbs';
import SiteHero from '../shared/SiteHero';
import TripleCTA from '../shared/TripleCTA';
import FooterSection from '../shared/Footer/FooterSection';
import ProtectPromise from '../AllPackages/components/ProtectPromise';
import WhyToday from './components/WhyToday';

import { useScrollToTop } from '@evdy/web-core/dist/customHooks';
import { useHideIntercom, useVerifyCookie } from '../../customHooks';

import styles from './WhyEverdays.module.scss';

import navyCheckIcon from '../../public/images/icons/navy-check.svg';
import bundledImg from '../../public/images/bundled.png';
import pickHomeImg from '../../public/images/pickHome.png';
import flexiblePaymentsImg from '../../public/images/flexiblePayments.png';
import allOnlineImg from '../../public/images/allOnline.png';

import eggShapedCircle from '../../public/images/egg-shaped-circle.svg';
import loveGraphicImage from '../../public/images/love-flower-graphic.svg';
import savingsGraphicImage from '../../public/images/savings-flower-graphic.svg';
import insuranceGraphicImage from '../../public/images/insurance-flower-graphic.svg';
import estateGraphicImage from '../../public/images/estate-flower-graphic.svg';

const whyPageData = [
  {
    title: 'Everything safely stored',
    copy:
      'Your free account makes it easy to start, save, and return to your plan whenever you want. ',
    image: bundledImg,
    imageGraphic: loveGraphicImage,
    hasMobileImg: true
  },
  {
    title: 'Easy, seamless planning',
    copy:
      'You can relax knowing Eva will guide you along the way, with helpful videos and tips when you need them.',
    image: pickHomeImg,
    imageGraphic: savingsGraphicImage
  },
  {
    title: 'Funds are secured & insured',
    copy:
      'When you’re ready, protect your family by funding your plans with the insurance that’s right for you.',
    image: flexiblePaymentsImg,
    imageGraphic: insuranceGraphicImage
  },
  {
    title: 'Instant access for loved ones',
    copy:
      'Your plans & documents are just a click away, ready and available whenever they’re needed.',
    image: allOnlineImg,
    imageGraphic: estateGraphicImage
  }
];

const {
  'why-everdays': whyEverdaysClass,
  'why-breadcrumbs': whyBreadcrumbsClass,
  'why-hero': whyHeroClass,
  'hero-subtitle': heroSubtitleClass,
  'why-content': whyContentClass,
  'why-page-card': whyPageCardClass,
  'right-align': rightAlignClass,
  'image-container': imageContainerClass,
  'card-image': cardImageClass,
  'egg-graphic': eggGraphicClass,
  'flower-graphic': flowerGraphicClass,
  'why-page-card-content': whyPageCardContentClass,
  'estate-card': estateCardClass,
  'dont-show-mobile': dontShowMobile,
  'why-today': whyTodaySection
} = styles;

const breadcrumbArray = [
  { pageName: 'Home', href: '/' },
  { pageName: 'Why Everdays', href: '/why' }
];

const WhyPageCard = ({
  title,
  copy,
  image,
  imageGraphic,
  textAlign = 'left',
  buttonCopy = 'Learn More',
  href,
  hasMobileImg = false
}) => {
  const whyPageCardClasses = classNames(whyPageCardClass, {
    [rightAlignClass]: textAlign === 'right',
    [estateCardClass]: href === '/why/estate'
  });

  return (
    <div className={whyPageCardClasses}>
      <div className={classNames(imageContainerClass, { [dontShowMobile]: !hasMobileImg })}>
        <img className={cardImageClass} src={image} alt="card-img" />
        <img className={eggGraphicClass} src={eggShapedCircle} alt="egg-graphic" />
        <img className={flowerGraphicClass} src={imageGraphic} alt="flower-graphic" />
      </div>
      <div className={whyPageCardContentClass}>
        <h4>{title}</h4>
        <p>{copy}</p>
        {!!href && (
          <a href={href}>
            <button>{buttonCopy}</button>
          </a>
        )}
      </div>
    </div>
  );
};

const WhyEverdays = ({ location }) => {
  //scroll to top
  useScrollToTop();

  //hide intercom
  useHideIntercom();

  const ctaLink = useVerifyCookie('rq-sid') ? '/get-my-price' : 'https://try.everdays.com/plan';

  return (
    <div className={whyEverdaysClass}>
      <NavBar {...{ location, ctaLink }} />
      <SiteHero
        customClass={whyHeroClass}
        heroTitle="Be prepared for whatever comes next"
        heroSubtitle={
          <div className={heroSubtitleClass}>
            <img src={navyCheckIcon} alt="checkmark" />
            <span>Pleasant</span>
            <img src={navyCheckIcon} alt="checkmark" />
            <span>Simple</span>
            <img src={navyCheckIcon} alt="checkmark" />
            <span>100% online</span>
          </div>
        }
        renderBreadcrumbs={
          <Breadcrumbs crumbTrail={breadcrumbArray} customClass={whyBreadcrumbsClass} />
        }
        currentPage="why"
      />
      <div className={whyContentClass}>
        <h3>We do things differently</h3>
        {whyPageData.map((page, idx) => (
          <WhyPageCard key={page.title} textAlign={idx % 2 === 0 ? 'right' : 'left'} {...page} />
        ))}
      </div>
      <div className={whyTodaySection}>
        <WhyToday />
      </div>
      <TripleCTA />
      <FooterSection />
    </div>
  );
};

export default WhyEverdays;
