import React, { useRef } from 'react';
import Slider from 'react-slick';

import styles from './Testimonials.module.scss';

import blueStar from '../../public/images/blue-star-sharp.svg';
import outline from '../../public/images/blue-star-outline.svg';
import leftChev from '../../public/images/icons/chevron-left-grey-2.svg';
import rightChev from '../../public/images/icons/chevron-right-grey-2.svg';

const {
  stars: starsClass,
  testimonial: testimonialClass,
  copy: copyClass,
  reviewer: reviewerClass,
  'review-name': reviewNameClass,
  'testimonials-carousel': testimonialsCarouselClass,
  'testimonials-slider': testimonialsSliderClass,
  testimonials: testimonialsClass
} = styles;

const slickSettings = {
  dots: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 8500
};

const Stars = ({ stars }) => {
  const selectStarImg = placementIdx => (placementIdx < stars ? blueStar : outline);
  return (
    <div className={starsClass}>
      {stars > 0 ? (
        <>
          <img src={selectStarImg(0)} alt="star-one" />
          <img src={selectStarImg(1)} alt="star-two" />
          <img src={selectStarImg(2)} alt="star-three" />
          <img src={selectStarImg(3)} alt="star-four" />
          <img src={selectStarImg(4)} alt="star-five" />
        </>
      ) : (
        ''
      )}
    </div>
  );
};

const Testimonial = ({ testimonial }) => {
  const { name, stars, copy, image } = testimonial ?? {};
  return (
    <div className={testimonialClass}>
      <Stars {...{ stars }} />
      <div className={copyClass}>
        <p>“{copy}”</p>
      </div>
      <div className={reviewerClass}>
        <img src={image} alt="" />
        <h5 className={reviewNameClass}>{name}</h5>
      </div>
    </div>
  );
};

const TestimonialsCarousel = ({ testimonials }) => {
  const slider = useRef(null);

  return (
    <div className={testimonialsCarouselClass}>
      <button onClick={() => slider?.current?.slickPrev()}>
        <img src={leftChev} alt="" />
      </button>
      <div className={testimonialsSliderClass}>
        <Slider ref={slider} {...slickSettings}>
          {testimonials.map(testimonial => (
            <Testimonial {...{ testimonial }} key={testimonial?.name} />
          ))}
        </Slider>
      </div>
      <button onClick={() => slider?.current?.slickNext()}>
        <img src={rightChev} alt="" />
      </button>
    </div>
  );
};

const Testimonials = ({ testimonials }) => {
  return (
    <div className={testimonialsClass}>
      <h2>See what our customers love</h2>
      <TestimonialsCarousel {...{ testimonials }} />
    </div>
  );
};

export default Testimonials;
