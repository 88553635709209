"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESET_EDIT_PRENEED_PRICES = exports.GET_RAWPRENEED_DATA_FAILURE = exports.GET_RAWPRENEED_DATA_SUCCESS = exports.GET_RAWPRENEED_DATA_REQUEST = exports.EDIT_PRENEED_PRICES_FAILURE = exports.EDIT_PRENEED_PRICES_SUCCESS = exports.EDIT_PRENEED_PRICES_REQUEST = exports.INIT_PRENEED_FAILURE = exports.INIT_PRENEED_SUCCESS = exports.INIT_PRENEED_REQUEST = exports.RESET_UPLOAD_GPL_PDF = exports.UPLOAD_GPL_PDF_FAILURE = exports.UPLOAD_GPL_PDF_SUCCESS = exports.UPLOAD_GPL_PDF_REQUEST = exports.RESET_PRENEED_TOS_ACCEPTED = exports.ACCEPT_PRENEED_TOS_FAILURE = exports.ACCEPT_PRENEED_TOS_SUCCESS = exports.ACCEPT_PRENEED_TOS_REQUEST = exports.RESET_ACC_LOCATIONS_HOME_UPDATE = exports.RESET_FUNERAL_HOME = exports.EDIT_FUNERAL_HOME_FAILURE = exports.EDIT_FUNERAL_HOME_SUCCESS = exports.EDIT_FUNERAL_HOME_REQUEST = exports.FETCH_FUNERAL_HOME_FAILURE = exports.FETCH_FUNERAL_HOME_SUCCESS = exports.FETCH_FUNERAL_HOME_REQUEST = exports.SET_RADIUS_SERVED_FAILURE = exports.SET_RADIUS_SERVED_SUCCESS = exports.SET_RADIUS_SERVED_REQUEST = void 0;
//map radius
var SET_RADIUS_SERVED_REQUEST = 'SET_RADIUS_SERVED_REQUEST';
exports.SET_RADIUS_SERVED_REQUEST = SET_RADIUS_SERVED_REQUEST;
var SET_RADIUS_SERVED_SUCCESS = 'SET_RADIUS_SERVED_SUCCESS';
exports.SET_RADIUS_SERVED_SUCCESS = SET_RADIUS_SERVED_SUCCESS;
var SET_RADIUS_SERVED_FAILURE = 'SET_RADIUS_SERVED_FAILURE';
exports.SET_RADIUS_SERVED_FAILURE = SET_RADIUS_SERVED_FAILURE;
var FETCH_FUNERAL_HOME_REQUEST = 'FETCH_FUNERAL_HOME_REQUEST';
exports.FETCH_FUNERAL_HOME_REQUEST = FETCH_FUNERAL_HOME_REQUEST;
var FETCH_FUNERAL_HOME_SUCCESS = 'FETCH_FUNERAL_HOME_SUCCESS';
exports.FETCH_FUNERAL_HOME_SUCCESS = FETCH_FUNERAL_HOME_SUCCESS;
var FETCH_FUNERAL_HOME_FAILURE = 'FETCH_FUNERAL_HOME_FAILURE';
exports.FETCH_FUNERAL_HOME_FAILURE = FETCH_FUNERAL_HOME_FAILURE;
var EDIT_FUNERAL_HOME_REQUEST = 'EDIT_FUNERAL_HOME_REQUEST';
exports.EDIT_FUNERAL_HOME_REQUEST = EDIT_FUNERAL_HOME_REQUEST;
var EDIT_FUNERAL_HOME_SUCCESS = 'EDIT_FUNERAL_HOME_SUCCESS';
exports.EDIT_FUNERAL_HOME_SUCCESS = EDIT_FUNERAL_HOME_SUCCESS;
var EDIT_FUNERAL_HOME_FAILURE = 'EDIT_FUNERAL_HOME_FAILURE';
exports.EDIT_FUNERAL_HOME_FAILURE = EDIT_FUNERAL_HOME_FAILURE;
var RESET_FUNERAL_HOME = 'RESET_FUNERAL_HOME';
exports.RESET_FUNERAL_HOME = RESET_FUNERAL_HOME;
var RESET_ACC_LOCATIONS_HOME_UPDATE = 'RESET_ACC_LOCATIONS_HOME_UPDATE'; // Terms

exports.RESET_ACC_LOCATIONS_HOME_UPDATE = RESET_ACC_LOCATIONS_HOME_UPDATE;
var ACCEPT_PRENEED_TOS_REQUEST = 'ACCEPT_PRENEED_TOS_REQUEST';
exports.ACCEPT_PRENEED_TOS_REQUEST = ACCEPT_PRENEED_TOS_REQUEST;
var ACCEPT_PRENEED_TOS_SUCCESS = 'ACCEPT_PRENEED_TOS_SUCCESS';
exports.ACCEPT_PRENEED_TOS_SUCCESS = ACCEPT_PRENEED_TOS_SUCCESS;
var ACCEPT_PRENEED_TOS_FAILURE = 'ACCEPT_PRENEED_TOS_FAILURE';
exports.ACCEPT_PRENEED_TOS_FAILURE = ACCEPT_PRENEED_TOS_FAILURE;
var RESET_PRENEED_TOS_ACCEPTED = 'RESET_PRENEED_TOS_ACCEPTED'; // PDF Upload

exports.RESET_PRENEED_TOS_ACCEPTED = RESET_PRENEED_TOS_ACCEPTED;
var UPLOAD_GPL_PDF_REQUEST = 'UPLOAD_GPL_PDF_REQUEST';
exports.UPLOAD_GPL_PDF_REQUEST = UPLOAD_GPL_PDF_REQUEST;
var UPLOAD_GPL_PDF_SUCCESS = 'UPLOAD_GPL_PDF_SUCCESS';
exports.UPLOAD_GPL_PDF_SUCCESS = UPLOAD_GPL_PDF_SUCCESS;
var UPLOAD_GPL_PDF_FAILURE = 'UPLOAD_GPL_PDF_FAILURE';
exports.UPLOAD_GPL_PDF_FAILURE = UPLOAD_GPL_PDF_FAILURE;
var RESET_UPLOAD_GPL_PDF = 'RESET_UPLOAD_GPL_PDF'; // Init Preneed

exports.RESET_UPLOAD_GPL_PDF = RESET_UPLOAD_GPL_PDF;
var INIT_PRENEED_REQUEST = 'INIT_PRENEED_REQUEST';
exports.INIT_PRENEED_REQUEST = INIT_PRENEED_REQUEST;
var INIT_PRENEED_SUCCESS = 'INIT_PRENEED_SUCCESS';
exports.INIT_PRENEED_SUCCESS = INIT_PRENEED_SUCCESS;
var INIT_PRENEED_FAILURE = 'INIT_PRENEED_FAILURE'; // Edit Preneed Package Prices

exports.INIT_PRENEED_FAILURE = INIT_PRENEED_FAILURE;
var EDIT_PRENEED_PRICES_REQUEST = 'EDIT_PRENEED_PRICES_REQUEST';
exports.EDIT_PRENEED_PRICES_REQUEST = EDIT_PRENEED_PRICES_REQUEST;
var EDIT_PRENEED_PRICES_SUCCESS = 'EDIT_PRENEED_PRICES_SUCCESS';
exports.EDIT_PRENEED_PRICES_SUCCESS = EDIT_PRENEED_PRICES_SUCCESS;
var EDIT_PRENEED_PRICES_FAILURE = 'EDIT_PRENEED_PRICES_FAILURE'; // GET raw preneed data

exports.EDIT_PRENEED_PRICES_FAILURE = EDIT_PRENEED_PRICES_FAILURE;
var GET_RAWPRENEED_DATA_REQUEST = 'GET_RAW_PRENEED_DATA_REQUEST';
exports.GET_RAWPRENEED_DATA_REQUEST = GET_RAWPRENEED_DATA_REQUEST;
var GET_RAWPRENEED_DATA_SUCCESS = 'GET_RAW_PRENEED_DATA_SUCCESS';
exports.GET_RAWPRENEED_DATA_SUCCESS = GET_RAWPRENEED_DATA_SUCCESS;
var GET_RAWPRENEED_DATA_FAILURE = 'GET_RAW_PRENEED_DATA_FAILURE';
exports.GET_RAWPRENEED_DATA_FAILURE = GET_RAWPRENEED_DATA_FAILURE;
var RESET_EDIT_PRENEED_PRICES = 'RESET_EDIT_PRENEED_PRICES';
exports.RESET_EDIT_PRENEED_PRICES = RESET_EDIT_PRENEED_PRICES;