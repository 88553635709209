"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VALIDATE_PIN_FAILURE = exports.VALIDATE_PIN_SUCCESS = exports.VALIDATE_PIN_REQUEST = exports.VALIDATE_PIN_RESET = void 0;
var VALIDATE_PIN_RESET = 'VALIDATE_PIN_RESET';
exports.VALIDATE_PIN_RESET = VALIDATE_PIN_RESET;
var VALIDATE_PIN_REQUEST = 'VALIDATE_PIN_REQUEST';
exports.VALIDATE_PIN_REQUEST = VALIDATE_PIN_REQUEST;
var VALIDATE_PIN_SUCCESS = 'VALIDATE_PIN_SUCCESS';
exports.VALIDATE_PIN_SUCCESS = VALIDATE_PIN_SUCCESS;
var VALIDATE_PIN_FAILURE = 'VALIDATE_PIN_FAILURE';
exports.VALIDATE_PIN_FAILURE = VALIDATE_PIN_FAILURE;