"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateUserPackageInfo = exports.startSale = exports.resetUpdateWeblead = exports.updateWeblead = exports.fetchWeblead = exports.resetPackageSelected = exports.selectNewPackage = exports.selectPackage = void 0;

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

var _actions = require("./actions");

var _constants = require("../../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var instance = _index.default.create({
  baseURL: _constants.API_URL
});

var selectPackage = function selectPackage(preneedObj) {
  return function (dispatch) {
    dispatch((0, _actions.selectPackageRequest)());
    return instance.post("/web/v1/public/weblead", {
      eFuneral: preneedObj
    }).then(function (res) {
      return dispatch((0, _actions.selectPackageSuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.selectPackageFailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.selectPackage = selectPackage;

var selectNewPackage = function selectNewPackage(webleadId, preneedObj) {
  return function (dispatch) {
    dispatch((0, _actions.selectNewPackageRequest)());
    return instance.put("/web/v1/public/weblead", {
      _id: webleadId,
      eFuneral: preneedObj
    }).then(function (res) {
      return dispatch((0, _actions.selectNewPackageSuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.selectNewPackageFailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.selectNewPackage = selectNewPackage;

var resetPackageSelected = function resetPackageSelected() {
  return function (dispatch) {
    return dispatch((0, _actions.resetPackageSelectedAction)());
  };
}; // fetch weblead //this is still in use based on logs. the other v1's in this file are not any longer


exports.resetPackageSelected = resetPackageSelected;

var fetchWeblead = function fetchWeblead(webleadId) {
  return function (dispatch) {
    dispatch((0, _actions.fetchWebleadRequest)());
    return instance.get("/v2/public/weblead/".concat(webleadId)).then(function (res) {
      return dispatch((0, _actions.fetchWebleadSuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.fetchWebleadFailure)((0, _transformAxiosError.default)(err)));
    });
  };
}; // update weblead


exports.fetchWeblead = fetchWeblead;

var updateWeblead = function updateWeblead(webleadId, updateObj) {
  return function (dispatch) {
    dispatch((0, _actions.updateWebleadRequest)());
    return instance.put("/web/v1/public/weblead", _objectSpread({
      _id: webleadId
    }, updateObj)).then(function (res) {
      return dispatch((0, _actions.updateWebleadSuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.updateWebleadFailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.updateWeblead = updateWeblead;

var resetUpdateWeblead = function resetUpdateWeblead() {
  return function (dispatch) {
    return dispatch((0, _actions.resetUpdateWebleadAction)());
  };
}; // start sale


exports.resetUpdateWeblead = resetUpdateWeblead;

var startSale = function startSale(_ref) {
  var userId = _ref.userId,
      funeralHomeId = _ref.funeralHomeId,
      preneedPackageId = _ref.preneedPackageId,
      paymentOption = _ref.paymentOption;
  return function (dispatch) {
    dispatch((0, _actions.startSaleRequest)());
    return instance.post("/web/v1/public/buypreneed/startsale", {
      userId: userId,
      funeralHomeId: funeralHomeId,
      preneedPackageId: preneedPackageId,
      paymentOption: paymentOption
    }).then(function (res) {
      return dispatch((0, _actions.startSaleSuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.startSaleFailure)((0, _transformAxiosError.default)(err)));
    });
  };
}; // update package and terms on user.preneed


exports.startSale = startSale;

var updateUserPackageInfo = function updateUserPackageInfo(userData) {
  return function (dispatch) {
    dispatch((0, _actions.updateUserPackageRequest)());
    return instance.post('/v2/private/preneed/edituser', _objectSpread({}, userData)).then(function (res) {
      return dispatch((0, _actions.updateUserPackageSuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.updateUserPackageFailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.updateUserPackageInfo = updateUserPackageInfo;