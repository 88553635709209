"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  existingPassword: '',
  newPassword: '',
  confirmPassword: ''
};
exports.default = _default;