"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LEAD_ACTIVITY_RESET = exports.GET_LEAD_ACTIVITY_FAILURE = exports.GET_LEAD_ACTIVITY_SUCCESS = exports.GET_LEAD_ACTIVITY_REQUEST = exports.PUT_LEAD_ACTIVITY_FAILURE = exports.PUT_LEAD_ACTIVITY_SUCCESS = exports.PUT_LEAD_ACTIVITY_REQUEST = exports.POST_LEAD_ACTIVITY_FAILURE = exports.POST_LEAD_ACTIVITY_SUCCESS = exports.POST_LEAD_ACTIVITY_REQUEST = void 0;
var POST_LEAD_ACTIVITY_REQUEST = 'POST_LEAD_ACTIVITY_REQUEST';
exports.POST_LEAD_ACTIVITY_REQUEST = POST_LEAD_ACTIVITY_REQUEST;
var POST_LEAD_ACTIVITY_SUCCESS = 'POST_LEAD_ACTIVITY_SUCCESS';
exports.POST_LEAD_ACTIVITY_SUCCESS = POST_LEAD_ACTIVITY_SUCCESS;
var POST_LEAD_ACTIVITY_FAILURE = 'POST_LEAD_ACTIVITY_FAILURE';
exports.POST_LEAD_ACTIVITY_FAILURE = POST_LEAD_ACTIVITY_FAILURE;
var PUT_LEAD_ACTIVITY_REQUEST = 'PUT_LEAD_ACTIVITY_REQUEST';
exports.PUT_LEAD_ACTIVITY_REQUEST = PUT_LEAD_ACTIVITY_REQUEST;
var PUT_LEAD_ACTIVITY_SUCCESS = 'PUT_LEAD_ACTIVITY_SUCCESS';
exports.PUT_LEAD_ACTIVITY_SUCCESS = PUT_LEAD_ACTIVITY_SUCCESS;
var PUT_LEAD_ACTIVITY_FAILURE = 'PUT_LEAD_ACTIVITY_FAILURE';
exports.PUT_LEAD_ACTIVITY_FAILURE = PUT_LEAD_ACTIVITY_FAILURE;
var GET_LEAD_ACTIVITY_REQUEST = 'GET_LEAD_ACTIVITY_REQUEST';
exports.GET_LEAD_ACTIVITY_REQUEST = GET_LEAD_ACTIVITY_REQUEST;
var GET_LEAD_ACTIVITY_SUCCESS = 'GET_LEAD_ACTIVITY_SUCCESS';
exports.GET_LEAD_ACTIVITY_SUCCESS = GET_LEAD_ACTIVITY_SUCCESS;
var GET_LEAD_ACTIVITY_FAILURE = 'GET_LEAD_ACTIVITY_FAILURE';
exports.GET_LEAD_ACTIVITY_FAILURE = GET_LEAD_ACTIVITY_FAILURE;
var LEAD_ACTIVITY_RESET = 'LEAD_ACTIVITY_RESET';
exports.LEAD_ACTIVITY_RESET = LEAD_ACTIVITY_RESET;