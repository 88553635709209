"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFBShareRender = void 0;

var _react = require("react");

var useFBShareRender = function useFBShareRender() {
  (0, _react.useLayoutEffect)(function () {
    var _window;

    var fb = (_window = window) === null || _window === void 0 ? void 0 : _window.FB;
    fb && fb.XFBML && fb.XFBML.parse();
  }, []);
};

exports.useFBShareRender = useFBShareRender;