"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EDIT_MANY_HOMES_FAILURE = exports.EDIT_MANY_HOMES_SUCCESS = exports.EDIT_MANY_HOMES_REQUEST = void 0;
var EDIT_MANY_HOMES_REQUEST = 'EDIT_MANY_HOMES_REQUEST';
exports.EDIT_MANY_HOMES_REQUEST = EDIT_MANY_HOMES_REQUEST;
var EDIT_MANY_HOMES_SUCCESS = 'EDIT_MANY_HOMES_SUCCESS';
exports.EDIT_MANY_HOMES_SUCCESS = EDIT_MANY_HOMES_SUCCESS;
var EDIT_MANY_HOMES_FAILURE = 'EDIT_MANY_HOMES_FAILURE';
exports.EDIT_MANY_HOMES_FAILURE = EDIT_MANY_HOMES_FAILURE;