"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.array.from.js");

require("core-js/modules/es6.array.slice.js");

require("core-js/modules/es6.weak-map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.regexp.to-string.js");

require("core-js/modules/es6.array.find.js");

require("core-js/modules/es6.function.name.js");

require("core-js/modules/es6.array.map.js");

var _redux = require("redux");

var _pick = _interopRequireDefault(require("lodash-es/pick"));

var _omit = _interopRequireDefault(require("lodash-es/omit"));

var actions = _interopRequireWildcard(require("./constants"));

var _helpers = require("./helpers");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialInviteMetaState = {
  data: {},
  isFetching: false,
  lastUpdated: null,
  error: {}
};
var inviteMetaKeys = ['_id', 'createdByFuneralHome', 'creationDate', 'isFinished', 'renderInvite', 'role', // TODO: not sure where this goes
'shareUrl', 'themeIdx', 'accessors'];

var inviteMetaReducer = function inviteMetaReducer() {
  var _state$data, _state$data2;

  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialInviteMetaState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actions.FETCH_ANNOUNCEMENT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true,
        error: {}
      });

    case actions.FETCH_ANNOUNCEMENT_FAILURE:
    case actions.FOLLOW_INVITE_FAILURE:
    case actions.UNFOLLOW_INVITE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: action.error
      });

    case actions.FETCH_ANNOUNCEMENT_SUCCESS:
      var data = (0, _pick.default)(action.payload.data, inviteMetaKeys);
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        data: data,
        error: {}
      });

    case actions.FOLLOW_INVITE_SUCCESS:
      var addedAccessor = [].concat(_toConsumableArray(((_state$data = state.data) === null || _state$data === void 0 ? void 0 : _state$data.accessors) || []), _toConsumableArray(action.payload));
      return _objectSpread(_objectSpread({}, state), {}, {
        data: _objectSpread(_objectSpread({}, state.data), {}, {
          accessors: addedAccessor
        }),
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: {}
      });

    case actions.UNFOLLOW_INVITE_SUCCESS:
      var removedAccessor = (((_state$data2 = state.data) === null || _state$data2 === void 0 ? void 0 : _state$data2.accessors) || []).filter(function (_ref) {
        var _userRef$_id, _action$payload;

        var userRef = _ref.userRef;
        return (userRef === null || userRef === void 0 ? void 0 : (_userRef$_id = userRef._id) === null || _userRef$_id === void 0 ? void 0 : _userRef$_id.toString()) !== ((_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload._id.toString());
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        data: _objectSpread(_objectSpread({}, state.data), {}, {
          accessors: removedAccessor
        }),
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: {}
      });

    default:
      return state;
  }
};

var initialDecedentDetailsState = {
  data: {},
  isFetching: false,
  lastUpdated: null,
  error: {}
};
var decedentDetailsKeys = ['cityOfBirth', 'cityOfDeath', 'dateOfBirth', 'dateOfDeath', 'decedentPhone', 'displayName', 'image', 'name', 'funeralHomeInfo'];

var decedentDetailsReducer = function decedentDetailsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialDecedentDetailsState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actions.FETCH_ANNOUNCEMENT_SUCCESS:
      var data = (0, _pick.default)(action.payload.data, decedentDetailsKeys);
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        data: data,
        error: {}
      });

    default:
      return state;
  }
};

var initialEventsState = {
  array: [],
  serviceStatus: null,
  isFetching: false,
  lastUpdated: null,
  error: {}
};

var eventsReducer = function eventsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialEventsState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actions.FETCH_ANNOUNCEMENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        array: action.payload.data.services,
        serviceStatus: action.payload.data.serviceStatus,
        error: {}
      });

    default:
      return state;
  }
};

var initialPhotosState = {
  array: [],
  isFetching: false,
  lastUpdated: null,
  error: {},
  commentLeft: false
};

var photosReducer = function photosReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialPhotosState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var _ref2 = action || {},
      _ref2$entityId = _ref2.entityId,
      entityId = _ref2$entityId === void 0 ? undefined : _ref2$entityId,
      _ref2$mediaId = _ref2.mediaId,
      mediaId = _ref2$mediaId === void 0 ? undefined : _ref2$mediaId;

  var mediaIdx = (0, _helpers.findItemIdx)(state.array, entityId || mediaId);

  switch (action.type) {
    // Init
    case actions.FETCH_ANNOUNCEMENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        array: action.payload.data.photos,
        error: {}
      });
    // Requests

    case actions.UPLOAD_PHOTOS_REQUEST:
      // make a photo obj for the pending photo
      var pendingPhoto = {
        _id: "action.photoName-".concat(Date.now()),
        createdAt: Date.now() + 100000,
        // pad the createdAt time with 100 seconds to keep the sort order from possibly changing during multi-photo upload
        mediaType: 'pending-photo',
        name: action.photoName,
        pendingUrl: action.blobUrl,
        progress: 0
      };
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true,
        array: [].concat(_toConsumableArray(state.array), [pendingPhoto])
      });

    case actions.LOVE_MEDIA_REQUEST:
    case actions.UNLOVE_MEDIA_REQUEST:
    case actions.LOVE_COMMENT_REQUEST:
    case actions.UNLOVE_COMMENT_REQUEST:
      if (action.mediaType === 'photo') {
        return _objectSpread({}, state);
      } else {
        return state;
      }

    // Failures

    case actions.UPLOAD_PHOTOS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: action.error
      });

    case actions.LOVE_MEDIA_FAILURE:
    case actions.UNLOVE_MEDIA_FAILURE:
    case actions.LOVE_COMMENT_FAILURE:
    case actions.UNLOVE_COMMENT_FAILURE:
      if (action.mediaType === 'photo') {
        return _objectSpread(_objectSpread({}, state), {}, {
          isFetching: false,
          error: action.error,
          lastUpdated: action.receivedAt
        });
      } else {
        return state;
      }

    // Successes

    case actions.PENDING_PHOTO_PROGRESS_UPDATE:
      var currentPendingPhoto = state.array.find(function (photo) {
        return photo.name === action.photoName;
      });

      var progressUpdatedPhoto = _objectSpread(_objectSpread({}, currentPendingPhoto), {}, {
        progress: action.progress
      });

      var progressUpdatedArray = _toConsumableArray(state.array).map(function (photo) {
        return photo.name === action.photoName ? progressUpdatedPhoto : photo;
      });

      return _objectSpread(_objectSpread({}, state), {}, {
        array: progressUpdatedArray
      });

    case actions.UPLOAD_PHOTOS_SUCCESS:
      var pendingPhotoObj = state.array.find(function (photo) {
        return photo.name === action.photoName;
      }) || {}; // on upload success, replace the pending photo obj with the one from the response, retaining a couple
      // of the fields from the pending obj

      var newPhotosArray = _toConsumableArray(state.array).filter(function (photo) {
        return (photo === null || photo === void 0 ? void 0 : photo.name) !== action.photoName;
      }).concat([_objectSpread(_objectSpread({}, action.payload), {}, {
        pendingUrl: pendingPhotoObj.pendingUrl,
        // retain pendingUrl to use so that there won't be any flickering/flashing of the grey background as the newly uploaded image loads in
        createdAt: pendingPhotoObj.createdAt // retain pending created at to preserve upload order on FE until refresh

      })]); // only update isFetching once all photos have finished uploading


      var isFetching = !!newPhotosArray.filter(function (photo) {
        return photo.mediaType === 'pending-photo';
      }).length;
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: isFetching,
        lastUpdated: action.receivedAt,
        array: newPhotosArray
      });

    case actions.LOVE_MEDIA_SUCCESS:
      if (action.mediaType === 'photo') {
        var array = (0, _helpers.getNewMediaLovesArray)({
          mediaData: action.payload,
          stateArray: state.array,
          mediaIdx: mediaIdx
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          array: array,
          isFetching: false,
          lastUpdated: action.receivedAt
        });
      } else {
        return state;
      }

    case actions.UNLOVE_MEDIA_SUCCESS:
      if (action.mediaType === 'photo') {
        return _objectSpread(_objectSpread({}, state), {}, {
          isFetching: false,
          lastUpdated: action.receivedAt,
          array: action.payload.photos
        });
      } else {
        return state;
      }

    case actions.LEAVE_COMMENT_SUCCESS:
      if (action.mediaType === 'photo') {
        var _array = (0, _helpers.getNewMediaCommentsArray)({
          mediaData: action.payload,
          stateArray: state.array,
          mediaIdx: mediaIdx
        });

        return _objectSpread(_objectSpread({}, state), {}, {
          isFetching: false,
          lastUpdated: action.receivedAt,
          array: _array,
          commentLeft: true
        });
      } else {
        return state;
      }

    case actions.LOVE_COMMENT_SUCCESS:
      if (action.mediaType === 'photo') {
        var _ref3 = action || {},
            commentId = _ref3.commentId;

        var loveData = action.payload;

        var _array2 = (0, _helpers.getNewCommentsLoveArray)({
          commentId: commentId,
          loveData: loveData,
          mediaIdx: mediaIdx,
          stateArray: state.array
        });

        return _objectSpread(_objectSpread({}, state), {}, {
          isFetching: false,
          lastUpdated: action.receivedAt,
          array: _array2
        });
      } else {
        return state;
      }

    case actions.UNLOVE_COMMENT_SUCCESS:
      if (action.mediaType === 'photo') {
        var unloveCommentMemorialData = action.payload;

        var _ref4 = unloveCommentMemorialData || {},
            _ref4$photos = _ref4.photos,
            photos = _ref4$photos === void 0 ? [] : _ref4$photos;

        return _objectSpread(_objectSpread({}, state), {}, {
          isFetching: false,
          lastUpdated: action.receivedAt,
          array: photos
        });
      } else {
        return state;
      }

    // Reset

    case actions.RESET_COMMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        commentLeft: false
      });

    default:
      return state;
  }
};

var initialCondolencesState = {
  array: [],
  condolenceLeft: false,
  isFetching: false,
  lastUpdated: null,
  error: {},
  commentLeft: false,
  videoUploadedForEncoding: false,
  vidoeEncoded: false
};

var condolencesReducer = function condolencesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialCondolencesState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var _ref5 = action || {},
      _ref5$entityId = _ref5.entityId,
      entityId = _ref5$entityId === void 0 ? undefined : _ref5$entityId,
      _ref5$mediaId = _ref5.mediaId,
      mediaId = _ref5$mediaId === void 0 ? undefined : _ref5$mediaId;

  var mediaIdx = (0, _helpers.findItemIdx)(state.array, entityId || mediaId);

  switch (action.type) {
    // Init
    case actions.FETCH_ANNOUNCEMENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        array: action.payload.data.condolences,
        error: {}
      });
    // Requests

    case actions.LEAVE_CONDOLENCE_REQUEST:
    case actions.UPLOAD_VIDEO_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true
      });

    case actions.LOVE_MEDIA_REQUEST:
    case actions.UNLOVE_MEDIA_REQUEST:
    case actions.LOVE_COMMENT_REQUEST:
    case actions.UNLOVE_COMMENT_REQUEST:
      if (action.mediaType === 'condolence') {
        return _objectSpread({}, state);
      } else {
        return state;
      }

    // Failures

    case actions.UPLOAD_VIDEO_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: action.error
      });

    case actions.LEAVE_CONDOLENCE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: action.error,
        lastUpdated: action.receivedAt
      });

    case actions.LOVE_MEDIA_FAILURE:
    case actions.UNLOVE_MEDIA_FAILURE:
    case actions.LOVE_COMMENT_FAILURE:
    case actions.UNLOVE_COMMENT_FAILURE:
      if (action.mediaType === 'condolence') {
        return _objectSpread(_objectSpread({}, state), {}, {
          isFetching: false,
          error: action.error,
          lastUpdated: action.receivedAt
        });
      } else {
        return state;
      }

    // Successes

    case actions.UPLOAD_VIDEO_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        condolenceLeft: true,
        videoUploadedForEncoding: true
      });

    case actions.ENCODE_VIDEO_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        videoEncoded: true,
        array: [].concat(_toConsumableArray(state.array), [action.payload])
      });

    case actions.LEAVE_CONDOLENCE_SUCCESS:
      var leaveCondolenceData = action.payload;
      var newCondolencesArray = [].concat(_toConsumableArray(state.array), [leaveCondolenceData]);
      return _objectSpread(_objectSpread({}, state), {}, {
        array: newCondolencesArray,
        isFetching: false,
        lastUpdated: action.receivedAt,
        condolenceLeft: true
      });

    case actions.LOVE_MEDIA_SUCCESS:
      if (action.mediaType === 'condolence') {
        var array = (0, _helpers.getNewMediaLovesArray)({
          mediaData: action.payload,
          stateArray: state.array,
          mediaIdx: mediaIdx
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          array: array,
          isFetching: false,
          lastUpdated: action.receivedAt
        });
      } else {
        return state;
      }

    case actions.UNLOVE_MEDIA_SUCCESS:
      if (action.mediaType === 'condolence') {
        return _objectSpread(_objectSpread({}, state), {}, {
          isFetching: false,
          lastUpdated: action.receivedAt,
          array: action.payload.condolences
        });
      } else {
        return state;
      }

    case actions.LEAVE_COMMENT_SUCCESS:
      if (action.mediaType === 'condolence') {
        var _array3 = (0, _helpers.getNewMediaCommentsArray)({
          mediaData: action.payload,
          stateArray: state.array,
          mediaIdx: mediaIdx
        });

        return _objectSpread(_objectSpread({}, state), {}, {
          isFetching: false,
          lastUpdated: action.receivedAt,
          array: _array3,
          commentLeft: true
        });
      } else {
        return state;
      }

    case actions.LOVE_COMMENT_SUCCESS:
      if (action.mediaType === 'condolence') {
        var _ref6 = action || {},
            commentId = _ref6.commentId;

        var loveData = action.payload;

        var _array4 = (0, _helpers.getNewCommentsLoveArray)({
          commentId: commentId,
          loveData: loveData,
          mediaIdx: mediaIdx,
          stateArray: state.array
        });

        return _objectSpread(_objectSpread({}, state), {}, {
          isFetching: false,
          lastUpdated: action.receivedAt,
          array: _array4
        });
      } else {
        return state;
      }

    case actions.UNLOVE_COMMENT_SUCCESS:
      if (action.mediaType === 'condolence') {
        var unloveCommentMemorialData = action.payload;

        var _ref7 = unloveCommentMemorialData || {},
            _ref7$condolences = _ref7.condolences,
            condolences = _ref7$condolences === void 0 ? [] : _ref7$condolences;

        return _objectSpread(_objectSpread({}, state), {}, {
          isFetching: false,
          lastUpdated: action.receivedAt,
          array: condolences
        });
      } else {
        return state;
      }

    // Reset

    case actions.RESET_CONDOLENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        condolenceLeft: false
      });

    case actions.RESET_COMMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        commentLeft: false
      });

    case actions.VIDEO_UPLOADED_RESET:
      return _objectSpread(_objectSpread({}, state), {}, {
        videoUploadedForEncoding: false
      });

    case actions.VIDEO_ENCODED_RESET:
      return _objectSpread(_objectSpread({}, state), {}, {
        videoEncoded: false
      });

    default:
      return state;
  }
};

var initialVideoProgressState = {
  videoList: {},
  modalClosed: false,
  isMuted: false
};

var videoReducer = function videoReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialVideoProgressState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var newVideosList = _objectSpread({}, state.videoList);

  switch (action.type) {
    case actions.SAVE_VIDEO_PROGRESS:
      var videoOptions = (0, _omit.default)(action, ['type', 'videoId']);
      newVideosList[action.videoId] = _objectSpread({}, videoOptions);
      return _objectSpread(_objectSpread({}, state), {}, {
        videoList: newVideosList
      });

    case actions.CLEAR_VIDEO_PROGRESS:
      delete newVideosList[action.videoId];
      return _objectSpread(_objectSpread({}, state), {}, {
        videoList: newVideosList
      });

    case actions.SET_VIDEO_MODAL_CLOSED:
      return _objectSpread(_objectSpread({}, state), {}, {
        modalClosed: true
      });

    case actions.RESET_VIDEO_MODAL_CLOSED:
      return _objectSpread(_objectSpread({}, state), {}, {
        modalClosed: false
      });

    default:
      return state;
  }
}; // TODO: maybe split the reducers into separate files?


var _default = (0, _redux.combineReducers)({
  inviteMeta: inviteMetaReducer,
  decedentDetails: decedentDetailsReducer,
  events: eventsReducer,
  photos: photosReducer,
  condolences: condolencesReducer,
  videos: videoReducer
});

exports.default = _default;