"use strict";

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.array.from.js");

require("core-js/modules/es6.array.slice.js");

require("core-js/modules/es6.function.name.js");

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getNewCommentsLoveArray = exports.getNewMediaCommentsArray = exports.getNewMediaLovesArray = exports.findItemIdx = void 0;

require("core-js/modules/es6.array.find-index.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.regexp.to-string.js");

require("core-js/modules/es6.array.map.js");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var findItemIdx = function findItemIdx() {
  var array = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var itemId = arguments.length > 1 ? arguments[1] : undefined;
  return array.findIndex(function (_ref) {
    var _id = _ref._id;
    return (_id === null || _id === void 0 ? void 0 : _id.toString()) === itemId;
  });
};

exports.findItemIdx = findItemIdx;

var getNewMediaLovesArray = function getNewMediaLovesArray(_ref2) {
  var mediaData = _ref2.mediaData,
      stateArray = _ref2.stateArray,
      mediaIdx = _ref2.mediaIdx;
  var currentLoves = stateArray[mediaIdx].likes || [];
  var newMediaLovesArray = [].concat(_toConsumableArray(currentLoves), [mediaData]);

  var newMediaObjLoveMedia = _objectSpread(_objectSpread({}, stateArray[mediaIdx]), {}, {
    likes: newMediaLovesArray
  });

  return stateArray.map(function (itm, idx) {
    return idx === mediaIdx ? newMediaObjLoveMedia : itm;
  });
};

exports.getNewMediaLovesArray = getNewMediaLovesArray;

var getNewMediaCommentsArray = function getNewMediaCommentsArray(_ref3) {
  var mediaData = _ref3.mediaData,
      stateArray = _ref3.stateArray,
      mediaIdx = _ref3.mediaIdx;
  var currentCommentsArray = stateArray[mediaIdx].comments || [];
  var newCommentsArrayComment = [].concat(_toConsumableArray(currentCommentsArray), [mediaData]);

  var newMediaObjComment = _objectSpread(_objectSpread({}, stateArray[mediaIdx]), {}, {
    comments: newCommentsArrayComment
  });

  return stateArray.map(function (itm, idx) {
    return idx === mediaIdx ? newMediaObjComment : itm;
  });
};

exports.getNewMediaCommentsArray = getNewMediaCommentsArray;

var getNewCommentsLoveArray = function getNewCommentsLoveArray(_ref4) {
  var commentId = _ref4.commentId,
      loveData = _ref4.loveData,
      mediaIdx = _ref4.mediaIdx,
      stateArray = _ref4.stateArray;
  var commentIdx = findItemIdx(stateArray[mediaIdx].comments, commentId);

  var newLove = _objectSpread({}, loveData);

  var currentCommentLikes = stateArray[mediaIdx].comments[commentIdx].likes || [];
  var newLikesArray = [].concat(_toConsumableArray(currentCommentLikes), [newLove]);

  var newComment = _objectSpread(_objectSpread({}, stateArray[mediaIdx].comments[commentIdx]), {}, {
    likes: newLikesArray
  });

  var newCommentsArrayLove = stateArray[mediaIdx].comments.map(function (cmt, idx) {
    return idx === commentIdx ? newComment : cmt;
  });

  var newMediaObjLove = _objectSpread(_objectSpread({}, stateArray[mediaIdx]), {}, {
    comments: newCommentsArrayLove
  });

  return stateArray.map(function (cnd, idx) {
    return idx === mediaIdx ? newMediaObjLove : cnd;
  });
};

exports.getNewCommentsLoveArray = getNewCommentsLoveArray;