"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _redux = require("redux");

var _user = _interopRequireDefault(require("./user"));

var _users = _interopRequireDefault(require("./users"));

var _userDetails = _interopRequireDefault(require("./userDetails"));

var _accountDetails = _interopRequireDefault(require("./accountDetails"));

var _annc = _interopRequireDefault(require("./annc"));

var _editUser = _interopRequireDefault(require("./editUser"));

var _resources = _interopRequireDefault(require("./resources"));

var _userSignup = _interopRequireDefault(require("./userSignup"));

var _editMemorial = _interopRequireDefault(require("./editMemorial"));

var _createMemorial = _interopRequireDefault(require("./createMemorial"));

var _editAccount = _interopRequireDefault(require("./editAccount"));

var _editHome = _interopRequireDefault(require("./editHome"));

var _editAfp = _interopRequireDefault(require("./editAfp"));

var _editCompany = _interopRequireDefault(require("./editCompany"));

var _fetchAccounts = _interopRequireDefault(require("./fetchAccounts"));

var _fetchCities = _interopRequireDefault(require("./fetchCities"));

var _fetchedCompany = _interopRequireDefault(require("./fetchedCompany"));

var _fetchCompanyReport = _interopRequireDefault(require("./fetchCompanyReport"));

var _deleteMemorial = _interopRequireDefault(require("./deleteMemorial"));

var _followMemorial = _interopRequireDefault(require("./followMemorial"));

var _setStepperPage = _interopRequireDefault(require("./setStepperPage"));

var _recordPageResize = _interopRequireDefault(require("./recordPageResize"));

var _fetchGoogleHomes = _interopRequireDefault(require("./fetchGoogleHomes"));

var _companyMemorials = _interopRequireDefault(require("./companyMemorials"));

var _companyMemorialsStats = _interopRequireDefault(require("../../lib/dash/reducers/companyMemorialsStats"));

var _addCompanyWithMisc = _interopRequireDefault(require("./addCompanyWithMisc"));

var _fetchAnnouncements = _interopRequireDefault(require("./fetchAnnouncements"));

var _phoneVerification = _interopRequireDefault(require("./phoneVerification"));

var _legacyTouch = _interopRequireDefault(require("./legacyTouch"));

var _activity = _interopRequireDefault(require("../../lib/dash/reducers/activity"));

var _dashNav = _interopRequireDefault(require("../../lib/dash/reducers/dashNav"));

var _emailConfirm = _interopRequireDefault(require("./emailConfirm"));

var _onboarding = _interopRequireDefault(require("./onboarding"));

var _crm = _interopRequireDefault(require("./crm"));

var _reducers = require("../../lib/lead/reducers");

var _reducers2 = require("../../lib/leadActivity/reducers");

var _profile = _interopRequireDefault(require("./profile"));

var _company = _interopRequireDefault(require("./company"));

var _reducers3 = require("../../lib/manyHomes/reducers");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var dash = (0, _redux.combineReducers)({
  user: _user.default,
  users: _users.default,
  userDetails: _userDetails.default,
  accountDetails: _accountDetails.default,
  annc: _annc.default,
  editAccount: _editAccount.default,
  editUser: _editUser.default,
  editHome: _editHome.default,
  userSignup: _userSignup.default,
  createMemorial: _createMemorial.default,
  editAfp: _editAfp.default,
  editMemorial: _editMemorial.default,
  editCompany: _editCompany.default,
  fetchAccounts: _fetchAccounts.default,
  fetchedCities: _fetchCities.default,
  fetchedCompany: _fetchedCompany.default,
  fetchedCompanyReport: _fetchCompanyReport.default,
  deleteMemorial: _deleteMemorial.default,
  followMemorial: _followMemorial.default,
  setStepperPage: _setStepperPage.default,
  recordPageResize: _recordPageResize.default,
  companyMemorials: _companyMemorials.default,
  memorialStats: _companyMemorialsStats.default,
  fetchGoogleHomes: _fetchGoogleHomes.default,
  addCompanyWithMisc: _addCompanyWithMisc.default,
  announcementStats: _fetchAnnouncements.default,
  phoneVerification: _phoneVerification.default,
  resources: _resources.default,
  legacyTouch: _legacyTouch.default,
  activity: _activity.default,
  dashNav: _dashNav.default,
  emailConfirm: _emailConfirm.default,
  onboarding: _onboarding.default,
  crm: _crm.default,
  fetchedLeads: _reducers.fetchedLeads,
  currentLead: _reducers.currentLead,
  fetchedLeadActivity: _reducers2.fetchedLeadActivity,
  profile: _profile.default,
  company: _company.default,
  editManyHomes: _reducers3.editManyHomes
});
var _default = dash;
exports.default = _default;