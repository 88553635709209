"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  image: '',
  name: {
    first: '',
    last: ''
  },
  email: '',
  phone: '',
  title: '',
  bio: '',
  shirtSize: ''
};
exports.default = _default;