"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FETCH_LOCALES_AUTOCOMPLETE_FAILURE = exports.FETCH_LOCALES_AUTOCOMPLETE_SUCCESS = exports.FETCH_LOCALES_AUTOCOMPLETE_REQUEST = void 0;
var FETCH_LOCALES_AUTOCOMPLETE_REQUEST = 'FETCH_LOCALES_AUTOCOMPLETE_REQUEST';
exports.FETCH_LOCALES_AUTOCOMPLETE_REQUEST = FETCH_LOCALES_AUTOCOMPLETE_REQUEST;
var FETCH_LOCALES_AUTOCOMPLETE_SUCCESS = 'FETCH_LOCALES_AUTOCOMPLETE_SUCCESS';
exports.FETCH_LOCALES_AUTOCOMPLETE_SUCCESS = FETCH_LOCALES_AUTOCOMPLETE_SUCCESS;
var FETCH_LOCALES_AUTOCOMPLETE_FAILURE = 'FETCH_LOCALES_AUTOCOMPLETE_FAILURE';
exports.FETCH_LOCALES_AUTOCOMPLETE_FAILURE = FETCH_LOCALES_AUTOCOMPLETE_FAILURE;