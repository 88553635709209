"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.array.from.js");

require("core-js/modules/es6.function.name.js");

require("core-js/modules/es6.weak-map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.map.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.regexp.to-string.js");

require("core-js/modules/es6.array.find-index.js");

require("core-js/modules/es6.array.slice.js");

var _redux = require("redux");

var _pick = _interopRequireDefault(require("lodash-es/pick"));

var _forms = require("./forms");

var _reactReduxForm = require("react-redux-form");

var types = _interopRequireWildcard(require("./constants"));

var creationTypes = _interopRequireWildcard(require("../inviteCreation/constants"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialInviteDetailsState = {
  data: {},
  isFetching: false,
  lastUpdated: null,
  error: {}
};
var inviteMetaKeys = ['_id', 'createdByFuneralHome', 'creationDate', 'isArchived', 'isFinished', 'isServices', 'renderInvite', 'role', // TODO: not sure where this goes
'serviceStatus', 'shareUrl', 'themeIdx', 'obituary', 'donations'];

var inviteMetaReducer = function inviteMetaReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialInviteDetailsState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.FETCH_INVITE_DETAILS_REQUEST:
    case types.ARCHIVE_INVITE_REQUEST:
    case creationTypes.EDIT_THEME_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true
      });

    case types.FETCH_INVITE_DETAILS_SUCCESS:
    case types.ARCHIVE_INVITE_SUCCESS:
      var data = (0, _pick.default)(action.payload, inviteMetaKeys);
      return _objectSpread(_objectSpread({}, state), {}, {
        data: data,
        isFetching: false,
        lastUpdated: action.receivedAt
      });
    // TODO: decide whether to refactor this or make separate actions for invite details

    case creationTypes.EDIT_THEME_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        data: _objectSpread(_objectSpread({}, state.data), {}, {
          themeIdx: action.payload.data.themeIdx
        }),
        isFetching: false,
        lastUpdated: action.receivedAt
      });

    case types.FETCH_INVITE_DETAILS_FAILURE:
    case types.ARCHIVE_INVITE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        isFetching: false,
        lastUpdated: action.receivedAt
      });

    case types.INVITE_DETAILS_RESET:
    case creationTypes.RESET_INVITE_CREATION:
      // TODO: decouple these resets and constant name overlaps in a future refactor
      return initialInviteDetailsState;

    default:
      return state;
  }
};

var initialServicesState = {
  array: [],
  isFetching: false,
  lastUpdated: null,
  error: {}
};

var servicesReducer = function servicesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialServicesState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.FETCH_INVITE_DETAILS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        array: action.payload.services
      });

    case creationTypes.ADD_NEW_SERVICE_REQUEST:
    case creationTypes.EDIT_SERVICE_REQUEST:
    case creationTypes.DELETE_SERVICE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true
      });

    case creationTypes.ADD_NEW_SERVICE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        array: [].concat(_toConsumableArray(state.array), [action.payload.data]),
        lastUpdated: action.receivedAt
      });

    case creationTypes.EDIT_SERVICE_SUCCESS:
      var editedService = action.payload.data;
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        array: state.array.map(function (service) {
          return service._id.toString() === editedService._id.toString() ? editedService : service;
        }),
        lastUpdated: action.receivedAt
      });

    case creationTypes.DELETE_SERVICE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        array: action.payload.data.services,
        lastUpdated: action.receivedAt
      });

    case types.INVITE_DETAILS_RESET:
    case creationTypes.RESET_INVITE_CREATION:
      // TODO: decouple these resets and constant name overlaps in a future refactor
      return initialServicesState;

    default:
      return state;
  }
}; // Locations for typeahead modal


var initialLocationState = {
  isFetching: false,
  locations: [],
  lastUpdated: null
};

var locationsReducer = function locationsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialLocationState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case creationTypes.GET_LOCATIONS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true
      });

    case creationTypes.GET_LOCATIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        locations: action.payload.data,
        lastUpdated: action.receivedAt
      });

    case types.INVITE_DETAILS_RESET:
    case creationTypes.RESET_INVITE_CREATION:
      return initialLocationState;

    default:
      return state;
  }
};

var initialDecedentDetailsState = {
  data: {},
  isFetching: false,
  lastUpdated: null,
  error: {}
};
var decedentDetailsKeys = ['cityOfBirth', 'cityOfDeath', 'dateOfBirth', 'dateOfDeath', 'decedentPhone', 'displayName', 'image', 'name', '_id', 'serviceStatus', 'traumatic'];

var decedentDetailsReducer = function decedentDetailsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialDecedentDetailsState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.FETCH_INVITE_DETAILS_SUCCESS:
      var data = (0, _pick.default)(action.payload, decedentDetailsKeys);
      return _objectSpread(_objectSpread({}, state), {}, {
        data: data
      });

    case creationTypes.EDIT_DECEDENT_DETAILS_REQUEST:
    case creationTypes.ADD_NOSERVICE_OPTIONS_REQUEST:
    case creationTypes.EDIT_DECEDENT_PHONE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true
      });
    // TODO: decide whether to refactor this or make separate actions for invite details

    case creationTypes.EDIT_DECEDENT_DETAILS_SUCCESS:
    case creationTypes.ADD_NOSERVICE_OPTIONS_SUCCESS:
    case creationTypes.EDIT_DECEDENT_PHONE_SUCCESS:
      var updatedDecedent = _objectSpread(_objectSpread({}, (0, _pick.default)(action.payload.data, decedentDetailsKeys)), {}, {
        decedentPhone: "".concat(action.payload.data.decedentPhone || '')
      });

      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        data: updatedDecedent,
        lastUpdated: action.receivedAt
      });

    case types.INVITE_DETAILS_RESET:
    case creationTypes.RESET_INVITE_CREATION:
      // TODO: decouple these resets and constant name overlaps in a future refactor
      return initialDecedentDetailsState;

    default:
      return state;
  }
};

var initialAccessorsState = {
  array: [],
  isFetching: false,
  lastUpdated: null,
  error: {}
};

var accessorsReducer = function accessorsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialAccessorsState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.FETCH_INVITE_DETAILS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        array: action.payload.accessors
      });

    case creationTypes.ADD_NEW_ACCESSOR_REQUEST:
    case creationTypes.EDIT_ACCESSOR_REQUEST:
    case creationTypes.DELETE_ACCESSOR_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true
      });

    case creationTypes.DELETE_ACCESSOR_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        array: action.payload.data.accessors,
        lastUpdated: action.receivedAt,
        error: {}
      });

    case creationTypes.ADD_NEW_ACCESSOR_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        array: [].concat(_toConsumableArray(state.array), _toConsumableArray(action.payload.data)),
        lastUpdated: action.receivedAt,
        error: {}
      });

    case creationTypes.EDIT_ACCESSOR_SUCCESS:
      var editedAccessor = action.payload.data;
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        array: state.array.map(function (accessor) {
          return accessor._id.toString() === editedAccessor._id.toString() ? editedAccessor : accessor;
        }),
        lastUpdated: action.receivedAt,
        error: {}
      });

    case creationTypes.ADD_NEW_ACCESSOR_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: action.error
      });

    case types.INVITE_DETAILS_RESET:
    case creationTypes.RESET_INVITE_CREATION:
      // TODO: decouple these resets and constant name overlaps in a future refactor
      return initialAccessorsState;

    default:
      return state;
  }
};

var initialFuneralInfoState = {
  agent: {},
  director: {},
  funeralHome: {},
  isFetching: false,
  lastUpdated: null,
  error: {}
};

var funeralInfoReducer = function funeralInfoReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialFuneralInfoState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var _ref = action.payload || {},
      funeralHomeInfo = _ref.funeralHomeInfo;

  var _ref2 = funeralHomeInfo || {},
      agent = _ref2.agent,
      director = _ref2.director,
      funeralHome = _ref2.funeralHome;

  switch (action.type) {
    case types.FETCH_INVITE_DETAILS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        agent: agent,
        director: director,
        funeralHome: funeralHome
      });

    case creationTypes.EDIT_FUNERAL_INFO_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true
      });

    case creationTypes.EDIT_FUNERAL_INFO_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        agent: agent,
        director: director,
        funeralHome: funeralHome
      });

    case creationTypes.EDIT_FUNERAL_INFO_RESET:
      return _objectSpread(_objectSpread({}, state), {}, {
        lastUpdated: null
      });

    case types.INVITE_DETAILS_RESET:
    case creationTypes.RESET_INVITE_CREATION:
      // TODO: decouple these resets and constant name overlaps in a future refactor
      return initialFuneralInfoState;

    default:
      return state;
  }
}; // Obituary


var initialObituaryState = {
  message: '',
  isFetching: false,
  lastUpdated: null,
  obitUpdated: null,
  obitCreated: null,
  error: {}
};

var obituaryReducer = function obituaryReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialObituaryState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case creationTypes.ADD_OBITUARY_REQUEST:
    case creationTypes.EDIT_OBITUARY_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true,
        obitUpdated: null,
        obitCreated: null
      });

    case types.FETCH_INVITE_DETAILS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        message: action.payload.obituary
      });

    case creationTypes.ADD_OBITUARY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        message: action.payload.data.obituary,
        lastUpdated: action.receivedAt,
        obitCreated: true,
        isFetching: false
      });

    case creationTypes.EDIT_OBITUARY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        message: action.payload.data.obituary,
        lastUpdated: action.receivedAt,
        obitUpdated: true,
        isFetching: false
      });

    case creationTypes.RESET_INVITE_CREATION:
    case types.INVITE_DETAILS_RESET:
      // TODO: decouple these resets and constant name overlaps in a future refactor
      return initialObituaryState;

    default:
      return state;
  }
}; // Donations


var initialDonationState = {
  array: [],
  isFetching: false,
  lastUpdated: null,
  donationAdded: false,
  donationUpdated: false,
  donationRemoved: false,
  error: {}
};

var donationReducer = function donationReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialDonationState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.FETCH_INVITE_DETAILS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        array: action.payload.donations || []
      });

    case creationTypes.ADD_DONATION_FAILURE:
    case creationTypes.EDIT_DONATION_FAILURE:
    case creationTypes.DELETE_DONATION_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        lastUpdated: action.receivedAt
      });

    case creationTypes.ADD_DONATION_REQUEST:
    case creationTypes.EDIT_DONATION_REQUEST:
    case creationTypes.DELETE_DONATION_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true,
        donationAdded: false,
        donationUpdated: false,
        donationRemoved: false
      });

    case creationTypes.ADD_DONATION_SUCCESS:
      //state.array is an obj on older invites due to old mongoose schema
      //dev/prod memos have been etl'd
      return _objectSpread(_objectSpread({}, state), {}, {
        array: [].concat(_toConsumableArray(state.array), [action.payload.data]),
        isFetching: false,
        donationAdded: true,
        lastUpdated: action.receivedAt
      });

    case creationTypes.EDIT_DONATION_SUCCESS:
      var updatedDonationIdx = state.array.findIndex(function (d) {
        return d._id.toString() === action.payload.data._id.toString();
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        array: [].concat(_toConsumableArray(state.array.slice(0, updatedDonationIdx)), [action.payload.data], _toConsumableArray(state.array.slice(updatedDonationIdx + 1))),
        isFetching: false,
        donationUpdated: true,
        lastUpdated: action.receivedAt
      });

    case creationTypes.DELETE_DONATION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        array: action.payload.data.donations,
        isFetching: false,
        lastUpdated: action.receivedAt,
        donationRemoved: true
      });

    default:
      return state;
  }
};

var _default = (0, _redux.combineReducers)({
  inviteMeta: inviteMetaReducer,
  services: servicesReducer,
  decedentDetails: decedentDetailsReducer,
  accessors: accessorsReducer,
  funeralInfo: funeralInfoReducer,
  googleLocations: locationsReducer,
  obituary: obituaryReducer,
  donations: donationReducer,
  forms: (0, _reactReduxForm.combineForms)({
    funeralInfo: _forms.funeralInfo,
    eventInfo: _forms.eventInfo,
    decedentInfo: _forms.decedentInfo,
    accessor: _forms.accessor,
    obituary: _forms.obituary,
    decedentPhone: _forms.decedentPhone
  }, 'inviteDetails.forms', {
    key: 'formsMeta'
  })
});

exports.default = _default;