import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { browserHistory } from 'react-router';

import {
  Form,
  InputGroup,
  parsePhone,
  isValidPhone,
  isRequired,
  isEmail
} from '@evdy/web-core/dist/components/shared/Input';
import SiteNav from '../../shared/SiteNav';
import FooterSection from '../../shared/Footer/FooterSection';
import TermsFooter from '../../shared/Footer/TermsFooter';
import HelpSection from '../../shared/HelpSection';
import LoaderComponent from '@evdy/web-core/dist/components/shared/elements/LoaderComponent';

import {
  requestDemoEmailAction,
  requestDemoEmailReset
} from '@evdy/web-redux/dist/reducers/requestDemoEmail';
import { formActions } from '@evdy/web-core/dist/components/shared/Input';
import { useHideIntercom } from '../../../customHooks';

import everdaysLogoWithFont from '../../../public/images/logo-provider.svg';

import styles from './ForHomes.module.scss';

const {
  'partners-page': partnersPageClass,
  'provider-login-cta': providerLoginCTAClass,
  pitch: pitchSectionClass,
  'pitch-reverse': pitchReverseSectionClass,
  'request-demo': requestDemoSectionClass,
  'request-demo-card': formCardClass,
  'request-demo-form': requestDemoFormClass,
  // General Content
  'b2b-content': b2bContentClass,
  'b2b-content-icon': b2bContentIconClass,
  'b2b-content-title': b2bContentTitleClass,
  'b2b-content-description': b2bContentDescriptionClass,
  'b2b-content-button': b2bContentButtonClass
} = styles;

const B2BContent = ({ icon, title, description = '', cta, backgroundColor }) => {
  const handleCTA = () => {
    window.location.href = cta.url;
  };
  const style = backgroundColor && { backgroundColor };
  return (
    <div className={b2bContentClass} {...{ style }}>
      {icon && <img className={b2bContentIconClass} src={icon} alt="b2b content" />}
      {title && <h1 className={b2bContentTitleClass}>{title}</h1>}
      {description.split(/\\n/).map(section => (
        <p key={section.slice(0, 5)} className={b2bContentDescriptionClass}>
          {section}
        </p>
      ))}
      {cta && (
        <button className={b2bContentButtonClass} onClick={handleCTA}>
          {cta.copy}
        </button>
      )}
    </div>
  );
};

const model = 'forms.requestDemo';

const ProviderLoginCTA = () => {
  return (
    <button className={providerLoginCTAClass}>
      <a href="/dash">Provider Log in</a>
    </button>
  );
};

const ForHomes = ({ user, location, client, children }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const { isFetching, isValid, receivedAt, formData } = useSelector(
    ({ forms, requestDemoEmail }) => ({
      isFetching: requestDemoEmail.isFetching,
      isValid: forms.formsMeta.requestDemo.$form.valid,
      receivedAt: requestDemoEmail.receivedAt,
      formData: forms.requestDemo
    })
  );

  const handleToggleMenu = () => {
    setMenuOpen(prevState => !prevState);
  };

  const handleSubmit = () => {
    const { fullName, email, phone, fHomeName } = formData;
    const [fName, ...lName] = fullName.split(' ');
    dispatch(
      requestDemoEmailAction({
        fName,
        lName: lName.join(' '),
        email,
        phone,
        fHomeName,
        from: '/for-homes'
      })
    );
    dispatch(formActions.reset('forms.requestDemo'));
  };

  // redirect when form data is received
  useEffect(() => {
    if (receivedAt > 0) {
      browserHistory.push('/provider');
    }
  }, [receivedAt]);

  // reset form on unmount
  useEffect(() => {
    return () => {
      dispatch(formActions.reset('forms.requestDemo'));
      dispatch(requestDemoEmailReset);
    };
  }, [dispatch]);

  //hide intercom
  useHideIntercom();

  return (
    <div className={partnersPageClass}>
      <SiteNav rightNavContent={() => <ProviderLoginCTA />} />
      <div className={pitchSectionClass}>
        <B2BContent
          title="Take your services directly to the modern consumer"
          //cta={{ copy: 'Learn more', url: '/' }}
          icon={everdaysLogoWithFont}
        />
        <img
          src="https://everdays-site.s3.amazonaws.com/invite-homepage/b2b_hero.jpg"
          alt="help your family"
        />
      </div>
      <div className={`${pitchSectionClass} ${pitchReverseSectionClass}`}>
        <B2BContent
          title="And help people take care of their families long before they’re gone"
          cta={{ copy: 'See it in action', url: 'https://youtu.be/1bYoloVVrzw' }}
          backgroundColor="#f7f6f6;"
        />
        <img
          src="https://everdays-site.s3.amazonaws.com/home-11-20/pitch-1_2x.jpg"
          alt="help your family"
        />
      </div>
      <div className={requestDemoSectionClass}>
        <h1>Learn more from our team today</h1>
        <div className={formCardClass}>
          <Form model={model} className={requestDemoFormClass} onSubmit={handleSubmit}>
            <div className="input-row">
              <InputGroup
                labelText="Full Name"
                model=".fullName"
                placeholder="Full Name"
                validators={{ isRequired }}
                messages={{ isRequired: 'Please enter your name' }}
              />
            </div>
            <div className="input-row">
              <InputGroup
                labelText="Email Address"
                model=".email"
                placeholder="email@everday.com"
                validators={{ isRequired, isEmail }}
                messages={{
                  isRequired: 'Please enter your email',
                  isEmail: 'Please enter a valid email'
                }}
              />
            </div>
            <div className="input-row">
              <InputGroup
                customClass="usa-country-code"
                labelText="Phone Number"
                model=".phone"
                parser={parsePhone}
                placeholder="###-###-####"
                validators={{
                  isRequired,
                  isValidPhone
                }}
                messages={{
                  isRequired: 'Please enter your phone',
                  isValidPhone: 'Please enter a valid phone number'
                }}
              />
            </div>
            <div className="input-row">
              <InputGroup
                labelText="Funeral Home"
                model=".fHomeName"
                placeholder="Everdays & Sons Funeral Home"
                validators={{ isRequired }}
                messages={{ isRequired: 'Please enter your funeral home name' }}
              />
            </div>
            <button type="submit" disabled={!isValid}>
              Get Started
            </button>
          </Form>
        </div>
      </div>
      <HelpSection />
      <FooterSection />
      <TermsFooter />
      {isFetching && <LoaderComponent />}
    </div>
  );
};

export default ForHomes;
