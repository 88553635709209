import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { useField } from 'formik';

import styles from './TextInput.module.scss';

const {
  'text-input': textInputClass,
  label: labelClass,
  input: inputClass,
  'has-error': hasErrorClass,
  'has-footnote': hasFootnoteClass,
  locked: lockedClass,
  focus: forceFocusClass,
  error: errorClass,
  footnote: footnoteClass
} = styles;

const TextInput = forwardRef(
  (
    {
      locked,
      label,
      footnote,
      customClass,
      storyClass,
      customInputClass,
      customLabelClass,
      customFootnoteClass,
      ...props
    },
    ref
  ) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>
    // https://formik.org/docs/api/useField
    // can remove this comment when we get more familiar with Formik
    const [field, meta] = useField(props);

    const textInputClasses = classNames(textInputClass, {
      [customClass]: customClass,
      [lockedClass]: locked,
      [hasErrorClass]: meta.touched && meta.error,
      [hasFootnoteClass]: footnote
    });

    const inputClasses = classNames(inputClass, {
      [forceFocusClass]: storyClass === 'focus'
    });

    return (
      <div className={textInputClasses}>
        <label className={`${labelClass} ${customLabelClass}`} htmlFor={props.id || props.name}>
          {label}
        </label>
        <input ref={ref} className={`${inputClasses} ${customInputClass}`} {...field} {...props} />
        {meta.touched && meta.error && <div className={errorClass}>{meta.error}</div>}
        {footnote && <div className={`${footnoteClass} ${customFootnoteClass}`}>{footnote}</div>}
      </div>
    );
  }
);

export default TextInput;
