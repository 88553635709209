"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userCompleteReset = exports.verifyPhone = exports.verifyPhoneFailure = exports.verifyPhoneSuccess = exports.verifyPhoneRequest = exports.checkPhone = exports.checkPhoneFailure = exports.checkPhoneSuccess = exports.checkPhoneRequest = void 0;

var types = _interopRequireWildcard(require("../../constants/dash/phoneVerification"));

var _constants = require("../../constants");

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var instance = _index.default.create({
  baseURL: _constants.API_URL,
  headers: {
    Accept: 'application/vnd.everdays+json; v=20171228'
  }
});
/**
 * App-Style Check Phone Number
 */


var checkPhoneRequest = function checkPhoneRequest() {
  return {
    type: types.CHECK_PHONE_REQUEST
  };
};

exports.checkPhoneRequest = checkPhoneRequest;

var checkPhoneSuccess = function checkPhoneSuccess(_ref) {
  var data = _ref.data;
  return {
    type: types.CHECK_PHONE_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.checkPhoneSuccess = checkPhoneSuccess;

var checkPhoneFailure = function checkPhoneFailure(error) {
  return {
    type: types.CHECK_PHONE_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.checkPhoneFailure = checkPhoneFailure;

var checkPhone = function checkPhone(phone) {
  return function (dispatch) {
    dispatch(checkPhoneRequest());
    return instance.post('/auth/check-phone?share_page=1', {
      phone: phone
    }).then(function (res) {
      return dispatch(checkPhoneSuccess(res.data));
    }).catch(function (error) {
      return dispatch(checkPhoneFailure((0, _transformAxiosError.default)(error)));
    });
  };
};
/**
 * App-Style Verify Phone Number
 */


exports.checkPhone = checkPhone;

var verifyPhoneRequest = function verifyPhoneRequest() {
  return {
    type: types.VERIFY_PHONE_REQUEST
  };
};

exports.verifyPhoneRequest = verifyPhoneRequest;

var verifyPhoneSuccess = function verifyPhoneSuccess(_ref2) {
  var data = _ref2.data;
  return {
    type: types.VERIFY_PHONE_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.verifyPhoneSuccess = verifyPhoneSuccess;

var verifyPhoneFailure = function verifyPhoneFailure(error) {
  return {
    type: types.VERIFY_PHONE_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.verifyPhoneFailure = verifyPhoneFailure;

var verifyPhone = function verifyPhone(phone, code, id) {
  return function (dispatch) {
    dispatch(verifyPhoneRequest());
    return instance.post('/auth/verify?share_page=1', {
      phone: phone,
      code: code,
      id: id
    }).then(function (res) {
      return dispatch(verifyPhoneSuccess(res.data));
    }).catch(function (error) {
      return dispatch(verifyPhoneFailure((0, _transformAxiosError.default)(error)));
    });
  };
};
/**
 * Reset User Complete
 */


exports.verifyPhone = verifyPhone;

var userCompleteReset = function userCompleteReset() {
  return {
    type: types.USER_COMPLETE_RESET
  };
};

exports.userCompleteReset = userCompleteReset;