"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESET_CURRENT_CITY = exports.FETCH_CITY_FAILURE = exports.FETCH_CITY_SUCCESS = exports.FETCH_CITY_REQUEST = exports.FETCH_LOCALES_FAILURE = exports.FETCH_LOCALES_SUCCESS = exports.FETCH_LOCALES_REQUEST = void 0;
var FETCH_LOCALES_REQUEST = 'FETCH_LOCALES_REQUEST';
exports.FETCH_LOCALES_REQUEST = FETCH_LOCALES_REQUEST;
var FETCH_LOCALES_SUCCESS = 'FETCH_LOCALES_SUCCESS';
exports.FETCH_LOCALES_SUCCESS = FETCH_LOCALES_SUCCESS;
var FETCH_LOCALES_FAILURE = 'FETCH_LOCALES_FAILURE';
exports.FETCH_LOCALES_FAILURE = FETCH_LOCALES_FAILURE;
var FETCH_CITY_REQUEST = 'FETCH_CITY_REQUEST';
exports.FETCH_CITY_REQUEST = FETCH_CITY_REQUEST;
var FETCH_CITY_SUCCESS = 'FETCH_CITY_SUCCESS';
exports.FETCH_CITY_SUCCESS = FETCH_CITY_SUCCESS;
var FETCH_CITY_FAILURE = 'FETCH_CITY_FAILURE';
exports.FETCH_CITY_FAILURE = FETCH_CITY_FAILURE;
var RESET_CURRENT_CITY = 'RESET_CURRENT_CITY';
exports.RESET_CURRENT_CITY = RESET_CURRENT_CITY;