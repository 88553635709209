import React from 'react';

import styles from './ProtectPromise.module.scss';

import threeShieldIcon from '../../../../public/images/icons/3-shield-icon.svg';

const {
  'promise-protect': promiseProtectClass,
  content: contentClass,
  'promises-section': promisesSection,
  'promise-copy': promiseCopyClass,
  'triple-guarantee': tripleGuaranteeClass,
  'guarantee-copy': guaranteeCopy,
  promise: promiseClass,
  shield: shieldClass,
  title: titleClass,
  subtitle: subtitleClass
} = styles;

const ProtectPromise = ({
  color1 = '#e8980e',
  subtitleOne,
  subtitleTwo,
  subtitleThree,
  titleOne = 'Funds Secured & Insured',
  titleTwo = 'Relocate Anywhere',
  titleThree = '30 Day Money Back'
}) => {
  return (
    <div className={promiseProtectClass}>
      <div className={contentClass}>
        <div className={tripleGuaranteeClass}>
          <img src={threeShieldIcon} alt="always triple protected" />
          <div className={guaranteeCopy}>
            <h4>Always Triple Protected</h4>
            <p>The confidence & control you deserve.</p>
          </div>
        </div>
        <div className={promisesSection}>
          <div className={promiseClass}>
            <div className={shieldClass} />
            <div className={promiseCopyClass}>
              <p className={titleClass}>{titleOne}</p>
              {subtitleOne && <p className={subtitleClass}>{subtitleOne}</p>}
            </div>
          </div>
          <div className={promiseClass}>
            <div className={shieldClass} />
            <div className={promiseCopyClass}>
              <p className={titleClass}>{titleTwo}</p>
              {subtitleTwo && <p className={subtitleClass}>{subtitleTwo}</p>}
            </div>
          </div>
          <div className={promiseClass}>
            <div className={shieldClass} />
            <div className={promiseCopyClass}>
              <p className={titleClass}>{titleThree}</p>
              {subtitleThree && <p className={subtitleClass}>{subtitleThree}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProtectPromise;
