"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _redux = require("redux");

var _reactReduxForm = require("react-redux-form");

var _overview = _interopRequireDefault(require("./overview"));

var _locationModal = _interopRequireDefault(require("./locationModal"));

var _memberModal = _interopRequireDefault(require("./memberModal"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// form reducers
var company = (0, _redux.combineReducers)({
  forms: (0, _reactReduxForm.combineForms)({
    overview: _overview.default,
    locationModal: _locationModal.default,
    memberModal: _memberModal.default
  }, 'dash.company.forms', {
    key: 'companyMeta'
  })
});
var _default = company;
exports.default = _default;