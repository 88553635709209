import React from 'react';
import classNames from 'classnames';

import styles from './WhyToday.module.scss';

import takeCareImg from '../../../../public/images/take-care-family.png';
import save40Img from '../../../../public/images/save-40-percent.png';

const {
  'why-today': whyToday,
  'section-img': sectionImgClass,
  'no-mobile': noMobileImg,
  'why-card': whyCardClass,
  'why-today-info': whyTodayInfoContainer
} = styles;

const whyTodayData = [
  {
    hasMobileImg: true,
    img: takeCareImg,
    title: 'Planning Over 60',
    subtitle: 'Helpful ways to plan ahead & take care of your family.',
    links: [
      { copy: 'Learn how you can help your family heal', href: '/why/love' },
      { copy: 'Learn how to prepare for the unexpected', href: '/why/protection' },
      { copy: 'Learn about financial & estate planning', href: '/why/estate' }
    ]
  },
  {
    img: save40Img,
    title: 'Funding Your Plans',
    subtitle: 'Learn how to protect your and your family’s future.',
    links: [
      { copy: 'Learn about savings', href: '/why/savings' },
      { copy: 'Learn about Medicaid spend-down', href: '/why/medicaid' }
    ]
  }
];

const WhyTodayCard = ({ hasMobileImg = false, img, title, subtitle, links = [] }) => (
  <div className={whyCardClass}>
    <img
      className={classNames(sectionImgClass, { [noMobileImg]: !hasMobileImg })}
      src={img}
      alt={`${title}`}
    />
    <h3>{title}</h3>
    <p>{subtitle}</p>
    {links.map(({ copy, href }) => (
      <a href={href}>{copy} &gt;</a>
    ))}
  </div>
);

const WhyToday = () => {
  return (
    <div className={whyToday}>
      <h2>Planning Resources</h2>
      <div className={whyTodayInfoContainer}>
        {whyTodayData.map(card => (
          <WhyTodayCard {...{ ...card }} key={card?.title} />
        ))}
      </div>
    </div>
  );
};

export default WhyToday;
