"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateUserPackageFailure = exports.updateUserPackageSuccess = exports.updateUserPackageRequest = exports.startSaleFailure = exports.startSaleSuccess = exports.startSaleRequest = exports.resetUpdateWebleadAction = exports.updateWebleadFailure = exports.updateWebleadSuccess = exports.updateWebleadRequest = exports.fetchWebleadFailure = exports.fetchWebleadSuccess = exports.fetchWebleadRequest = exports.resetPackageSelectedAction = exports.selectNewPackageFailure = exports.selectNewPackageSuccess = exports.selectNewPackageRequest = exports.selectPackageFailure = exports.selectPackageSuccess = exports.selectPackageRequest = void 0;

var types = _interopRequireWildcard(require("./constants"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var selectPackageRequest = function selectPackageRequest() {
  return {
    type: types.SELECT_PACKAGE_REQUEST
  };
};

exports.selectPackageRequest = selectPackageRequest;

var selectPackageSuccess = function selectPackageSuccess(_ref) {
  var data = _ref.data;
  return {
    type: types.SELECT_PACKAGE_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.selectPackageSuccess = selectPackageSuccess;

var selectPackageFailure = function selectPackageFailure(error) {
  return {
    type: types.SELECT_PACKAGE_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.selectPackageFailure = selectPackageFailure;

var selectNewPackageRequest = function selectNewPackageRequest() {
  return {
    type: types.SELECT_NEW_PACKAGE_REQUEST
  };
};

exports.selectNewPackageRequest = selectNewPackageRequest;

var selectNewPackageSuccess = function selectNewPackageSuccess(_ref2) {
  var data = _ref2.data;
  return {
    type: types.SELECT_NEW_PACKAGE_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.selectNewPackageSuccess = selectNewPackageSuccess;

var selectNewPackageFailure = function selectNewPackageFailure(error) {
  return {
    type: types.SELECT_NEW_PACKAGE_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.selectNewPackageFailure = selectNewPackageFailure;

var resetPackageSelectedAction = function resetPackageSelectedAction() {
  return {
    type: types.RESET_PACKAGE_SELECTED
  };
};

exports.resetPackageSelectedAction = resetPackageSelectedAction;

var fetchWebleadRequest = function fetchWebleadRequest() {
  return {
    type: types.FETCH_WEBLEAD_REQUEST
  };
};

exports.fetchWebleadRequest = fetchWebleadRequest;

var fetchWebleadSuccess = function fetchWebleadSuccess(_ref3) {
  var data = _ref3.data;
  return {
    type: types.FETCH_WEBLEAD_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.fetchWebleadSuccess = fetchWebleadSuccess;

var fetchWebleadFailure = function fetchWebleadFailure(error) {
  return {
    type: types.FETCH_WEBLEAD_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
}; // update weblead


exports.fetchWebleadFailure = fetchWebleadFailure;

var updateWebleadRequest = function updateWebleadRequest() {
  return {
    type: types.UPDATE_WEBLEAD_REQUEST
  };
};

exports.updateWebleadRequest = updateWebleadRequest;

var updateWebleadSuccess = function updateWebleadSuccess(_ref4) {
  var data = _ref4.data;
  return {
    type: types.UPDATE_WEBLEAD_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.updateWebleadSuccess = updateWebleadSuccess;

var updateWebleadFailure = function updateWebleadFailure(error) {
  return {
    type: types.UPDATE_WEBLEAD_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.updateWebleadFailure = updateWebleadFailure;

var resetUpdateWebleadAction = function resetUpdateWebleadAction() {
  return {
    type: types.RESET_UPDATE_WEBLEAD
  };
}; // start sale


exports.resetUpdateWebleadAction = resetUpdateWebleadAction;

var startSaleRequest = function startSaleRequest() {
  return {
    type: types.START_SALE_REQUEST
  };
};

exports.startSaleRequest = startSaleRequest;

var startSaleSuccess = function startSaleSuccess(_ref5) {
  var data = _ref5.data;
  return {
    type: types.START_SALE_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.startSaleSuccess = startSaleSuccess;

var startSaleFailure = function startSaleFailure(error) {
  return {
    type: types.START_SALE_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
}; //update package and terms on user


exports.startSaleFailure = startSaleFailure;

var updateUserPackageRequest = function updateUserPackageRequest() {
  return {
    type: types.UPDATE_USER_PACKAGE_REQUEST
  };
};

exports.updateUserPackageRequest = updateUserPackageRequest;

var updateUserPackageSuccess = function updateUserPackageSuccess(_ref6) {
  var data = _ref6.data;
  return {
    type: types.UPDATE_USER_PACKAGE_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.updateUserPackageSuccess = updateUserPackageSuccess;

var updateUserPackageFailure = function updateUserPackageFailure(error) {
  return {
    type: types.UPDATE_WEBLEAD_FAILURE,
    error: error,
    receviedAt: Date.now()
  };
};

exports.updateUserPackageFailure = updateUserPackageFailure;