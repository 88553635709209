"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPreneedPackages = void 0;

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

var _actions = require("./actions");

var _constants = require("../../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var instance = _index.default.create({
  baseURL: _constants.API_URL
});

var getPreneedPackages = function getPreneedPackages() {
  var loggedUser = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return function (dispatch) {
    dispatch((0, _actions.getPreneedPackagesRequest)());
    return instance // use private ep if user is logged to add user to req
    .get("".concat(_constants.API_URL, "/v2/").concat(loggedUser ? 'private' : 'public', "/preneedpackages")).then(function (res) {
      return dispatch((0, _actions.getPreneedPackagesSuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.getPreneedPackagesFailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.getPreneedPackages = getPreneedPackages;