"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NEW_MAT_FAILURE = exports.NEW_MAT_SUCCESS = exports.NEW_MAT_REQUEST = void 0;
var NEW_MAT_REQUEST = 'NEW_MAT_REQUEST';
exports.NEW_MAT_REQUEST = NEW_MAT_REQUEST;
var NEW_MAT_SUCCESS = 'NEW_MAT_SUCCESS';
exports.NEW_MAT_SUCCESS = NEW_MAT_SUCCESS;
var NEW_MAT_FAILURE = 'NEW_MAT_FAILURE';
exports.NEW_MAT_FAILURE = NEW_MAT_FAILURE;