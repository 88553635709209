import React from 'react';
import classNames from 'classnames';

import CharacterProfileImage from '@evdy/web-core/dist/components/shared/elements/CharacterProfileImage';

import { getAPIUrl } from '@evdy/web-redux/dist/constants';

import { useVerifyCookie } from '../../../customHooks';

import styles from './SlideOutMenu.module.scss';

import everdaysLogo from '../../../public/images/logos/everdays-logo-trademark-white.svg';
import closeIcon from '../../../public/images/icons/thin-line-close-icon.svg';
import chevronDown from '../../../public/images/icons/chevron-down-white.svg';

const API_URL_LOCAL_3000 = getAPIUrl('', 3000);

const {
  'slide-out-menu': slideOutMenuClass,
  'menu-open': menuOpenClass,
  'menu-header': menuHeaderClass,
  'menu-links': menuLinksClass,
  'menu-link': menuLinkClass,
  'menu-footer': menuFooterClass,
  'package-links': packageLinksClass,
  'link-title': linkTitleClass,
  'package-link': packageLinkClass,
  'links-wrapper': packageLinkWrapper,
  'planning-btn': planningBtnClass,
  'phone-content': phoneContentClass,
  'contact-us': contactUsClass,
  'char-profile': charProfileClass,
  'more-info-links': moreInfoLinksClass,
  'more-info-link': moreInfoLinkClass,
  'planning-links': planningLinksClass,
  'planning-link': planningLinkClass,
  'planning-wrapper': planningLinksWrapper,
  'chevron-right': chevRight,
  phone: contactPhoneClass
} = styles;

const uniqueLinks = [
  {
    linkCopy: 'Features',
    href: '/why',
    target: ''
  },
  {
    linkCopy: 'Company',
    href: 'https://learn.everdays.com/about/',
    target: ''
  }
];

const planningLinks = [
  {
    linkCopy: 'Prepaid Funeral Plans',
    href: `${API_URL_LOCAL_3000}/prepaid-funeral-plans/`,
    target: ''
  },
  {
    linkCopy: 'Prepaid Cremation Services',
    href: `${API_URL_LOCAL_3000}/prepaid-funeral-plans/options/prepaid-cremation/`,
    target: ''
  },
  {
    linkCopy: 'The Pros and Cons',
    href: `${API_URL_LOCAL_3000}/resources/pros-cons-prepaid-funeral-plans/`,
    target: ''
  },
  {
    linkCopy: 'Funeral & Burial Insurance',
    href: `${API_URL_LOCAL_3000}/prepaid-funeral-plans/options/funeral-insurance/`,
    target: ''
  },
  {
    linkCopy: 'Average Cremation Costs',
    href: `${API_URL_LOCAL_3000}/resources/cremation-cost/`,
    target: ''
  }
  // waiting for this content page to go in
  // { linkCopy: 'Celebration of Life Planning', href: '', target: '' }
];

const SlideOutMenu = ({
  closeMenu,
  isMenuOpen,
  getPriceAction,
  name,
  image,
  userId,
  ctaBtnCopy = 'Get Started'
}) => {
  const menuClasses = classNames({
    [slideOutMenuClass]: slideOutMenuClass,
    [menuOpenClass]: isMenuOpen
  });

  const ctaLink = useVerifyCookie('rq-sid') ? '/get-my-price' : 'https://try.everdays.com/plan';

  const moreInfoLinks = [
    {
      linkCopy: 'Get Started',
      href: ctaLink,
      target: ''
    }
  ];

  return (
    <div className={menuClasses}>
      <div className={menuHeaderClass}>
        <img
          className="logo"
          src={everdaysLogo}
          alt="everdays logo"
          onClick={() => (window.location.href = '/')}
        />
        <button onClick={closeMenu}>
          <img className="close" src={closeIcon} alt="close icon" />
        </button>
      </div>
      <div className={menuLinksClass}>
        {uniqueLinks.map(({ linkCopy, href, target }, i) => (
          <a
            target={target}
            rel="noopener noreferrer"
            href={href}
            className={menuLinkClass}
            key={`${linkCopy}_${i}`}
          >
            {linkCopy}
          </a>
        ))}
        <div className={planningLinksClass}>
          <div className={linkTitleClass}>
            <p>Learn</p>
            <img src={chevronDown} alt="down-chev" />
          </div>
          <div className={planningLinksWrapper}>
            {planningLinks.map(({ linkCopy, href, target }, i) => (
              <a
                rel="noopener noreferrer"
                target={target}
                key={`${linkCopy}_${i}`}
                href={href}
                className={planningLinkClass}
              >
                {linkCopy}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className={menuFooterClass}>
        <button className={planningBtnClass} onClick={getPriceAction}>
          {ctaBtnCopy}
        </button>
        <div className={contactUsClass}>
          <a href="tel:8774003297" className={phoneContentClass}>
            <div className={contactPhoneClass} />
            <p>Call Us</p>
          </a>
          {!!userId && (
            <CharacterProfileImage {...{ name, image }} customClasses={[charProfileClass]} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SlideOutMenu;
