import React from 'react';

import chevron from '../../../public/images/icons/chevron-right-navy-blue.svg';

import styles from './OverlappingCTA.module.scss';

const OverlappingCTA = ({
  header = 'Start your plan',
  subheader = 'Our thoughtful packages have everything you need to pick an experience your loved ones will cherish.',
  buttonCopy = 'Start Planning',
  buttonAction = () => {}
}) => {
  return (
    <div className={styles.overlappingCTA}>
      <div className={styles.copy}>
        <h2>{header}</h2>
        <p>{subheader}</p>
      </div>
      <button onClick={buttonAction}>
        {buttonCopy} <img src={chevron} />
      </button>
    </div>
  );
};

export default OverlappingCTA;
