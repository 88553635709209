"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EDIT_MAT_FAILURE = exports.EDIT_MAT_SUCCESS = exports.EDIT_MAT_REQUEST = void 0;
var EDIT_MAT_REQUEST = 'EDIT_MAT_REQUEST';
exports.EDIT_MAT_REQUEST = EDIT_MAT_REQUEST;
var EDIT_MAT_SUCCESS = 'EDIT_MAT_SUCCESS';
exports.EDIT_MAT_SUCCESS = EDIT_MAT_SUCCESS;
var EDIT_MAT_FAILURE = 'EDIT_MAT_FAILURE';
exports.EDIT_MAT_FAILURE = EDIT_MAT_FAILURE;