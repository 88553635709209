"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOAD_MORE_HOMES_SUCCESS = exports.FETCH_FUNERAL_HOMES_FAILURE = exports.FETCH_FUNERAL_HOMES_SUCCESS = exports.FETCH_FUNERAL_HOMES_REQUEST = void 0;
var FETCH_FUNERAL_HOMES_REQUEST = 'FETCH_FUNERAL_HOMES_REQUEST';
exports.FETCH_FUNERAL_HOMES_REQUEST = FETCH_FUNERAL_HOMES_REQUEST;
var FETCH_FUNERAL_HOMES_SUCCESS = 'FETCH_FUNERAL_HOMES_SUCCESS';
exports.FETCH_FUNERAL_HOMES_SUCCESS = FETCH_FUNERAL_HOMES_SUCCESS;
var FETCH_FUNERAL_HOMES_FAILURE = 'FETCH_FUNERAL_HOMES_FAILURE';
exports.FETCH_FUNERAL_HOMES_FAILURE = FETCH_FUNERAL_HOMES_FAILURE;
var LOAD_MORE_HOMES_SUCCESS = 'LOAD_MORE_HOMES_SUCCESS';
exports.LOAD_MORE_HOMES_SUCCESS = LOAD_MORE_HOMES_SUCCESS;