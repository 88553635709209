"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setPageHeight = exports.setPageWidth = void 0;

var _actions = require("./actions");

var setPageWidth = function setPageWidth(size) {
  return function (dispatch) {
    dispatch((0, _actions.setPageWidthAction)(size));
  };
};

exports.setPageWidth = setPageWidth;

var setPageHeight = function setPageHeight(size) {
  return function (dispatch) {
    dispatch((0, _actions.setPageHeightAction)(size));
  };
};

exports.setPageHeight = setPageHeight;