"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.editUserVisitedMemorialsAction = exports.editUserVisitedMemorialsFailure = exports.editUserVisitedMemorialsSuccess = exports.editUserVisitedMemorialsRequest = exports.editUserTermsAction = exports.editUserTermsFailure = exports.editUserTermsSuccess = exports.editUserTermsRequest = exports.addRecentLocation = exports.editSpecificUserPassword = exports.editUserPassword = exports.editSpecificUser = exports.getSpecificUser = exports.editUserAction = exports.getSpecificUserFailure = exports.editUserFailure = exports.addRecentLocationSuccess = exports.editUserPasswordSuccess = exports.editSpecificUserSuccess = exports.getSpecificUserSuccess = exports.editUserSuccess = exports.editSpecificUserPasswordRequest = exports.getSpecificUserRequest = exports.editUserRequest = exports.editSpecificUserReset = exports.editUserReset = void 0;

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

var _constants = require("../../constants");

var types = _interopRequireWildcard(require("../../constants/dash/editUser"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var instance = _index.default.create({
  baseURL: _constants.API_URL
});

var editUserReset = function editUserReset() {
  return {
    type: types.EDIT_USER_RESET
  };
};

exports.editUserReset = editUserReset;

var editSpecificUserReset = function editSpecificUserReset() {
  return {
    type: types.EDIT_SPECIFIC_USER_RESET
  };
};

exports.editSpecificUserReset = editSpecificUserReset;

var editUserRequest = function editUserRequest() {
  return {
    type: types.EDIT_USER_REQUEST
  };
};

exports.editUserRequest = editUserRequest;

var getSpecificUserRequest = function getSpecificUserRequest() {
  return {
    type: types.GET_SPECIFIC_USER_REQUEST
  };
};

exports.getSpecificUserRequest = getSpecificUserRequest;

var editSpecificUserPasswordRequest = function editSpecificUserPasswordRequest() {
  return {
    type: types.EDIT_USER_REQUEST
  };
};

exports.editSpecificUserPasswordRequest = editSpecificUserPasswordRequest;

var editUserSuccess = function editUserSuccess(_ref) {
  var data = _ref.data;
  return {
    payload: data,
    receivedAt: Date.now(),
    type: types.EDIT_USER_SUCCESS
  };
};

exports.editUserSuccess = editUserSuccess;

var getSpecificUserSuccess = function getSpecificUserSuccess(_ref2) {
  var data = _ref2.data;
  return {
    payload: data,
    receivedAt: Date.now(),
    type: types.GET_SPECIFIC_USER_SUCCESS
  };
};

exports.getSpecificUserSuccess = getSpecificUserSuccess;

var editSpecificUserSuccess = function editSpecificUserSuccess(_ref3) {
  var data = _ref3.data;
  return {
    payload: data,
    receivedAt: Date.now(),
    type: types.EDIT_SPECIFIC_USER_SUCCESS
  };
};

exports.editSpecificUserSuccess = editSpecificUserSuccess;

var editUserPasswordSuccess = function editUserPasswordSuccess(_ref4) {
  var data = _ref4.data;
  return {
    isPassword: true,
    receivedAt: Date.now(),
    type: types.EDIT_USER_SUCCESS
  };
};

exports.editUserPasswordSuccess = editUserPasswordSuccess;

var addRecentLocationSuccess = function addRecentLocationSuccess(_ref5) {
  var data = _ref5.data;
  return {
    payload: data,
    receivedAt: Date.now(),
    type: types.EDIT_USER_SUCCESS
  };
};

exports.addRecentLocationSuccess = addRecentLocationSuccess;

var editUserFailure = function editUserFailure(error) {
  return {
    error: error,
    receivedAt: Date.now(),
    type: types.EDIT_USER_FAILURE
  };
};

exports.editUserFailure = editUserFailure;

var getSpecificUserFailure = function getSpecificUserFailure(error) {
  return {
    error: error,
    receivedAt: Date.now(),
    type: types.GET_SPECIFIC_USER_FAILURE
  };
};

exports.getSpecificUserFailure = getSpecificUserFailure;

var editUserAction = function editUserAction(data) {
  return function (dispatch) {
    dispatch(editUserRequest());
    return instance.put('/user', data).then(function (res) {
      return dispatch(editUserSuccess(res.data));
    }).catch(function (error) {
      return dispatch(editUserFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.editUserAction = editUserAction;

var getSpecificUser = function getSpecificUser(id) {
  return function (dispatch) {
    dispatch(getSpecificUserRequest());
    return instance.get("/user/".concat(id)).then(function (res) {
      return dispatch(getSpecificUserSuccess(res.data));
    }).catch(function (error) {
      return dispatch(getSpecificUserFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.getSpecificUser = getSpecificUser;

var editSpecificUser = function editSpecificUser(data, id) {
  return function (dispatch) {
    dispatch(editUserRequest());
    return instance.put("/user/".concat(id), data).then(function (res) {
      return dispatch(editSpecificUserSuccess(res.data));
    }).catch(function (error) {
      return dispatch(editUserFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.editSpecificUser = editSpecificUser;

var editUserPassword = function editUserPassword(data) {
  return function (dispatch) {
    dispatch(editUserRequest());
    return _index.default.put('/v2/private/user/change-password', data).then(function (res) {
      return dispatch(editUserPasswordSuccess(res.data));
    }).catch(function (error) {
      return dispatch(editUserFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.editUserPassword = editUserPassword;

var editSpecificUserPassword = function editSpecificUserPassword(data, id) {
  return function (dispatch) {
    dispatch(editUserRequest());
    return _index.default.put("/v2/private/user/change-password/".concat(id), data).then(function (res) {
      return dispatch(editSpecificUserSuccess(res.data));
    }).catch(function (error) {
      return dispatch(editUserFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.editSpecificUserPassword = editSpecificUserPassword;

var addRecentLocation = function addRecentLocation(userId, data) {
  return function (dispatch) {
    dispatch(editUserRequest());
    return instance.put("/user/".concat(userId, "/recent-location"), data).then(function (res) {
      return dispatch(addRecentLocationSuccess(res.data));
    }).catch(function (error) {
      return dispatch(editUserFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.addRecentLocation = addRecentLocation;

var editUserTermsRequest = function editUserTermsRequest() {
  return {
    type: types.EDIT_USER_TERMS_REQUEST
  };
};

exports.editUserTermsRequest = editUserTermsRequest;

var editUserTermsSuccess = function editUserTermsSuccess(data) {
  return {
    type: types.EDIT_USER_TERMS_SUCCESS,
    receivedAt: Date.now(),
    data: data.data
  };
};

exports.editUserTermsSuccess = editUserTermsSuccess;

var editUserTermsFailure = function editUserTermsFailure(error) {
  return {
    type: types.EDIT_USER_TERMS_FAILURE,
    receivedAt: Date.now(),
    error: error
  };
};

exports.editUserTermsFailure = editUserTermsFailure;

var editUserTermsAction = function editUserTermsAction(id, tosAgree) {
  return function (dispatch) {
    dispatch(editUserTermsRequest());
    return instance.put("/public/user/".concat(id, "/terms"), {
      tosAgree: tosAgree
    }).then(function (res) {
      return dispatch(editUserTermsSuccess(res.data));
    }).catch(function (error) {
      return dispatch(editUserTermsFailure(error));
    });
  };
};

exports.editUserTermsAction = editUserTermsAction;

var editUserVisitedMemorialsRequest = function editUserVisitedMemorialsRequest() {
  return {
    type: types.EDIT_USER_VISITED_MEMORIALS_REQUEST
  };
};

exports.editUserVisitedMemorialsRequest = editUserVisitedMemorialsRequest;

var editUserVisitedMemorialsSuccess = function editUserVisitedMemorialsSuccess() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    type: types.EDIT_USER_VISITED_MEMORIALS_SUCCESS,
    receivedAt: Date.now(),
    data: data.data
  };
};

exports.editUserVisitedMemorialsSuccess = editUserVisitedMemorialsSuccess;

var editUserVisitedMemorialsFailure = function editUserVisitedMemorialsFailure(error) {
  return {
    type: types.EDIT_USER_VISITED_MEMORIALS_FAILURE,
    receivedAt: Date.now(),
    error: error
  };
};

exports.editUserVisitedMemorialsFailure = editUserVisitedMemorialsFailure;

var editUserVisitedMemorialsAction = function editUserVisitedMemorialsAction(visitedId) {
  return function (dispatch) {
    dispatch(editUserVisitedMemorialsRequest());
    return instance.put('/user/memorial-visited', {
      visitedId: visitedId
    }).then(function (res) {
      return dispatch(editUserVisitedMemorialsSuccess());
    }).catch(function (error) {
      return dispatch(editUserVisitedMemorialsFailure(error));
    });
  };
};

exports.editUserVisitedMemorialsAction = editUserVisitedMemorialsAction;