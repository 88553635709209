import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';

import MinimalNav from '../MinimalNav';
import FooterSection from '../shared/Footer/FooterSection';
import TermsFooter from '../shared/Footer/TermsFooter';
import TextInput from '../TextInput';
import LoaderComponent from '@evdy/web-core/dist/components/shared/elements/LoaderComponent';

import { useDetectWebleadUser, useGetWebleadFromCookie, useHideIntercom } from '../../customHooks';

import { purchaseFlowOperations } from '@evdy/web-redux/dist/lib/webPurchase/purchaseFlow';
import { authUserOperations } from '@evdy/web-redux/dist/lib/authUser';

import whiteChevron from '../../public/images/icons/white-chevron-right.svg';

import styles from './SetupCall.module.scss';

const { updateWebleadData, resetWebleadUpdated } = authUserOperations;

const {
  'setup-call': setupCallClass,
  hero: heroClass,
  'cta-section': ctaSectionClass,
  'schedule-call': scheduleCallClass,
  'or-circle': orCircleClass,
  'leave-number': leaveNumberClass,
  'phone-input': phoneInputClass
} = styles;

const SetupCall = () => {
  const dispatch = useDispatch();
  //phone field ref
  const phoneRef = useRef(null);

  const { authUser = {}, purchaseFlow = {} } = useSelector(({ authUser, webPurchase }) => ({
    authUser,
    purchaseFlow: webPurchase.purchaseFlow
  }));

  // on mount, if no cookie detected navigate back to get estimate
  useDetectWebleadUser({ redirectHref: '/get-my-estimate' });

  // get weblead from cookie
  useGetWebleadFromCookie();

  // Hide Intercom on mobile
  useHideIntercom();

  // assign most prioritized user data
  const { data: userData, webleadUpdated, isFetching } = useMemo(() => {
    return !!authUser?.data?._id ? authUser : purchaseFlow;
  }, [authUser, purchaseFlow]);

  const { _id: userId, phone, meta = {} } = userData ?? {};
  const userPhone = phone || meta?.webLeadPhone || '';

  // after submit, navigate to thank you page
  useEffect(() => {
    if (webleadUpdated) {
      dispatch(purchaseFlowOperations?.resetUpdateWeblead());
      dispatch(resetWebleadUpdated());
      window.location.href = '/schedule-success';
    }
  }, [dispatch, webleadUpdated]);

  const AddPhoneFormSchema = Yup.object().shape({
    phone: Yup.string()
      .phone('US', true, 'Please enter a valid phone number.')
      .required('Phone is required!')
  });

  const handleSubmit = e => {
    const strippedPhone = e?.phone?.toString()?.replace(/\D+/g, '');
    const updatedUserBody = {
      meta: {
        webLeadPhone: strippedPhone
      },
      eFuneral: {
        lastScreen: {
          flow: 'everdays',
          screen: 'preSchedule',
          date: new Date().toISOString()
        }
      }
    };
    if (authUser?.data?._id) {
      dispatch(updateWebleadData(updatedUserBody));
    } else {
      dispatch(purchaseFlowOperations.updateWeblead(userId, updatedUserBody));
    }
  };

  const formatPhoneNumber = phoneNumberString => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let formattedNum = cleaned;
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    // if phone number is complete or has +1 return formatted with parenthesis
    // otherwise return formatted phone number with dashes
    if (match && !!phoneRef?.current) {
      const intlCode = match[1] ? '+1' : '';
      phoneRef.current.value = [intlCode, '(' + match[2] + ')', '-', match[3], '-', match[4]].join(
        ''
      );
      // return for initial form values
      return [intlCode, '(' + match[2] + ')', '-', match[3], '-', match[4]].join('');
    }
    // else
    if (cleaned.length > 3 && cleaned.length < 4) {
      formattedNum = cleaned + '-';
    } else if (cleaned.length >= 4 && cleaned.length <= 6) {
      formattedNum = cleaned.slice(0, 3) + '-' + cleaned.slice(3, 6);
    } else if (cleaned.length > 6) {
      formattedNum = cleaned.slice(0, 3) + '-' + cleaned.slice(3, 6) + '-' + cleaned.slice(6);
    }
    if (phoneRef?.current) {
      phoneRef.current.value = formattedNum;
    }
    // return for initial form values
    return formattedNum;
  };

  const initialValues = { phone: userPhone ? formatPhoneNumber(userPhone) : '' };

  return (
    <div className={setupCallClass}>
      <MinimalNav />
      <div className={heroClass}>
        <h1>We'll walk you through</h1>
        <h6>
          One of our helpful planning experts is ready to jump on a quick call to answer any
          questions, assist with selecting a funeral home, and provide your exact purchase price.
        </h6>
      </div>
      <div className={ctaSectionClass}>
        <div className={scheduleCallClass}>
          <h3>If you have an ideal time in mind, let's get it scheduled</h3>
          <a href="/schedule-call">
            Schedule Call <img src={whiteChevron} />
          </a>
        </div>
        <div className={orCircleClass}>OR</div>
        <div className={leaveNumberClass}>
          <h3>Unsure? Leave your number and we'll email you to find a time</h3>
          <div className={phoneInputClass}>
            <Formik
              initialValues={initialValues}
              validationSchema={AddPhoneFormSchema}
              onSubmit={handleSubmit}
              enableReinitialize
              validateOnMount
            >
              <Form>
                <TextInput
                  type="tel"
                  label="Phone Number"
                  name="phone"
                  placeholder="###-###-####"
                  onInput={e => formatPhoneNumber(e?.target?.value)}
                  ref={phoneRef}
                />
                <button type="submit">
                  Submit <img src={whiteChevron} />
                </button>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <FooterSection />
      <TermsFooter />
      {isFetching && <LoaderComponent />}
    </div>
  );
};

export default SetupCall;
