"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SHARE_ESTIMATES_RESET = exports.SHARE_ESTIMATES_FAILURE = exports.SHARE_ESTIMATES_SUCCESS = exports.SHARE_ESTIMATES_REQUEST = void 0;
var SHARE_ESTIMATES_REQUEST = 'SHARE_ESTIMATES_REQUEST';
exports.SHARE_ESTIMATES_REQUEST = SHARE_ESTIMATES_REQUEST;
var SHARE_ESTIMATES_SUCCESS = 'SHARE_ESTIMATES_SUCCESS';
exports.SHARE_ESTIMATES_SUCCESS = SHARE_ESTIMATES_SUCCESS;
var SHARE_ESTIMATES_FAILURE = 'SHARE_ESTIMATES_FAILURE';
exports.SHARE_ESTIMATES_FAILURE = SHARE_ESTIMATES_FAILURE;
var SHARE_ESTIMATES_RESET = 'SHARE_ESTIMATES_RESET';
exports.SHARE_ESTIMATES_RESET = SHARE_ESTIMATES_RESET;