"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TRACK_FAILURE = exports.TRACK_SUCCESS = exports.TRACK_REQUEST = void 0;
var TRACK_REQUEST = 'TRACK_REQUEST';
exports.TRACK_REQUEST = TRACK_REQUEST;
var TRACK_SUCCESS = 'TRACK_SUCCESS';
exports.TRACK_SUCCESS = TRACK_SUCCESS;
var TRACK_FAILURE = 'TRACK_FAILURE';
exports.TRACK_FAILURE = TRACK_FAILURE;