import 'react-app-polyfill/ie11';

import React from 'react';
import Routes from './routes';
import { hydrate } from 'react-dom';
import { browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

if (process.env.NODE_ENV === 'development') {
  global.__DEVCLIENT__ = true;
}

import configureStore from '@evdy/web-core/dist/store/configureStore';
import { Provider } from 'react-redux';

const store = configureStore(window.__PRELOADED_STATE__, browserHistory);
const history = syncHistoryWithStore(browserHistory, store);

hydrate(
  <Provider store={store}>{Routes(store, history)}</Provider>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
