import React from 'react';

import Logo from '@evdy/web-core/dist/images/pages/V4/everdays-full-logo-blue.svg';

import './LegalTextHeader.scss';

const LegalTextHeader = () => {
  return (
    <div className="legal-text-header">
      {/*<img src={Logo} alt="Everdays Logo" />*/}
      <h4>EVERDAYS, INC.</h4>
    </div>
  );
};

export default LegalTextHeader;
