import React from 'react';
import classNames from 'classnames';

import OverlappingCTA from '../../OverlappingCTA';

import { APPLE_LINK, GOOGLE_LINK } from '@evdy/web-redux/dist/constants';

import styles from './FooterSection.module.scss';

import everdaysIcon from '../../../../public/images/icons/everdays-icon-square.svg';
import everdaysText from '../../../../public/images/logos/everdays-black-text.svg';
import linkedInIcon from '../../../../public/images/icons/linkedin-dark-icon.svg';
import fbIcon from '../../../../public/images/icons/facebook-dark-icon.svg';
import envelopeIcon from '../../../../public/images/icons/envelop-icon.svg';
import speechBubbleIcon from '../../../../public/images/icons/speech-bubble-icon.svg';
import phoneIcon from '../../../../public/images/icons/phone-icon.svg';

const {
  'footer-section': footerSectionClass,
  'overlapping-cta': overlappingCTAClass,
  'asterisk-copy': asteriskCopyClass,
  'footer-content': footerContentClass,
  'logo-and-contact-container': logoAndContactContainerClass,
  'footer-content-group': footerContentGroupClass,
  'logo-and-app-links': logoAndAppLinksClass,
  'contact-links': contactLinksClass,
  'phone-link': phoneLinkClass,
  'more-info-links': moreInfoLinksClass,
  'social-links': socialLinksClass,
  'footer-links': footerLinksClass,
  'logo-section': logoSectionClass,
  'everdays-logo': logoClass,
  'everdays-logo-copy': eDaysFontClass,
  'footer-slogan': footerSloganClass,
  'app-store-buttons': appStoreButtons,
  'overlapping-cta-padding': overlappingCTAPaddingClass,
  'contact-us-title': contactUsTitleClass,
  'contact-us-subtitle': contactUsSubtitle,
  'talk-to-us': talkToUsClass,
  'footer-content-wrapper': footerContentWrapperClass,
  'styling-wrapper-app-links': stylingWrapperForAppLinksAndInfo,
  'terms-footer': termsFooterClass,
  'terms-footer-content': termsFooterContentClass,
  'terms-and-copyright': termsAndCopyrightClass,
  'terms-and-privacy': termsAndPrivacyClass,
  'asterisk-container': asteriskCopyContainer,
  'content-container': contentContainer,
  copyright: copyrightClass,
  disclaimer: disclaimerClass,
  'login-button': loginButtonClass
} = styles;

const bucket = 'https://everdays-site.s3.amazonaws.com/share';
//play/store icons
const appStoreBtn = `${bucket}/app-store-button-new.svg`;
const googlePlayBtn = `${bucket}/google-play-button-new.svg`;

const moreInfoLinks = [
  // { linkCopy: 'For Providers', href: 'https://partners.everdays.com' },
  { linkCopy: 'About us', href: 'https://everdays.com/about-us/' },
  { linkCopy: 'Blog', href: 'https://everdays.com/blog/' },
  {
    linkCopy: 'FAQs',
    href: 'https://help.everdays.com/en/collections/2674330-funeral-package-faqs#package-questions'
  },
  { linkCopy: 'Provider Search', href: '/funeral-homes/' },
  // { linkCopy: 'Press', href: '/press' },
  { linkCopy: 'Careers', href: 'https://try.everdays.com/careers/' }
];

const FooterSection = ({
  firstAsteriskCopy = '',
  secondAsteriskCopy = '',
  thirdAsteriskCopy = '',
  overlappingCTAHeader: header,
  overlappingCTASubheader: subheader,
  overlappingCTAButtonCopy: buttonCopy,
  overlappingCTAButtonAction: buttonAction
}) => {
  const showOverlappingCTA = !!header || !!subheader || !!buttonCopy || !!buttonAction;
  const showAsteriskCopy = !!firstAsteriskCopy || !!secondAsteriskCopy || !!thirdAsteriskCopy;

  const footerSectionClasses = classNames(footerSectionClass, {
    [overlappingCTAPaddingClass]: showOverlappingCTA
  });

  return (
    <div className={footerSectionClasses}>
      {!!showOverlappingCTA && (
        <div className={overlappingCTAClass}>
          <OverlappingCTA {...{ header, subheader, buttonCopy, buttonAction }} />
        </div>
      )}
      {showAsteriskCopy && (
        <div className={asteriskCopyContainer}>
          <div className={asteriskCopyClass}>
            {!!firstAsteriskCopy && <div>{firstAsteriskCopy}</div>}
            {!!secondAsteriskCopy && <div>{secondAsteriskCopy}</div>}
            {!!thirdAsteriskCopy && <div>{thirdAsteriskCopy}</div>}
          </div>
        </div>
      )}
      <div className={contentContainer}>
        <div className={footerContentClass}>
          <div className={`${footerContentGroupClass} ${contactLinksClass}`}>
            <h3 className={contactUsTitleClass}>Talk to our care team</h3>
            <p className={contactUsSubtitle}>
              Our team is always here to guide you through the process and answer any questions
            </p>
            <div className={talkToUsClass}>
              <a href="mailto:support@everdays.com">
                <img src={envelopeIcon} /> support@everdays.com
              </a>
              <a className="intercom-chat" href="mailto:wdj13bk6@intercom-mail.com">
                <img src={speechBubbleIcon} /> Live Chat
              </a>
              <div className={phoneLinkClass}>
                <a href="tel:8774003297">
                  <img src={phoneIcon} /> 877-400-3297
                </a>
                <span>Monday - Friday</span>
                <span>9:00am - 6:00pm ET</span>
              </div>
            </div>
          </div>
          <div className={footerContentWrapperClass}>
            <div className={stylingWrapperForAppLinksAndInfo}>
              <div className={logoAndContactContainerClass}>
                <div className={`${footerContentGroupClass} ${logoAndAppLinksClass}`}>
                  <div className={logoSectionClass}>
                    <img src={everdaysIcon} alt="everdays logo" className={logoClass} />
                    <img src={everdaysText} alt="everdays text" className={eDaysFontClass} />
                  </div>
                  <div className={footerSloganClass}>
                    <h3>
                      Our simple, pleasant experience gives people everywhere the confidence &
                      control they need to plan for what comes next.
                    </h3>
                  </div>
                  <div className={appStoreButtons}>
                    <a href={GOOGLE_LINK}>
                      <img src={googlePlayBtn} alt="google-play-link" />
                    </a>
                    <a href={APPLE_LINK}>
                      <img src={appStoreBtn} alt="app-store-ios-link" />
                    </a>
                  </div>
                </div>
              </div>
              <div className={`${footerContentGroupClass} ${footerLinksClass}`}>
                <div className={moreInfoLinksClass}>
                  <h5>More Info</h5>
                  <ul>
                    {moreInfoLinks.map(link => (
                      <li key={link.href}>
                        <a href={link.href}>{link.linkCopy}</a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={socialLinksClass}>
                  <h5>Social</h5>
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/JoinEverdays/">
                        <img src={fbIcon} />
                        Facebook
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/everdays-inc.">
                        <img src={linkedInIcon} />
                        LinkedIn
                      </a>
                    </li>
                  </ul>
                  <h5>For Partners</h5>
                  <ul>
                    <li>
                      <a href="https://partners.everdays.com">Sign Up</a>
                    </li>
                    <li>
                      <a className={loginButtonClass} href="https://everdays.com/dash/login">
                        Log in
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={termsFooterClass}>
              <div className={termsFooterContentClass}>
                <div className={termsAndCopyrightClass}>
                  <div className={termsAndPrivacyClass}>
                    <a href="/terms">Terms of Service</a>&nbsp;|&nbsp;
                    <a href="/privacy">Privacy Policy</a>
                  </div>
                  <span className={copyrightClass}>
                    © {new Date().getFullYear()} Everdays, Inc. All rights reserved.
                  </span>
                </div>
                <p className={disclaimerClass}>
                  The Everdays mark and the Everdays logo are registered trademarks of Everdays,
                  Inc.
                  <br />* This app is owned and operated by Everdays, Inc., a Delaware corporation
                  doing business in California as Everdays Moments Insurance Marketing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
