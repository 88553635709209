"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unfollowInviteFailure = exports.unfollowInviteSuccess = exports.unfollowInviteRequest = exports.followInviteFailure = exports.followInviteSuccess = exports.followInviteRequest = exports.resetVideoModalClosedAction = exports.setVideoModalClosedAction = exports.clearVideoProgressAction = exports.saveVideoProgressAction = exports.unloveCommentFailure = exports.unloveCommentSuccess = exports.unloveCommentRequest = exports.loveCommentFailure = exports.loveCommentSuccess = exports.loveCommentRequest = exports.resetCommentAction = exports.leaveCommentFailure = exports.leaveCommentSuccess = exports.leaveCommentRequest = exports.unloveMediaFailure = exports.unloveMediaSuccess = exports.unloveMediaRequest = exports.loveMediaFailure = exports.loveMediaSuccess = exports.loveMediaRequest = exports.resetCondolenceAction = exports.leaveCondolenceSuccess = exports.leaveCondolenceFailure = exports.leaveCondolenceRequest = exports.pendingVideoProgressUpdate = exports.resetVideoEncodedAction = exports.resetVideoUploadedAction = exports.encodeVideoFailure = exports.encodeVideoSuccess = exports.encodeVideoRequest = exports.uploadVideoFailure = exports.uploadVideoSuccess = exports.uploadVideoRequest = exports.pendingPhotoProgressUpdate = exports.uploadPhotosFailure = exports.uploadPhotosSuccess = exports.uploadPhotosRequest = exports.fetchMemorialNoticeFailure = exports.fetchMemorialNoticeSuccess = exports.fetchMemorialNoticeRequest = void 0;

var types = _interopRequireWildcard(require("./constants"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Fetch Invite
var fetchMemorialNoticeRequest = function fetchMemorialNoticeRequest() {
  return {
    type: types.FETCH_ANNOUNCEMENT_REQUEST
  };
};

exports.fetchMemorialNoticeRequest = fetchMemorialNoticeRequest;

var fetchMemorialNoticeSuccess = function fetchMemorialNoticeSuccess(_ref) {
  var data = _ref.data;
  return {
    type: types.FETCH_ANNOUNCEMENT_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.fetchMemorialNoticeSuccess = fetchMemorialNoticeSuccess;

var fetchMemorialNoticeFailure = function fetchMemorialNoticeFailure(error) {
  return {
    type: types.FETCH_ANNOUNCEMENT_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
}; // Photos


exports.fetchMemorialNoticeFailure = fetchMemorialNoticeFailure;

var uploadPhotosRequest = function uploadPhotosRequest(_ref2) {
  var photoName = _ref2.photoName,
      blobUrl = _ref2.blobUrl;
  return {
    type: types.UPLOAD_PHOTOS_REQUEST,
    photoName: photoName,
    blobUrl: blobUrl
  };
};

exports.uploadPhotosRequest = uploadPhotosRequest;

var uploadPhotosSuccess = function uploadPhotosSuccess(_ref3) {
  var data = _ref3.data,
      photoName = _ref3.photoName;
  return {
    type: types.UPLOAD_PHOTOS_SUCCESS,
    payload: data,
    photoName: photoName,
    receivedAt: Date.now()
  };
};

exports.uploadPhotosSuccess = uploadPhotosSuccess;

var uploadPhotosFailure = function uploadPhotosFailure(error) {
  return {
    type: types.UPLOAD_PHOTOS_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.uploadPhotosFailure = uploadPhotosFailure;

var pendingPhotoProgressUpdate = function pendingPhotoProgressUpdate(_ref4) {
  var progress = _ref4.progress,
      photoName = _ref4.photoName;
  return {
    type: types.PENDING_PHOTO_PROGRESS_UPDATE,
    progress: progress,
    photoName: photoName
  };
}; // Upload Videos


exports.pendingPhotoProgressUpdate = pendingPhotoProgressUpdate;

var uploadVideoRequest = function uploadVideoRequest(_ref5) {
  var videoName = _ref5.videoName,
      blobUrl = _ref5.blobUrl,
      userData = _ref5.userData,
      memorialId = _ref5.memorialId,
      pendingVideoId = _ref5.pendingVideoId;
  return {
    type: types.UPLOAD_VIDEO_REQUEST,
    videoName: videoName,
    blobUrl: blobUrl,
    userData: userData,
    memorialId: memorialId,
    pendingVideoId: pendingVideoId
  };
};

exports.uploadVideoRequest = uploadVideoRequest;

var uploadVideoSuccess = function uploadVideoSuccess(_ref6) {
  var data = _ref6.data,
      videoName = _ref6.videoName;
  return {
    type: types.UPLOAD_VIDEO_SUCCESS,
    payload: data,
    videoName: videoName,
    receivedAt: Date.now()
  };
};

exports.uploadVideoSuccess = uploadVideoSuccess;

var uploadVideoFailure = function uploadVideoFailure(error) {
  return {
    type: types.UPLOAD_VIDEO_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.uploadVideoFailure = uploadVideoFailure;

var encodeVideoRequest = function encodeVideoRequest() {
  return {
    type: types.ENCODE_VIDEO_REQUEST
  };
};

exports.encodeVideoRequest = encodeVideoRequest;

var encodeVideoSuccess = function encodeVideoSuccess(_ref7) {
  var data = _ref7.data,
      pendingVideoId = _ref7.pendingVideoId;
  return {
    type: types.ENCODE_VIDEO_SUCCESS,
    payload: data,
    receivedAt: Date.now(),
    pendingVideoId: pendingVideoId
  };
};

exports.encodeVideoSuccess = encodeVideoSuccess;

var encodeVideoFailure = function encodeVideoFailure(error) {
  return {
    type: types.ENCODE_VIDEO_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.encodeVideoFailure = encodeVideoFailure;

var resetVideoUploadedAction = function resetVideoUploadedAction() {
  return {
    type: types.VIDEO_UPLOADED_RESET
  };
};

exports.resetVideoUploadedAction = resetVideoUploadedAction;

var resetVideoEncodedAction = function resetVideoEncodedAction() {
  return {
    type: types.VIDEO_ENCODED_RESET
  };
};

exports.resetVideoEncodedAction = resetVideoEncodedAction;

var pendingVideoProgressUpdate = function pendingVideoProgressUpdate(_ref8) {
  var progress = _ref8.progress,
      videoId = _ref8.videoId;
  return {
    type: types.PENDING_VIDEO_PROGRESS_UPDATE,
    progress: progress,
    videoId: videoId
  };
}; // Condolences


exports.pendingVideoProgressUpdate = pendingVideoProgressUpdate;

var leaveCondolenceRequest = function leaveCondolenceRequest() {
  return {
    type: types.LEAVE_CONDOLENCE_REQUEST
  };
};

exports.leaveCondolenceRequest = leaveCondolenceRequest;

var leaveCondolenceFailure = function leaveCondolenceFailure(error) {
  return {
    error: error,
    type: types.LEAVE_CONDOLENCE_FAILURE,
    receivedAt: Date.now()
  };
};

exports.leaveCondolenceFailure = leaveCondolenceFailure;

var leaveCondolenceSuccess = function leaveCondolenceSuccess(_ref9) {
  var data = _ref9.data;
  return {
    payload: data,
    type: types.LEAVE_CONDOLENCE_SUCCESS,
    receivedAt: Date.now()
  };
};

exports.leaveCondolenceSuccess = leaveCondolenceSuccess;

var resetCondolenceAction = function resetCondolenceAction() {
  return {
    type: types.RESET_CONDOLENCE
  };
}; // Love/Unlove Media


exports.resetCondolenceAction = resetCondolenceAction;

var loveMediaRequest = function loveMediaRequest(_ref10) {
  var mediaType = _ref10.mediaType;
  return {
    type: types.LOVE_MEDIA_REQUEST,
    mediaType: mediaType
  };
};

exports.loveMediaRequest = loveMediaRequest;

var loveMediaSuccess = function loveMediaSuccess(_ref11) {
  var data = _ref11.data,
      mediaType = _ref11.mediaType,
      entityId = _ref11.entityId;
  return {
    payload: data,
    mediaType: mediaType,
    entityId: entityId,
    type: types.LOVE_MEDIA_SUCCESS,
    receivedAt: Date.now()
  };
};

exports.loveMediaSuccess = loveMediaSuccess;

var loveMediaFailure = function loveMediaFailure(_ref12) {
  var error = _ref12.error,
      mediaType = _ref12.mediaType;
  return {
    error: error,
    type: types.LOVE_MEDIA_FAILURE,
    receivedAt: Date.now(),
    mediaType: mediaType
  };
};

exports.loveMediaFailure = loveMediaFailure;

var unloveMediaRequest = function unloveMediaRequest(_ref13) {
  var mediaType = _ref13.mediaType;
  return {
    type: types.UNLOVE_MEDIA_REQUEST,
    mediaType: mediaType
  };
};

exports.unloveMediaRequest = unloveMediaRequest;

var unloveMediaSuccess = function unloveMediaSuccess(_ref14) {
  var data = _ref14.data,
      mediaType = _ref14.mediaType;
  return {
    payload: data,
    mediaType: mediaType,
    type: types.UNLOVE_MEDIA_SUCCESS,
    receivedAt: Date.now()
  };
};

exports.unloveMediaSuccess = unloveMediaSuccess;

var unloveMediaFailure = function unloveMediaFailure(_ref15) {
  var error = _ref15.error,
      mediaType = _ref15.mediaType;
  return {
    error: error,
    type: types.UNLOVE_MEDIA_FAILURE,
    receivedAt: Date.now(),
    mediaType: mediaType
  };
}; // Comments


exports.unloveMediaFailure = unloveMediaFailure;

var leaveCommentRequest = function leaveCommentRequest(_ref16) {
  var mediaType = _ref16.mediaType;
  return {
    type: types.LEAVE_COMMENT_REQUEST,
    mediaType: mediaType
  };
};

exports.leaveCommentRequest = leaveCommentRequest;

var leaveCommentSuccess = function leaveCommentSuccess(_ref17) {
  var data = _ref17.data,
      mediaType = _ref17.mediaType,
      mediaId = _ref17.mediaId;
  return {
    payload: data,
    mediaType: mediaType,
    mediaId: mediaId,
    type: types.LEAVE_COMMENT_SUCCESS,
    receivedAt: Date.now()
  };
};

exports.leaveCommentSuccess = leaveCommentSuccess;

var leaveCommentFailure = function leaveCommentFailure(_ref18) {
  var error = _ref18.error,
      mediaType = _ref18.mediaType;
  return {
    error: error,
    type: types.LEAVE_COMMENT_FAILURE,
    recievedAt: Date.now(),
    mediaType: mediaType
  };
};

exports.leaveCommentFailure = leaveCommentFailure;

var resetCommentAction = function resetCommentAction() {
  return {
    type: types.RESET_COMMENT
  };
}; // Love/Unlove Comment


exports.resetCommentAction = resetCommentAction;

var loveCommentRequest = function loveCommentRequest(_ref19) {
  var mediaType = _ref19.mediaType;
  return {
    type: types.LOVE_COMMENT_REQUEST,
    mediaType: mediaType
  };
};

exports.loveCommentRequest = loveCommentRequest;

var loveCommentSuccess = function loveCommentSuccess(_ref20) {
  var data = _ref20.data,
      mediaType = _ref20.mediaType,
      entityId = _ref20.entityId,
      commentId = _ref20.commentId;
  return {
    payload: data,
    mediaType: mediaType,
    entityId: entityId,
    commentId: commentId,
    type: types.LOVE_COMMENT_SUCCESS,
    receivedAt: Date.now()
  };
};

exports.loveCommentSuccess = loveCommentSuccess;

var loveCommentFailure = function loveCommentFailure(_ref21) {
  var error = _ref21.error,
      mediaType = _ref21.mediaType;
  return {
    error: error,
    type: types.LOVE_COMMENT_FAILURE,
    receivedAt: Date.now(),
    mediaType: mediaType
  };
};

exports.loveCommentFailure = loveCommentFailure;

var unloveCommentRequest = function unloveCommentRequest(_ref22) {
  var mediaType = _ref22.mediaType;
  return {
    type: types.UNLOVE_COMMENT_REQUEST,
    mediaType: mediaType
  };
};

exports.unloveCommentRequest = unloveCommentRequest;

var unloveCommentSuccess = function unloveCommentSuccess(_ref23) {
  var data = _ref23.data,
      mediaType = _ref23.mediaType;
  return {
    payload: data,
    mediaType: mediaType,
    type: types.UNLOVE_COMMENT_SUCCESS,
    receivedAt: Date.now()
  };
};

exports.unloveCommentSuccess = unloveCommentSuccess;

var unloveCommentFailure = function unloveCommentFailure(_ref24) {
  var error = _ref24.error,
      mediaType = _ref24.mediaType;
  return {
    error: error,
    type: types.UNLOVE_COMMENT_FAILURE,
    receivedAt: Date.now(),
    mediaType: mediaType
  };
}; // Video


exports.unloveCommentFailure = unloveCommentFailure;

var saveVideoProgressAction = function saveVideoProgressAction(_ref25) {
  var videoId = _ref25.videoId,
      videoOptions = _ref25.videoOptions;
  return _objectSpread({
    type: types.SAVE_VIDEO_PROGRESS,
    videoId: videoId
  }, videoOptions);
};

exports.saveVideoProgressAction = saveVideoProgressAction;

var clearVideoProgressAction = function clearVideoProgressAction(_ref26) {
  var videoId = _ref26.videoId;
  return {
    type: types.CLEAR_VIDEO_PROGRESS,
    videoId: videoId
  };
};

exports.clearVideoProgressAction = clearVideoProgressAction;

var setVideoModalClosedAction = function setVideoModalClosedAction() {
  return {
    type: types.SET_VIDEO_MODAL_CLOSED
  };
};

exports.setVideoModalClosedAction = setVideoModalClosedAction;

var resetVideoModalClosedAction = function resetVideoModalClosedAction() {
  return {
    type: types.RESET_VIDEO_MODAL_CLOSED
  };
}; // Follow Invite


exports.resetVideoModalClosedAction = resetVideoModalClosedAction;

var followInviteRequest = function followInviteRequest() {
  return {
    type: types.FOLLOW_INVITE_REQUEST
  };
};

exports.followInviteRequest = followInviteRequest;

var followInviteSuccess = function followInviteSuccess(_ref27) {
  var data = _ref27.data;
  return {
    type: types.FOLLOW_INVITE_SUCCESS,
    payload: data.data,
    receivedAt: Date.now()
  };
};

exports.followInviteSuccess = followInviteSuccess;

var followInviteFailure = function followInviteFailure(error) {
  return {
    type: types.FOLLOW_INVITE_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
}; // Unfollow Invite


exports.followInviteFailure = followInviteFailure;

var unfollowInviteRequest = function unfollowInviteRequest() {
  return {
    type: types.UNFOLLOW_INVITE_REQUEST
  };
};

exports.unfollowInviteRequest = unfollowInviteRequest;

var unfollowInviteSuccess = function unfollowInviteSuccess(_ref28) {
  var data = _ref28.data;
  return {
    type: types.UNFOLLOW_INVITE_SUCCESS,
    payload: data.data,
    receivedAt: Date.now()
  };
};

exports.unfollowInviteSuccess = unfollowInviteSuccess;

var unfollowInviteFailure = function unfollowInviteFailure(error) {
  return {
    type: types.UNFOLLOW_INVITE_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.unfollowInviteFailure = unfollowInviteFailure;