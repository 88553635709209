import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { signOut } from '@evdy/web-redux/dist/actions/dash/user';

const LogOut = ({ user, signOut, location }) => {
  const { redirect } = location?.query;
  useEffect(() => {
    if (global.window && user && user._id) {
      signOut(window.location.href);
    }
    browserHistory.push(redirect || '/');
  }, [user, signOut, redirect]);

  return <></>;
};

export default connect(({ dash }) => ({ user: dash.user.data }), { signOut })(LogOut);
