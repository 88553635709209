import React from 'react';
import classNames from 'classnames';

import styles from './FirstStepIndicator.module.scss';

const {
  'first-step-indicator': firstStepIndicatorClass,
  step: stepClass,
  'step-num': currentStepNumClass,
  'step-copy': currentStepCopyClass,
  'selected-step': selectedStepClass,
  'selected-step-copy': selectedStepCopy
} = styles;

const FirstStepIndicator = () => {
  const steps = ['Select an experience', 'Personalize the details', 'Secure for your family'];

  return (
    <div className={firstStepIndicatorClass}>
      {steps.map((stepCopy, i) => {
        const firstStepClass = classNames(currentStepNumClass, { [selectedStepClass]: i === 0 });
        const firstStepCopyClass = classNames(currentStepCopyClass, {
          [selectedStepCopy]: i === 0
        });

        return (
          <div className={stepClass} key={`${stepCopy}_${i}`}>
            <p className={firstStepClass}>{i + 1}</p>
            <p className={firstStepCopyClass}>{stepCopy}</p>
          </div>
        );
      })}
    </div>
  );
};

export default FirstStepIndicator;
