"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DELETE_MEMORIAL_RESET = exports.DELETE_MEMORIAL_FAILURE = exports.DELETE_MEMORIAL_SUCCESS = exports.DELETE_MEMORIAL_REQUEST = void 0;
var DELETE_MEMORIAL_REQUEST = 'DELETE_MEMORIAL_REQUEST';
exports.DELETE_MEMORIAL_REQUEST = DELETE_MEMORIAL_REQUEST;
var DELETE_MEMORIAL_SUCCESS = 'DELETE_MEMORIAL_SUCCESS';
exports.DELETE_MEMORIAL_SUCCESS = DELETE_MEMORIAL_SUCCESS;
var DELETE_MEMORIAL_FAILURE = 'DELETE_MEMORIAL_FAILURE';
exports.DELETE_MEMORIAL_FAILURE = DELETE_MEMORIAL_FAILURE;
var DELETE_MEMORIAL_RESET = 'DELETE_MEMORIAL_RESET';
exports.DELETE_MEMORIAL_RESET = DELETE_MEMORIAL_RESET;