import React from 'react';

import styles from './AsSeenIn.module.scss';

const {
  'as-seen-in': asSeenInClass,
  'logo-container': logoContainerClass,
  logo: logoClass
} = styles;

const logoArray = [
  { altText: 'Fox' },
  { altText: 'Forbes' },
  { altText: 'USA Today' },
  { altText: 'Business Insider' }
];

const AsSeenIn = () => {
  return (
    <div className={asSeenInClass}>
      <div>
        <span>Featured In:</span>
        <div className={logoContainerClass}>
          {logoArray.map(({ altText }) => (
            <div className={logoClass} key={altText} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AsSeenIn;
