import React from 'react';
import { useSelector } from 'react-redux';

import PagesHeader from '@evdy/web-core/dist/components/PagesHeader2';
import PagesFooter from '@evdy/web-core/dist/components/PagesFooter';
import LegalTextHeader from '../../shared/LegalTextHeader';
import { renderAuthModal } from '@evdy/web-core/dist/lib/utils';

import './ClientAgreement.scss';

const ClientAgreement = ({ location, children }) => {
  const { user = {} } = useSelector(({ authUser }) => ({
    user: authUser.data
  }));

  return (
    <>
      <PagesHeader
        currentPage="client-agreement"
        pathname={location.pathname}
        queryString={location.search}
      />
      <div className="client-agreement">
        <LegalTextHeader />
        <div className="agreement-body">
          <h1>Client Agreement</h1>
          <p className="date-updated">
            Last Modified: <span>July 6, 2020</span>
          </p>
          <div className="client-agreement-links">
            <a href="/termsandconditions" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </a>
          </div>
          <p>
            This Client Agreement (the “Client <b>Agreement</b>”) is entered into by and between
            Everdays, Inc. (“<b>Everdays</b>,” “<b>we,</b>” “<b>our,</b>” or “<b>us</b>”) and the
            organization agreeing to the terms of this Client Agreement (“<b>Client,</b>” “
            <b>you,</b>” or “<b>your</b>”). This Client Agreement shall be effective on the earliest
            of (a) the date Client clicks a button indicating its agreement with the terms of this
            Client Agreement; (b) Client entering into a pricing , product or other input form
            referencing or otherwise incorporating this Client Agreement; or (c) Client’s use of the
            Service (as defined below) (the “<b>Effective Date</b>”). Our website, software and any
            online or mobile services (including, without limitation, our Mobile Applications)
            provided on or in connection with the service we offer to consumers on behalf of funeral
            homes is collectively referred to as the “<b>Service</b>”. You or we may be referred to
            as a “Party”, or as “Parties” collectively.
          </p>
          <p>
            By downloading, accessing or using the Service, or by clicking a button or checking a
            box marked “I Agree” (or something similar) or otherwise consenting to be a
            participating funeral home as part of our Service, you signify that you have read,
            understood, and agree to be bound by this Client Agreement and by the terms of our{' '}
            <a href="/clientprivacy" target="_blank" rel="noopener noreferrer">
              Client User Privacy Policy
            </a>{' '}
            which is expressly incorporated herein. You also signify that you have read, understood,
            and agree to be bound by Everdays’{' '}
            <a href="/clientterms" target="_blank" rel="noopener noreferrer">
              Client User Terms of Service
            </a>
            ,{' '}
            <a href="/terms" target="_blank" rel="noopener noreferrer">
              User Terms of Service
            </a>{' '}
            and{' '}
            <a href="/privacy" target="_blank" rel="noopener noreferrer">
              User Privacy Policy
            </a>
            , which are also expressly incorporated herein. Everdays reserves the right to modify
            these terms and will provide notice of these changes as described below. This Client
            Agreement and the Client User Terms of Service apply to all users who access or
            participate in the Service on behalf of Client funeral homes (“<b>Client Users</b>”)
            and,{' '}
            <b>
              together with the agreements incorporated by reference, this Client Agreement forms a
              part of a binding between us and you. You agree that this Client Agreement shall be
              binding upon your funeral home and its respective directors, officers, employees,
              owners, agents, or any other person or entity, including Client Users, you authorize
              to use the services contemplated herein without further agreement or consent.
            </b>
          </p>
          <h5>
            PLEASE READ THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. THIS
            AGREEMENT CONTAINS A MANDATORY INDIVIDUAL ARBITRATION AND CLASS ACTION/JURY TRIAL WAIVER
            PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE
            DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS. YOU MUST READ AND AGREE TO THIS
            AGREEMENT BEFORE USING THE SERVICE. IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS
            CONTAINED HEREIN, YOU DO NOT HAVE OUR PERMISSION TO ACCESS OR USE THE SERVICE.
          </h5>
          <section>
            <h3>1. EVERDAYS SERVICE</h3>
            <h4>
              <b>1.1 Eligibility</b>
            </h4>
            <p>
              You may use the Service only if you can form a binding contract with Everdays, and
              only in compliance with this Client Agreement and all applicable local, state,
              national, and international laws, rules and regulations. Any use or access to the
              Service by anyone under eighteen (18) years is strictly prohibited and in violation of
              this Client Agreement. The Service is not available to any Clients previously removed
              from the Service by Everdays.
            </p>
            <h4>
              <b>1.2 Limited License</b>
            </h4>
            <p>
              Subject to the terms and conditions of this Client Agreement, you are hereby granted a
              non-exclusive, limited, non-transferable, freely revocable license to use the Service
              as permitted by the features of the Service. Everdays reserves all rights not
              expressly granted herein in the Service and the Everdays Content (as defined below).
              Everdays may terminate this license at any time for any reason or no reason.
            </p>
            <h4>
              <b>1.3 Client Accounts</b>
            </h4>
            <p>
              Your account on the Service (your “<b>Client Account</b>”) gives you access to the
              services and functionality that we may establish and maintain from time to time and in
              our sole discretion. We may maintain different types of Client Accounts for different
              types of Clients. If you open a Client Account on behalf of a funeral home company,
              organization, or other entity, then (i) “you” includes you and that entity; (ii) you
              represent and warrant that you are an authorized representative of the entity with the
              authority to bind the entity to this Client Agreement; and (iii) you agree to this
              Client Agreement on the entity’s behalf. By connecting to Everdays with a third-party
              service, you give us permission to access and use your information from that service
              as permitted by that service, and to store your log-in credentials for that service.
            </p>
            <p>
              You may never use another Client’s Client Account without our permission. When
              creating your Client Account, you must provide accurate and complete information, and
              you must keep this information up to date. You are solely responsible for the activity
              that occurs on your Client Account, and you must keep your Client Account password
              secure. We encourage you to use “strong” passwords (passwords that use a combination
              of upper- and lower-case letters, numbers and symbols) with your Client Account. You
              must notify Everdays immediately of any breach of security or unauthorized use of your
              Client Account. Everdays will not be liable for any losses caused by any unauthorized
              use of your Client Account.
            </p>
            <p>
              You may control your Client profile and how you interact with the Service by changing
              the settings in your settings page. By providing Everdays your email address you
              consent to our using the email address to send you Service-related notices, including
              any notices required by law, in lieu of communication by postal mail. We may also use
              your email address to send you other messages, such as changes to features of the
              Service and special offers. If you do not want to receive such email messages, you may
              opt out or change your preferences in your settings page. Opting out may prevent you
              from receiving email messages regarding updates, improvements, or offers.
            </p>
            <p>
              This Client Agreement shall apply to all funeral home locations that you enroll in the
              Service and to the subsidiaries or affiliate companies, if any, that control such
              locations. You represent and warrant that you have the authority to enter into this
              Client Agreement on behalf of such entities.
            </p>
            <h4>
              <b>1.4 Changes to the Service</b>
            </h4>
            <p>
              We may, without prior notice, change the Service; stop providing the Service or
              features of the Service, to you or to Clients generally; or create usage limits for
              the Service. We may permanently or temporarily terminate or suspend your access to the
              Service without notice and liability for any reason, including if in our sole
              determination you violate any provision of this Client Agreement, or for no reason.
              Upon termination for any reason or no reason, you continue to be bound by this Client
              Agreement.
            </p>
            <h4>
              <b>1.5 Your Consent to This and Other Agreements</b>
            </h4>
            <p>
              When you sign up to use a special feature of this Service, or when we notify you of
              updates to the Service, you may be asked to agree to special terms governing your use
              of the special feature. In such cases, you may be asked to expressly consent to the
              special terms, for example, by checking a box or clicking on a button marked “I
              agree”. This type of agreement is known as a “click-through” agreement. If any of the
              terms of the click-through agreement are different than the terms of this Client
              Agreement, the terms of the click-through agreement will supplement or amend this
              Client Agreement, but only with respect to the matters governed by the click-through
              agreement.
            </p>
            <h4>
              <b>1.6 Relationship of the Parties</b>
            </h4>
            <p>
              The Parties are independent contractors. The Client Agreement does not create a
              partnership, franchise, joint venture, agency, fiduciary or employment relationship
              between the Parties. Other than the third-party beneficiary relationships with Apple
              and Google as set forth in Section 25, there are no third-party beneficiaries to the
              Client Agreement.
            </p>
          </section>
          <section>
            <h3>2. MARKETING AND TRANSACTIONS</h3>
            <p>
              During the term of the Client Agreement, we will develop and offer online and mobile
              services to, among other things, enable you to market, promote and transact
              pre-arranged and/or at-need funeral planning with potential customers (“
              <b>Consumers</b>”). The Service will be utilized by Consumers to, among other things,
              purchase pre-need and/or at-need funeral services (“<b>Transactions</b>”) from you,
              and you hereby authorize us to market such products and services on your behalf.
            </p>
            <p>
              You will be required to provide pricing and descriptions for your funeral home’s
              products and services being marketed on the Service, and you hereby grant us
              permission to display and offer your products, services and pricing to any and all
              visitors to our Service. You further agree to execute Transactions with all Consumers
              who elect to purchase your products and Services that are in accordance with the
              packages, price lists and terms you have provided to us.{' '}
              <b>
                In this regard, you agree to sign, electronically or otherwise, any documents
                requiring funeral home signature within 24 hours of receiving notice of the
                requirement.
              </b>{' '}
              You are responsible for the accuracy of the pricing, and for its compliance with the
              Funeral Rule as detailed by the Federal Trade Commission, including any updates or
              revisions to the Funeral Rule over time. You agree to provide us in writing with
              timely updates to your price list for your products or services marketed on the
              Service. At all times, you are solely responsible for the packages of goods and
              services being marketed to Consumers, including pricing and fulfillment, and you agree
              to be bound by the terms of any Transactions you execute through our Service with
              Consumers.
            </p>
            <p>
              By your participation in the Service, you grant us the right to communicate with
              Consumers on your behalf, and to utilize your name, logo, email address, phone number
              and address when communicating with Consumers. We may decline to pursue or do business
              with any Consumer in our reasonable discretion, including those Consumers who do not
              meet our standards and policies as established from time to time. You agree to comply
              with the policies and procedures related to this Client Agreement promulgated by us
              from time to time.
            </p>
            <p>
              For any Transactions you complete through the Service, you acknowledge and agree that
              the Transactions will be funded through the Assurance Elite products of Homesteaders
              Life Company (“<b>HLC</b>”), an Iowa mutual life insurance company. Not all HLC
              products are available in all states, and we will only market insurance where we and
              HLC are licensed to sell.
            </p>
            <p>
              You also agree not to charge Consumers separately or additionally, directly or
              indirectly, for the use of our Service or in connection with any Transactions
              completed through our Service, beyond what you’ve listed as pricing associated with
              the packages of goods and services you are offering through the Service.
            </p>
          </section>
          <section>
            <h3>3. TRADEMARKS</h3>
            <p>
              Neither Party will have the right to use the trademarks, service marks, trade dress,
              logos and trade names (collectively, “<b>Trademarks</b>”) of the other Party except as
              expressly authorized in this Section. During the term of your participation in this
              Service, you grant us a non-exclusive, non-assignable license to use your Trademarks
              solely and exclusively for the purposes described herein. You authorize us to use your
              name, logo and/or Trademarks without notice to or consent by you, in connection with
              the Service.
            </p>
            <p>
              You grant us the right to use your company name and logo as a reference for marketing
              or promotional purposes on our website and in other public or private communications
              with our existing or potential customers, subject to your standard trademark usage
              guidelines as provided to us from time to time. You may terminate this right by
              sending an email to <a href="mailto:legal@everdays.com">legal@everdays.com</a> stating
              that you do not wish to be used as a reference, and within a reasonable period of time
              we will remove you from any future marketing references.
            </p>
            <p>
              The participating funeral homes’ names and logos, product and service names,
              trademarks, and service marks appearing within the Service, unless otherwise noted,
              are trademarks (whether registered or not), service marks and/or trade dress of each
              such participating funeral home. All other trademarks, product names, company names,
              logos, service marks and/or trade dress mentioned, displayed, cited or otherwise
              indicated within the Service are the property of their respective owners. You are not
              authorized to display or use the trademarks, product names, company names, logos,
              service marks and/or trade dress of other owners featured within this Service,
              including ours, without the prior written permission of such owners. The use or misuse
              of other owners’ marks or other trademarks, product names, company names, logos,
              service marks and/or trade dress or any other materials contained herein, is expressly
              prohibited.
            </p>
          </section>
          <section>
            <h3>4. CLIENT CONTENT</h3>
            <p>
              Some areas of the Service allow Clients to submit, post, edit, display, provide, or
              otherwise make available content such as company names, locations, company
              information, products and services offered (including descriptions and pricing
              information), as well as information relating to your customers’ funeral services,
              including event dates, times and locations, other messages for the family or other
              participants, responses to comments and questions, and other content or information
              (any such materials a Client submits, posts, displays, provides, or otherwise makes
              available on the Service is referred to as “<b>Client Content</b>”). Client Content
              does NOT include Trademarks.
            </p>
            <p>
              Except for information posted by you regarding services or other information relating
              to a customers’ death, we claim no ownership rights over Client Content you create.
              The Client Content you create remains yours. However, you understand that certain
              portions of the Service may allow other Users to view, share, edit, and/or otherwise
              interact with your Client Content, and that family members of the deceased are allowed
              to edit information you may have posted regarding their deceased relative. By
              providing or sharing Client Content through the Service, you agree to allow others to
              view, edit, share, and/or interact with your Client Content in accordance with your
              settings and this Client Agreement. Everdays has the right (but not the obligation) in
              its sole discretion to remove any Client Content that is shared via the Service. If
              there are any claims of inaccuracy asserted about any of the Client Content, Everdays
              shall investigate said claims with reasonable dispatch, and will use its best
              judgment, in its sole discretion, to determine whether the disputed Content should be
              removed, corrected or left as is. However, in any case, Everdays is not responsible
              for any inaccuracy that may occur or exist for any reason whatsoever.
            </p>
            <p>
              By submitting, posting, displaying, providing, or otherwise making available any
              Client Content on or through the Service, you expressly grant, and you represent and
              warrant that you have all rights necessary to grant, to Everdays a royalty-free,
              sublicensable, transferable, perpetual, irrevocable, non-exclusive, worldwide license
              to use, reproduce, modify, publish, list information regarding, edit, translate,
              distribute, syndicate, publicly perform, publicly display, and make derivative works
              of all such Client Content and your name, voice, and/or likeness as contained in your
              Client Content, in whole or in part, and in any form, media or technology, whether now
              known or hereafter developed, for use in connection with the Service and Everdays’
              (and its successors’ and affiliates’) business, including without limitation for
              promoting and redistributing part or all of the Service (and derivative works thereof)
              in any media formats and through any media channels. You also hereby grant each User
              of the Service a non-exclusive license to access your Client Content through the
              Service, and to use, reproduce, distribute, display and perform such Client Content as
              permitted through the functionality of the Service and under this Client Agreement.
            </p>
            <p>
              In connection with your Client Content, you affirm, represent and warrant the
              following:
            </p>
            <ul>
              <li>
                Your Client Content and Everdays’ use thereof as contemplated by this Client
                Agreement and the Service will not violate any law or infringe any rights of any
                third party, including but not limited to any Intellectual Property Rights and
                privacy rights.
              </li>
              <li>
                Everdays may exercise the rights to your Client Content granted under this Client
                Agreement without liability for payment of any guild fees, residuals, payments,
                fees, or royalties payable under any collective bargaining agreement or otherwise.
              </li>
              <li>
                To the best of your knowledge, all your Client Content and other information that
                you provide to us is truthful and accurate.
              </li>
            </ul>
            <p>
              Everdays takes no responsibility and assumes no liability for any Client Content that
              you or any other Client or third party posts, sends, or otherwise makes available over
              the Service. You shall be solely responsible for your Client Content and the
              consequences of posting, publishing it, sharing it, or otherwise making it available
              on the Service, and you agree that we are only acting as a passive conduit for your
              online distribution and publication of your Client Content.
            </p>
          </section>
          <section>
            <h3>5. PROHIBITED USES</h3>
            <p>
              You may use the Service only for lawful purposes and in accordance with these Terms of
              Use and the Privacy Notice. To the extent you create an account through this Service,
              you understand and agree that any account you create, including your username and
              password, are personal to you and may not be used by anyone else. You are responsible
              for maintaining the confidentiality of your username and password and are fully
              responsible for all activities that occur under your username and password by you or
              by anyone else using your username and password, whether or not authorized by you. You
              agree to change your password immediately if you believe your password may have been
              compromised or used without authorization. You also agree to immediately inform us of
              any apparent breaches of security such as loss, theft or unauthorized disclosure or
              use of your username or password by contacting us using the information provided
              below. Until we are so notified, you will remain liable for any unauthorized use of
              your account. You agree not to use the Service in any of the following ways:
            </p>
            <ul>
              <li>
                In any way that intentionally or unintentionally violates any applicable federal,
                state, local or international law or regulation (including, without limitation, any
                laws regarding the export of data or software to and from the United States or other
                countries), or encouraging others to do so.
              </li>
              <li>
                For the purpose of exploiting, harming or attempting to exploit or harm minors in
                any way by exposing them to inappropriate content, asking for Personally
                Identifiable Information or otherwise.
              </li>
              <li>
                To transmit, or procure the sending of, any advertising or promotional material,
                including any “junk mail,” “chain letter,” or “spam” or any other similar
                solicitation.
              </li>
              <li>
                To impersonate or attempt to impersonate Everdays, an Everdays employee, another
                user, or any other person or entity (including, without limitation, by using e-mail
                associated with any of the foregoing).
              </li>
              <li>
                To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment
                of the Website, or which, as determined by us, may harm Everdays or users of the
                Service or expose them to liability.
              </li>
              <li>
                Additionally, you agree not to do any of the following:
                <ul>
                  <li>
                    Circumvent, disable, or otherwise interfere with security-related features of
                    the Service or features that prevent or restrict use or copying of any content
                    or enforce limitations on the use of our Service or any content on the Service.
                  </li>
                  <li>Publicly disparage anyone or any Unser Content.</li>
                  <li>
                    Use the Service in any manner that could disable, overburden, damage, or impair
                    the Service or interfere with any other party’s use of the Service, including
                    its ability to engage in real time activities through the Service.
                  </li>
                  <li>
                    Use any robot, spider, “scraper,” “crawler,” or other automatic device, process,
                    or means to access the Service for any purpose, including monitoring or copying
                    any of the material on the Service.
                  </li>
                  <li>
                    Use any manual process to monitor or copy any of the material on the Service or
                    for any other unauthorized purpose without our prior written consent.
                  </li>
                  <li>
                    Use any device, software, or routine that interferes with the proper working of
                    the Service.
                  </li>
                  <li>
                    Introduce any viruses, trojan horses, worms, logic bombs, or other material that
                    is malicious or technologically harmful.
                  </li>
                  <li>
                    Attempt to gain unauthorized access to, interfere with, damage, or disrupt any
                    parts of the Service, the servers on which the Service is stored, or any server,
                    computer, or database connected to the Service.
                  </li>
                  <li>
                    Attack the Service via a denial-of-service attack or a distributed
                    denial-of-service attack.
                  </li>
                  <li>Otherwise attempt to interfere with the proper working of the Service.</li>
                  <li>Modify copies of any materials from the Service.</li>
                  <li>
                    Use any illustrations, photographs, video or audio sequences or any graphics
                    from the Service or any text.
                  </li>
                  <li>
                    Attempt to reverse engineer, decompile, hack, disable, interfere with,
                    disassemble, modify, copy, translate, or disrupt the features, functionality,
                    integrity, or performance of the Service, any third-party use of the Service, or
                    any third-party data contained therein.
                  </li>
                  <li>
                    Send altered, deceptive or false source-identifying information, including
                    “spoofing” or “phishing”.
                  </li>
                  <li>
                    Use contact or other user information obtained from the Service (including email
                    addresses) to contact Users outside the Service without their express permission
                    or authority or to create or distribute mailing lists or other collections of
                    contact or user profile information of Users for use outside the Service.
                  </li>
                  <li>
                    Delete or alter any copyright, trademark, or other proprietary rights notices
                    from copies of materials from the Service.
                  </li>
                  <li>
                    Authorize, permit, enable, induce or encourage any third party to do any of the
                    above.
                  </li>
                </ul>
              </li>
            </ul>
          </section>
          <section>
            <h3>6. OUR PROPRIETARY RIGHTS</h3>
            <p>
              For the purposes of this Client Agreement, “<b>Intellectual Property Rights</b>” means
              all patent rights, copyright rights, mask work rights, moral rights, rights of
              publicity, trademark, trade dress and service mark rights, goodwill, trade secret
              rights, technology, system, application tool, process, software, module, standard
              software application, know-how, methodology and other intellectual property rights
              owned or used by us as may now exist or hereafter come into existence, and all
              applications therefore and registrations, renewals and extensions thereof, under the
              laws of any state, country, territory or other jurisdiction. Except as provided
              herein, you will have no rights, title or interests in any of our Intellectual
              Property Rights as a result of this Client Agreement, and in no event shall you obtain
              any source code from us. All use of our Intellectual Property Rights will inure to our
              benefit.
            </p>
            <p>
              Except for your Client Content and Trademarks, the Service and all materials therein
              or transferred thereby, including, without limitation, software, images, text,
              graphics, illustrations, logos, patents, trademarks, service marks, copyrights,
              photographs, audio, videos, music, Client Content belonging to other Clients, and User
              Content (as defined in our User Terms of Service) belonging to other Users (the “
              <b>Everdays Content</b>”), and all Intellectual Property Rights related thereto, are
              the exclusive property of Everdays and its licensors (including other Users who post
              User Content to the Service and other Clients who post Client Content to the Service).
              Except as explicitly provided herein, nothing in this Client Agreement shall be deemed
              to create a license in or under any such Intellectual Property Rights, and you agree
              not to sell, license, rent, modify, distribute, copy, reproduce, transmit, publicly
              display, publicly perform, publish, adapt, edit or create derivative works from any
              Everdays Content. Use of the Everdays Content for any purpose not expressly permitted
              by this Client Agreement is strictly prohibited. You are not authorized to display or
              use trademarks, product names, company names, logos, service marks and/or trade dress
              of other owners featured within this Service without the prior written permission of
              such owners.
            </p>
            <p>
              You may choose to, or we may invite you to, submit comments or ideas about the
              Service, including without limitation about how to improve the Service or our products
              (“<b>Ideas</b>”). By submitting any Idea, you agree that your disclosure is
              gratuitous, unsolicited and without restriction and will not place Everdays under any
              fiduciary or other obligation, and that we are free to use the Idea without any
              additional compensation to you, and/or to disclose the Idea on a non-confidential
              basis or otherwise to anyone. You further acknowledge that, by acceptance of your
              submission, Everdays does not waive any rights to use similar or related ideas
              previously known to Everdays, or developed by its employees, or obtained from sources
              other than you.
            </p>
            <p>
              As between you and us, we will own any and all personally identifiable information
              that Consumers provide to us through the Service, or that you provide to us on a
              Consumer’s behalf (“<b>Consumer PII</b>”); however, we may, and you authorize us to,
              transfer ownership of Consumer PII to HLC to effectuate the sale of an insurance
              policy. In such case, HLC will have the same obligations to treat Consumer PII as
              Confidential Information. If consent is obtained from a Consumer as required by any
              applicable State and/or Federal law, we will disclose the following Consumer PII to
              you at the time a Consumer completes a Transaction through the Service: Consumer name,
              address, phone number and e-mail address. You may use Consumer PII only for the
              purpose of providing at-need and pre-need funeral arrangements or any other business
              purposes we and you may hereafter agree to in writing. Consumer PII is Confidential
              Information as that term is defined in Section 13, and you shall treat Consumer PII in
              a manner consistent with that Section. Furthermore, you agree to modify or amend your
              privacy policies as necessary to share Consumer PII with us.
            </p>
          </section>
          <section>
            <h3>7. FEES AND LICENSING</h3>
            <p>
              Other than set forth elsewhere in this Client Agreement, during the term of your
              participation in this Service, there shall be NO fees, dues, commissions or other
              direct forms of compensation payable by either Party to the other Party. You
              acknowledge, however, that we will be compensated by HLC for the sales of HLC products
              to Consumers in connection with Transactions, including Transactions involving your
              funeral home(s) and you agree that you have no rights or claims to any of this
              compensation. If you have not already executed a Funeral Home Authorization Agreement
              with HLC, you agree to do so.
            </p>
            <p>
              During the term of your participation in this Service, you agree to obtain and
              maintain all licenses and appointments in each state as may be required for the
              funeral prearrangement and at-need Transactions, including but not limited to any
              required insurance licensing. You further agree that your representatives will be
              properly licensed and appointed prior to engaging in the activities set forth in this
              Client Agreement or which may be required as part of the Service. You agree to
              promptly notify us of any material changes with regard to the licensing and
              appointment of you and/or your representatives.
            </p>
            <p>
              You agree to comply with all regulations, bulletins, rulings, circular letters, and
              statutes, federal, state and local, now or hereafter in force, which are applicable to
              your performance under this Client Agreement, including, but not limited to, any
              funeral home or pre-need or at-need license requirements.
            </p>
            <p>
              Except as otherwise provided in this Client Agreement, you will be responsible for all
              costs and expenses incurred by you or your representatives in the performance of your
              duties under this Client Agreement, including, but not limited to, computer and
              related internet expenses, rentals, office facilities, travel expenses, transportation
              facilities, and licensing fees.
            </p>
            <p>
              You shall promptly notify us in writing of any Consumer complaint or of any complaint
              which involves a potential regulatory investigation or potential litigation which you
              become aware of relating to the Service or Transactions with Consumers which may occur
              under this Client Agreement.
            </p>
          </section>
          <section>
            <h3>8.TERM AND TERMINATION</h3>
            <p>
              The term of this Client Agreement shall be for two years commencing on the date you
              indicated your acceptance and agreement with this Client Agreement, and will be
              automatically renewed for successive one-year terms, unless either you or we give
              notice to the other party of termination at least thirty (30) days prior to the end of
              the then-current term.
            </p>
            <p>
              Notwithstanding the foregoing, you or we may terminate this Client Agreement (i) with
              or without cause upon thirty (30) days prior written notice to the other party, or
              (ii) immediately upon the other party’s breach of the terms or conditions of this
              Client Agreement.
            </p>
            <p>
              For continued use of the Service at no charge to Clients, Clients are required to put
              on Everdays a minimum of 65% of the individual death cases handled monthly. If you
              miss the 65% threshold, Everdays may terminate your Client Account in its sole
              discretion.
            </p>
            <p>
              Termination of this Client Agreement will not relieve any party of any obligations or
              liabilities that are expressly indicated to survive termination or expiration of this
              Client Agreement and will be without prejudice to any rights that will have accrued to
              the benefit of any Party prior to such termination or expiration.
            </p>
            <p>
              Sections 3, 4, 6, 7, 10, 12-24, and 26 will survive the expiration or termination of
              this Client Agreement. Further, any termination of this Client Agreement will not
              affect the rights of Consumers that may have effectuated Transactions through the
              Service.
            </p>
          </section>
          <section>
            <h3>9. EXCLUSIVITY</h3>
            <p>
              You agree that during the term of this Client Agreement, you will not enter into any
              similar mobile-based marketing and transaction service arrangements with third
              parties, other than eFuneral Solutions, LLC, without our written approval.
            </p>
          </section>
          <section>
            <h3>10. CALIFORNIA RESIDENTS</h3>
            <p>
              The provider of services is Everdays, Inc. If you are a California resident, in
              accordance with Cal. Civ. Code §1789.3, you may report complaints to the Complaint
              Assistance Unit of the Division of Consumer Services of the California Department of
              Consumer Affairs by contacting them in writing at 1625 North Market Blvd., Suite N 112
              Sacramento, CA 95834, or by telephone at (800) 952-5210 or (916) 445-1254.
            </p>
          </section>
          <section>
            <h3>11. ELECTRONIC COMMUNICATIONS</h3>
            <h4>
              <b>11.1 Text Messaging</b>
            </h4>
            <p>
              We offer you the chance to enroll to receive SMS/text messages from Everdays. You may
              enroll to receive text messages about account-related news and alerts. By enrolling in
              Everdays’ SMS/text messaging service, you agree to receive text messages from Everdays
              to your mobile phone number provided, and you certify that your mobile number provided
              is true and accurate and that you are authorized to enroll the designated mobile
              number to receive such text messages. You are solely responsible for keeping us
              updated with your current phone number, respectively updating to the latest version of
              the mobile app, and for any charges incurred by receiving such messages. We will not
              be liable for information sent to a device that is associated with your outdated
              mobile phone number or using an outdated app. You acknowledge and agree that the text
              messages may be sent using an automatic telephone dialing system and that standard
              message and data rates apply. Consent is not required as a condition of purchase.
            </p>
            <p>
              To unsubscribe from text messages at any time, reply STOP to any text message you
              receive from Everdays. You consent that following such a request to unsubscribe, you
              may receive one final text message from Everdays confirming your request. For help
              contact us at <a href="mailto:support@everdays.com">support@everdays.com</a>.
            </p>
            <h4>
              <b>11.2 Electronic Signature Agreement</b>
            </h4>
            <p>
              By selecting the “I Accept” or “I Agree” button (or something similar), you are
              signing the Agreement electronically. You agree your electronic signature is the legal
              equivalent of your manual signature on this Agreement. You further agree that your use
              of a key pad, mouse or other device to select an item, button, icon or similar
              act/action, in accessing or making any transaction regarding any agreement,
              acknowledgement, consent terms, disclosures or conditions constitutes your signature
              (hereafter referred to as “<b>E-Signature</b>”), acceptance and agreement as if
              actually signed by you in writing. You also agree that no certification authority or
              other third-party verification is necessary to validate your E-Signature and that the
              lack of such certification or third-party verification will not in any way affect the
              enforceability of your E-Signature or any resulting contract between you and Everdays.
              You also represent that you are authorized to enter into this Agreement for all
              persons who own or are authorized to access any of your accounts and that such persons
              will be bound by the terms of this Agreement. You further agree that each use of your
              E-Signature in connection with the Service constitutes your agreement to be bound by
              the terms and conditions of the Agreement.
            </p>
            <h4>
              <b>11.3 Electronic Delivery</b>
            </h4>
            <p>
              When you use the Service or send emails or texts to us, you are communicating with us
              electronically. You consent to receive communications from us electronically. We will
              communicate with you by email or by posting notices on our website. You agree that all
              agreements, notices, disclosures and other communications that we provide to you
              electronically satisfy any legal requirement that such communication be in writing.
              You are responsible for providing, at your expense, any access to the internet and any
              required equipment.
            </p>
          </section>
          <section>
            <h3>12. PRIVACY</h3>
            <p>
              We care about the privacy of our Clients. You understand that by using the Service you
              consent to the collection, use and disclosure of your personally identifiable
              information and aggregate and/or anonymized data as set forth in our Client 
              <a href="/privacy">Privacy Policy</a>.
            </p>
          </section>
          <section>
            <h3>13. CONFIDENTIALITY</h3>
            <p>
              In the course of their activities pursuant to this Client Agreement, we may disclose
              Confidential Information to you and wish to protect such Confidential Information in
              accordance with this Section.
            </p>
            <p>
              (a) For purposes hereof, “Confidential Information” means any trade secrets; any
              confidential, proprietary or competitively sensitive information, knowledge, designs,
              data, or know-how, including Consumer PII; or any other information considered
              reasonably as “confidential” that we disclose to you. Confidential Information does
              not include information that (i) is or hereafter becomes generally available to the
              public other than as a result of a disclosure by you (except that Consumer PII shall
              be considered Confidential Information notwithstanding such general availability),
              (ii) was already known to you prior to receipt from us as evidenced by prior written
              documents in its possession not subject to an existing confidentiality obligation to
              us, (iii) is disclosed to you on a non-confidential basis by a person who is not in
              default of any confidentiality obligation to us, or (iv) is developed by or on your
              behalf without reliance on Confidential received hereunder.
            </p>
            <p>
              (b) You agree to (i) use such Confidential Information solely in connection with the
              activities contemplated by this Client Agreement and (ii) not disclose such
              Confidential Information to any person other than those of your agents and
              representatives who need to know such Confidential Information in order to accomplish
              the objectives for which it was disclosed. You agree to inform such agents and
              representatives of the confidential nature of the information and cause them to
              observe the limitations on the use thereof. Notwithstanding the foregoing, you may
              disclose Confidential Information to the extent necessary to comply with applicable
              laws or regulations or with an order issued by a court or regulatory body with
              competent jurisdiction; provided that, in connection with such disclosure, you notify
              us in advance of such disclosure and use commercially reasonable efforts to obtain
              confidential treatment or an appropriate protective order, to the extent available,
              with respect to such Confidential Information.
            </p>
            <p>
              (c) Upon our request, you agree to promptly redeliver to us all Confidential
              Information provided to you in tangible form, and you will not retain any copies,
              extracts or other reproductions, in whole or in part, of such Confidential
              Information. Notwithstanding the foregoing, your legal counsel will be permitted to
              retain in its files one copy of all Confidential Information to evidence the scope of
              and to enforce the parties’ obligation of confidentiality under this Section.
            </p>
            <p>
              To the extent you provide us with Consumer PII, you represent and warrant that you
              have obtained all necessary consents to provide such information to us, for our use in
              any manner consistent with the Client Privacy Policy. You also represent and warrant
              that to the extent you provide Consumer PII to us, such disclosure is consistent with
              any applicable agreements you have with the relevant Consumer and with any
              disclosures, policies, and notices you have presented to the relevant Consumer.
            </p>
          </section>
          <section>
            <h3>14. SECURITY</h3>
            <p>
              Everdays uses commercially reasonable physical, managerial, and technical safeguards
              to preserve the integrity and security of your personal information and Consumer PII.
              However, we cannot guarantee that unauthorized third parties will never be able to
              defeat our security measures or use your personal information for improper purposes.
              You acknowledge that you provide your personal information at your own risk. You also
              agree that the security you will provide for any Consumer PII shall be consistent
              with, or above, industry standards. Should a breach of Consumer PII occur, you agree
              to notify us within twenty-four (24) hours of said breach. You agree to cooperate with
              us regarding any breach of Consumer PII including, but not limited to reporting of the
              breach, notification to individuals, providing information regarding the breach, and
              any other items necessary under applicable State or Federal laws or as may be required
              by us, regulators or law enforcement. You shall, to the fullest extent permitted by
              law, indemnify and hold harmless us and our directors, employees, officers, owners,
              and affiliates from and against any and all liability, loss, cost, expense, or damage
              incurred or sustained due to your fraud, willful misconduct or negligence under this
              Paragraph. You agree to defend and hold us and our agents, directors, employees,
              officers, owners, and affiliates harmless from all claims, liabilities, damages, or
              judgments involving a third party, including our costs and attorney fees, which arise
              as a result of your failure to meet any of your obligations under this Paragraph.
            </p>
          </section>
          <section>
            <h3>15. DMCA NOTICE</h3>
            <p>
              Since we respect artist and content owner rights, it is Everdays’ policy to respond to
              alleged infringement notices that comply with the Digital Millennium Copyright Act of
              1998 (“<b>DMCA</b>”).
            </p>
            <p>
              If you believe that your copyrighted work has been copied in a way that constitutes
              copyright infringement and is accessible via the Service, please notify Everdays’
              copyright agent as set forth in the DMCA. For your complaint to be valid under the
              DMCA, you must provide the following information in writing:
            </p>
            <ol>
              <li>
                An electronic or physical signature of a person authorized to act on behalf of the
                copyright owner;
              </li>
              <li>Identification of the copyrighted work that you claim has been infringed;</li>
              <li>
                Identification of the material that is claimed to be infringing and where it is
                located on the Service;
              </li>
              <li>
                Information reasonably sufficient to permit Everdays to contact you, such as your
                address, telephone number, and, e-mail address;
              </li>
              <li>
                A statement that you have a good faith belief that use of the material in the manner
                complained of is not authorized by the copyright owner, its agent, or law; and
              </li>
              <li>
                A statement, made under penalty of perjury, that the above information is accurate,
                and that you are the copyright owner or are authorized to act on behalf of the
                owner.
              </li>
            </ol>
            <p>The above information must be submitted to the following DMCA Agent:</p>
            <p>
              Attn: DMCA Notice
              <br />
              Everdays, Inc.
              <br />
              Address: 320 Martin St
              <br />
              Ste. 140
              <br />
              Birmingham MI, 48009
              <br />
              Tel.: 248-480-2444
              <br />
              Fax: 248-480-2402
              <br />
              Email: <a href="mailto:copyright@everdays.com">copyright@everdays.com</a>
            </p>
            <h5>
              UNDER FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT THAT ONLINE MATERIAL IS INFRINGING,
              YOU MAY BE SUBJECT TO CRIMINAL PROSECUTION FOR PERJURY AND CIVIL PENALTIES, INCLUDING
              MONETARY DAMAGES, COURT COSTS, AND ATTORNEYS’ FEES.
            </h5>
            <p>
              Please note that this procedure is exclusively for notifying Everdays and its
              affiliates that your copyrighted material has been infringed. The preceding
              requirements are intended to comply with Everdays’ rights and obligations under the
              DMCA, including 17 U.S.C. §512(c), but do not constitute legal advice. It may be
              advisable to contact an attorney regarding your rights and obligations under the DMCA
              and other applicable laws.
            </p>
            <p>
              In accordance with the DMCA and other applicable law, Everdays has adopted a policy of
              terminating, in appropriate circumstances, Clients who are deemed to be repeat
              infringers. Everdays may also at its sole discretion limit access to the Service
              and/or terminate the Client Accounts of any Clients who infringe any intellectual
              property rights of others, whether or not there is any repeat infringement.
            </p>
          </section>
          <section>
            <h3>16. THIRD-PARTY LINKS AND INFORMATION</h3>
            <p>
              The Service may contain links to third-party materials that are not owned or
              controlled by Everdays. Everdays does not endorse or assume any responsibility for any
              such third-party sites, information, materials, products, or services. If you access a
              third-party website or service from the Service or share your Client Content on or
              through any third-party website or service, you do so at your own risk, and you
              understand that this Client Agreement and the Privacy Policy do not apply to your use
              of such sites. You expressly relieve Everdays from any and all liability arising from
              your use of any third-party website, service, or content, including without limitation
              User Content submitted by other Users and Client Content submitted by other Clients.
              Additionally, your dealings with or participation in promotions of advertisers found
              on the Service, including payment and delivery of goods, and any other terms (such as
              warranties) are solely between you and such advertisers. You agree that Everdays shall
              not be responsible for any loss or damage of any sort relating to your dealings with
              such advertisers.
            </p>
          </section>
          <section>
            <h3>17. EVERDAYS’ PARTNERS</h3>
            <p>
              We have engaged with third-party companies to enable Consumers to complete the
              purchase of at-need and/or preneed funeral goods and services from Clients, including
              you. These providers include (i) one or more agents who may assist with insurance
              agency, document processing, payment processing and/or compliance services, among
              other things, and (ii) one or more life insurance companies, who will underwrite the
              insurance used to fund prearranged funeral products and services. It may be necessary
              for you to accept and operate under the terms of service and/or privacy policy with
              either or both of those companies in order to complete Transactions.
            </p>
          </section>
          <section>
            <h3>18. INDEMNITY</h3>
            <p>
              You agree to defend, indemnify and hold harmless Everdays and its subsidiaries,
              agents, licensors, managers, and other affiliated companies, and their employees,
              contractors, agents, officers and directors, from and against any and all claims,
              damages, obligations, losses, liabilities, costs or debt, and expenses (including but
              not limited to attorney’s fees) arising from: (i) your use of and access to the
              Service, including any data or content transmitted or received by you; (ii) your
              violation of any term of this Client Agreement, including without limitation your
              breach of any of the representations and warranties above; (iii) your violation of any
              third-party right, including without limitation any right of privacy or Intellectual
              Property Rights; (iv) your violation of any applicable law, rule or regulation; (v)
              Client or User Content or any content that is submitted via your Client Account
              including without limitation misleading, false, or inaccurate information; (vi) your
              willful misconduct; or (vii) any other party’s access and use of the Service with your
              unique username, password or other appropriate security code.
            </p>
          </section>
          <section>
            <h3>19. NO WARRANTY</h3>
            <p>
              THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. USE OF THE SERVICE IS
              AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE IS
              PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT
              NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, OR NON-INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
              OBTAINED BY YOU FROM EVERDAYS OR THROUGH THE SERVICE WILL CREATE ANY WARRANTY NOT
              EXPRESSLY STATED HEREIN. WITHOUT LIMITING THE FOREGOING, EVERDAYS, ITS SUBSIDIARIES,
              ITS AFFILIATES, AND ITS LICENSORS DO NOT WARRANT THAT THE CONTENT IS ACCURATE,
              RELIABLE OR CORRECT; THAT THE SERVICE WILL MEET YOUR REQUIREMENTS; THAT THE SERVICE
              WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE; THAT
              ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE SERVICE IS FREE OF VIRUSES OR
              OTHER HARMFUL COMPONENTS. ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE
              OF THE SERVICE IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR
              ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM
              SUCH DOWNLOAD OR YOUR USE OF THE SERVICE.
            </p>
            <p>
              FURTHER, EVERDAYS DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR
              ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICE OR
              ANY HYPERLINKED WEBSITE OR SERVICE, AND EVERDAYS WILL NOT BE A PARTY TO OR IN ANY WAY
              MONITOR ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
            </p>
            <p>
              FEDERAL LAW, SOME STATES, PROVINCES AND OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION
              AND LIMITATIONS OF CERTAIN IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY
              TO YOU. THIS AGREEMENT GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER
              RIGHTS WHICH VARY FROM STATE TO STATE. THE DISCLAIMERS AND EXCLUSIONS UNDER THIS
              AGREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
            </p>
          </section>
          <section>
            <h3>20. LIMITATION OF LIABILITY</h3>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL EVERDAYS, ITS
              AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS OR LICENSORS BE LIABLE FOR ANY
              INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING
              WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
              INTANGIBLE LOSSES, ARISING OUT OF OR RELATING TO THE USE OF, OR INABILITY TO USE, THE
              SERVICE. UNDER NO CIRCUMSTANCES WILL EVERDAYS BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR
              INJURY RESULTING FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF THE
              SERVICE OR YOUR ACCOUNT OR THE INFORMATION CONTAINED THEREIN.
            </p>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EVERDAYS ASSUMES NO LIABILITY OR
              RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (II) PERSONAL
              INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR
              USE OF OUR SERVICE; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
              AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY INTERRUPTION OR
              CESSATION OF TRANSMISSION TO OR FROM THE SERVICE; (V) ANY BUGS, VIRUSES, TROJAN
              HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD
              PARTY; (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED
              AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE
              AVAILABLE THROUGH THE SERVICE; AND/OR (VII) CLIENT OR USER CONTENT OR THE DEFAMATORY,
              OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY. IN NO EVENT SHALL EVERDAYS, ITS
              AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS, OR LICENSORS BE LIABLE TO YOU FOR
              ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN AN
              AMOUNT EXCEEDING THE AMOUNT YOU PAID TO EVERDAYS HEREUNDER OR $100.00, WHICHEVER IS
              GREATER.
            </p>
            <p>
              THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON
              CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF EVERDAYS HAS
              BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
            </p>
            <p>
              SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL
              DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. THIS AGREEMENT
              GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM
              STATE TO STATE. THE DISCLAIMERS, EXCLUSIONS, AND LIMITATIONS OF LIABILITY UNDER THIS
              AGREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
            </p>
          </section>
          <section>
            <h3>21. REMEDIES</h3>
            <p>
              The Parties agree that it would be impossible or inadequate to measure and calculate
              damages from breach of the provisions set forth in this Client Agreement. Accordingly,
              the Parties agree that the non-breaching Party will have available, in addition to any
              other right or remedy available, the right to obtain an injunction from a court of
              competent jurisdiction restraining such breach or threatened breach of this Client
              Agreement and to specific performance of any such provision of this Client Agreement.
            </p>
          </section>
          <section>
            <h3>22. COMPLIANCE WITH LAWS</h3>
            <p>
              At all times, each Party’s performance under this Client Agreement will comply with
              all applicable laws, rules and regulations, including all applicable laws, rules,
              regulations and best practices concerning data privacy and security. In the event
              either Party breaches any of its obligations under this Section, the other Party may
              take any action available under law or contract.
            </p>
            <p>
              You warrant that you will not use the Everdays software platform (“<b>Systems</b>”) in
              a manner subject to the EU General Data Protection Regulation (“<b>GDPR</b>”).
              Furthermore, you acknowledge that the Systems may not meet the requirements of GDPR
              and that we have not represented the Systems as complying with such requirements. You
              also agree not to (i) permit any third party to access or use the Systems in violation
              of any U.S. law or regulation, or (ii) export the Systems or otherwise remove them
              from the United States except in compliance with all applicable U.S. laws and
              regulations. Without limiting the generality of the foregoing, you shall not permit
              any third party to access or use Systems in, or export them to, a country subject to a
              United States embargo.
            </p>
          </section>
          <section>
            <h3>23. FORCE MAJEURE</h3>
            <p>
              Neither Party will be liable by reason of any failure or delay in the performance of
              its obligations on account of events beyond the reasonable control of a Party, which
              may include, but not be limited to, denial-of-service attacks, a failure by a
              third-party hosting provider or utility provider, strikes, shortages, riots, fires,
              acts of God, war, terrorism, pandemics and government action.
            </p>
          </section>
          <section>
            <h3>24. GOVERNING LAW, ARBITRATION, AND CLASS ACTION/JURY TRIAL WAIVER</h3>
            <h4>
              <b>24.1 Governing Law</b>
            </h4>
            <p>
              This Client Agreement shall be governed by the internal substantive laws of Michigan,
              without respect to its conflict of laws principles. The parties acknowledge that this
              Client Agreement evidences a transaction involving interstate commerce.
              Notwithstanding the preceding sentences with respect to the substantive law, any
              arbitration conducted pursuant to the terms of this Client Agreement shall be governed
              by the Federal Arbitration Act (9 U.S.C. §§ 1-16). The application of the United
              Nations Convention on Contracts for the International Sale of Goods is expressly
              excluded. You agree to submit to the personal jurisdiction of the federal and state
              courts located in Oakland County, Michigan for any actions for which we retain the
              right to seek injunctive or other equitable relief in a court of competent
              jurisdiction to prevent the actual or threatened infringement, misappropriation or
              violation of a our copyrights, trademarks, trade secrets, patents, or other
              intellectual property or proprietary rights, as set forth in the Arbitration provision
              below, including any provisional relief required to prevent irreparable harm. You
              agree that Oakland County, Michigan is the proper forum for any appeals of an
              arbitration award or for trial court proceedings in the event that the arbitration
              provision below is found to be unenforceable.
            </p>
            <h4>24.2 Arbitration</h4>
            <p>
              READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE PARTIES TO ARBITRATE THEIR
              DISPUTES AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM EVERDAYS. For any
              dispute with Everdays, you agree to first contact us at 
              <a href="mailto:legal@everdays.com">legal@everdays.com</a> and attempt to resolve the
              dispute with us informally. In the unlikely event that Everdays has not been able to
              resolve a dispute it has with you after sixty (60) days, we each agree to resolve any
              claim, dispute, or controversy (excluding any claims for injunctive or other equitable
              relief as provided below) arising out of or in connection with or relating to this
              Client Agreement, or the breach or alleged breach thereof (collectively, “
              <b>Claims</b>”), by binding arbitration by JAMS, under the Optional Expedited
              Arbitration Procedures then in effect for JAMS, except as provided herein. JAMS may be
              contacted at www.jamsadr.com. The arbitration will be conducted in Oakland County,
              Michigan, unless you and Everdays agree otherwise. If you are using the Service for
              commercial purposes, each party will be responsible for paying any JAMS filing,
              administrative and arbitrator fees in accordance with JAMS rules, and the award
              rendered by the arbitrator shall include costs of arbitration, reasonable attorneys’
              fees and reasonable costs for expert and other witnesses. If you are an individual
              using the Service for non-commercial purposes: (i) JAMS may require you to pay a fee
              for the initiation of your case, unless you apply for and successfully obtain a fee
              waiver from JAMS; (ii) the award rendered by the arbitrator may include your costs of
              arbitration, your reasonable attorney’s fees, and your reasonable costs for expert and
              other witnesses; and (iii) you may sue in a small claims court of competent
              jurisdiction without first engaging in arbitration, but this does not absolve you of
              your commitment to engage in the informal dispute resolution process. Any judgment on
              the award rendered by the arbitrator may be entered in any court of competent
              jurisdiction. Nothing in this Section shall be deemed as preventing Everdays from
              seeking injunctive or other equitable relief from the courts as necessary to prevent
              the actual or threatened infringement, misappropriation, or violation of our data
              security, Intellectual Property Rights or other proprietary rights.
            </p>
            <h4>
              <b>24.3 CLASS ACTION/JURY TRIAL WAIVER</b>
            </h4>
            <p>
              WITH RESPECT TO ALL PERSONS AND ENTITIES, REGARDLESS OF WHETHER THEY HAVE OBTAINED OR
              USED THE SERVICE FOR PERSONAL, COMMERCIAL OR OTHER PURPOSES, ALL CLAIMS MUST BE
              BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
              ANY PURPORTED CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION OR
              OTHER REPRESENTATIVE PROCEEDING. THIS WAIVER APPLIES TO CLASS ARBITRATION, AND, UNLESS
              WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS.
              YOU AGREE THAT, BY ENTERING INTO THIS AGREEMENT, YOU AND EVERDAYS ARE EACH WAIVING THE
              RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION, COLLECTIVE ACTION,
              PRIVATE ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF ANY KIND.
            </p>
          </section>
          <section>
            <h3>25. ADDITIONAL TERMS FOR MOBILE APPLICATION</h3>
            <h4>
              <b>25.1 Mobile Applications</b>
            </h4>
            <p>
              We may make available software to access the Service via a mobile device (“
              <b>Mobile Applications</b>”). To use any Mobile Applications, you must have a mobile
              device that is compatible with the Mobile Applications. Everdays does not warrant that
              the Mobile Applications will be compatible with your mobile device. You may use mobile
              data in connection with the Mobile Applications and may incur additional charges from
              your wireless provider for these services. You agree that you are solely responsible
              for any such charges. Everdays hereby grants you a non-exclusive, non-transferable,
              revocable license to use a compiled code copy of the Mobile Applications for one
              Everdays Client Account on one mobile device owned or leased solely by you, for your
              personal use. You may not: (i) modify, disassemble, decompile or reverse engineer the
              Mobile Applications, except to the extent that such restriction is expressly
              prohibited by law; (ii) rent, lease, loan, resell, sublicense, distribute or otherwise
              transfer the Mobile Applications to any third party or use the Mobile Applications to
              provide time sharing or similar services for any third party; (iii) make any copies of
              the Mobile Applications; (iv) remove, circumvent, disable, damage or otherwise
              interfere with security-related features of the Mobile Applications, features that
              prevent or restrict use or copying of any content accessible through the Mobile
              Applications, or features that enforce limitations on use of the Mobile Applications;
              or (v) delete the copyright and other proprietary rights notices on the Mobile
              Applications. You acknowledge that Everdays may from time to time issue upgraded
              versions of the Mobile Applications and may automatically electronically upgrade the
              version of the Mobile Applications that you are using on your mobile device. You
              consent to such automatic upgrading on your mobile device and agree that the terms and
              conditions of this Client Agreement will apply to all such upgrades. Any third-party
              code that may be incorporated in the Mobile Applications is covered by the applicable
              open source or third-party license EULA, if any, authorizing use of such code. The
              foregoing license grant is not a sale of the Mobile Applications or any copy thereof,
              and Everdays or its third-party partners or suppliers retain all right, title, and
              interest in the Mobile Applications (and any copy thereof). Any attempt by you to
              transfer any of the rights, duties or obligations hereunder, except as expressly
              provided for in this Client Agreement, is void. Everdays reserves all rights not
              expressly granted under this Client Agreement. If the Mobile Applications is being
              acquired on behalf of the United States Government, then the following provision
              applies. The Mobile Applications will be deemed to be “commercial computer software”
              and “commercial computer software documentation,” respectively, pursuant to DFAR
              Section 227.7202 and FAR Section 12.212, as applicable. Any use, reproduction,
              release, performance, display or disclosure of the Service and any accompanying
              documentation by the U.S. Government will be governed solely by this Client Agreement
              and is prohibited except to the extent expressly permitted by this Client Agreement.
              The Mobile Applications originates in the United States and is subject to United
              States export laws and regulations. The Mobile Applications may not be exported or
              re-exported to certain countries or those persons or entities prohibited from
              receiving exports from the United States. In addition, the Mobile Applications may be
              subject to the import and export laws of other countries. You agree to comply with all
              United States and foreign laws related to use of the Mobile Applications and the
              Service.
            </p>
            <h4>
              <b>25.2 Mobile Applications from Apple App Store</b>
            </h4>
            <p>
              The following applies to any Mobile Applications you acquire from the Apple App Store
              (“<b>Apple-Sourced Software</b>”): You acknowledge and agree that this Client
              Agreement is solely between you and Everdays, not Apple, Inc. (“<b>Apple</b>”) and
              that Apple has no responsibility for the Apple-Sourced Software or content thereof.
              Your use of the Apple-Sourced Software must comply with the App Store Terms of
              Service. You acknowledge that Apple has no obligation whatsoever to furnish any
              maintenance and support services with respect to the Apple-Sourced Software. In the
              event of any failure of the Apple-Sourced Software to conform to any applicable
              warranty, you may notify Apple, and Apple will refund the purchase price for the
              Apple-Sourced Software to you; to the maximum extent permitted by applicable law,
              Apple will have no other warranty obligation whatsoever with respect to the
              Apple-Sourced Software, and any other claims, losses, liabilities, damages, costs or
              expenses attributable to any failure to conform to any warranty will be solely
              governed by this Client Agreement and any law applicable to Everdays as provider of
              the software. You acknowledge that Apple is not responsible for addressing any claims
              of you or any third party relating to the Apple-Sourced Software or your possession
              and/or use of the Apple-Sourced Software, including, but not limited to: (i) product
              liability claims; (ii) any claim that the Apple-Sourced Software fails to conform to
              any applicable legal or regulatory requirement; and (iii) claims arising under
              consumer protection or similar legislation; and all such claims are governed solely by
              this Client Agreement and any law applicable to Everdays as provider of the software.
              You acknowledge that, in the event of any third-party claim that the Apple-Sourced
              Software or your possession and use of that Apple-Sourced Software infringes that
              third party’s intellectual property rights, Everdays, not Apple, will be solely
              responsible for the investigation, defense, settlement and discharge of any such
              intellectual property infringement claim to the extent required by this Client
              Agreement. You and Everdays acknowledge and agree that Apple, and Apple’s
              subsidiaries, are third-party beneficiaries of this Client Agreement as relates to
              your license of the Apple-Sourced Software, and that, upon your acceptance of the
              terms and conditions of this Client Agreement, Apple will have the right (and will be
              deemed to have accepted the right) to enforce this Client Agreement as relates to your
              license of the Apple-Sourced Software against you as a third-party beneficiary
              thereof.
            </p>
            <h4>25.3 Mobile Applications from Google Play Store</h4>
            <p>
              The following applies to any Mobile Applications you acquire from the Google Play
              Store (“<b>Google-Sourced Software</b>”): (i) you acknowledge that the Client
              Agreement is between you and Everdays only, and not with Google, Inc. (“<b>Google</b>
              ”); (ii) your use of Google-Sourced Software must comply with Google’s then-current
              Google Play Store Terms of Service; (iii) Google is only a provider of the Google Play
              Store where you obtained the Google-Sourced Software; (iv) Everdays, and not Google,
              is solely responsible for its Google-Sourced Software; (v) Google has no obligation or
              liability to you with respect to Google-Sourced Software or the Client Agreement; and
              (vi) you acknowledge and agree that Google is a third-party beneficiary to the Client
              Agreement as it relates to Everdays’ Google-Sourced Software.
            </p>
          </section>
          <section>
            <h3>26. GENERAL</h3>
            <h4>26.1 Assignment</h4>
            <p>
              This Client Agreement, and any rights and licenses granted hereunder, may not be
              transferred or assigned by you, but may be assigned by Everdays without restriction.
              Any attempted transfer or assignment in violation hereof shall be null and void.
            </p>
            <h4>26.2 Notification Procedures and Changes to the Agreement</h4>
            <p>
              Everdays may provide notifications, whether such notifications are required by law or
              are for marketing or other business-related purposes, to you via email notice, written
              or hard copy notice, or through posting of such notice on our website, as determined
              by Everdays in our sole discretion. Everdays reserves the right to determine the form
              and means of providing notifications to our Clients, provided that you may opt out of
              certain means of notification as described in this Client Agreement. Everdays is not
              responsible for any automatic filtering you or your network provider may apply to
              email notifications we send to the email address you provide us. Everdays may, in its
              sole discretion, modify or update this Client Agreement from time to time, and so you
              should review this page periodically. When we change the Client Agreement in a
              material manner, we will update the ‘last modified’ date at the top of this page and
              notify you that material changes have been made to the Client Agreement. Your
              continued use of the Service after any such change constitutes your acceptance of the
              new Client Agreement. If you do not agree to any of these terms or any future Client
              Agreement, do not use or access (or continue to access) the Service.
            </p>
            <h4>26.3 Entire Agreement/Severability</h4>
            <p>
              This Client Agreement, together with the Client 
              <a href="/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
              , Client User Terms of Service, User Terms of Service and User Privacy Policy, and any
              amendments and any additional agreements you may enter into with Everdays in
              connection with the Service, shall constitute the entire agreement between you and
              Everdays concerning the Service. If any provision of this Client Agreement is deemed
              invalid by a court of competent jurisdiction, the invalidity of such provision shall
              not affect the validity of the remaining provisions of this Client Agreement, which
              shall remain in full force and effect, except that in the event of unenforceability of
              the universal Class Action/Jury Trial Waiver, the entire arbitration agreement shall
              be unenforceable.
            </p>
            <h4>26.4 No Waiver</h4>
            <p>
              No waiver of any term of this Client Agreement shall be deemed a further or continuing
              waiver of such term or any other term, and Everdays’ failure to assert any right or
              provision under this Client Agreement shall not constitute a waiver of such right or
              provision.
            </p>
            <h4>26.5 Contact</h4>
            <p>
              Please contact us at <a href="mailto:legal@everdays.com">legal@everdays.com</a> with
              any questions regarding this Client Agreement.
            </p>
          </section>
        </div>
      </div>
      <PagesFooter
        currentPage="client-agreement"
        pathname={location.pathname}
        queryString={location.search}
      />
      {renderAuthModal(user, location, children, { dlHideAuth: true, hasOptions: true })}
    </>
  );
};

export default ClientAgreement;
