"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EVENT_UNLIKE_FAILURE = exports.EVENT_UNLIKE_SUCCESS = exports.EVENT_UNLIKE_REQUEST = exports.EVENT_LIKE_FAILURE = exports.EVENT_LIKE_SUCCESS = exports.EVENT_LIKE_REQUEST = void 0;
var EVENT_LIKE_REQUEST = 'EVENT_LIKE_REQUEST';
exports.EVENT_LIKE_REQUEST = EVENT_LIKE_REQUEST;
var EVENT_LIKE_SUCCESS = 'EVENT_LIKE_SUCCESS';
exports.EVENT_LIKE_SUCCESS = EVENT_LIKE_SUCCESS;
var EVENT_LIKE_FAILURE = 'EVENT_LIKE_FAILURE';
exports.EVENT_LIKE_FAILURE = EVENT_LIKE_FAILURE;
var EVENT_UNLIKE_REQUEST = 'EVENT_UNLIKE_REQUEST';
exports.EVENT_UNLIKE_REQUEST = EVENT_UNLIKE_REQUEST;
var EVENT_UNLIKE_SUCCESS = 'EVENT_UNLIKE_SUCCESS';
exports.EVENT_UNLIKE_SUCCESS = EVENT_UNLIKE_SUCCESS;
var EVENT_UNLIKE_FAILURE = 'EVENT_UNLIKE_FAILURE';
exports.EVENT_UNLIKE_FAILURE = EVENT_UNLIKE_FAILURE;