"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESET_ERROR = exports.RESET_META = exports.RESET_PASSWORD_FAILURE = exports.RESET_PASSWORD_SUCCESS = exports.RESET_PASSWORD_REQUEST = exports.FB_LOGIN_FAILURE = exports.FB_LOGIN_SUCCESS = exports.FB_LOGIN_REQUEST = exports.SIMPLE_SIGNUP_WITH_PASSWORD_FAILURE = exports.SIMPLE_SIGNUP_WITH_PASSWORD_SUCCESS = exports.SIMPLE_SIGNUP_WITH_PASSWORD_REQUEST = exports.SIMPLE_SIGNUP_FAILURE = exports.SIMPLE_SIGNUP_SUCCESS = exports.SIMPLE_SIGNUP_REQUEST = exports.APP_SIGNUP_FAILURE = exports.APP_SIGNUP_SUCCESS = exports.APP_SIGNUP_REQUEST = exports.APP_LOGIN_FAILURE = exports.APP_LOGIN_SUCCESS = exports.APP_LOGIN_REQUEST = exports.DASH_LOGIN_FAILURE = exports.DASH_LOGIN_SUCCESS = exports.DASH_LOGIN_REQUEST = void 0;
var DASH_LOGIN_REQUEST = 'DASH_LOGIN_REQUEST';
exports.DASH_LOGIN_REQUEST = DASH_LOGIN_REQUEST;
var DASH_LOGIN_SUCCESS = 'DASH_LOGIN_SUCCESS';
exports.DASH_LOGIN_SUCCESS = DASH_LOGIN_SUCCESS;
var DASH_LOGIN_FAILURE = 'DASH_LOGIN_FAILURE';
exports.DASH_LOGIN_FAILURE = DASH_LOGIN_FAILURE;
var APP_LOGIN_REQUEST = 'APP_LOGIN_REQUEST';
exports.APP_LOGIN_REQUEST = APP_LOGIN_REQUEST;
var APP_LOGIN_SUCCESS = 'APP_LOGIN_SUCCESS';
exports.APP_LOGIN_SUCCESS = APP_LOGIN_SUCCESS;
var APP_LOGIN_FAILURE = 'APP_LOGIN_FAILURE';
exports.APP_LOGIN_FAILURE = APP_LOGIN_FAILURE;
var APP_SIGNUP_REQUEST = 'APP_SIGNUP_REQUEST';
exports.APP_SIGNUP_REQUEST = APP_SIGNUP_REQUEST;
var APP_SIGNUP_SUCCESS = 'APP_SIGNUP_SUCCESS';
exports.APP_SIGNUP_SUCCESS = APP_SIGNUP_SUCCESS;
var APP_SIGNUP_FAILURE = 'APP_SIGNUP_FAILURE';
exports.APP_SIGNUP_FAILURE = APP_SIGNUP_FAILURE;
var SIMPLE_SIGNUP_REQUEST = 'SIMPLE_SIGNUP_REQUEST';
exports.SIMPLE_SIGNUP_REQUEST = SIMPLE_SIGNUP_REQUEST;
var SIMPLE_SIGNUP_SUCCESS = 'SIMPLE_SIGNUP_SUCCESS';
exports.SIMPLE_SIGNUP_SUCCESS = SIMPLE_SIGNUP_SUCCESS;
var SIMPLE_SIGNUP_FAILURE = 'SIMPLE_SIGNUP_FAILURE';
exports.SIMPLE_SIGNUP_FAILURE = SIMPLE_SIGNUP_FAILURE;
var SIMPLE_SIGNUP_WITH_PASSWORD_REQUEST = 'SIMPLE_SIGNUP_WITH_PASSWORD_REQUEST';
exports.SIMPLE_SIGNUP_WITH_PASSWORD_REQUEST = SIMPLE_SIGNUP_WITH_PASSWORD_REQUEST;
var SIMPLE_SIGNUP_WITH_PASSWORD_SUCCESS = 'SIMPLE_SIGNUP_WITH_PASSWORD_SUCCESS';
exports.SIMPLE_SIGNUP_WITH_PASSWORD_SUCCESS = SIMPLE_SIGNUP_WITH_PASSWORD_SUCCESS;
var SIMPLE_SIGNUP_WITH_PASSWORD_FAILURE = 'SIMPLE_SIGNUP_WITH_PASSWORD_FAILURE';
exports.SIMPLE_SIGNUP_WITH_PASSWORD_FAILURE = SIMPLE_SIGNUP_WITH_PASSWORD_FAILURE;
var FB_LOGIN_REQUEST = 'FB_LOGIN_REQUEST';
exports.FB_LOGIN_REQUEST = FB_LOGIN_REQUEST;
var FB_LOGIN_SUCCESS = 'FB_LOGIN_SUCCESS';
exports.FB_LOGIN_SUCCESS = FB_LOGIN_SUCCESS;
var FB_LOGIN_FAILURE = 'FB_LOGIN_FAILURE';
exports.FB_LOGIN_FAILURE = FB_LOGIN_FAILURE;
var RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
exports.RESET_PASSWORD_REQUEST = RESET_PASSWORD_REQUEST;
var RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
exports.RESET_PASSWORD_SUCCESS = RESET_PASSWORD_SUCCESS;
var RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
exports.RESET_PASSWORD_FAILURE = RESET_PASSWORD_FAILURE;
var RESET_META = 'RESET_META';
exports.RESET_META = RESET_META;
var RESET_ERROR = 'RESET_ERROR';
exports.RESET_ERROR = RESET_ERROR;