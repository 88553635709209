"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SET_UPLOAD_PROGRESS = exports.SET_UPLOAD_IN_PROGRESS = exports.STREAM_LIST_UPDATE = exports.INITAL_STREAM_LIST = exports.LIVE_STREAM_CONNECTION_CHANGE = void 0;
var LIVE_STREAM_CONNECTION_CHANGE = 'LIVE_STREAM_CONNECTION_CHANGE';
exports.LIVE_STREAM_CONNECTION_CHANGE = LIVE_STREAM_CONNECTION_CHANGE;
var INITAL_STREAM_LIST = 'INITAL_STREAM_LIST';
exports.INITAL_STREAM_LIST = INITAL_STREAM_LIST;
var STREAM_LIST_UPDATE = 'STREAM_LIST_UPDATE';
exports.STREAM_LIST_UPDATE = STREAM_LIST_UPDATE;
var SET_UPLOAD_IN_PROGRESS = 'SET_UPLOAD_IN_PROGRESS';
exports.SET_UPLOAD_IN_PROGRESS = SET_UPLOAD_IN_PROGRESS;
var SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
exports.SET_UPLOAD_PROGRESS = SET_UPLOAD_PROGRESS;