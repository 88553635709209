"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _redux = require("redux");

var _reactReduxForm = require("react-redux-form");

var profile = {
  name: {
    first: '',
    last: ''
  },
  email: '',
  shellEmail: '',
  fbEmail: '',
  phone: '',
  title: '',
  bio: '',
  shirtSize: '',
  image: ''
};
var details = {
  prefs: {
    sms: false,
    push: false,
    email: false
  },
  hasPassword: false,
  meta: {
    isRep: false,
    everdaysAdmin: false,
    everdaysAdminJourneys: false
  },
  isAFP: false,
  isCompanyAdmin: false,
  isBanned: false,
  isArchived: false
};
var password = {
  existingPassword: '',
  newPassword: '',
  confirmPassword: ''
};
var userDetails = (0, _redux.combineReducers)({
  forms: (0, _reactReduxForm.combineForms)({
    profile: profile,
    details: details,
    password: password
  }, 'dash.userDetails.forms', {
    key: 'userDetailsMeta'
  })
});
var _default = userDetails;
exports.default = _default;