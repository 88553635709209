"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePageHeightListener = exports.usePageWidthListener = void 0;

var _react = require("react");

var _reactRedux = require("react-redux");

var _throttle = _interopRequireDefault(require("lodash-es/throttle"));

var _pageSize = require("@evdy/web-redux/dist/lib/pageSize");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var setPageWidth = _pageSize.pageSizeOperations.setPageWidth,
    setPageHeight = _pageSize.pageSizeOperations.setPageHeight;

var usePageWidthListener = function usePageWidthListener(customThrottle) {
  var dispatch = (0, _reactRedux.useDispatch)();

  var handleResize = function handleResize() {
    dispatch(setPageWidth(window.innerWidth));
  };

  (0, _react.useEffect)(function () {
    // on mount, set page width
    dispatch(setPageWidth(window.innerWidth));
    var throttledResize = (0, _throttle.default)(handleResize, 200, {
      trailing: true
    });
    window.addEventListener('resize', throttledResize);
    return function () {
      window.removeEventListener('resize', throttledResize);
    };
  }, [dispatch]);
};

exports.usePageWidthListener = usePageWidthListener;

var usePageHeightListener = function usePageHeightListener() {
  var dispatch = (0, _reactRedux.useDispatch)();

  var handleResize = function handleResize() {
    dispatch(setPageHeight(window.innerHeight));
  };

  (0, _react.useEffect)(function () {
    // on mount, set page height
    dispatch(setPageHeight(window.innerHeight));
    var throttledResize = (0, _throttle.default)(handleResize, 200, {
      trailing: true
    });
    window.addEventListener('resize', throttledResize);
    return function () {
      window.removeEventListener('resize', throttledResize);
    };
  }, [dispatch]);
};

exports.usePageHeightListener = usePageHeightListener;