"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FETCH_INBOX_FAILURE = exports.FETCH_INBOX_SUCCESS = exports.FETCH_INBOX_REQUEST = void 0;
var FETCH_INBOX_REQUEST = 'FETCH_INBOX_REQUEST';
exports.FETCH_INBOX_REQUEST = FETCH_INBOX_REQUEST;
var FETCH_INBOX_SUCCESS = 'FETCH_INBOX_SUCCESS';
exports.FETCH_INBOX_SUCCESS = FETCH_INBOX_SUCCESS;
var FETCH_INBOX_FAILURE = 'FETCH_INBOX_FAILURE';
exports.FETCH_INBOX_FAILURE = FETCH_INBOX_FAILURE;