"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useHideIntercom = void 0;

var _react = require("react");

/**
 * Requires adding class to root stylesheet
 *
 * body#memorial.hide-intercom {
 *     #intercom-container {
 *         display: none;
 *     }
 * }
 */
// hide intercom while mounted
var useHideIntercom = function useHideIntercom(bodyId) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$showAfterClose = _ref.showAfterClose,
      showAfterClose = _ref$showAfterClose === void 0 ? true : _ref$showAfterClose;

  (0, _react.useEffect)(function () {
    var rootElement = document.querySelector("body#".concat(bodyId));
    rootElement && rootElement.classList.add('hide-intercom');
    return function () {
      rootElement && showAfterClose && rootElement.classList.remove('hide-intercom');
    };
  }, [bodyId, showAfterClose]);
};

exports.useHideIntercom = useHideIntercom;