"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FETCH_GOOGLE_ADDRESS_FAILURE = exports.FETCH_GOOGLE_ADDRESS_SUCCESS = exports.FETCH_GOOGLE_ADDRESS_REQUEST = void 0;
var FETCH_GOOGLE_ADDRESS_REQUEST = 'FETCH_GOOGLE_ADDRESS_REQUEST';
exports.FETCH_GOOGLE_ADDRESS_REQUEST = FETCH_GOOGLE_ADDRESS_REQUEST;
var FETCH_GOOGLE_ADDRESS_SUCCESS = 'FETCH_GOOGLE_ADDRESS_SUCCESS';
exports.FETCH_GOOGLE_ADDRESS_SUCCESS = FETCH_GOOGLE_ADDRESS_SUCCESS;
var FETCH_GOOGLE_ADDRESS_FAILURE = 'FETCH_GOOGLE_ADDRESS_FAILURE';
exports.FETCH_GOOGLE_ADDRESS_FAILURE = FETCH_GOOGLE_ADDRESS_FAILURE;