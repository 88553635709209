"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetInviteLinkRequest = exports.getInviteLinkFailure = exports.getInviteLinkSuccess = exports.getInviteLinkRequest = exports.logAccessorAppTrackingFailure = exports.logAccessorAppTrackingSuccess = exports.logAccessorAppTrackingRequest = exports.logAdTrackFailure = exports.logAdTrackSuccess = exports.logAdTrackRequest = exports.resetCommentAction = exports.leaveCommentFailure = exports.leaveCommentSuccess = exports.leaveCommentRequest = exports.setShowMoreMessages = exports.resetMessageStaging = exports.setMessageStaging = exports.resetMessageAction = exports.leaveMessageSuccess = exports.leaveMessageFailure = exports.leaveMessageRequest = exports.fetchWebCommunityFailure = exports.fetchWebCommunitySuccess = exports.fetchWebCommunityRequest = void 0;

var types = _interopRequireWildcard(require("./constants"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

// Fetch Invite
var fetchWebCommunityRequest = function fetchWebCommunityRequest() {
  return {
    type: types.FETCH_WEB_COMMUNITY_REQUEST
  };
};

exports.fetchWebCommunityRequest = fetchWebCommunityRequest;

var fetchWebCommunitySuccess = function fetchWebCommunitySuccess(_ref) {
  var data = _ref.data;
  return {
    type: types.FETCH_WEB_COMMUNITY_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.fetchWebCommunitySuccess = fetchWebCommunitySuccess;

var fetchWebCommunityFailure = function fetchWebCommunityFailure(error) {
  return {
    type: types.FETCH_WEB_COMMUNITY_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
}; // Messages


exports.fetchWebCommunityFailure = fetchWebCommunityFailure;

var leaveMessageRequest = function leaveMessageRequest() {
  return {
    type: types.LEAVE_MESSAGE_REQUEST
  };
};

exports.leaveMessageRequest = leaveMessageRequest;

var leaveMessageFailure = function leaveMessageFailure(error) {
  return {
    error: error,
    type: types.LEAVE_MESSAGE_FAILURE,
    receivedAt: Date.now()
  };
};

exports.leaveMessageFailure = leaveMessageFailure;

var leaveMessageSuccess = function leaveMessageSuccess(_ref2) {
  var data = _ref2.data;
  return {
    payload: data,
    type: types.LEAVE_MESSAGE_SUCCESS,
    receivedAt: Date.now()
  };
};

exports.leaveMessageSuccess = leaveMessageSuccess;

var resetMessageAction = function resetMessageAction() {
  return {
    type: types.RESET_MESSAGE
  };
};

exports.resetMessageAction = resetMessageAction;

var setMessageStaging = function setMessageStaging(values) {
  return {
    type: types.STAGE_MESSAGE_VALUES,
    payload: values
  };
};

exports.setMessageStaging = setMessageStaging;

var resetMessageStaging = function resetMessageStaging() {
  return {
    type: types.RESET_MESSAGE_STAGING
  };
};

exports.resetMessageStaging = resetMessageStaging;

var setShowMoreMessages = function setShowMoreMessages(incrementAmount) {
  return {
    type: types.SET_SHOW_MORE_MESSAGES,
    payload: incrementAmount
  };
}; // Comments


exports.setShowMoreMessages = setShowMoreMessages;

var leaveCommentRequest = function leaveCommentRequest() {
  return {
    type: types.POST_COMMUNITY_COMMENT_REQUEST
  };
};

exports.leaveCommentRequest = leaveCommentRequest;

var leaveCommentSuccess = function leaveCommentSuccess(_ref3) {
  var data = _ref3.data,
      mediaId = _ref3.mediaId;
  return {
    payload: data,
    mediaType: 'message',
    mediaId: mediaId,
    type: types.POST_COMMUNITY_COMMENT_SUCCESS,
    receivedAt: Date.now()
  };
};

exports.leaveCommentSuccess = leaveCommentSuccess;

var leaveCommentFailure = function leaveCommentFailure(_ref4) {
  var error = _ref4.error;
  return {
    error: error,
    type: types.POST_COMMUNITY_COMMENT_FAILURE,
    recievedAt: Date.now(),
    mediaType: 'message'
  };
};

exports.leaveCommentFailure = leaveCommentFailure;

var resetCommentAction = function resetCommentAction() {
  return {
    type: types.RESET_COMMENT
  };
}; // Ads


exports.resetCommentAction = resetCommentAction;

var logAdTrackRequest = function logAdTrackRequest() {
  return {
    type: types.LOG_ADS_TRACKING_REQUEST
  };
};

exports.logAdTrackRequest = logAdTrackRequest;

var logAdTrackSuccess = function logAdTrackSuccess() {
  return {
    type: types.LOG_ADS_TRACKING_SUCCESS
  };
};

exports.logAdTrackSuccess = logAdTrackSuccess;

var logAdTrackFailure = function logAdTrackFailure() {
  return {
    type: types.LOG_ADS_TRACKING_FAILURE
  };
}; // Tracking


exports.logAdTrackFailure = logAdTrackFailure;

var logAccessorAppTrackingRequest = function logAccessorAppTrackingRequest() {
  return {
    type: types.TRACK_ACCESSOR_APP_REQUEST
  };
};

exports.logAccessorAppTrackingRequest = logAccessorAppTrackingRequest;

var logAccessorAppTrackingSuccess = function logAccessorAppTrackingSuccess(_ref5) {
  var data = _ref5.data;
  return {
    payload: data,
    type: types.TRACK_ACCESSOR_APP_SUCCESS,
    receivedAt: Date.now()
  };
};

exports.logAccessorAppTrackingSuccess = logAccessorAppTrackingSuccess;

var logAccessorAppTrackingFailure = function logAccessorAppTrackingFailure(error) {
  return {
    error: error,
    type: types.TRACK_ACCESSOR_APP_FAILURE,
    receivedAt: Date.now()
  };
}; // Invite Link


exports.logAccessorAppTrackingFailure = logAccessorAppTrackingFailure;

var getInviteLinkRequest = function getInviteLinkRequest() {
  return {
    type: types.GET_INVITE_LINK_REQUEST
  };
};

exports.getInviteLinkRequest = getInviteLinkRequest;

var getInviteLinkSuccess = function getInviteLinkSuccess() {
  return {
    payload: true,
    type: types.GET_INVITE_LINK_SUCCESS,
    receivedAt: Date.now()
  };
};

exports.getInviteLinkSuccess = getInviteLinkSuccess;

var getInviteLinkFailure = function getInviteLinkFailure(error) {
  return {
    error: error,
    type: types.GET_INVITE_LINK_FAILURE,
    receivedAt: Date.now()
  };
};

exports.getInviteLinkFailure = getInviteLinkFailure;

var resetInviteLinkRequest = function resetInviteLinkRequest() {
  return {
    type: types.RESET_INVITE_LINK
  };
};

exports.resetInviteLinkRequest = resetInviteLinkRequest;