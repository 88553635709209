"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SUBSCRIBE_KLAVIYO_NEWSLETTER_FAILURE = exports.SUBSCRIBE_KLAVIYO_NEWSLETTER_SUCCESS = exports.SUBSCRIBE_KLAVIYO_NEWSLETTER_REQUEST = void 0;
var SUBSCRIBE_KLAVIYO_NEWSLETTER_REQUEST = 'SUBSCRIBE_KLAVIYO_NEWSLETTER_REQUEST';
exports.SUBSCRIBE_KLAVIYO_NEWSLETTER_REQUEST = SUBSCRIBE_KLAVIYO_NEWSLETTER_REQUEST;
var SUBSCRIBE_KLAVIYO_NEWSLETTER_SUCCESS = 'SUBSCRIBE_KLAVIYO_NEWSLETTER_SUCCESS';
exports.SUBSCRIBE_KLAVIYO_NEWSLETTER_SUCCESS = SUBSCRIBE_KLAVIYO_NEWSLETTER_SUCCESS;
var SUBSCRIBE_KLAVIYO_NEWSLETTER_FAILURE = 'SUBSCRIBE_KLAVIYO_NEWSLETTER_FAILURE';
exports.SUBSCRIBE_KLAVIYO_NEWSLETTER_FAILURE = SUBSCRIBE_KLAVIYO_NEWSLETTER_FAILURE;