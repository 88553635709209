"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FETCH_USERS_FAILURE = exports.FETCH_USERS_SUCCESS = exports.FETCH_USERS_REQUEST = void 0;
var FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
exports.FETCH_USERS_REQUEST = FETCH_USERS_REQUEST;
var FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
exports.FETCH_USERS_SUCCESS = FETCH_USERS_SUCCESS;
var FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
exports.FETCH_USERS_FAILURE = FETCH_USERS_FAILURE;