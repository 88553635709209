"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetUpdateAddOns = exports.resetCreatePreneedUser = exports.resetResumePage = exports.resetSelectedFuneralHome = exports.resetUserPreneedDetails = exports.resetUserBirthdayUpdated = exports.resetPlanPackageUpdated = exports.resetSelectedPlan = exports.resetSelectedPackage = exports.resetWebleadUpdated = exports.resetError = exports.resetMeta = exports.resetSubmitOrder = exports.updateAddOns = exports.resumePurchaseFlow = exports.saveLastScreen = exports.submitOrder = exports.userStartSale = exports.updateWebleadData = exports.saveFuneralHomeToUser = exports.updateUserBirthday = exports.updateUserPackageInfo = exports.savePlanToUser = exports.savePackageToUser = exports.confirmOrderDetails = exports.createPreneedUser = exports.saveUserPhone = exports.V2Login = exports.editUserProfile = exports.confirmUser = exports.V2ShellLogin = exports.verifyPin = exports.sendPinAndCall = exports.sendPin = exports.dashLogin = exports.editUserById = exports.loginById = void 0;

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.regexp.to-string.js");

require("core-js/modules/es6.function.name.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

var _merge = _interopRequireDefault(require("lodash/merge"));

var _actions = require("./actions");

var _constants = require("../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var instance = _index.default.create({
  baseURL: _constants.API_URL
}); // Dash Login


var dashLogin = function dashLogin(_ref) {
  var email = _ref.email,
      dashPassword = _ref.dashPassword;
  return function (dispatch) {
    dispatch((0, _actions.dashLoginRequest)());
    return instance.post('/v2/auth/login', {
      email: email,
      dashPassword: dashPassword
    }).then(function (res) {
      return dispatch((0, _actions.dashLoginSuccess)(res));
    }).catch(function (error) {
      return dispatch((0, _actions.dashLoginFailure)((0, _transformAxiosError.default)(error)));
    });
  };
}; // Invited share page login


exports.dashLogin = dashLogin;

var loginById = function loginById(id) {
  return function (dispatch) {
    dispatch((0, _actions.loginByIdRequest)());
    return instance.get("/v2/public/user/".concat(id)).then(function (res) {
      return dispatch((0, _actions.loginByIdSuccess)(res.data));
    }).catch(function (error) {
      return dispatch((0, _actions.loginByIdError)((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.loginById = loginById;

var editUserById = function editUserById(data, id) {
  return function (dispatch) {
    dispatch((0, _actions.editUserByIdRequest)());
    return instance.put("/v2/public/user/".concat(id), data).then(function (res) {
      return dispatch((0, _actions.editUserByIdSuccess)(res.data));
    }).catch(function (error) {
      return dispatch((0, _actions.editUserByIdError)((0, _transformAxiosError.default)(error)));
    });
  };
}; // V2 Login


exports.editUserById = editUserById;

var sendPin = function sendPin(_ref2) {
  var phone = _ref2.phone,
      platform = _ref2.platform;
  return function (dispatch) {
    dispatch((0, _actions.sendPinRequest)());
    return instance.post("/v2/auth/send-pin", {
      phone: phone,
      platform: platform
    }).then(function (res) {
      return dispatch((0, _actions.sendPinSuccess)(res.data));
    }, function (error) {
      return dispatch((0, _actions.sendPinFailure)((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.sendPin = sendPin;

var sendPinAndCall = function sendPinAndCall(_ref3) {
  var phone = _ref3.phone,
      platform = _ref3.platform;
  return function (dispatch) {
    dispatch((0, _actions.sendPinAndCallRequest)());
    return instance.post("/v2/auth/call-pin", {
      phone: phone,
      platform: platform
    }).then(function (res) {
      return dispatch((0, _actions.sendPinAndCallSuccess)(res.data));
    }, function (error) {
      return dispatch((0, _actions.sendPinAndCallFailure)((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.sendPinAndCall = sendPinAndCall;

var verifyPin = function verifyPin(_ref4) {
  var phone = _ref4.phone,
      incomingPin = _ref4.pin,
      dontCreate = _ref4.dontCreate;
  return function (dispatch) {
    dispatch((0, _actions.verifyPinRequest)()); //ensures that pin is a string, since it might be a number

    var pin = incomingPin.toString();
    return instance.post("/v2/auth/verify-pin", {
      phone: phone,
      pin: pin,
      dontCreate: dontCreate
    }).then(function (res) {
      return dispatch((0, _actions.verifyPinSuccess)(res.data));
    }, function (error) {
      return dispatch((0, _actions.verifyPinFailure)((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.verifyPin = verifyPin;

var V2ShellLogin = function V2ShellLogin(_ref5) {
  var email = _ref5.email,
      name = _ref5.name,
      phone = _ref5.phone,
      image = _ref5.image;
  return function (dispatch) {
    dispatch((0, _actions.shellLoginRequest)());
    return instance.post("/v2/auth/shell", {
      email: email,
      name: name,
      phone: phone,
      image: image
    }).then(function (res) {
      return dispatch((0, _actions.shellLoginSuccess)(res.data));
    }, function (error) {
      return dispatch((0, _actions.shellLoginFailure)((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.V2ShellLogin = V2ShellLogin;

var confirmUser = function confirmUser(_ref6) {
  var name = _ref6.name,
      email = _ref6.email,
      image = _ref6.image;
  return function (dispatch) {
    dispatch((0, _actions.confirmUserRequest)());
    return instance.put("/v2/private/user", {
      name: name,
      email: email,
      image: image
    }).then(function (res) {
      return dispatch((0, _actions.confirmUserSuccess)(res.data));
    }, function (error) {
      return dispatch((0, _actions.confirmUserFailure)((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.confirmUser = confirmUser;

var editUserProfile = function editUserProfile(userData) {
  return function (dispatch) {
    dispatch((0, _actions.editProfileUserRequest)());
    return instance.put('/v2/private/user', userData).then(function (res) {
      return dispatch((0, _actions.editProfileUserSuccess)(res.data));
    }, function (err) {
      return dispatch((0, _actions.editProfileUserFailure)(err));
    });
  };
}; // This doesn't appear to be used at the moment - Marc 4.15.2020


exports.editUserProfile = editUserProfile;

var V2Login = function V2Login(_ref7) {
  var phone = _ref7.phone,
      pin = _ref7.pin;
  return function (dispatch) {
    dispatch((0, _actions.V2LoginRequest)());
    return instance.post("/v2/auth/login", {
      phone: phone,
      pin: pin
    }).then(function (res) {
      return dispatch((0, _actions.V2LoginSuccess)(res.data));
    }, function (error) {
      return dispatch((0, _actions.V2LoginFailure)((0, _transformAxiosError.default)(error)));
    });
  };
}; // add phone to user's account


exports.V2Login = V2Login;

var saveUserPhone = function saveUserPhone(_ref8) {
  var phone = _ref8.phone;
  return function (dispatch) {
    dispatch((0, _actions.saveUserPhoneRequest)());
    return instance.post("/v2/private/user/postershellphone", {
      phone: phone
    }).then(function (res) {
      return dispatch((0, _actions.saveUserPhoneSuccess)(res.data));
    }, function (error) {
      return dispatch((0, _actions.saveUserPhoneFailure)((0, _transformAxiosError.default)(error)));
    });
  };
};
/** Purchase Flow */
// create a new preneed user


exports.saveUserPhone = saveUserPhone;

var createPreneedUser = function createPreneedUser(userData) {
  return function (dispatch) {
    dispatch((0, _actions.createPreneedUserRequest)());
    var birthDay = userData.birthDay,
        birthMonth = userData.birthMonth,
        birthYear = userData.birthYear,
        email = userData.email,
        zipcode = userData.zipcode,
        firstName = userData.firstName,
        lastName = userData.lastName,
        preselectedcompany = userData.preselectedcompany;

    var body = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, birthYear && {
      dateOfBirth: new Date(birthYear, birthMonth - 1, birthDay).toISOString()
    }), zipcode && {
      zip: "".concat(zipcode)
    }), preselectedcompany && {
      preselectedcompany: preselectedcompany
    }), {}, {
      email: email,
      firstName: firstName,
      lastName: lastName
    });

    return instance.post('/v2/public/preneed/createuser', body).then(function (res) {
      return dispatch((0, _actions.createPreneedUserSuccess)(res.data));
    }, function (err) {
      return dispatch((0, _actions.createPreneedUserFailure)(err));
    });
  };
}; // update users preneed details


exports.createPreneedUser = createPreneedUser;

var confirmOrderDetails = function confirmOrderDetails(_ref9) {
  var userInfo = _ref9.userInfo,
      values = _ref9.values;
  return function (dispatch) {
    dispatch((0, _actions.updateUserPreneedDetailsRequest)());
    var firstName = values.firstName,
        lastName = values.lastName,
        gender = values.gender,
        birthMonth = values.birthMonth,
        birthDay = values.birthDay,
        birthYear = values.birthYear,
        street1 = values.street1,
        city = values.city,
        state = values.state,
        zip = values.zip,
        phone = values.phone,
        medicaid = values.medicaid,
        plan = values.plan;
    var body = {
      // userInfo will not save previous values if they are not included
      userInfo: (0, _merge.default)(userInfo, {
        name: {
          first: firstName,
          last: lastName
        },
        dateOfBirth: new Date(birthYear, birthMonth - 1, birthDay).toISOString(),
        gender: gender,
        address: {
          street1: street1,
          city: city,
          state: state,
          zip: zip
        },
        phone: +phone
      }),
      medicaid: medicaid,
      plan: plan
    };
    return instance.post('/v2/private/preneed/edituser', body).then(function (res) {
      return dispatch((0, _actions.updateUserPreneedDetailsSuccess)(res.data));
    }, function (err) {
      return dispatch((0, _actions.updateUserPreneedDetailsFailure)(err));
    });
  };
}; // update selected package on user


exports.confirmOrderDetails = confirmOrderDetails;

var savePackageToUser = function savePackageToUser(packageId) {
  return function (dispatch) {
    dispatch((0, _actions.savePackageToUserRequest)());
    return instance.post('/v2/private/preneed/edituser', {
      packageId: packageId
    }).then(function (res) {
      return dispatch((0, _actions.savePackageToUserSuccess)(res.data));
    }, function (err) {
      return dispatch((0, _actions.savePackageToUserFailure)(err));
    });
  };
}; // update selected plan on user


exports.savePackageToUser = savePackageToUser;

var savePlanToUser = function savePlanToUser(plan) {
  return function (dispatch) {
    dispatch((0, _actions.savePlanToUserRequest)());
    return instance.post('/v2/private/preneed/edituser', {
      plan: plan
    }).then(function (res) {
      return dispatch((0, _actions.savePlanToUserSuccess)(res.data));
    }, function (err) {
      return dispatch((0, _actions.savePlanToUserFailure)(err));
    });
  };
}; // update package and terms on user.preneed


exports.savePlanToUser = savePlanToUser;

var updateUserPackageInfo = function updateUserPackageInfo(userData) {
  return function (dispatch) {
    dispatch((0, _actions.updateUserPackageRequest)());
    return instance.post('/v2/private/preneed/edituser', _objectSpread({}, userData)).then(function (res) {
      return dispatch((0, _actions.updateUserPackageSuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.updateUserPackageFailure)((0, _transformAxiosError.default)(err)));
    });
  };
}; // update user birthday


exports.updateUserPackageInfo = updateUserPackageInfo;

var updateUserBirthday = function updateUserBirthday(_ref10) {
  var userInfo = _ref10.userInfo,
      values = _ref10.values;
  return function (dispatch) {
    dispatch((0, _actions.updateUserBirthdayRequest)());
    var birthMonth = values.birthMonth,
        birthDay = values.birthDay,
        birthYear = values.birthYear;
    var body = {
      // userInfo will not save previous values if they are not included
      userInfo: (0, _merge.default)(userInfo, {
        dateOfBirth: new Date(birthYear, birthMonth - 1, birthDay).toISOString()
      })
    };
    return instance.post('/v2/private/preneed/edituser', body).then(function (res) {
      return dispatch((0, _actions.updateUserBirthdaySuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.updateUserBirthdayFailure)((0, _transformAxiosError.default)(err)));
    });
  };
}; // upate funearlHome on user.preneed


exports.updateUserBirthday = updateUserBirthday;

var saveFuneralHomeToUser = function saveFuneralHomeToUser(_ref11) {
  var funeralHome = _ref11.funeralHome;
  return function (dispatch) {
    dispatch((0, _actions.userSaveFuneralHomeRequest)());
    return instance.post('/v2/private/preneed/editUser', {
      funeralHome: funeralHome
    }).then(function (res) {
      return dispatch((0, _actions.userSaveFuneralHomeSuccess)(res));
    }).catch(function (err) {
      return (0, _actions.userSaveFuneralHomeFailure)((0, _transformAxiosError.default)(err));
    });
  };
}; // Update eFuneral data on user


exports.saveFuneralHomeToUser = saveFuneralHomeToUser;

var updateWebleadData = function updateWebleadData(userData) {
  return function (dispatch) {
    dispatch((0, _actions.updateWebleadDataRequest)());
    return instance.put('/v2/private/user', userData).then(function (res) {
      return dispatch((0, _actions.updateWebleadDataSuccess)(res.data));
    }, function (err) {
      return dispatch((0, _actions.updateWebleadDataFailure)(err));
    });
  };
}; // start sale //def not used since we moved to v13 with user.preneed


exports.updateWebleadData = updateWebleadData;

var userStartSale = function userStartSale(_ref12) {
  var userId = _ref12.userId,
      funeralHomeId = _ref12.funeralHomeId,
      preneedPackageId = _ref12.preneedPackageId,
      paymentOption = _ref12.paymentOption;
  return function (dispatch) {
    dispatch((0, _actions.userStartSaleRequest)());
    return instance.post("/web/v1/public/buypreneed/startsale", {
      userId: userId,
      funeralHomeId: funeralHomeId,
      preneedPackageId: preneedPackageId,
      paymentOption: paymentOption
    }).then(function (res) {
      return dispatch((0, _actions.userStartSaleSuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.userStartSaleFailure)((0, _transformAxiosError.default)(err)));
    });
  };
}; // Submit order


exports.userStartSale = userStartSale;

var submitOrder = function submitOrder(_ref13) {
  var userInfo = _ref13.userInfo,
      values = _ref13.values;
  return function (dispatch) {
    dispatch((0, _actions.submitOrderRequest)());
    var firstName = values.firstName,
        lastName = values.lastName,
        gender = values.gender,
        birthMonth = values.birthMonth,
        birthDay = values.birthDay,
        birthYear = values.birthYear,
        street1 = values.street1,
        city = values.city,
        state = values.state,
        zip = values.zip,
        phone = values.phone,
        medicaid = values.medicaid,
        plan = values.plan;
    var body = {
      // userInfo will not save previous values if they are not included
      userInfo: (0, _merge.default)(userInfo, {
        name: {
          first: firstName,
          last: lastName
        },
        dateOfBirth: new Date(birthYear, birthMonth - 1, birthDay).toISOString(),
        gender: gender,
        address: {
          street1: street1,
          city: city,
          state: state,
          zip: zip
        },
        phone: +phone
      }),
      medicaid: medicaid,
      plan: plan
    };
    return instance.post("/v2/private/preneed/submitorder", body).then(function (res) {
      return dispatch((0, _actions.submitOrderSuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.submitOrderFailure)((0, _transformAxiosError.default)(err)));
    });
  };
}; //lastScreen on User


exports.submitOrder = submitOrder;

var saveLastScreen = function saveLastScreen(_ref14) {
  var screen = _ref14.screen;
  return function (dispatch) {
    dispatch((0, _actions.userUpdateLastScreenRequest)());
    return instance.post('/v2/private/preneed/viewedscreen', {
      from: 'web',
      screen: screen
    }).then(function (res) {
      return dispatch((0, _actions.userUpdateLastScreenSuccess)(res));
    }).catch(function (err) {
      return dispatch((0, _actions.userUpdateLastScreenFailure)((0, _transformAxiosError.default)(err)));
    });
  };
}; // resume purchase flow


exports.saveLastScreen = saveLastScreen;

var resumePurchaseFlow = function resumePurchaseFlow(_ref15) {
  var userId = _ref15.userId;
  return function (dispatch) {
    dispatch((0, _actions.resumePurchaseFlowRequest)());
    return instance.get("/v2/public/preneed/resume?userId=".concat(userId)).then(function (res) {
      return dispatch((0, _actions.resumePurchaseFlowSuccess)(res));
    }).catch(function (err) {
      return dispatch((0, _actions.resumePurchaseFlowFailure)((0, _transformAxiosError.default)(err)));
    });
  };
}; // update add ons


exports.resumePurchaseFlow = resumePurchaseFlow;

var updateAddOns = function updateAddOns(_ref16) {
  var updatedAddOns = _ref16.updatedAddOns;
  return function (dispatch) {
    dispatch((0, _actions.updateAddOnsRequest)());
    return instance.post('/v2/private/preneed/edituser', {
      addOns: updatedAddOns
    }).then(function (res) {
      return dispatch((0, _actions.updateAddOnsSuccess)(res));
    }).catch(function (err) {
      return (0, _actions.updateAddOnsFailure)((0, _transformAxiosError.default)(err));
    });
  };
}; // Resets


exports.updateAddOns = updateAddOns;

var resetMeta = function resetMeta() {
  return function (dispatch) {
    return dispatch((0, _actions.resetMetaAction)());
  };
};

exports.resetMeta = resetMeta;

var resetError = function resetError() {
  return function (dispatch) {
    return dispatch((0, _actions.resetErrorAction)());
  };
};

exports.resetError = resetError;

var resetWebleadUpdated = function resetWebleadUpdated() {
  return function (dispatch) {
    return dispatch((0, _actions.resetWebleadUpdatedAction)());
  };
};

exports.resetWebleadUpdated = resetWebleadUpdated;

var resetSelectedPackage = function resetSelectedPackage() {
  return function (dispatch) {
    return dispatch((0, _actions.resetSelectedPackageAction)());
  };
};

exports.resetSelectedPackage = resetSelectedPackage;

var resetSelectedPlan = function resetSelectedPlan() {
  return function (dispatch) {
    return dispatch((0, _actions.resetSelectedPlanAction)());
  };
};

exports.resetSelectedPlan = resetSelectedPlan;

var resetPlanPackageUpdated = function resetPlanPackageUpdated() {
  return function (dispatch) {
    return dispatch((0, _actions.resetPlanPackageUpdatedAction)());
  };
};

exports.resetPlanPackageUpdated = resetPlanPackageUpdated;

var resetUserBirthdayUpdated = function resetUserBirthdayUpdated() {
  return function (dispatch) {
    return dispatch((0, _actions.resetUserBirthdayUpdatedAction)());
  };
};

exports.resetUserBirthdayUpdated = resetUserBirthdayUpdated;

var resetUserPreneedDetails = function resetUserPreneedDetails() {
  return function (dispatch) {
    return dispatch((0, _actions.resetUserPreneedDetailsAction)());
  };
};

exports.resetUserPreneedDetails = resetUserPreneedDetails;

var resetSelectedFuneralHome = function resetSelectedFuneralHome() {
  return function (dispatch) {
    return dispatch((0, _actions.resetUserSelectHomeAction)());
  };
};

exports.resetSelectedFuneralHome = resetSelectedFuneralHome;

var resetSubmitOrder = function resetSubmitOrder() {
  return function (dispatch) {
    return dispatch((0, _actions.resetSubmitOrderAction)());
  };
};

exports.resetSubmitOrder = resetSubmitOrder;

var resetResumePage = function resetResumePage() {
  return function (dispatch) {
    return dispatch((0, _actions.resumePurchaseReset)());
  };
};

exports.resetResumePage = resetResumePage;

var resetCreatePreneedUser = function resetCreatePreneedUser() {
  return function (dispatch) {
    return dispatch((0, _actions.resetCreatePreneedUserAction)());
  };
};

exports.resetCreatePreneedUser = resetCreatePreneedUser;

var resetUpdateAddOns = function resetUpdateAddOns() {
  return function (dispatch) {
    return dispatch((0, _actions.resetUpdateAddOnsAction)());
  };
};

exports.resetUpdateAddOns = resetUpdateAddOns;