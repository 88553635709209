"use strict";

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.from.js");

require("core-js/modules/es6.array.slice.js");

require("core-js/modules/es6.set.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.array.map.js");

require("core-js/modules/es7.object.values.js");

require("core-js/modules/es6.regexp.constructor.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.function.name.js");

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.string.includes.js");

require("core-js/modules/es7.array.includes.js");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var states = {
  AL: {
    normalized: 'AL'
  },
  al: {
    normalized: 'AL'
  },
  Alabama: {
    normalized: 'AL'
  },
  alabama: {
    normalized: 'AL'
  },
  AK: {
    normalized: 'AK'
  },
  ak: {
    normalized: 'AK'
  },
  Alaska: {
    normalized: 'AK'
  },
  alaska: {
    normalized: 'AK'
  },
  AZ: {
    normalized: 'AZ'
  },
  az: {
    normalized: 'AZ'
  },
  Arizona: {
    normalized: 'AZ'
  },
  arizona: {
    normalized: 'AZ'
  },
  AR: {
    normalized: 'AR'
  },
  ar: {
    normalized: 'AR'
  },
  Arkansas: {
    normalized: 'AR'
  },
  arkansas: {
    normalized: 'AR'
  },
  CA: {
    normalized: 'CA'
  },
  ca: {
    normalized: 'CA'
  },
  California: {
    normalized: 'CA'
  },
  california: {
    normalized: 'CA'
  },
  CO: {
    normalized: 'CO'
  },
  co: {
    normalized: 'CO'
  },
  Colorado: {
    normalized: 'CO'
  },
  colorado: {
    normalized: 'CO'
  },
  CT: {
    normalized: 'CT'
  },
  ct: {
    normalized: 'CT'
  },
  Connecticut: {
    normalized: 'CT'
  },
  connecticut: {
    normalized: 'CT'
  },
  DE: {
    normalized: 'DE'
  },
  de: {
    normalized: 'DE'
  },
  Delaware: {
    normalized: 'DE'
  },
  delaware: {
    normalized: 'DE'
  },
  dc: {
    normalized: 'DC'
  },
  DC: {
    normalized: 'DC'
  },
  'washington dc': {
    normalized: 'DC'
  },
  'Washington DC': {
    normalized: 'DC'
  },
  'District of Columbia': {
    normalized: 'DC'
  },
  'district of columbia': {
    normalized: 'DC'
  },
  FL: {
    normalized: 'FL'
  },
  fl: {
    normalized: 'FL'
  },
  Florida: {
    normalized: 'FL'
  },
  florida: {
    normalized: 'FL'
  },
  GA: {
    normalized: 'GA'
  },
  ga: {
    normalized: 'GA'
  },
  Georgia: {
    normalized: 'GA'
  },
  georgia: {
    normalized: 'GA'
  },
  HI: {
    normalized: 'HI'
  },
  hi: {
    normalized: 'HI'
  },
  Hawaii: {
    normalized: 'HI'
  },
  hawaii: {
    normalized: 'HI'
  },
  ID: {
    normalized: 'ID'
  },
  id: {
    normalized: 'ID'
  },
  Idaho: {
    normalized: 'ID'
  },
  idaho: {
    normalized: 'ID'
  },
  IL: {
    normalized: 'IL'
  },
  il: {
    normalized: 'IL'
  },
  Illinois: {
    normalized: 'IL'
  },
  illinois: {
    normalized: 'IL'
  },
  IN: {
    normalized: 'IN'
  },
  in: {
    normalized: 'IN'
  },
  Indiana: {
    normalized: 'IN'
  },
  indiana: {
    normalized: 'IN'
  },
  IA: {
    normalized: 'IA'
  },
  ia: {
    normalized: 'IA'
  },
  Iowa: {
    normalized: 'IA'
  },
  iowa: {
    normalized: 'IA'
  },
  KS: {
    normalized: 'KS'
  },
  ks: {
    normalized: 'KS'
  },
  Kansas: {
    normalized: 'KS'
  },
  kansas: {
    normalized: 'KS'
  },
  KY: {
    normalized: 'KY'
  },
  ky: {
    normalized: 'KY'
  },
  Kentucky: {
    normalized: 'KY'
  },
  kentucky: {
    normalized: 'KY'
  },
  LA: {
    normalized: 'LA'
  },
  la: {
    normalized: 'LA'
  },
  Louisiana: {
    normalized: 'LA'
  },
  louisiana: {
    normalized: 'LA'
  },
  ME: {
    normalized: 'ME'
  },
  me: {
    normalized: 'ME'
  },
  Maine: {
    normalized: 'ME'
  },
  maine: {
    normalized: 'ME'
  },
  MD: {
    normalized: 'MD'
  },
  md: {
    normalized: 'MD'
  },
  Maryland: {
    normalized: 'MD'
  },
  maryland: {
    normalized: 'MD'
  },
  MA: {
    normalized: 'MA'
  },
  ma: {
    normalized: 'MA'
  },
  Massachusetts: {
    normalized: 'MA'
  },
  massachusetts: {
    normalized: 'MA'
  },
  MI: {
    normalized: 'MI'
  },
  mi: {
    normalized: 'MI'
  },
  Michigan: {
    normalized: 'MI'
  },
  michigan: {
    normalized: 'MI'
  },
  MN: {
    normalized: 'MN'
  },
  mn: {
    normalized: 'MN'
  },
  Minnesota: {
    normalized: 'MN'
  },
  minnesota: {
    normalized: 'MN'
  },
  MS: {
    normalized: 'MS'
  },
  ms: {
    normalized: 'MS'
  },
  Mississippi: {
    normalized: 'MS'
  },
  mississippi: {
    normalized: 'MS'
  },
  MO: {
    normalized: 'MO'
  },
  mo: {
    normalized: 'MO'
  },
  Missouri: {
    normalized: 'MO'
  },
  missouri: {
    normalized: 'MO'
  },
  MT: {
    normalized: 'MT'
  },
  mt: {
    normalized: 'MT'
  },
  Montana: {
    normalized: 'MT'
  },
  montana: {
    normalized: 'MT'
  },
  NE: {
    normalized: 'NE'
  },
  ne: {
    normalized: 'NE'
  },
  Nebraska: {
    normalized: 'NE'
  },
  nebraska: {
    normalized: 'NE'
  },
  NV: {
    normalized: 'NV'
  },
  nv: {
    normalized: 'NV'
  },
  Nevada: {
    normalized: 'NV'
  },
  nevada: {
    normalized: 'NV'
  },
  NH: {
    normalized: 'NH'
  },
  nh: {
    normalized: 'NH'
  },
  'New Hampshire': {
    normalized: 'NH'
  },
  'new hampshire': {
    normalized: 'NH'
  },
  NJ: {
    normalized: 'NJ'
  },
  nj: {
    normalized: 'NJ'
  },
  'New Jersey': {
    normalized: 'NJ'
  },
  'new jersey': {
    normalized: 'NJ'
  },
  NM: {
    normalized: 'NM'
  },
  nm: {
    normalized: 'NM'
  },
  'New Mexico': {
    normalized: 'NM'
  },
  'new mexico': {
    normalized: 'NM'
  },
  NY: {
    normalized: 'NY'
  },
  ny: {
    normalized: 'NY'
  },
  'New York': {
    normalized: 'NY'
  },
  'new york': {
    normalized: 'NY'
  },
  NC: {
    normalized: 'NC'
  },
  nc: {
    normalized: 'NC'
  },
  'North Carolina': {
    normalized: 'NC'
  },
  'north carolina': {
    normalized: 'NC'
  },
  ND: {
    normalized: 'ND'
  },
  nd: {
    normalized: 'ND'
  },
  'North Dakota': {
    normalized: 'ND'
  },
  'north dakota': {
    normalized: 'ND'
  },
  OH: {
    normalized: 'OH'
  },
  oh: {
    normalized: 'OH'
  },
  Ohio: {
    normalized: 'OH'
  },
  ohio: {
    normalized: 'OH'
  },
  OK: {
    normalized: 'OK'
  },
  ok: {
    normalized: 'OK'
  },
  Oklahoma: {
    normalized: 'OK'
  },
  oklahoma: {
    normalized: 'OK'
  },
  OR: {
    normalized: 'OR'
  },
  or: {
    normalized: 'OR'
  },
  Oregon: {
    normalized: 'OR'
  },
  oregon: {
    normalized: 'OR'
  },
  PA: {
    normalized: 'PA'
  },
  pa: {
    normalized: 'PA'
  },
  Pennsylvania: {
    normalized: 'PA'
  },
  pennsylvania: {
    normalized: 'PA'
  },
  RI: {
    normalized: 'RI'
  },
  ri: {
    normalized: 'RI'
  },
  'Rhode Island': {
    normalized: 'RI'
  },
  'rhode island': {
    normalized: 'RI'
  },
  SC: {
    normalized: 'SC'
  },
  sc: {
    normalized: 'SC'
  },
  'South Carolina': {
    normalized: 'SC'
  },
  'south carolina': {
    normalized: 'SC'
  },
  SD: {
    normalized: 'SD'
  },
  sd: {
    normalized: 'SD'
  },
  'South Dakota': {
    normalized: 'SD'
  },
  'south dakota': {
    normalized: 'SD'
  },
  TN: {
    normalized: 'TN'
  },
  tn: {
    normalized: 'TN'
  },
  Tennessee: {
    normalized: 'TN'
  },
  tennessee: {
    normalized: 'TN'
  },
  TX: {
    normalized: 'TX'
  },
  tx: {
    normalized: 'TX'
  },
  Texas: {
    normalized: 'TX'
  },
  texas: {
    normalized: 'TX'
  },
  UT: {
    normalized: 'UT'
  },
  ut: {
    normalized: 'UT'
  },
  Utah: {
    normalized: 'UT'
  },
  utah: {
    normalized: 'UT'
  },
  VT: {
    normalized: 'VT'
  },
  vt: {
    normalized: 'VT'
  },
  Vermont: {
    normalized: 'VT'
  },
  vermont: {
    normalized: 'VT'
  },
  VA: {
    normalized: 'VA'
  },
  va: {
    normalized: 'VA'
  },
  Virginia: {
    normalized: 'VA'
  },
  virginia: {
    normalized: 'VA'
  },
  WA: {
    normalized: 'WA'
  },
  wa: {
    normalized: 'WA'
  },
  Washington: {
    normalized: 'WA'
  },
  washington: {
    normalized: 'WA'
  },
  WV: {
    normalized: 'WV'
  },
  wv: {
    normalized: 'WV'
  },
  'West Virginia': {
    normalized: 'WV'
  },
  'west virginia': {
    normalized: 'WV'
  },
  WI: {
    normalized: 'WI'
  },
  wi: {
    normalized: 'WI'
  },
  Wisconsin: {
    normalized: 'WI'
  },
  wisconsin: {
    normalized: 'WI'
  },
  WY: {
    normalized: 'WY'
  },
  wy: {
    normalized: 'WY'
  },
  Wyoming: {
    normalized: 'WY'
  },
  wyoming: {
    normalized: 'WY'
  }
};
var provinces = {
  Alberta: {
    normalized: 'AB'
  },
  AB: {
    normalized: 'AB'
  },
  ab: {
    normalized: 'AB'
  },
  Labrador: {
    normalized: 'LB'
  },
  LB: {
    normalized: 'LB'
  },
  lb: {
    normalized: 'LB'
  },
  'New Brunswick': {
    normalized: 'NB'
  },
  NB: {
    normalized: 'NB'
  },
  nb: {
    normalized: 'NB'
  },
  'Nova Scotia': {
    normalized: 'NS'
  },
  NS: {
    normalized: 'NS'
  },
  ns: {
    normalized: 'NS'
  },
  'North West Territory': {
    normalized: 'NW'
  },
  NW: {
    normalized: 'NW'
  },
  nw: {
    normalized: 'NW'
  },
  'Prince Edward Island': {
    normalized: 'PE'
  },
  PE: {
    normalized: 'PE'
  },
  pe: {
    normalized: 'PE'
  },
  Saskatchewen: {
    normalized: 'SK'
  },
  SK: {
    normalized: 'SK'
  },
  sk: {
    normalized: 'SK'
  },
  'British Columbia': {
    normalized: 'BC'
  },
  BC: {
    normalized: 'BC'
  },
  bc: {
    normalized: 'BC'
  },
  Manitoba: {
    normalized: 'MB'
  },
  MB: {
    normalized: 'MB'
  },
  mb: {
    normalized: 'MB'
  },
  Newfoundland: {
    normalized: 'NF'
  },
  NF: {
    normalized: 'NF'
  },
  nf: {
    normalized: 'NF'
  },
  Nunavut: {
    normalized: 'NU'
  },
  NU: {
    normalized: 'NU'
  },
  nu: {
    normalized: 'NU'
  },
  Ontario: {
    normalized: 'ON'
  },
  ON: {
    normalized: 'ON'
  },
  on: {
    normalized: 'ON'
  },
  Quebec: {
    normalized: 'QC'
  },
  QC: {
    normalized: 'QC'
  },
  qc: {
    normalized: 'QC'
  },
  Yukon: {
    normalized: 'YU'
  },
  YU: {
    normalized: 'YU'
  },
  yu: {
    normalized: 'YU'
  }
};

var abbreviatedStatesArray = _toConsumableArray(new Set(Object.values(states).map(function (state) {
  return state.normalized;
})));

var abbreviatedProvincesArray = _toConsumableArray(new Set(Object.values(provinces).map(function (province) {
  return province.normalized;
})));

var stateGroups = {
  AL: {
    group: 0
  },
  AK: {
    group: 1
  },
  AZ: {
    group: 0
  },
  AR: {
    group: 0
  },
  CA: {
    group: 1
  },
  CO: {
    group: 1
  },
  CT: {
    group: 1
  },
  DE: {
    group: 1
  },
  FL: {
    group: 1
  },
  GA: {
    group: 0
  },
  HI: {
    group: 1
  },
  ID: {
    group: 0
  },
  IL: {
    group: 0
  },
  IN: {
    group: 0
  },
  IA: {
    group: 0
  },
  KS: {
    group: 1
  },
  KY: {
    group: 0
  },
  LA: {
    group: 0
  },
  ME: {
    group: 0
  },
  MD: {
    group: 0
  },
  MA: {
    group: 0
  },
  MI: {
    group: 0
  },
  MN: {
    group: 0
  },
  MS: {
    group: 1
  },
  MO: {
    group: 1
  },
  MT: {
    group: 0
  },
  NE: {
    group: 1
  },
  NV: {
    group: 1
  },
  NH: {
    group: 0
  },
  NJ: {
    group: 1
  },
  NM: {
    group: 1
  },
  NY: {
    group: 1
  },
  NC: {
    group: 1
  },
  ND: {
    group: 0
  },
  OH: {
    group: 1
  },
  OK: {
    group: 1
  },
  OR: {
    group: 0
  },
  PA: {
    group: 1
  },
  RI: {
    group: 1
  },
  SC: {
    group: 1
  },
  SD: {
    group: 0
  },
  TN: {
    group: 0
  },
  TX: {
    group: 1
  },
  UT: {
    group: 1
  },
  VT: {
    group: 0
  },
  VA: {
    group: 0
  },
  WA: {
    group: 0
  },
  WV: {
    group: 1
  },
  WI: {
    group: 0
  },
  WY: {
    group: 0
  }
};
var reps = [{
  name: 'Bryan Kata',
  email: 'bkata@everdays.com',
  group: 0
}, {
  name: 'Alexa Lindsay',
  email: 'alindsay@everdays.com',
  group: 1
}];

function getRepSearchStates(searchKey) {
  var searchRegExp = new RegExp(searchKey, 'i');
  var searchGroups = reps.filter(function (_ref) {
    var name = _ref.name;
    return searchRegExp.test(name);
  }).map(function (r) {
    return r.group;
  });
  return Object.keys(stateGroups).filter(function (state) {
    return searchGroups.includes(stateGroups[state].group);
  });
}

function getRepObj(state) {
  return states[state] ? reps[stateGroups[states[state].normalized].group] : reps[reps.length - 1];
}

function getRep(state) {
  return getRepObj(state).name;
}

module.exports = {
  getRep: getRep,
  getRepObj: getRepObj,
  getRepSearchStates: getRepSearchStates,
  states: states,
  provinces: provinces,
  abbreviatedStatesArray: abbreviatedStatesArray,
  abbreviatedProvincesArray: abbreviatedProvincesArray
};