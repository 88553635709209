import React, { useState } from 'react';

import SlideOutMenu from './SlideOutMenu';

import styles from './SiteNav.module.scss';

const {
  'site-nav': siteNavClass,
  hamburger: hamburgerClass,
  'nav-left': navLeftClass,
  'nav-right': navRightClass,
  'everdays-logo': everdaysLogo
} = styles;

const SiteNav = ({ rightNavContent = () => {} }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <div className={siteNavClass}>
      <div className={navLeftClass}>
        <div className={hamburgerClass} onClick={() => setMenuOpen(true)} />
        <a className={everdaysLogo} href="/" />
      </div>
      <div className={navRightClass}>{rightNavContent()}</div>
      <SlideOutMenu isMenuOpen={isMenuOpen} closeMenu={() => setMenuOpen(false)} />
    </div>
  );
};

export default SiteNav;
