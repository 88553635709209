"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var accountInfo = {
  funeralHomeName: '',
  leadTemperature: '',
  insuranceCarrier: '',
  casesPerYear: 0,
  thirdPartyMarketer: '',
  numberOfLocations: 0,
  notes: ''
};
var _default = accountInfo;
exports.default = _default;