"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var talentNetwork = {
  name: '',
  email: '',
  interest: ''
};
var requestDemo = {
  fullName: '',
  fName: '',
  lName: '',
  email: '',
  phone: '',
  fHomeName: ''
};
var _default = {
  talentNetwork: talentNetwork,
  requestDemo: requestDemo
};
exports.default = _default;