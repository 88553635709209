"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _redux = require("redux");

var _preneedPackages = _interopRequireDefault(require("./preneedPackages"));

var _getPrice = _interopRequireDefault(require("./getPrice"));

var _providers = _interopRequireDefault(require("./providers"));

var _purchaseFlow = _interopRequireDefault(require("./purchaseFlow"));

var _calculator = _interopRequireDefault(require("./calculator"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = (0, _redux.combineReducers)({
  preneedPackages: _preneedPackages.default,
  getPrice: _getPrice.default,
  providers: _providers.default,
  purchaseFlow: _purchaseFlow.default,
  // rename to weblead?
  calculator: _calculator.default
});

exports.default = _default;