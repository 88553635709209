import React from 'react';
import classNames from 'classnames';

import styles from './NavDropdown.module.scss';

const {
  'nav-menu-dropdown': navMenuDropdownClass,
  'menu-dropdown-wrapper': menuWrapperClass,
  'menu-spacer': menuSpacerClass,
  'tablet-menu-copy': tabletMenuCopyClass,
  'desktop-menu-copy': desktopMenuClass,
  chevron: chevronClass
} = styles;

const NavBarDropdown = ({
  setIsDropdownOpen,
  isDropdownOpen,
  renderDropdown = () => {},
  customClass,
  customDropdownClass,
  tabletCopy,
  desktopCopy,
  isActive = false
}) => {
  const chevronClasses = classNames(chevronClass, {
    active: !!isDropdownOpen || isActive
  });
  const activeSpacerClasses = classNames({ [menuSpacerClass]: !!isDropdownOpen });
  const tabletMenuCopyClasses = classNames(tabletMenuCopyClass, {
    active: !!isDropdownOpen || isActive
  });
  const desktopMenuCopyClasses = classNames(desktopMenuClass, {
    active: !!isDropdownOpen || isActive
  });
  const menuWrapperClasses = classNames(menuWrapperClass, {
    active: !!isDropdownOpen
  });

  const closeNavOnClick = ({ currentTarget, relatedTarget }) => {
    if (!currentTarget.contains(relatedTarget)) {
      setIsDropdownOpen(false);
    }
  };

  return (
    <div
      className={`${navMenuDropdownClass} ${customClass}`}
      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      onBlur={closeNavOnClick}
      tabIndex={0}
    >
      <p className={tabletMenuCopyClasses}>{tabletCopy}</p>
      <p className={desktopMenuCopyClasses}>{desktopCopy}</p>
      <div className={`${menuWrapperClasses} ${customDropdownClass}`}>{renderDropdown()}</div>
      <div className={chevronClasses} />
      <div className={activeSpacerClasses} />
    </div>
  );
};

export default NavBarDropdown;
