import React from 'react';
import { useSelector } from 'react-redux';

import CharacterProfileImage from '@evdy/web-core/dist/components/shared/elements/CharacterProfileImage';

import styles from './MinimalNav.module.scss';

const {
  'nav-bar-wrapper': navBarWrapperClass,
  'everdays-logo': everdaysLogoClass,
  'left-nav-wrapper': leftSideNavWrapperClass,
  'right-nav-wrapper': rightSideNavWrapperClass,
  'contact-us': contactUsClasses,
  'char-profile': charProfileClass,
  'phone-content': phoneContentClass,
  'intercom-icon': intercomIconClass,
  'page-copy': pageCopyClass,
  divider: dividerClass,
  phone: contactPhoneClass,
  intercom: contactIntercomClass
} = styles;

const ContactUsOptions = () => (
  <div className={contactUsClasses}>
    <a href="tel:8774003297" className={phoneContentClass}>
      <div className={contactPhoneClass} />
      <p>Call Us</p>
    </a>
    <div className={`${contactIntercomClass} intercom-chat`}>
      <div className={intercomIconClass} />
      <p>Live Chat</p>
    </div>
  </div>
);

const MinimalNav = ({ pageCopy = 'Get Estimate' }) => {
  const { userData } = useSelector(({ authUser }) => ({
    userData: authUser.data
  }));
  const { _id: userId, image, name = {} } = userData || {};

  return (
    <div className={navBarWrapperClass}>
      <div className={leftSideNavWrapperClass}>
        <a href="/">
          <div className={everdaysLogoClass} />
        </a>
        <div className={dividerClass} />
        <p className={pageCopyClass}>{pageCopy}</p>
      </div>
      <div className={rightSideNavWrapperClass}>
        <ContactUsOptions {...{ name, image }} />
        {!!userId && (
          <CharacterProfileImage {...{ name, image }} customClasses={[charProfileClass]} />
        )}
      </div>
    </div>
  );
};

export default MinimalNav;
