"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FETCH_COMPANY_MEMORIALS_STATS_FAILURE = exports.FETCH_COMPANY_MEMORIALS_STATS_SUCCESS = exports.FETCH_COMPANY_MEMORIALS_STATS_REQUEST = void 0;
var FETCH_COMPANY_MEMORIALS_STATS_REQUEST = 'FETCH_COMPANY_MEMORIALS_STATS_REQUEST';
exports.FETCH_COMPANY_MEMORIALS_STATS_REQUEST = FETCH_COMPANY_MEMORIALS_STATS_REQUEST;
var FETCH_COMPANY_MEMORIALS_STATS_SUCCESS = 'FETCH_COMPANY_MEMORIALS_STATS_SUCCESS';
exports.FETCH_COMPANY_MEMORIALS_STATS_SUCCESS = FETCH_COMPANY_MEMORIALS_STATS_SUCCESS;
var FETCH_COMPANY_MEMORIALS_STATS_FAILURE = 'FETCH_COMPANY_MEMORIALS_STATS_FAILURE';
exports.FETCH_COMPANY_MEMORIALS_STATS_FAILURE = FETCH_COMPANY_MEMORIALS_STATS_FAILURE;