"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SUBMIT_DEMO_INVITES_RESET_ALL = exports.SUBMIT_DEMO_INVITES_RESET_SUBMIT = exports.SUBMIT_DEMO_INVITES_FAILURE = exports.SUBMIT_DEMO_INVITES_SUCCESS = exports.SUBMIT_DEMO_INVITES_REQUEST = void 0;
var SUBMIT_DEMO_INVITES_REQUEST = 'SUBMIT_DEMO_INVITES_REQUEST';
exports.SUBMIT_DEMO_INVITES_REQUEST = SUBMIT_DEMO_INVITES_REQUEST;
var SUBMIT_DEMO_INVITES_SUCCESS = 'SUBMIT_DEMO_INVITES_SUCCESS';
exports.SUBMIT_DEMO_INVITES_SUCCESS = SUBMIT_DEMO_INVITES_SUCCESS;
var SUBMIT_DEMO_INVITES_FAILURE = 'SUBMIT_DEMO_INVITES_FAILURE';
exports.SUBMIT_DEMO_INVITES_FAILURE = SUBMIT_DEMO_INVITES_FAILURE;
var SUBMIT_DEMO_INVITES_RESET_SUBMIT = 'SUBMIT_DEMO_INVITES_RESET_SUBMIT';
exports.SUBMIT_DEMO_INVITES_RESET_SUBMIT = SUBMIT_DEMO_INVITES_RESET_SUBMIT;
var SUBMIT_DEMO_INVITES_RESET_ALL = 'SUBMIT_DEMO_INVITES_RESET_ALL';
exports.SUBMIT_DEMO_INVITES_RESET_ALL = SUBMIT_DEMO_INVITES_RESET_ALL;