import React from 'react';
import startCase from 'lodash/startCase';
import classNames from 'classnames';

import Breadcrumbs from '../../../shared/Breadcrumbs';

import styles from './WhyHero.module.scss';

import eggShapedCircle from '../../../../public/images/blue-egg-shaped-circle.svg';

const {
  'why-hero': whyHeroClass,
  breadcrumbs: breadcrumbsClass,
  'hero-content': heroContentClass,
  'hero-text': heroTextClass,
  'hero-image': heroImageClass,
  image: imageClass,
  graphic: graphicClass,
  egg: eggClass,
  // page classes for any page-specific stylings for the image graphics
  love: loveClass,
  savings: savingsClass,
  insurance: insuranceClass,
  medicaid: medicaidClass,
  estate: estateClass
} = styles;

const WhyHero = ({
  heroHeader,
  image,
  heroGraphic,
  heroButtonCopy,
  heroButtonHref,
  url,
  name,
  currentPageName
}) => {
  const breadcrumbArray = [
    { pageName: 'Home', href: '/' },
    { pageName: 'Why Everdays', href: '/why' }
  ];

  breadcrumbArray.push({ pageName: startCase(name), href: url });

  const whyHeroClasses = classNames(whyHeroClass, {
    [loveClass]: currentPageName === 'love',
    [savingsClass]: currentPageName === 'savings',
    [insuranceClass]: currentPageName === 'insurance',
    [medicaidClass]: currentPageName === 'medicaid',
    [estateClass]: currentPageName === 'estate'
  });

  return (
    <div className={whyHeroClasses}>
      <div className={heroContentClass}>
        <div className={heroTextClass}>
          <Breadcrumbs crumbTrail={breadcrumbArray} customClass={breadcrumbsClass} />
          <h1>{heroHeader}</h1>
          <button onClick={() => (window.location.href = heroButtonHref)}>{heroButtonCopy}</button>
        </div>
        <div className={heroImageClass}>
          <img className={imageClass} src={image} />
          <img className={eggClass} src={eggShapedCircle} />
          <img className={graphicClass} src={heroGraphic} />
        </div>
      </div>
    </div>
  );
};

export default WhyHero;
