"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UNFOLLOW_MEMORIAL_RESET = exports.UNFOLLOW_MEMORIAL_FAILURE = exports.UNFOLLOW_MEMORIAL_SUCCESS = exports.UNFOLLOW_MEMORIAL_REQUEST = exports.FOLLOW_MEMORIAL_RESET = exports.FOLLOW_MEMORIAL_FAILURE = exports.FOLLOW_MEMORIAL_SUCCESS = exports.FOLLOW_MEMORIAL_REQUEST = void 0;
var FOLLOW_MEMORIAL_REQUEST = 'FOLLOW_MEMORIAL_REQUEST';
exports.FOLLOW_MEMORIAL_REQUEST = FOLLOW_MEMORIAL_REQUEST;
var FOLLOW_MEMORIAL_SUCCESS = 'FOLLOW_MEMORIAL_SUCCESS';
exports.FOLLOW_MEMORIAL_SUCCESS = FOLLOW_MEMORIAL_SUCCESS;
var FOLLOW_MEMORIAL_FAILURE = 'FOLLOW_MEMORIAL_FAILURE';
exports.FOLLOW_MEMORIAL_FAILURE = FOLLOW_MEMORIAL_FAILURE;
var FOLLOW_MEMORIAL_RESET = 'FOLLOW_MEMORIAL_RESET';
exports.FOLLOW_MEMORIAL_RESET = FOLLOW_MEMORIAL_RESET;
var UNFOLLOW_MEMORIAL_REQUEST = 'UNFOLLOW_MEMORIAL_REQUEST';
exports.UNFOLLOW_MEMORIAL_REQUEST = UNFOLLOW_MEMORIAL_REQUEST;
var UNFOLLOW_MEMORIAL_SUCCESS = 'UNFOLLOW_MEMORIAL_SUCCESS';
exports.UNFOLLOW_MEMORIAL_SUCCESS = UNFOLLOW_MEMORIAL_SUCCESS;
var UNFOLLOW_MEMORIAL_FAILURE = 'UNFOLLOW_MEMORIAL_FAILURE';
exports.UNFOLLOW_MEMORIAL_FAILURE = UNFOLLOW_MEMORIAL_FAILURE;
var UNFOLLOW_MEMORIAL_RESET = 'UNFOLLOW_MEMORIAL_RESET';
exports.UNFOLLOW_MEMORIAL_RESET = UNFOLLOW_MEMORIAL_RESET;