import React from 'react';

import styles from './OtherPagesGrid.module.scss';

const { 'page-card': pageCardClass } = styles;

const PageCard = ({ image, name, url, ctaCopy = 'Learn More' }) => {
  return (
    <a href={url} className={pageCardClass}>
      <div style={{ backgroundImage: `url('${image}')` }} />
      <h3>{name}</h3>
      <p>{ctaCopy} &gt;</p>
    </a>
  );
};

const { 'other-pages-grid': otherPagesGridClass, 'page-grid': pageGridClass } = styles;

const OtherPagesGrid = ({ pagesArray }) => {
  return (
    <div className={otherPagesGridClass}>
      <h2>Plan ahead – for your family, your finances & yourself</h2>
      <div className={pageGridClass}>
        {pagesArray?.map(page => (
          <PageCard key={page.name} image={page.image} name={page.name} url={page.url} />
        ))}
      </div>
    </div>
  );
};

export default OtherPagesGrid;
