import React, { useLayoutEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import SiteHero from '../shared/SiteHero';
import MinimalNav from '../MinimalNav';
import FooterSection from '../shared/Footer/FooterSection';
import TermsFooter from '../shared/Footer/TermsFooter';

import { nameString } from '@evdy/web-core/dist/lib/utils';
import { useGetWebleadFromCookie, useHideIntercom } from '../../customHooks';
import { getCookie } from '../../helperFns';

import greyArrow from '../../public/images/grey-curved-arrow-down-right.svg';

import styles from './ScheduleCall.module.scss';

const {
  'schedule-call': scheduleCallClass,
  hero: heroClass,
  'fb-hero': fbHeroClass,
  'calendly-section': calendlySectionClass
} = styles;

const ScheduleCall = ({ location }) => {
  const { authUser = {}, purchaseFlow = {} } = useSelector(({ authUser, webPurchase }) => ({
    authUser,
    purchaseFlow: webPurchase.purchaseFlow
  }));

  // get weblead from cookie
  useGetWebleadFromCookie();

  // Hide Intercom
  useHideIntercom();

  // detect if coming from FB Ad
  const isFromFB = location?.query?.UTM_SOURCE === 'fb_ad';

  // assign most prioritized user data
  const { data: userData } = useMemo(() => {
    return !!authUser?.data?._id ? authUser : purchaseFlow;
  }, [authUser, purchaseFlow]);

  const { _id: userId, email = '', name = {}, meta = {} } = userData ?? {};
  const { webLeadEmail = '' } = meta || {};

  const calendlyUrl =
    process.env.DEPLOY_ENV === 'prod'
      ? 'https://calendly.com/everdays/getmyprice'
      : 'https://calendly.com/admin-408/15min';

  // scroll to top on mount
  useLayoutEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  // add event listener to detect submission
  useLayoutEffect(() => {
    // Calendly docs: https://help.calendly.com/hc/en-us/articles/360020052833-Advanced-embed-options
    const isCalendlyEvent = e => e.data.event && e.data.event.indexOf('calendly') === 0;

    // eslint-disable-next-line no-unused-expressions
    window?.addEventListener('message', e => {
      if (isCalendlyEvent(e)) {
        if (e.data.event === 'calendly.event_scheduled') {
          setTimeout(() => {
            window.location.href = '/schedule-success';
          }, 1000);
        }
      }
    });
  }, []);

  // make API call to Calendly for when coming from FB Ad or coming directly to page
  useLayoutEffect(() => {
    const webleadCookie = getCookie('weblead');
    if (isFromFB || (!isFromFB && !webleadCookie && !userId)) {
      // eslint-disable-next-line no-undef
      Calendly.initInlineWidget({
        url: calendlyUrl,
        parentElement: document.getElementById('calendly-section')
      });
    }
  }, []);

  // make API call to Calendly to load the calendar for non FB Ad with email & name prefill from weblead
  useLayoutEffect(() => {
    if (userId && !isFromFB) {
      // eslint-disable-next-line no-undef
      Calendly.initInlineWidget({
        url: calendlyUrl,
        parentElement: document.getElementById('calendly-section'),
        prefill: {
          // handle prefill once flow is ready
          name: nameString(name),
          email: email || webLeadEmail
        }
      });
    }
  }, [userId, email, name]);

  // if coming from FB, render a different hero
  const renderHero = () => {
    if (isFromFB) {
      return (
        <SiteHero
          heroTitle="Schedule a free consultation"
          heroSubtitle="Set up a time with one of our helpful planning experts to get questions answered, walk through your options, or find out what your services will cost."
          renderMarkup={<img src={greyArrow} />}
          currentPage="schedule-call"
          customClass={fbHeroClass}
        />
      );
    } else {
      return (
        <div className={heroClass}>
          <h1>Schedule a time to talk</h1>
          <h6>
            Simply pick the day and time that works best for you and one of our helpful planning
            experts will give you a call.
          </h6>
        </div>
      );
    }
  };

  return (
    <div className={scheduleCallClass}>
      <MinimalNav pageCopy="free consultation" />
      {renderHero()}
      <div id="calendly-section" className={calendlySectionClass} />
      <FooterSection />
      <TermsFooter />
    </div>
  );
};

export default ScheduleCall;
