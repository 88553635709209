"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  obituary: '',
  familyMessage: '',
  flowers: {
    url: '',
    active: false,
    image: undefined,
    title: undefined,
    button: undefined
  },
  lockedObit: false,
  legacyTouchPIN: ''
};
exports.default = _default;