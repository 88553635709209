import React from 'react';

import { useVerifyCookie } from '../../customHooks';

import styles from './ComparePackages.module.scss';

import rightChev from '../../public/images/icons/chevron-right-navy-blue.svg';

const {
  'compare-packages-section': comparePackagesClass,
  title: titleClass,
  //package detail cards
  'package-cards': packageDetailsCards,
  'pkg-details-card': packageDetailsCardClass,
  'pkg-name': packageNameClass,
  'pkg-main-subtitle': mainCardSubtitleClass,
  'unique-pkg-list': uniqueToThisPackageClass,
  'get-my-price-link': getMyPriceLinkClass,
  'get-my-price-btn': getMyPriceBtnClass,
  'unique-name': uniqueNameClass,
  'view-details': viewDetailsLinkClass,
  check: checkMark
} = styles;

const PackageDetails = ({
  packageTitle,
  comparePackageSubtitle,
  color1,
  color3,
  points,
  name,
  mainCardSubtitle
}) => {
  const ctaLink = useVerifyCookie('rq-sid') ? '/get-my-price' : 'https://try.everdays.com/plan';
  return (
    <div className={packageDetailsCardClass}>
      <h2 className={packageNameClass}>{name}</h2>
      <a
        className={viewDetailsLinkClass}
        href={`/packages/${name?.toLowerCase()?.replace(new RegExp(' ', 'g'), '-')}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        View Details
        <img src={rightChev} alt="pdf-icon" />
      </a>
      <p className={mainCardSubtitleClass}>{mainCardSubtitle}</p>
      <div className={uniqueToThisPackageClass}>{points}</div>
      <a href={ctaLink} className={getMyPriceLinkClass}>
        <button className={getMyPriceBtnClass}>Get My Price</button>
      </a>
    </div>
  );
};

const ComparePackages = ({ title, sectionDescription, preneedPackages, downloadPdfUrl }) => {
  const mapPackageCards = () =>
    //package sort happens on b.e.
    preneedPackages.map(
      ({
        comparisonSection,
        packageSubtitle: mainCardSubtitle,
        name,
        bulletPoints = [],
        colors
      }) => {
        const { title: packageTitle, subtitle: comparePackageSubtitle } = comparisonSection || {};
        const { color1, color3 } = colors || {};
        const points = bulletPoints.map(point => (
          <div className={uniqueNameClass} key={`${name}-${point}`}>
            <div className={checkMark} />
            {point}
          </div>
        ));

        return (
          <PackageDetails
            {...{
              packageTitle,
              comparePackageSubtitle,
              color1,
              color3,
              name,
              mainCardSubtitle,
              points
            }}
            key={name}
          />
        );
      }
    );

  return (
    <div className={comparePackagesClass}>
      <h2 className={titleClass}>{title}</h2>
      <div className={packageDetailsCards}>{mapPackageCards()}</div>
    </div>
  );
};

export default ComparePackages;
