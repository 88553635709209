"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var assetSelection = {
  asset: 'url'
};
var _default = assetSelection;
exports.default = _default;