"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESET_HOME_SUMMARY = exports.DELETE_REVIEW_FAILURE = exports.DELETE_REVIEW_SUCCESS = exports.DELETE_REVIEW_REQUEST = exports.UPDATE_REVIEW_FAILURE = exports.UPDATE_REVIEW_SUCCESS = exports.UPDATE_REVIEW_REQUEST = exports.LEAVE_REVIEW_FAILURE = exports.LEAVE_REVIEW_SUCCESS = exports.LEAVE_REVIEW_REQUEST = exports.FETCH_HOME_SUMMARY_FAILURE = exports.FETCH_HOME_SUMMARY_SUCCESS = exports.FETCH_HOME_SUMMARY_REQUEST = void 0;
var FETCH_HOME_SUMMARY_REQUEST = 'FETCH_HOME_SUMMARY_REQUEST';
exports.FETCH_HOME_SUMMARY_REQUEST = FETCH_HOME_SUMMARY_REQUEST;
var FETCH_HOME_SUMMARY_SUCCESS = 'FETCH_HOME_SUMMARY_SUCCESS';
exports.FETCH_HOME_SUMMARY_SUCCESS = FETCH_HOME_SUMMARY_SUCCESS;
var FETCH_HOME_SUMMARY_FAILURE = 'FETCH_HOME_SUMMARY_FAILURE';
exports.FETCH_HOME_SUMMARY_FAILURE = FETCH_HOME_SUMMARY_FAILURE;
var LEAVE_REVIEW_REQUEST = 'LEAVE_REVIEW_REQUEST';
exports.LEAVE_REVIEW_REQUEST = LEAVE_REVIEW_REQUEST;
var LEAVE_REVIEW_SUCCESS = 'LEAVE_REVIEW_SUCCESS';
exports.LEAVE_REVIEW_SUCCESS = LEAVE_REVIEW_SUCCESS;
var LEAVE_REVIEW_FAILURE = 'LEAVE_REVIEW_FAILURE';
exports.LEAVE_REVIEW_FAILURE = LEAVE_REVIEW_FAILURE;
var UPDATE_REVIEW_REQUEST = 'UPDATE_REVIEW_REQUEST';
exports.UPDATE_REVIEW_REQUEST = UPDATE_REVIEW_REQUEST;
var UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEW_SUCCESS';
exports.UPDATE_REVIEW_SUCCESS = UPDATE_REVIEW_SUCCESS;
var UPDATE_REVIEW_FAILURE = 'UPDATE_REVIEW_FAILURE';
exports.UPDATE_REVIEW_FAILURE = UPDATE_REVIEW_FAILURE;
var DELETE_REVIEW_REQUEST = 'DELETE_REVIEW_REQUEST';
exports.DELETE_REVIEW_REQUEST = DELETE_REVIEW_REQUEST;
var DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS';
exports.DELETE_REVIEW_SUCCESS = DELETE_REVIEW_SUCCESS;
var DELETE_REVIEW_FAILURE = 'DELETE_REVIEW_FAILURE';
exports.DELETE_REVIEW_FAILURE = DELETE_REVIEW_FAILURE;
var RESET_HOME_SUMMARY = 'RESET_HOME_SUMMARY';
exports.RESET_HOME_SUMMARY = RESET_HOME_SUMMARY;