"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _redux = require("redux");

var _createMediaComment = _interopRequireDefault(require("./createMediaComment"));

var _mediaLikes = _interopRequireDefault(require("./mediaLikes"));

var _mediaReport = _interopRequireDefault(require("./mediaReport"));

var _deleteMedia = _interopRequireDefault(require("./deleteMedia"));

var _autofill = _interopRequireDefault(require("./autofill"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var announcement = (0, _redux.combineReducers)({
  createMediaComment: _createMediaComment.default,
  mediaLikes: _mediaLikes.default,
  mediaReport: _mediaReport.default,
  deleteMedia: _deleteMedia.default,
  autofill: _autofill.default
});
var _default = announcement;
exports.default = _default;