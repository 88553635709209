"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _redux = require("redux");

var _reactReduxForm = require("react-redux-form");

var _onboardUser = _interopRequireDefault(require("./onboardUser"));

var _onboardCompany = _interopRequireDefault(require("./onboardCompany"));

var _fetchCompanyTypeahead = _interopRequireDefault(require("./fetchCompanyTypeahead"));

var _start = _interopRequireDefault(require("./start"));

var _requestEmail = _interopRequireDefault(require("./requestEmail"));

var _accountExists = _interopRequireDefault(require("./accountExists"));

var _checkPhone = _interopRequireDefault(require("./checkPhone"));

var _verifyPhone = _interopRequireDefault(require("./verifyPhone"));

var _updateEmail = _interopRequireDefault(require("./updateEmail"));

var _companyName = _interopRequireDefault(require("./companyName"));

var _companyInfo = _interopRequireDefault(require("./companyInfo"));

var _finish = _interopRequireDefault(require("./finish"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// form reducers
var onboarding = (0, _redux.combineReducers)({
  onboardUser: _onboardUser.default,
  onboardCompany: _onboardCompany.default,
  fetchCompanyTypeahead: _fetchCompanyTypeahead.default,
  forms: (0, _reactReduxForm.combineForms)({
    start: _start.default,
    requestEmail: _requestEmail.default,
    accountExists: _accountExists.default,
    checkPhone: _checkPhone.default,
    verifyPhone: _verifyPhone.default,
    updateEmail: _updateEmail.default,
    companyName: _companyName.default,
    companyInfo: _companyInfo.default,
    finish: _finish.default
  }, 'dash.onboarding.forms', {
    key: 'onboardingMeta'
  })
});
var _default = onboarding;
exports.default = _default;