"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useScrollToTop = void 0;

var _react = require("react");

// for scrolling to the top of the page on mount
var useScrollToTop = function useScrollToTop() {
  (0, _react.useEffect)(function () {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
};

exports.useScrollToTop = useScrollToTop;