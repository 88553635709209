import React from 'react';

import styles from './TermsFooter.module.scss';

const {
  'terms-footer': termsFooterClass,
  'terms-footer-content': termsFooterContentClass,
  'terms-and-copyright': termsAndCopyrightClass,
  'terms-and-privacy': termsAndPrivacyClass,
  copyright: copyrightClass,
  disclaimer: disclaimerClass
} = styles;

const TermsFooter = () => {
  return (
    <div className={termsFooterClass}>
      <div className={termsFooterContentClass}>
        <div className={termsAndCopyrightClass}>
          <div className={termsAndPrivacyClass}>
            <a href="/terms">Terms of Service</a>&nbsp;|&nbsp;<a href="/privacy">Privacy Policy</a>
          </div>
          <span className={copyrightClass}>
            © {new Date().getFullYear()} Everdays, Inc. All rights reserved.
          </span>
        </div>
        <p className={disclaimerClass}>
          The Everdays mark and the Everdays logo are registered trademarks of Everdays, Inc.
          <br />* This app is owned and operated by Everdays, Inc., a Delaware corporation doing
          business in California as Everdays Moments Insurance Marketing.
        </p>
      </div>
    </div>
  );
};

export default TermsFooter;
