"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SET_BROWSER = exports.SET_UA = exports.SET_OS = void 0;
var SET_OS = 'SET_OS';
exports.SET_OS = SET_OS;
var SET_UA = 'SET_UA';
exports.SET_UA = SET_UA;
var SET_BROWSER = 'SET_BROWSER';
exports.SET_BROWSER = SET_BROWSER;