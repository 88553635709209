"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useGoogleMaps = void 0;

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.promise.js");

var _constants = require("@evdy/web-redux/dist/constants");

var _utils = require("../lib/utils");

// NOT CURRENTLY USED - MARKED FOR DELETION - Ken 8.26.2020
var src = "https://maps.googleapis.com/maps/api/js?key=".concat(_constants.GMAPS_API_KEY, "&callback=initMap");
var scriptIsLoaded = false;

var useGoogleMaps = function useGoogleMaps() {
  var event = new Event('googleMapsLoaded');

  if ((0, _utils.checkLoadedScripts)(src)) {
    if (scriptIsLoaded) return new Promise(function (res) {
      return res(window.google);
    });else return new Promise(function (res) {
      window.addEventListener('googleMapsLoaded', function () {
        return res(window.google);
      });
    });
  }

  var script = document.createElement('script');
  script.src = src;
  script.defer = true;
  script.async = true;
  document.head.appendChild(script);
  return new Promise(function (res) {
    window.initMap = function () {
      scriptIsLoaded = true;
      window.dispatchEvent(event);
      res(window.google);
    };
  });
};

exports.useGoogleMaps = useGoogleMaps;