import loveImage from '../../../public/images/why-love.png';
import savingsImage from '../../../public/images/why-savings.png';
import insuranceImage from '../../../public/images/why-insurance.png';
import medicaidImage from '../../../public/images/why-medicaid.png';
import estateImage from '../../../public/images/why-estate.png';

import loveGraphicImage from '../../../public/images/love-flower-graphic.svg';
import savingsGraphicImage from '../../../public/images/savings-flower-graphic.svg';
import insuranceGraphicImage from '../../../public/images/insurance-flower-graphic.svg';
import medicaidGraphicImage from '../../../public/images/medicaid-flower-graphic.svg';
import estateGraphicImage from '../../../public/images/estate-flower-graphic.svg';

import presentGraphic from '../../../public/images/present-graphic.svg';
import priceTagGraphic from '../../../public/images/price-tag-graphic-grey.svg';
import umbrellaGraphic from '../../../public/images/umbrella-graphic-grey.svg';
import shieldGraphic from '../../../public/images/heartbeat-shield-graphic.svg';
import journalsGraphic from '../../../public/images/journals-graphic.svg';

const whyPagesData = [
  {
    name: 'love',
    url: '/why/love',
    heroHeader: 'Buying your funeral is an act of love and a gift for your family.',
    heroButtonCopy: 'Get Started',
    heroButtonHref: '/packages',
    image: loveImage,
    heroGraphic: loveGraphicImage,
    sectionGraphic: presentGraphic,
    sectionCopy: [
      {
        header:
          'Put your loved ones on a path to healing and make sure they’ll have what they need to say goodbye with comfort and support.',
        content:
          'When memorial plans haven’t been decided ahead of time, surviving family members often find themselves asking questions like: Did mom want to be cremated? How much does a funeral cost? How are we going to pay for this? What funeral home would dad have wanted to work with? \n\nOver 75% of people are compelled to buy their funerals so their families don’t have to worry about making the decisions themselves during an already difficult time.* When you your own memorial experience you join many others who also want to save their loved ones from these difficult decisions.'
      },
      {
        header:
          'A purchase you’ll make and never see – the most selfless gift you can leave your family and friends.',
        content:
          'The immediate days after the passing of a family member can leave emotions running high, and loved ones in the midst of grief and exhaustion. \n\nWhen the decisions and payments have been made ahead of time, family members can avoid many potential arguments or differences of opinion, allowing more room for support and healing. \n\nYou’ll bring everyone peace of mind as they are surrounded by the guidance of trusted funeral home professionals and participating in the services you’ve lovingly set up for them.'
      }
    ],
    ctaButtonCopy: 'Get Started',
    ctaButtonHref: '/packages'
  },
  {
    name: 'savings',
    url: '/why/savings',
    heroHeader: 'The sooner you buy, the lower your price.',
    heroButtonCopy: 'Get My Price',
    heroButtonHref: '/get-my-price',
    image: savingsImage,
    heroGraphic: savingsGraphicImage,
    sectionGraphic: priceTagGraphic,
    sectionCopy: [
      {
        header: 'Save 40% or more on the cost of your services.',
        content:
          'Funeral and memorial costs are expected to rise by 30-40% from service price increases and inflation over the next 20 years.* \n\nBuying your funeral or memorial now means you pay today’s prices for the services you want. In the future, no matter how long you live after the time of your purchase, it won’t cost your family more to benefit from the supportive services you’ve already put in place.'
      },
      {
        header: 'Lock in the services you want at today’s prices.',
        content:
          'When you choose to buy your funeral or memorial services far in advance, you can avoid rising costs and save yourself and your family thousands of dollars.'
      }
    ],
    ctaButtonCopy: 'Get My Price',
    ctaButtonHref: '/get-my-price'
  },
  {
    // insurance was renamed to protection - Marc 02/23/21
    name: 'protection',
    url: '/why/protection',
    heroHeader: 'Protect your family from the unexpected.',
    heroButtonCopy: 'Get Started',
    heroButtonHref: '/packages',
    image: insuranceImage,
    heroGraphic: insuranceGraphicImage,
    sectionGraphic: umbrellaGraphic,
    sectionCopy: [
      {
        header: 'Be prepared, no matter what.',
        content:
          'Health insurance, retirement savings, college funds – we plan ahead for these things and countless others to make sure loved ones are taken care of, no matter what. \n\nMaking your final arrangements is an equally important way to prepare and make sure your family is protected. One of the greatest comforts of purchasing your own services is knowing that your family will be cared for even in the event of your passing happening unexpectedly.'
      },
      {
        header: 'Take comfort in knowing your family is covered from day one.',
        content:
          'Whether you’re ready to pay for your arrangements outright, or want to buy insurance with low monthly payments, your family is immediately protected from the unforeseen financial burden that so often occurs when having to arrange a funeral in the difficult days after the loss of a loved one.*'
      }
    ],
    ctaButtonCopy: 'Get Started',
    ctaButtonHref: '/packages'
  },
  {
    name: 'medicaid',
    url: '/why/medicaid',
    heroHeader: 'Purchasing your funeral or memorial can help you qualify for Medicaid.',
    heroButtonCopy: 'Get My Price',
    heroButtonHref: '/get-my-price',
    image: medicaidImage,
    heroGraphic: medicaidGraphicImage,
    sectionGraphic: shieldGraphic,
    sectionCopy: [
      {
        header: 'Reduce your assets for Medicaid eligibility.',
        content:
          'There are lots of people looking to qualify for Medicaid, but they have too much income or too many countable assets to get the coverage they need. \n\nFortunately, there is an opportunity to spend this excess income on medical bills and other qualified purchases for Medicaid eligibility purposes.'
      },
      {
        header: 'Qualify for the benefits you need now. ',
        content:
          'When you buy a funeral for yourself or your spouse, the funds you set aside in an irrevocable trust are excluded from your net assets for Medicaid eligibility purposes. This reduces your countable assets and can help you qualify for the benefits you need now.*'
      }
    ],
    ctaButtonCopy: 'Get My Price',
    ctaButtonHref: '/get-my-price'
  },
  {
    name: 'estate',
    url: '/why/estate',
    heroHeader: 'Your estate plan isn’t done until you’ve arranged your memorial services.',
    heroButtonCopy: 'Start Planning',
    heroButtonHref: '/packages',
    image: estateImage,
    heroGraphic: estateGraphicImage,
    sectionGraphic: journalsGraphic,
    sectionCopy: [
      {
        header:
          'Final arrangements should be made along with the rest of your financial and estate planning.',
        content:
          'Outlining funeral instructions are a key element to getting your affairs in order, and including your purchased funeral or memorial arrangements in your estate plan makes it very clear for your family exactly what you wanted. \n\nFor many families, funeral and memorial purchases sadly happen in the immediate days after a passing, during a highly stressful and difficult time. But it doesn’t have to be that way. You can take the time you need now to decide on what services are important to you and how you’d like to pay for them.'
      },
      {
        header: 'Buying your memorial services is an important step to take now.',
        content:
          'Just like wills, trusts, and advance directives, buying your funeral is an important step to take now. Getting everything set up ahead of time ensures your wishes are honored and your family is relieved of a large burden, whenever the time comes.'
      }
    ],
    ctaButtonCopy: 'Start Planning',
    ctaButtonHref: '/packages'
  }
];

export default whyPagesData;
