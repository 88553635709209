"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.requestDemoEmailReset = exports.requestDemoEmailAction = void 0;

var _index = _interopRequireDefault(require("axios/index"));

var _constants = require("../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var instance = _index.default.create({
  baseURL: "".concat(_constants.API_URL)
}); //constants


var REQUEST_DEMO_EMAIL = 'REQUEST_DEMO_EMAIL';
var REQUEST_DEMO_EMAIL_SUCCESS = 'REQUEST_DEMO_EMAIL_SUCCESS';
var REQUEST_DEMO_EMAIL_FAIL = 'REQUEST_DEMO_EMAIL_FAIL';
var REQUEST_DEMO_EMAIL_RESET = 'REQUEST_DEMO_EMAIL_RESET'; //actions

var requestDemoEmail = {
  type: REQUEST_DEMO_EMAIL
};

var demoEmailSuccess = function demoEmailSuccess() {
  return {
    type: REQUEST_DEMO_EMAIL_SUCCESS,
    receivedAt: Date.now()
  };
};

var demoEmailFailure = function demoEmailFailure(err) {
  return {
    type: REQUEST_DEMO_EMAIL_FAIL,
    receivedAt: Date.now(),
    err: err
  };
};

var demoEmailReset = function demoEmailReset() {
  return {
    type: REQUEST_DEMO_EMAIL_RESET
  };
}; //operation


var requestDemoEmailAction = function requestDemoEmailAction(_ref) {
  var fName = _ref.fName,
      lName = _ref.lName,
      email = _ref.email,
      phone = _ref.phone,
      fHomeName = _ref.fHomeName,
      from = _ref.from;
  return function (dispatch) {
    dispatch(requestDemoEmail);
    var body = {
      fName: fName,
      lName: lName,
      email: email,
      phone: phone,
      fHomeName: fHomeName,
      from: from
    };
    return instance.post('/v2/public/get-started', body).then(function (res) {
      return dispatch(demoEmailSuccess());
    }, function (err) {
      return dispatch(demoEmailFailure(err));
    });
  };
};

exports.requestDemoEmailAction = requestDemoEmailAction;

var requestDemoEmailReset = function requestDemoEmailReset() {
  return function (dispatch) {
    dispatch(demoEmailReset);
  };
}; //reducer


exports.requestDemoEmailReset = requestDemoEmailReset;
var initialDemoEmailState = {
  isFetching: false,
  receivedAt: null,
  err: {}
};

var demoEmailReducer = function demoEmailReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialDemoEmailState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case REQUEST_DEMO_EMAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true
      });

    case REQUEST_DEMO_EMAIL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        receivedAt: Date.now(),
        err: {}
      });

    case REQUEST_DEMO_EMAIL_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        err: action.err
      });

    case REQUEST_DEMO_EMAIL_RESET:
      return initialDemoEmailState;

    default:
      return state;
  }
};

var _default = demoEmailReducer;
exports.default = _default;