import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { webCommunityOperations } from '@evdy/web-redux/dist/lib/webCommunity';

const { trackAccessorGetApp } = webCommunityOperations;

const getOS = ({ memorialId }) => {
  const userAgent = window?.navigator.userAgent;
  const platform = window?.navigator.platform;
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let redirect = '';
  const urls = {
    ios: 'https://geo.itunes.apple.com/us/app/requiem-timely-notifications/id1123339295?mt=8',
    android: 'https://play.google.com/store/apps/details?id=com.izimobile.requiem&hl=en',
    home: 'https://everdays.com/',
    community: `/r/memorial/${memorialId}`
  };

  if (iosPlatforms.indexOf(platform) !== -1) {
    redirect = urls.ios;
  } else if (/Android/.test(userAgent)) {
    redirect = urls.android;
    // memorialId was part of url, redirect to community page
  } else if (memorialId) {
    redirect = urls.community;
  } else {
    redirect = urls.home;
  }

  return redirect;
};

const Apps = ({ params }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const { userId, memorialId } = params || {};
    userId && memorialId && dispatch(trackAccessorGetApp({ memorialId, userId, pageName: 'apps' }));
    window?.location.replace(getOS({ memorialId }));
  });

  return <div />;
};

export default Apps;
