import React from 'react';
import classNames from 'classnames';

import styles from './SiteHero.module.scss';

const {
  'site-hero': siteHeroClass,
  'hero-image': heroImageClass,
  'hero-text-container': heroTextContainerClass,
  'hero-text': heroTextClass,
  'right-align': rightAlignClass,
  markup: markupClass,
  // page classes for handling bg images
  'schedule-call': scheduleCallClass,
  home: homeClass,
  package: packageClass,
  why: whyClass,
  'how-it-works': howItWorksClass,
  estimate: estimateClass
} = styles;

const SiteHero = ({
  heroTitle,
  heroSubtitle,
  renderBreadcrumbs,
  renderMarkup,
  textAlign = 'left',
  customClass = '',
  customTextClass = '',
  currentPage = 'home'
}) => {
  const heroClasses = classNames(siteHeroClass, customClass, {
    [rightAlignClass]: textAlign === 'right'
  });

  const heroImageClasses = classNames(heroImageClass, {
    [homeClass]: currentPage === 'home',
    [packageClass]: currentPage === 'packages',
    [whyClass]: currentPage === 'why',
    [howItWorksClass]: currentPage === 'how-it-works',
    [estimateClass]: currentPage === 'estimate',
    [scheduleCallClass]: currentPage === 'schedule-call'
  });

  return (
    <div className={heroClasses}>
      <div className={heroImageClasses} />
      <div className={heroTextContainerClass}>
        <div className={`${heroTextClass} ${customTextClass}`}>
          {!!renderBreadcrumbs && renderBreadcrumbs}
          <h1>{heroTitle}</h1>
          <p>{heroSubtitle}</p>
          {!!renderMarkup && <div className={markupClass}>{renderMarkup}</div>}
        </div>
      </div>
    </div>
  );
};

export default SiteHero;
