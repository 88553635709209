"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESET_USER_ERROR = exports.SUBMIT_USER_SIGNUP_FAILURE = exports.SUBMIT_USER_SIGNUP_SUCCESS = exports.SUBMIT_USER_SIGNUP_REQUEST = void 0;
var SUBMIT_USER_SIGNUP_REQUEST = 'SUBMIT_USER_SIGNUP_REQUEST';
exports.SUBMIT_USER_SIGNUP_REQUEST = SUBMIT_USER_SIGNUP_REQUEST;
var SUBMIT_USER_SIGNUP_SUCCESS = 'SUBMIT_USER_SIGNUP_SUCCESS';
exports.SUBMIT_USER_SIGNUP_SUCCESS = SUBMIT_USER_SIGNUP_SUCCESS;
var SUBMIT_USER_SIGNUP_FAILURE = 'SUBMIT_USER_SIGNUP_FAILURE';
exports.SUBMIT_USER_SIGNUP_FAILURE = SUBMIT_USER_SIGNUP_FAILURE;
var RESET_USER_ERROR = 'RESET_USER_ERROR';
exports.RESET_USER_ERROR = RESET_USER_ERROR;