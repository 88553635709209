import React from 'react';

import { useVerifyCookie } from '../../../../customHooks';

import styles from './LowCostTodayCTA.module.scss';

import lowCostTrophy from '../../../../public/images/low-cost-trophy.svg';

const {
  'low-cost-today-cta': lowCostTodayClass,
  'cta-section': ctaSection,
  'left-cta': leftCTA,
  'right-cta': rightCTA,
  'my-cost': myCostClass,
  'my-cost-tablet': myCostTabletClass,
  'monthly-low': monthlyLowClass,
  'as-low-as-copy': asLowAsCopyClass,
  'as-low-as-price-copy': asLowAsPriceCopyClass,
  'coverage-begins': coverageBeginsClass,
  divider: dividerClass
} = styles;

const LowCostTodayCTA = ({
  titleCopy = 'Take care of the finances to save your family thousands',
  experiencePrice = '',
  experienceMonthlyPrice = ''
}) => {
  const asLowAsCopy = experiencePrice.split(' ').slice(0, 3).join(' ');
  const asLowAsPriceCopy = experiencePrice.split(' ')[3];
  const monthlyLowPriceCopy = experienceMonthlyPrice.split('/')[0];
  const monthlyLowTermsCopy = experienceMonthlyPrice.split('/')[1];

  const ctaLink = useVerifyCookie('rq-sid') ? '/get-my-price' : 'https://try.everdays.com/plan';

  return (
    <div className={lowCostTodayClass}>
      <h2>{titleCopy}</h2>
      <div className={ctaSection}>
        <div className={leftCTA}>
          <img src={lowCostTrophy} alt="" />
          <h3>Your Cost Today</h3>
          <p className={asLowAsCopyClass}>{asLowAsCopy}</p>
          <p className={asLowAsPriceCopyClass}>{asLowAsPriceCopy}</p>
          <div className={dividerClass} />
          <div className={monthlyLowClass}>
            <strong>{monthlyLowPriceCopy}</strong>
            <p>/{monthlyLowTermsCopy}</p>
          </div>
          <p className={coverageBeginsClass}>Coverage begins on day one*</p>
          <a href={ctaLink} className={myCostTabletClass}>
            <button>See how much my plan will cost</button>
          </a>
        </div>
        <div className={rightCTA}>
          <strong>VS.</strong>
          <h4>Your Family's Cost in the Future</h4>
          <h2>$6400</h2>
        </div>
      </div>
      <a href={ctaLink} className={myCostClass}>
        <button>See how much my plan will cost</button>
      </a>
    </div>
  );
};

export default LowCostTodayCTA;
