"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.array.from.js");

require("core-js/modules/es6.weak-map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name.js");

require("core-js/modules/es6.array.find-index.js");

require("core-js/modules/es6.array.slice.js");

var actions = _interopRequireWildcard(require("./constants"));

var calculatorConstants = _interopRequireWildcard(require("../webPurchase/calculator/constants"));

var _constants3 = require("../invitePage/constants");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialState = {
  data: null,
  meta: null,
  isFetching: false,
  lastUpdated: null,
  packageSelected: false,
  funeralHomeSelected: false,
  providerUpdated: false,
  planSelected: false,
  planPackageUpdated: false,
  birthdayUpdated: false,
  preneedDetailsUpdated: false,
  saleStarted: false,
  orderSubmitted: false,
  resumePageSuccess: false,
  preneedUserCreated: false,
  addOnsUpdated: false,
  error: {}
};

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actions.SEND_PIN_REQUEST:
    case actions.VERIFY_PIN_REQUEST:
    case actions.V2_SHELL_LOGIN_REQUEST:
    case actions.V2_CONFIRM_USER_REQUEST:
    case actions.DASH_LOGIN_REQUEST:
    case actions.ID_LOGIN_REQUEST:
    case actions.EDIT_USER_BY_ID_REQUEST:
    case actions.V2_EDIT_USER_PROFILE_REQUEST:
    case actions.SAVE_USER_PHONE_REQUEST:
    case actions.CREATE_NEW_PRENEED_USER_REQUEST:
    case actions.SAVE_PACKAGE_TO_USER_REQUEST:
    case actions.SAVE_PLAN_TO_USER_REQUEST:
    case actions.UPDATE_USER_PACKAGE_REQUEST:
    case actions.UPDATE_USER_BIRTHDAY_REQUEST:
    case actions.UPDATE_USER_PRENEED_DETAILS_REQUEST:
    case actions.UPDATE_WEBLEAD_DATA_REQUEST:
    case actions.SAVE_LAST_SCREEN_REQUEST:
    case actions.SAVE_HOME_TO_USER_REQUEST:
    case actions.SUBMIT_ORDER_REQUEST:
    case actions.RESUME_PURCHASE_LOGIN_REQUEST:
    case actions.UPDATE_ADDONS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true,
        error: {}
      });

    case actions.SEND_PIN_SUCCESS:
    case actions.VERIFY_PIN_SUCCESS:
    case actions.V2_SHELL_LOGIN_SUCCESS:
    case actions.V2_CONFIRM_USER_SUCCESS:
    case actions.V2_EDIT_USER_PROFILE_SUCCESS:
    case actions.ID_LOGIN_SUCCESS:
    case actions.EDIT_USER_BY_ID_SUCCESS:
    case actions.SAVE_USER_PHONE_SUCCESS:
    case actions.CREATE_NEW_PRENEED_USER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: {},
        lastUpdated: action.receivedAt,
        data: action.payload.data,
        preneedUserCreated: true
      });

    case calculatorConstants.CALCULATE_PRICE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: {},
        lastUpdated: action.receivedAt,
        data: action.payload
      });

    case actions.RESUME_PURCHASE_LOGIN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: {},
        lastUpdated: action.receivedAt,
        data: action.payload.data,
        resumePageSuccess: true
      });

    case actions.SAVE_PACKAGE_TO_USER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: {},
        lastUpdated: action.receivedAt,
        data: action.payload.data,
        packageSelected: true
      });

    case actions.SAVE_HOME_TO_USER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        data: action.payload.data,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: {},
        funeralHomeSelected: true
      });

    case actions.SAVE_PLAN_TO_USER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: {},
        lastUpdated: action.receivedAt,
        data: action.payload.data,
        planSelected: true
      });

    case actions.UPDATE_WEBLEAD_DATA_SUCCESS: // okay to delete?

    case actions.UPDATE_USER_PACKAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: {},
        lastUpdated: action.receivedAt,
        data: action.payload.data,
        planPackageUpdated: true
      });

    case actions.UPDATE_USER_BIRTHDAY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: {},
        lastUpdated: action.receivedAt,
        data: action.payload.data,
        birthdayUpdated: true
      });

    case actions.UPDATE_USER_PRENEED_DETAILS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: {},
        lastUpdated: action.receivedAt,
        data: action.payload.data,
        preneedDetailsUpdated: true
      });

    case actions.DASH_LOGIN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: {},
        lastUpdated: action.receivedAt,
        data: action.payload
      });

    case actions.SAVE_LAST_SCREEN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        data: action.payload.data,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: {}
      });

    case actions.SUBMIT_ORDER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: {},
        lastUpdated: action.receivedAt,
        data: action.payload.data,
        orderSubmitted: true
      });

    case actions.UPDATE_ADDONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: {},
        lastUpdated: action.receivedAt,
        data: action.payload.data,
        addOnsUpdated: true
      });

    case actions.SEND_PIN_FAILURE:
    case actions.VERIFY_PIN_FAILURE:
    case actions.V2_SHELL_LOGIN_FAILURE:
    case actions.V2_CONFIRM_USER_FAILURE:
    case actions.V2_EDIT_USER_PROFILE_FAILURE:
    case actions.DASH_LOGIN_FAILURE:
    case actions.ID_LOGIN_FAILURE:
    case actions.EDIT_USER_BY_ID_FAILURE:
    case actions.SAVE_USER_PHONE_FAILURE:
    case actions.CREATE_NEW_PRENEED_USER_FAILURE:
    case actions.SAVE_PACKAGE_TO_USER_FAILURE:
    case actions.SAVE_PLAN_TO_USER_FAILURE:
    case actions.UPDATE_WEBLEAD_DATA_FAILURE:
    case actions.SAVE_LAST_SCREEN_FAILURE:
    case actions.SAVE_HOME_TO_USER_FAILURE:
    case actions.SUBMIT_ORDER_FAILURE:
    case actions.RESUME_PURCHASE_LOGIN_FAILURE:
    case actions.UPDATE_ADDONS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: action.error,
        meta: undefined
      });

    case actions.RESET_META:
      return _objectSpread(_objectSpread({}, state), {}, {
        meta: undefined
      });

    case actions.RESET_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: {}
      });

    case actions.RESET_SELECTED_PACKAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        packageSelected: false
      });

    case actions.SAVE_HOME_TO_USER_RESET:
      return _objectSpread(_objectSpread({}, state), {}, {
        funeralHomeSelected: false
      });

    case actions.RESET_SELECTED_PLAN:
      return _objectSpread(_objectSpread({}, state), {}, {
        planSelected: false
      });

    case actions.RESET_PLAN_PACKAGE_UPDATED:
      return _objectSpread(_objectSpread({}, state), {}, {
        planPackageUpdated: false
      });

    case actions.RESET_USER_BIRTHDAY_UPDATED:
      return _objectSpread(_objectSpread({}, state), {}, {
        birthdayUpdated: false
      });

    case actions.RESET_USER_PRENEED_DETAILS_UPDATED:
      return _objectSpread(_objectSpread({}, state), {}, {
        preneedDetailsUpdated: false
      });

    case actions.RESET_SUBMIT_ORDER:
      return _objectSpread(_objectSpread({}, state), {}, {
        orderSubmitted: false
      });

    case actions.RESET_WEBLEAD_UPDATED:
      return _objectSpread(_objectSpread({}, state), {}, {
        webleadUpdated: false
      });

    case actions.RESUME_PURCHASE_RESET:
      return _objectSpread(_objectSpread({}, state), {}, {
        resumePageSuccess: false
      });

    case actions.UPDATE_ADDONS_RESET:
      return _objectSpread(_objectSpread({}, state), {}, {
        addOnsUpdated: false
      });

    case actions.EDIT_USER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        data: _objectSpread({}, action.payload)
      });
    // MARKED FOR REMOVAL 4.07.21 - Ken

    case _constants3.UPLOAD_VIDEO_REQUEST:
      // add the pending video to the user to keep track of processing videos
      var _ref = action.userData || {},
          _id = _ref._id,
          image = _ref.image,
          name = _ref.name;

      var pendingVideo = {
        _id: "action.videoName-".concat(Date.now()),
        memorialId: action.memorialId,
        pendingVideoId: action.pendingVideoId,
        createdAt: Date.now(),
        mediaType: 'video',
        isProcessing: true,
        name: action.videoName,
        pendingUrl: action.blobUrl,
        poster: {
          _id: _id,
          image: image,
          name: name
        },
        progress: 0,
        video: {
          url: action.blobUrl
        }
      };
      var previousVideos = state.data.processingVideos || [];
      return _objectSpread(_objectSpread({}, state), {}, {
        data: _objectSpread(_objectSpread({}, state.data), {}, {
          processingVideos: [].concat(_toConsumableArray(previousVideos), [pendingVideo])
        })
      });
    // MARKED FOR REMOVAL 4.07.21 - Ken

    case _constants3.ENCODE_VIDEO_SUCCESS:
      // remove the pending video from the user once encoding has finished
      var oldProcessingVideos = state.data.processingVideos || [];
      var processingVideoIdx = oldProcessingVideos.findIndex(function (video) {
        return video.pendingVideoId === action.pendingVideoId;
      });
      var newProcessingVideos = [].concat(_toConsumableArray(oldProcessingVideos.slice(0, processingVideoIdx)), _toConsumableArray(oldProcessingVideos.slice(processingVideoIdx + 1)));
      return _objectSpread(_objectSpread({}, state), {}, {
        data: _objectSpread(_objectSpread({}, state.data), {}, {
          processingVideos: newProcessingVideos
        })
      });
    // weblead / purchase flow

    case actions.USER_START_SALE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdate: action.receivedAt,
        data: _objectSpread(_objectSpread({}, state.data), {}, {
          eFuneral: _objectSpread(_objectSpread({}, state.data.eFuneral), {}, {
            url: action.payload.url,
            saleId: action.payload.saleId
          })
        }),
        saleStarted: true
      });

    default:
      return state;
  }
};

exports.default = _default;