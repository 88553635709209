import React from 'react';

import { useVerifyCookie } from '../../../../customHooks';

import styles from './EstimateCTA.module.scss';

const { 'estimate-cta': estimateCTAClass } = styles;

const EstimateCTA = ({ buttonCopy = 'Buy my funeral' }) => {
  const ctaLink = useVerifyCookie('rq-sid') ? '/get-my-price' : 'https://try.everdays.com/plan';

  return (
    <div className={estimateCTAClass}>
      <button>
        <a href={ctaLink}>{buttonCopy}</a>
      </button>
    </div>
  );
};

export default EstimateCTA;
