"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _redux = require("redux");

var _saveContactData = _interopRequireDefault(require("./saveContactData"));

var _fetchCompanies = _interopRequireDefault(require("./fetchCompanies"));

var _fetchMat = _interopRequireDefault(require("./fetchMat"));

var _reducers = require("../../lib/journey/reducers");

var _reducers2 = require("../../lib/activity/reducers");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var internalDash = (0, _redux.combineReducers)({
  saveContactData: _saveContactData.default,
  fetchedCompanies: _fetchCompanies.default,
  fetchedMat: _fetchMat.default,
  fetchedJourneys: _reducers.fetchedJourneys,
  fetchedJourneyConfig: _reducers.fetchedJourneyConfig,
  fetchedActivities: _reducers2.fetchedActivities
});
var _default = internalDash;
exports.default = _default;