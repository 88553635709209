"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPrices = void 0;

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

var _actions = require("./actions");

var _constants = require("../../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var instance = _index.default.create({
  baseURL: _constants.API_URL
}); // ig: v1/web/public/buypreneed/getprice?userId=123&funeralHomeId=x&amount=price


var getPrices = function getPrices(_ref) {
  var amounts = _ref.amounts,
      userId = _ref.userId,
      funeralHomeId = _ref.funeralHomeId,
      webleadId = _ref.webleadId;
  return function (dispatch) {
    dispatch((0, _actions.getPricesRequest)());
    return instance.get("/web/v1/public/buypreneed/getprice", {
      params: {
        amounts: amounts,
        userId: userId
      }
    }).then(function (res) {
      return dispatch((0, _actions.getPricesSucess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.getPricesFailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.getPrices = getPrices;