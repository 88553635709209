"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CRAWL_DONATION_URL_FAILURE = exports.CRAWL_DONATION_URL_SUCCESS = exports.CRAWL_DONATION_URL_REQUEST = exports.SUBMIT_INVITE_CLEAR = exports.SUBMIT_INVITE_FAILURE = exports.SUBMIT_INVITE_SUCCESS = exports.SUBMIT_INVITE_REQUEST = exports.SUBMIT_SIGNUP_FAILURE = exports.SUBMIT_SIGNUP_SUCCESS = exports.SUBMIT_SIGNUP_REQUEST = exports.UPDATE_CONVENTION_FORM = exports.FETCH_FUNERAL_HOMES_FAILURE = exports.FETCH_FUNERAL_HOMES_SUCCESS = exports.FETCH_FUNERAL_HOMES_REQUEST = exports.FETCH_CLIENT_CONFIG_FAILURE = exports.FETCH_CLIENT_CONFIG_SUCCESS = exports.FETCH_CLIENT_CONFIG_REQUEST = exports.FETCH_MEMORIAL_WALL_FAILURE = exports.FETCH_MEMORIAL_WALL_SUCCESS = exports.FETCH_MEMORIAL_WALL_REQUEST = exports.FETCH_MEMORIAL_NOTICE_FAILURE = exports.FETCH_MEMORIAL_NOTICE_SUCCESS = exports.FETCH_MEMORIAL_NOTICE_REQUEST = void 0;

/* Memorial Share Page */
var FETCH_MEMORIAL_NOTICE_REQUEST = 'FETCH_MEMORIAL_NOTICE_REQUEST';
exports.FETCH_MEMORIAL_NOTICE_REQUEST = FETCH_MEMORIAL_NOTICE_REQUEST;
var FETCH_MEMORIAL_NOTICE_SUCCESS = 'FETCH_MEMORIAL_NOTICE_SUCCESS';
exports.FETCH_MEMORIAL_NOTICE_SUCCESS = FETCH_MEMORIAL_NOTICE_SUCCESS;
var FETCH_MEMORIAL_NOTICE_FAILURE = 'FETCH_MEMORIAL_NOTICE_FAILURE';
/* Memorial Wall Post Page */

exports.FETCH_MEMORIAL_NOTICE_FAILURE = FETCH_MEMORIAL_NOTICE_FAILURE;
var FETCH_MEMORIAL_WALL_REQUEST = 'FETCH_MEMORIAL_WALL_REQUEST';
exports.FETCH_MEMORIAL_WALL_REQUEST = FETCH_MEMORIAL_WALL_REQUEST;
var FETCH_MEMORIAL_WALL_SUCCESS = 'FETCH_MEMORIAL_WALL_SUCCESS';
exports.FETCH_MEMORIAL_WALL_SUCCESS = FETCH_MEMORIAL_WALL_SUCCESS;
var FETCH_MEMORIAL_WALL_FAILURE = 'FETCH_MEMORIAL_WALL_FAILURE';
/* Client Config Page */

exports.FETCH_MEMORIAL_WALL_FAILURE = FETCH_MEMORIAL_WALL_FAILURE;
var FETCH_CLIENT_CONFIG_REQUEST = 'FETCH_CLIENT_CONFIG_REQUEST';
exports.FETCH_CLIENT_CONFIG_REQUEST = FETCH_CLIENT_CONFIG_REQUEST;
var FETCH_CLIENT_CONFIG_SUCCESS = 'FETCH_CLIENT_CONFIG_SUCCESS';
exports.FETCH_CLIENT_CONFIG_SUCCESS = FETCH_CLIENT_CONFIG_SUCCESS;
var FETCH_CLIENT_CONFIG_FAILURE = 'FETCH_CLIENT_CONFIG_FAILURE';
/* Funeral Home Search */

exports.FETCH_CLIENT_CONFIG_FAILURE = FETCH_CLIENT_CONFIG_FAILURE;
var FETCH_FUNERAL_HOMES_REQUEST = 'FETCH_FUNERAL_HOMES_REQUEST';
exports.FETCH_FUNERAL_HOMES_REQUEST = FETCH_FUNERAL_HOMES_REQUEST;
var FETCH_FUNERAL_HOMES_SUCCESS = 'FETCH_FUNERAL_HOMES_SUCCESS';
exports.FETCH_FUNERAL_HOMES_SUCCESS = FETCH_FUNERAL_HOMES_SUCCESS;
var FETCH_FUNERAL_HOMES_FAILURE = 'FETCH_FUNERAL_HOMES_FAILURE';
/* Convention Form */

exports.FETCH_FUNERAL_HOMES_FAILURE = FETCH_FUNERAL_HOMES_FAILURE;
var UPDATE_CONVENTION_FORM = 'UDPATE_CONVENTION_FORM';
exports.UPDATE_CONVENTION_FORM = UPDATE_CONVENTION_FORM;
var SUBMIT_SIGNUP_REQUEST = 'SUBMIT_SIGNUP_REQUEST';
exports.SUBMIT_SIGNUP_REQUEST = SUBMIT_SIGNUP_REQUEST;
var SUBMIT_SIGNUP_SUCCESS = 'SUBMIT_SIGNUP_SUCCESS';
exports.SUBMIT_SIGNUP_SUCCESS = SUBMIT_SIGNUP_SUCCESS;
var SUBMIT_SIGNUP_FAILURE = 'SUBMIT_SIGNUP_FAILURE';
exports.SUBMIT_SIGNUP_FAILURE = SUBMIT_SIGNUP_FAILURE;
var SUBMIT_INVITE_REQUEST = 'SUBMIT_INVITE_REQUEST';
exports.SUBMIT_INVITE_REQUEST = SUBMIT_INVITE_REQUEST;
var SUBMIT_INVITE_SUCCESS = 'SUBMIT_INVITE_SUCCESS';
exports.SUBMIT_INVITE_SUCCESS = SUBMIT_INVITE_SUCCESS;
var SUBMIT_INVITE_FAILURE = 'SUBMIT_INVITE_FAILURE';
exports.SUBMIT_INVITE_FAILURE = SUBMIT_INVITE_FAILURE;
var SUBMIT_INVITE_CLEAR = 'SUBMIT_INVITE_CLEAR';
exports.SUBMIT_INVITE_CLEAR = SUBMIT_INVITE_CLEAR;
var CRAWL_DONATION_URL_REQUEST = 'CRAWL_DONATION_URL_REQUEST';
exports.CRAWL_DONATION_URL_REQUEST = CRAWL_DONATION_URL_REQUEST;
var CRAWL_DONATION_URL_SUCCESS = 'CRAWL_DONATION_URL_SUCCESS';
exports.CRAWL_DONATION_URL_SUCCESS = CRAWL_DONATION_URL_SUCCESS;
var CRAWL_DONATION_URL_FAILURE = 'CRAWL_DONATION_URL_FAILURE';
exports.CRAWL_DONATION_URL_FAILURE = CRAWL_DONATION_URL_FAILURE;