"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setUploadProgress = exports.setUploadInProgress = exports.streamListUpdate = exports.initalStreamList = exports.liveStreamConnectionChange = void 0;

var types = _interopRequireWildcard(require("./constants"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

// Socket Status
var liveStreamConnectionChange = function liveStreamConnectionChange(status) {
  return {
    type: types.LIVE_STREAM_CONNECTION_CHANGE,
    payload: status
  };
}; // Stream List Load and Updates


exports.liveStreamConnectionChange = liveStreamConnectionChange;

var initalStreamList = function initalStreamList(_ref) {
  var streams = _ref.streams;
  return {
    type: types.INITAL_STREAM_LIST,
    payload: streams,
    receivedAt: Date.now()
  };
};

exports.initalStreamList = initalStreamList;

var streamListUpdate = function streamListUpdate(_ref2) {
  var stream = _ref2.stream;
  return {
    type: types.STREAM_LIST_UPDATE,
    payload: stream,
    receivedAt: Date.now()
  };
}; // Video Upload Status


exports.streamListUpdate = streamListUpdate;

var setUploadInProgress = function setUploadInProgress(isInProgress) {
  return {
    type: types.SET_UPLOAD_IN_PROGRESS,
    payload: isInProgress
  };
};

exports.setUploadInProgress = setUploadInProgress;

var setUploadProgress = function setUploadProgress(progress) {
  return {
    type: types.SET_UPLOAD_PROGRESS,
    payload: progress
  };
};

exports.setUploadProgress = setUploadProgress;