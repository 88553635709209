"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RSVP_FAILURE = exports.RSVP_SUCCESS = exports.RSVP_REQUEST = void 0;
var RSVP_REQUEST = 'RSVP_REQUEST';
exports.RSVP_REQUEST = RSVP_REQUEST;
var RSVP_SUCCESS = 'RSVP_SUCCESS';
exports.RSVP_SUCCESS = RSVP_SUCCESS;
var RSVP_FAILURE = 'RSVP_FAILURE';
exports.RSVP_FAILURE = RSVP_FAILURE;