"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkShellUser = void 0;

var types = _interopRequireWildcard(require("../../constants/webAnnouncement/checkShellUser"));

var _constants = require("../../constants");

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var checkShellUserRequest = function checkShellUserRequest() {
  return {
    type: types.CHECK_SHELL_USER_REQUEST
  };
};

var checkShellUserSuccess = function checkShellUserSuccess(_ref) {
  var data = _ref.data;
  return {
    type: types.CHECK_SHELL_USER_SUCCESS,
    receivedAt: Date.now(),
    payload: data
  };
};

var checkShellUserFailure = function checkShellUserFailure(error) {
  return {
    type: types.CHECK_SHELL_USER_FAILURE,
    receivedAt: Date.now(),
    error: error
  };
};

var checkShellUser = function checkShellUser(userId) {
  return function (dispatch) {
    dispatch(checkShellUserRequest());
    return _index.default.get("".concat(_constants.API_URL, "/public/user/").concat(userId, "/basic")).then(function (res) {
      return dispatch(checkShellUserSuccess(res));
    }).catch(function (err) {
      return dispatch(checkShellUserFailure((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.checkShellUser = checkShellUser;