"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _redux = require("redux");

var _reactReduxForm = require("react-redux-form");

var profile = {
  name: '',
  type: '',
  website: '',
  address: {
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: ''
  },
  phone: '',
  logo: '',
  sfRef: '',
  beta: {
    precoa: false
  }
};
var members = {};
var location = {
  name: '',
  websiteUrl: '',
  type: '',
  address: {
    street: '',
    street2: '',
    city: '',
    state: '',
    zip: ''
  },
  phone: '',
  email: '',
  casesPerYear: '',
  flowers: {
    url: ''
  },
  facebookUrl: '',
  placeId: '',
  yelpUrl: '',
  description: '',
  profileImage: '',
  photos: [],
  displayPublicly: true,
  premiumProgram: '',
  eFuneralHomeId: ''
};
var agents = {
  current: '',
  replaceWith: ''
};
var accountDetails = (0, _redux.combineReducers)({
  forms: (0, _reactReduxForm.combineForms)({
    profile: profile,
    members: members,
    location: location,
    agents: agents
  }, 'dash.accountDetails.forms', {
    key: 'accountDetailsMeta'
  })
});
var _default = accountDetails;
exports.default = _default;