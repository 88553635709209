"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PHOTO_REMOVE_SUCCESS = exports.PHOTO_REMOVE_FAILURE = exports.PHOTO_REMOVE_REQUEST = exports.PHOTO_UPLOAD_SUCCESS = exports.PHOTO_UPLOAD_FAILURE = exports.PHOTO_UPLOAD_REQUEST = void 0;
var PHOTO_UPLOAD_REQUEST = 'PHOTO_UPLOAD_REQUEST';
exports.PHOTO_UPLOAD_REQUEST = PHOTO_UPLOAD_REQUEST;
var PHOTO_UPLOAD_FAILURE = 'PHOTO_UPLOAD_FAILURE';
exports.PHOTO_UPLOAD_FAILURE = PHOTO_UPLOAD_FAILURE;
var PHOTO_UPLOAD_SUCCESS = 'PHOTO_UPLOAD_SUCCESS';
exports.PHOTO_UPLOAD_SUCCESS = PHOTO_UPLOAD_SUCCESS;
var PHOTO_REMOVE_REQUEST = 'PHOTO_REMOVE_REQUEST';
exports.PHOTO_REMOVE_REQUEST = PHOTO_REMOVE_REQUEST;
var PHOTO_REMOVE_FAILURE = 'PHOTO_REMOVE_FAILURE';
exports.PHOTO_REMOVE_FAILURE = PHOTO_REMOVE_FAILURE;
var PHOTO_REMOVE_SUCCESS = 'PHOTO_REMOVE_SUCCESS';
exports.PHOTO_REMOVE_SUCCESS = PHOTO_REMOVE_SUCCESS;