"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ACTIVITIES_VIEWED_FAILURE = exports.ACTIVITIES_VIEWED_SUCCESS = exports.ACTIVITIES_VIEWED_REQUEST = exports.FETCH_ACTIVITIES_FAILURE = exports.FETCH_ACTIVITIES_SUCCESS = exports.FETCH_ACTIVITIES_REQUEST = void 0;
var FETCH_ACTIVITIES_REQUEST = 'FETCH_ACTIVITIES_REQUEST';
exports.FETCH_ACTIVITIES_REQUEST = FETCH_ACTIVITIES_REQUEST;
var FETCH_ACTIVITIES_SUCCESS = 'FETCH_ACTIVITIES_SUCCESS';
exports.FETCH_ACTIVITIES_SUCCESS = FETCH_ACTIVITIES_SUCCESS;
var FETCH_ACTIVITIES_FAILURE = 'FETCH_ACTIVITIES_FAILURE';
exports.FETCH_ACTIVITIES_FAILURE = FETCH_ACTIVITIES_FAILURE;
var ACTIVITIES_VIEWED_REQUEST = 'ACTIVITIES_VIEWED_REQUEST';
exports.ACTIVITIES_VIEWED_REQUEST = ACTIVITIES_VIEWED_REQUEST;
var ACTIVITIES_VIEWED_SUCCESS = 'ACTIVITIES_VIEWED_SUCCESS';
exports.ACTIVITIES_VIEWED_SUCCESS = ACTIVITIES_VIEWED_SUCCESS;
var ACTIVITIES_VIEWED_FAILURE = 'ACTIVITIES_VIEWED_FAILURE';
exports.ACTIVITIES_VIEWED_FAILURE = ACTIVITIES_VIEWED_FAILURE;