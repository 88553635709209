"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.recordBrowser = exports.recordMobileUA = exports.recordMobileOS = exports.setBrowser = exports.setUA = exports.setOS = void 0;

var types = _interopRequireWildcard(require("../constants/dash/setMobileOS"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var setOS = function setOS(os) {
  return {
    os: os,
    type: types.SET_OS
  };
};

exports.setOS = setOS;

var setUA = function setUA(ua) {
  return {
    ua: ua,
    type: types.SET_UA
  };
};

exports.setUA = setUA;

var setBrowser = function setBrowser(browser) {
  return {
    browser: browser,
    type: types.SET_BROWSER
  };
};

exports.setBrowser = setBrowser;

var recordMobileOS = function recordMobileOS(os) {
  return function (dispatch) {
    dispatch(setOS(os));
  };
};

exports.recordMobileOS = recordMobileOS;

var recordMobileUA = function recordMobileUA(ua) {
  return function (dispatch) {
    dispatch(setUA(ua));
  };
};

exports.recordMobileUA = recordMobileUA;

var recordBrowser = function recordBrowser(browser) {
  return function (dispatch) {
    dispatch(setBrowser(browser));
  };
};

exports.recordBrowser = recordBrowser;