import React from 'react';

import styles from './HowEverdaysWorks.module.scss';

import imageOne from '../../public/images/how-everdays-works-1-desktop.jpg';
import imageTwo from '../../public/images/how-everdays-works-2-desktop.jpg';
import imageThree from '../../public/images/how-everdays-works-3-desktop.png';

const {
  'how-everdays-works': howEverdaysWorksClass,
  'steps-container': stepsContainerClass,
  step: stepClass,
  'step-content': stepContentClass,
  number: numberClass,
  'step-copy': stepCopyClass
} = styles;

const steps = [
  {
    image: imageOne,
    title: 'Decide what you want',
    subtitle:
      'Answer a few simple questions about your wishes & we’ll turn your decisions into a complete, digital plan.'
  },
  {
    image: imageTwo,
    title: 'Share with your family',
    subtitle:
      'Your plan is safely stored in your account, where you can give loved ones instant access to everything they need.'
  },
  {
    image: imageThree,
    title: 'Secure your plan',
    subtitle:
      'When you’re ready, fund your plans with the right insurance to protect your loved ones and your future.'
  }
];

const HowEverdaysWorks = () => {
  return (
    <div className={howEverdaysWorksClass}>
      <h2>We make it easier than you think</h2>
      <div className={stepsContainerClass}>
        {steps.map((step, idx) => (
          <div className={stepClass} key={step.title}>
            <img src={step.image} alt="" />
            <div className={stepContentClass}>
              <div className={numberClass}>{idx + 1}</div>
              <div className={stepCopyClass}>
                <h3>{step.title}</h3>
                <p>{step.subtitle}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowEverdaysWorks;
