"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var homeSearch = {
  country: '',
  state: '',
  city: '',
  save: ''
};
var hdSearch = {
  city: '',
  name: '',
  sort: ''
};
var hdContact = {
  name: '',
  phone: '',
  email: '',
  reason: ''
};
var hdReviewModal = {
  name: '',
  email: '',
  rating: -1,
  message: ''
};
var _default = {
  homeSearch: homeSearch,
  hdSearch: hdSearch,
  hdContact: hdContact,
  hdReviewModal: hdReviewModal
};
exports.default = _default;