import React from 'react';
import classNames from 'classnames';

import rightArrow from '../../public/images/blue-curved-arrow-down-right.svg';
import leftArrow from '../../public/images/blue-curved-arrow-down-left-two.svg';

import styles from './StepCard.module.scss';

const {
  'step-card': stepCardClass,
  image: imageClass,
  'image-crescent': imageCrescentClass,
  arrow: arrowClass,
  'right-align': rightAlignClass
} = styles;

const StepCard = ({
  cardImage,
  cardTitle,
  cardText,
  customClass,
  customArrowClass,
  hideArrow = false,
  textAlign
}) => {
  const stepCardClasses = classNames(stepCardClass, customClass, {
    [rightAlignClass]: textAlign === 'right'
  });
  const arrowClasses = classNames(arrowClass, customArrowClass);

  return (
    <div className={stepCardClasses}>
      <div className={imageClass}>
        <img src={cardImage} />
        <div className={imageCrescentClass} />
      </div>
      <h3>{cardTitle}</h3>
      <p>{cardText}</p>
      {!hideArrow && (
        <div className={arrowClasses}>
          <img src={textAlign === 'right' ? rightArrow : leftArrow} />
        </div>
      )}
    </div>
  );
};

export default StepCard;
