"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SET_LEAD_FAILURE = exports.SET_LEAD_SUCCESS = exports.SET_LEAD_REQUEST = exports.GET_LEAD_FAILURE = exports.GET_LEAD_SUCCESS = exports.GET_LEAD_REQUEST = exports.FETCH_LEADS_FAILURE = exports.FETCH_LEADS_SUCCESS = exports.FETCH_LEADS_REQUEST = void 0;
var FETCH_LEADS_REQUEST = 'FETCH_LEADS_REQUEST';
exports.FETCH_LEADS_REQUEST = FETCH_LEADS_REQUEST;
var FETCH_LEADS_SUCCESS = 'FETCH_LEADS_SUCCESS';
exports.FETCH_LEADS_SUCCESS = FETCH_LEADS_SUCCESS;
var FETCH_LEADS_FAILURE = 'FETCH_LEADS_FAILURE';
exports.FETCH_LEADS_FAILURE = FETCH_LEADS_FAILURE;
var GET_LEAD_REQUEST = 'GET_LEAD_REQUEST';
exports.GET_LEAD_REQUEST = GET_LEAD_REQUEST;
var GET_LEAD_SUCCESS = 'GET_LEAD_SUCCESS';
exports.GET_LEAD_SUCCESS = GET_LEAD_SUCCESS;
var GET_LEAD_FAILURE = 'GET_LEAD_FAILURE';
exports.GET_LEAD_FAILURE = GET_LEAD_FAILURE;
var SET_LEAD_REQUEST = 'SET_LEAD_REQUEST';
exports.SET_LEAD_REQUEST = SET_LEAD_REQUEST;
var SET_LEAD_SUCCESS = 'SET_LEAD_SUCCESS';
exports.SET_LEAD_SUCCESS = SET_LEAD_SUCCESS;
var SET_LEAD_FAILURE = 'SET_LEAD_FAILURE';
exports.SET_LEAD_FAILURE = SET_LEAD_FAILURE;