"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_PRENEED_PACKAGES_FAILURE = exports.GET_PRENEED_PACKAGES_SUCCESS = exports.GET_PRENEED_PACKAGES_REQUEST = void 0;
var GET_PRENEED_PACKAGES_REQUEST = 'GET_PRENEED_PACKAGES_REQUEST';
exports.GET_PRENEED_PACKAGES_REQUEST = GET_PRENEED_PACKAGES_REQUEST;
var GET_PRENEED_PACKAGES_SUCCESS = 'GET_PRENEED_PACKAGES_SUCCESS';
exports.GET_PRENEED_PACKAGES_SUCCESS = GET_PRENEED_PACKAGES_SUCCESS;
var GET_PRENEED_PACKAGES_FAILURE = 'GET_PRENEED_PACKAGES_FAILURE';
exports.GET_PRENEED_PACKAGES_FAILURE = GET_PRENEED_PACKAGES_FAILURE;