"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESET_ERROR = exports.ADD_COMPANY_WITH_MISC_FAILURE = exports.ADD_COMPANY_WITH_MISC_SUCCESS = exports.ADD_COMPANY_WITH_MISC_REQUEST = void 0;
var ADD_COMPANY_WITH_MISC_REQUEST = 'ADD_COMPANY_WITH_MISC_REQUEST';
exports.ADD_COMPANY_WITH_MISC_REQUEST = ADD_COMPANY_WITH_MISC_REQUEST;
var ADD_COMPANY_WITH_MISC_SUCCESS = 'ADD_COMPANY_WITH_MISC_SUCCESS';
exports.ADD_COMPANY_WITH_MISC_SUCCESS = ADD_COMPANY_WITH_MISC_SUCCESS;
var ADD_COMPANY_WITH_MISC_FAILURE = 'ADD_COMPANY_WITH_MISC_FAILURE';
exports.ADD_COMPANY_WITH_MISC_FAILURE = ADD_COMPANY_WITH_MISC_FAILURE;
var RESET_ERROR = 'RESET_ERROR';
exports.RESET_ERROR = RESET_ERROR;