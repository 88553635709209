"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOAD_MORE_ANNOUNCEMENTS_STATS_SUCCESS = exports.FETCH_ANNOUNCEMENTS_STATS_FAILURE = exports.FETCH_ANNOUNCEMENTS_STATS_SUCCESS = exports.FETCH_ANNOUNCEMENTS_STATS_REQUEST = void 0;
var FETCH_ANNOUNCEMENTS_STATS_REQUEST = 'FETCH_ANNOUNCEMENTS_STATS_REQUEST';
exports.FETCH_ANNOUNCEMENTS_STATS_REQUEST = FETCH_ANNOUNCEMENTS_STATS_REQUEST;
var FETCH_ANNOUNCEMENTS_STATS_SUCCESS = 'FETCH_ANNOUNCEMENTS_STATS_SUCCESS';
exports.FETCH_ANNOUNCEMENTS_STATS_SUCCESS = FETCH_ANNOUNCEMENTS_STATS_SUCCESS;
var FETCH_ANNOUNCEMENTS_STATS_FAILURE = 'FETCH_ANNOUNCEMENTS_STATS_FAILURE';
exports.FETCH_ANNOUNCEMENTS_STATS_FAILURE = FETCH_ANNOUNCEMENTS_STATS_FAILURE;
var LOAD_MORE_ANNOUNCEMENTS_STATS_SUCCESS = 'LOAD_MORE_ANNOUNCEMENTS_STATS_SUCCESS';
exports.LOAD_MORE_ANNOUNCEMENTS_STATS_SUCCESS = LOAD_MORE_ANNOUNCEMENTS_STATS_SUCCESS;