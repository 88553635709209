"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FETCH_CITIES_FAILURE = exports.FETCH_CITIES_SUCCESS = exports.FETCH_CITIES_REQUEST = void 0;
var FETCH_CITIES_REQUEST = 'FETCH_CITIES_REQUEST';
exports.FETCH_CITIES_REQUEST = FETCH_CITIES_REQUEST;
var FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS';
exports.FETCH_CITIES_SUCCESS = FETCH_CITIES_SUCCESS;
var FETCH_CITIES_FAILURE = 'FETCH_CITIES_FAILURE';
exports.FETCH_CITIES_FAILURE = FETCH_CITIES_FAILURE;