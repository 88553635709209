"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FETCH_COMPANY_TYPEAHEAD_FAILURE = exports.FETCH_COMPANY_TYPEAHEAD_SUCCESS = exports.FETCH_COMPANY_TYPEAHEAD_REQUEST = void 0;
var FETCH_COMPANY_TYPEAHEAD_REQUEST = 'FETCH_COMPANY_TYPEAHEAD_REQUEST';
exports.FETCH_COMPANY_TYPEAHEAD_REQUEST = FETCH_COMPANY_TYPEAHEAD_REQUEST;
var FETCH_COMPANY_TYPEAHEAD_SUCCESS = 'FETCH_COMPANY_TYPEAHEAD_SUCCESS';
exports.FETCH_COMPANY_TYPEAHEAD_SUCCESS = FETCH_COMPANY_TYPEAHEAD_SUCCESS;
var FETCH_COMPANY_TYPEAHEAD_FAILURE = 'FETCH_COMPANY_TYPEAHEAD_FAILURE';
exports.FETCH_COMPANY_TYPEAHEAD_FAILURE = FETCH_COMPANY_TYPEAHEAD_FAILURE;