"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useURLParamSync = void 0;

var _react = require("react");

var _reactRouter = require("react-router");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * keep the page url param in sync with reducer hook when switching professional modes
 * feature is used when returning to account list from account details
 * @param {Object} filtersObj memoized activeFilters/isExternalMode(if needed) from component
 * @param {Object} location location from React router
 * @param {Array} omit properties to omit from filters, ie: 'selected'
 */
var useURLParamSync = function useURLParamSync(filtersObj, location) {
  var omitArray = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var query = location.query;

  var filters = _objectSpread({}, filtersObj);

  if (omitArray.length) omitArray.forEach(function (toOmit) {
    return delete filters[toOmit];
  });
  (0, _react.useEffect)(function () {
    _reactRouter.browserHistory.push(_objectSpread(_objectSpread({}, location), {}, {
      query: _objectSpread(_objectSpread({}, query), filters)
    }));
  }, [filtersObj]);
};

exports.useURLParamSync = useURLParamSync;