import moment from 'moment';

import { nameString } from '@evdy/web-core/dist/lib/utils';
import evdySquareLogo from '@evdy/web-dash/src/components/Notifications/everdays-square-logo.png';

const now = Date.now();
const tzOffset = new Date().getTimezoneOffset() * 1000 * 60;

const daysInMs = days => {
  return 1000 * 60 * 60 * 24 * days;
};

const getMonthString = date => {
  const dateMoment = moment.utc(date);
  const month = dateMoment.format('MMM');
  const year = dateMoment.format('YY');

  return `${month} ${year}`;
};

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

const makeMonthlyGraphArray = (total, monthRange) => {
  let array = [];
  let remainingCount = total;
  const average = Math.round(total / monthRange);
  const lowerBound = Math.floor(average * 0.75);
  const upperBound = Math.floor(average * 1.25);
  const now = Date.now();
  const startDate = moment(now).subtract(monthRange - 1, 'months');
  for (let i = 0; i < monthRange; i++) {
    const date = moment(startDate)
      .add(i, 'months')
      .valueOf();
    const dateString = getMonthString(moment(startDate).add(i, 'months'));
    let count = getRandomInt(lowerBound, upperBound);
    if (remainingCount - count < 0 || i === monthRange - 1) count = remainingCount;
    remainingCount -= count;
    if (remainingCount < 0) remainingCount = 0;

    array.push({ dateString, count, date });
  }

  return array;
};

export const demoDirectors = [
  {
    _id: 'christine-smith',
    name: {
      first: 'Christine',
      last: 'Smith'
    },
    email: 'christine@smithfuneralhome.com',
    isCompanyAdmin: true,
    title: 'owner',
    phone: 8105554321
  },
  {
    _id: 'jennifer-smith',
    name: {
      first: 'Jennifer',
      last: 'Smith'
    },
    email: 'jennifer@smithfuneralhome.com',
    title: 'funeralDirector'
  },
  {
    _id: 'theo-smith',
    name: {
      first: 'Theo',
      last: 'Smith'
    },
    email: 'theo@smithfuneralhome.com',
    title: 'administrativeAssistant'
  },
  {
    _id: 'Alicia-smith',
    name: {
      first: 'Alicia',
      last: 'Smith'
    },
    email: 'alicia@smithfuneralhome.com',
    title: 'officeAdministrator'
  },
  {
    _id: 'Bryan-smith',
    name: {
      first: 'Bryan',
      last: 'Smith'
    },
    email: 'bryan@smithfuneralhome.com',
    title: 'apprentice'
  }
];

export const demoHomes = [
  {
    _id: 'smith-funeral-home-north',
    name: 'Memorial Funeral Home',
    address: {
      street: '123 North Street',
      city: 'Northington',
      state: 'AK',
      zip: '12345'
    },
    afps: [demoDirectors[0], demoDirectors[2]],
    phone: 8105557890,
    email: 'northlocation@smithfuneralhome.demo',
    websiteUrl: 'smithfuneralhome.demo/north',
    profileImage:
      'https://requiem-dashboard-media.s3.amazonaws.com/2019/08/e7de02fa-e20e-4895-abe8-4b0e8fc8856e_funeral-home.jpg'
  },
  {
    _id: 'smith-funeral-home-south',
    name: 'Smith Funeral Home South',
    address: {
      street: '321 South Street',
      city: 'Southington',
      state: 'AK',
      zip: '12345'
    },
    afps: [demoDirectors[1]],
    phone: 8105551234,
    email: 'southlocation@smithfuneralhome.demo',
    profileImage:
      'https://requiem-dashboard-media.s3.amazonaws.com/2019/08/a68006a6-13f6-4353-a554-7e89cb26f89b_funeral-home-2.jpg'
  }
];

export const demoCompany = {
  _id: 'demo-company',
  name: 'Smith Funeral Home',
  phone: '+19495559041',
  website: 'http://smithfuneralhomedemohome.com',
  sfRef: '00146000013pZCyAAM',
  beta: {
    addOns: true,
    legacyTouch: true,
    precoa: false,
    asd: false,
    merchandising: true
  },
  logo:
    'https://requiem-dashboard-media.s3.amazonaws.com/2019/08/e7de02fa-e20e-4895-abe8-4b0e8fc8856e_funeral-home.jpg',
  memorialStats: [],
  funeralHomes: demoHomes,
  users: demoDirectors,
  address: {
    street1: '123 Demo Rd.',
    street2: '',
    city: 'Smith',
    state: 'DE',
    zip: '12345'
  },
  contactName: {
    first: 'Christine',
    last: 'Smith'
  },
  numberOfLocations: 2,
  crmRef: '100363',
  ccRef: '100363',
  managerStats: [
    {
      min: 0,
      max: 4,
      range: 4,
      mean: 1.0178571428571428,
      stdDev: 0.47076522594514814,
      variance: 0.22161989795918635,
      median: 1,
      mode: 1,
      coefficientOfVariation: 46.25061868934789,
      total: 392,
      mostRecentDate: 1567026370156.0,
      _id: 'manager-id'
    }
  ],
  memorialStatsSummary: [],
  settings: {
    disableReengageBotForDirectors: false
  },
  afps: [],
  defaultAnnouncementImages: [],
  type: 'crematory'
};

export const demoAnnouncements = [
  {
    _id: 'demo-announcement-1',
    name: {
      first: 'Bob',
      last: 'Jones'
    },
    coverIdx: 0,
    creationDate: 1565818431463,
    dateOfDeath: 1565766000000,
    funeralDirectorCreator: demoDirectors[3],
    funeralDirectorId: demoDirectors[3]._id,
    funeralHome: demoHomes[0],
    funeralHomeDirector: nameString(demoDirectors[3].name),
    accessors: [],
    services: [],
    media: [],
    isDraft: false,
    isFinished: true,
    donationsComplex: []
  },
  {
    _id: 'demo-announcement-2',
    name: {
      first: 'Leo',
      last: 'Garcia'
    },
    coverIdx: 1,
    creationDate: 1565818431463,
    dateOfDeath: 1565766000000,
    funeralDirectorCreator: demoDirectors[3],
    funeralDirectorId: demoDirectors[3]._id,
    funeralHome: demoHomes[0],
    funeralHomeDirector: nameString(demoDirectors[3].name),
    owner: demoDirectors[3],
    accessors: [{ userRef: '5d261a71305690f1f32c523f', primarySurvivor: true }],
    services: [],
    media: [],
    isFinished: true,
    serviceStatus: 'servicesUpcoming'
  },
  {
    _id: 'demo-announcement-3',
    name: {
      first: 'Daniel',
      last: 'Lee'
    },
    coverIdx: 2,
    creationDate: 1565818431463,
    dateOfDeath: 1565766000000,
    funeralDirectorCreator: demoDirectors[3],
    funeralDirectorId: demoDirectors[3]._id,
    funeralHome: demoHomes[0],
    funeralHomeDirector: nameString(demoDirectors[3].name),
    accessors: [],
    services: [],
    media: [],
    isFinished: true,
    createdByFuneralHome: true,
    serviceStatus: 'servicesUpcoming'
  },
  {
    _id: 'demo-announcement-4',
    name: {
      first: 'Earl',
      last: 'Summers'
    },
    coverIdx: 3,
    creationDate: 1565818431463,
    dateOfDeath: 1565766000000,
    funeralDirectorCreator: demoDirectors[3],
    funeralDirectorId: demoDirectors[3]._id,
    funeralHome: demoHomes[0],
    funeralHomeDirector: nameString(demoDirectors[3].name),
    owner: demoDirectors[3],
    accessors: [],
    services: [],
    media: [],
    isFinished: true,
    serviceStatus: 'servicesUpcoming'
  },
  {
    _id: 'demo-announcement-5',
    name: {
      first: 'Frank',
      last: 'Allen'
    },
    coverIdx: 4,
    creationDate: 1565818431463,
    dateOfDeath: 1565766000000,
    funeralDirectorCreator: demoDirectors[3],
    funeralDirectorId: demoDirectors[3]._id,
    funeralHome: demoHomes[0],
    funeralHomeDirector: nameString(demoDirectors[3].name),
    accessors: [],
    services: [],
    media: [],
    isFinished: true,
    createdByFuneralHome: true,
    serviceStatus: 'servicesUpcoming'
  }
];

export const demoLeads = [
  {
    _id: 'demo-daniel-bryant',
    memorials: [demoAnnouncements[1]],
    companies: [
      // "5a0c993973671bdadc47438c"
    ],
    lifecycleStage: 'new',
    isArchived: false,
    userRef: { _id: '5d261a71305690f1f32c523f' },
    name: {
      first: 'Daniel',
      last: 'Bryant',
      middle: ''
    },
    afp: demoDirectors[0],
    phone: '8605551234',
    activity: [
      {
        currentStage: 'new',
        status: 'open',
        isArchived: false,
        attempt: 1,
        _id: '5d683eb644dea735f2770134',
        type: 'stage',
        message: '',
        createdBy: '5a0c993973671bdadc47438d',
        dueDate: moment
          .utc(now - tzOffset)
          .hour(23)
          .minute(59)
          .second(59)
          .utcOffset(0)
          .toISOString(),
        closeReason: 'purchased',
        taskList: [],
        createdAt: '2019-08-29T21:08:06.661+0000',
        updatedAt: '2019-08-29T21:08:06.661+0000'
      }
    ],
    scoreHistory: [],
    qualifications: [
      {
        qualified: true
      }
    ],
    createdAt: now - daysInMs(2),
    updatedAt: now - daysInMs(2),
    searchName: 'Daniel Bryant',
    dueActivity: '2019-08-09T23:59:45.286+0000',
    state: 'MI',
    zip: '48009',
    ageRange: '41-65',
    gender: 'Male',
    shareUrl:
      'https://everdays.com/funeral-homes/usa/tx/san-antonio/mission-park-funeral-chapel-2/leo-garcia?skipMat=true',
    demoInformant: 'No',
    demoReceptivityScore: 'Best',
    demoUrl: 'https://evdy.me/BJCgyYySV?v=78',
    demoDecedentAge: '63'
  },
  {
    _id: 'demo-robert-lopez',
    memorials: [
      // "5d26105416eda4de0b265248"
    ],
    companies: [
      // "5a0c993973671bdadc47438c"
    ],
    lifecycleStage: 'meeting-set',
    isArchived: false,
    userRef: '5d261a71305690f1f32c523f',
    name: {
      first: 'Robert',
      last: 'Lopez',
      middle: ''
    },
    afp: demoDirectors[2],
    phone: '5105551234',
    activity: [
      {
        currentStage: 'new',
        status: 'open',
        isArchived: false,
        attempt: 1,
        _id: '5d261a71305690f1f32c5247',
        createdAt: now - daysInMs(9),
        type: 'stage',
        dueDate: now + daysInMs(5),
        taskList: []
      },
      {
        currentStage: 'new',
        status: 'open',
        isArchived: false,
        attempt: 1,
        _id: '5d683f4b44dea735f2770172',
        type: 'note',
        message: 'Called once, does not connect',
        createdBy: demoDirectors[0],
        dueDate: null,
        closeReason: '',
        taskList: [],
        createdAt: now - daysInMs(5),
        updatedAt: now - daysInMs(5)
      },
      {
        currentStage: 'meeting-set',
        status: 'open',
        isArchived: false,
        attempt: 1,
        _id: '5d261a71305690f1f32c5248',
        createdAt: now - daysInMs(3),
        type: 'stage',
        dueDate: now + daysInMs(5),
        taskList: []
      }
    ],
    scoreHistory: [],
    qualifications: [
      {
        qualified: true
      }
    ],
    createdAt: now - daysInMs(15),
    updatedAt: now - daysInMs(15),
    searchName: 'Robert Lopez',
    dueActivity: '2019-08-09T23:59:45.286+0000'
  },
  {
    _id: 'demo-erin-anderson',
    memorials: [demoAnnouncements[2]],
    companies: [
      // "5a0c993973671bdadc47438c"
    ],
    lifecycleStage: 'meeting-held',
    isArchived: false,
    userRef: '5d261a71305690f1f32c523f',
    name: {
      first: 'Erin',
      last: 'Anderson',
      middle: ''
    },
    afp: demoDirectors[1],
    phone: '7145551234',
    activity: [
      {
        currentStage: 'new',
        status: 'open',
        isArchived: false,
        attempt: 1,
        _id: '5d261a71305690f1f32c5247',
        createdAt: now - daysInMs(11),
        type: 'stage',
        dueDate: now - daysInMs(8),
        taskList: []
      },
      {
        currentStage: 'new',
        status: 'open',
        isArchived: false,
        attempt: 1,
        _id: '5d683f4b44dea735f2770172',
        type: 'note',
        message: 'Looking forward to hearing from us. Recently lost a close family friend',
        createdBy: demoDirectors[0],
        dueDate: null,
        closeReason: '',
        taskList: [],
        createdAt: now - daysInMs(5),
        updatedAt: now - daysInMs(5)
      },
      {
        currentStage: 'meeting-set',
        status: 'open',
        isArchived: false,
        attempt: 1,
        _id: '5d683f4b44dea735f2770173',
        type: 'stage',
        message: '',
        createdBy: demoDirectors[0],
        dueDate: null,
        closeReason: '',
        taskList: [],
        createdAt: now - daysInMs(3),
        updatedAt: now - daysInMs(3)
      },
      {
        currentStage: 'meeting-held',
        status: 'open',
        isArchived: false,
        attempt: 1,
        _id: '5d683f4b44dea735f2770174',
        type: 'stage',
        message: '',
        createdBy: demoDirectors[0],
        dueDate: now - daysInMs(2),
        closeReason: '',
        taskList: [],
        createdAt: now - daysInMs(2),
        updatedAt: now - daysInMs(2)
      }
    ],
    scoreHistory: [],
    qualifications: [
      {
        qualified: true
      }
    ],
    createdAt: now - daysInMs(15),
    updatedAt: now - daysInMs(15),
    searchName: 'Erin Anderson',
    dueActivity: '2019-08-09T23:59:45.286+0000'
  },
  {
    _id: 'demo-brandon-downs',
    memorials: [
      // "5d26105416eda4de0b265248"
    ],
    companies: [
      // "5a0c993973671bdadc47438c"
    ],
    lifecycleStage: 'closed',
    isArchived: false,
    userRef: '5d261a71305690f1f32c523f',
    name: {
      first: 'Brandon',
      last: 'Downs',
      middle: ''
    },
    phone: '7145551234',
    activity: [
      {
        currentStage: 'new',
        status: 'open',
        isArchived: false,
        attempt: 1,
        _id: '5d261a71305690f1f32c5247',
        createdAt: now - daysInMs(10),
        type: 'stage',
        dueDate: now + daysInMs(3),
        taskList: []
      },
      {
        currentStage: 'meeting-set',
        status: 'open',
        isArchived: false,
        attempt: 1,
        _id: '5d261a71305690f1f32c5248',
        createdAt: now - daysInMs(3),
        type: 'stage',
        dueDate: now + daysInMs(3),
        taskList: []
      },
      {
        currentStage: 'meeting-held',
        status: 'open',
        isArchived: false,
        attempt: 1,
        _id: '5d261a71305690f1f32c5249',
        createdAt: now - daysInMs(2),
        type: 'stage',
        dueDate: now + daysInMs(3),
        taskList: []
      },
      {
        currentStage: 'closed',
        status: 'open',
        isArchived: false,
        attempt: 1,
        _id: '5d261a71305690f1f32c52410',
        createdAt: now - daysInMs(1),
        type: 'stage',
        dueDate: null,
        taskList: [],
        closeReason: 'purchased'
      }
    ],
    scoreHistory: [],
    qualifications: [
      {
        qualified: true
      }
    ],
    createdAt: now - daysInMs(10),
    updatedAt: now - daysInMs(10),
    searchName: 'Brandon Downs',
    dueActivity: '2019-08-09T23:59:45.286+0000'
  },
  {
    _id: 'demo-dana-miller',
    memorials: [
      // "5d26105416eda4de0b265248"
    ],
    companies: [
      // "5a0c993973671bdadc47438c"
    ],
    lifecycleStage: 'closed',
    isArchived: false,
    userRef: '5d261a71305690f1f32c523f',
    name: {
      first: 'Dana',
      last: 'Miller',
      middle: ''
    },
    phone: '7145551234',
    activity: [
      {
        currentStage: 'new',
        status: 'open',
        isArchived: false,
        attempt: 1,
        _id: '5d261a71305690f1f32c5247',
        createdAt: now - daysInMs(6),
        type: 'stage',
        dueDate: now + daysInMs(3),
        taskList: []
      },
      {
        currentStage: 'meeting-set',
        status: 'open',
        isArchived: false,
        attempt: 1,
        _id: '5d261a71305690f1f32c5248',
        createdAt: now - daysInMs(5),
        type: 'stage',
        dueDate: now + daysInMs(3),
        taskList: []
      },
      {
        currentStage: 'meeting-held',
        status: 'open',
        isArchived: false,
        attempt: 1,
        _id: '5d261a71305690f1f32c5249',
        createdAt: now - daysInMs(2),
        type: 'stage',
        dueDate: now + daysInMs(3),
        taskList: []
      },
      {
        currentStage: 'closed',
        status: 'open',
        isArchived: false,
        attempt: 1,
        _id: '5d261a71305690f1f32c5210',
        createdAt: now - daysInMs(0),
        type: 'stage',
        dueDate: null,
        taskList: [],
        closeReason: 'not-qualified'
      }
    ],
    scoreHistory: [],
    qualifications: [
      {
        qualified: true
      }
    ],
    createdAt: now - daysInMs(7),
    updatedAt: now - daysInMs(7),
    searchName: 'Dana Miller',
    dueActivity: '2019-08-09T23:59:45.286+0000'
  }
];

const monthRange = 27;
const anncTotal = 529;
const anncAverage = 19.6;
const communityTotal = 78756;
const communityAverage = 148.9;
const familyTotal = 1265;
const familyAverage = 2.4;

export const demoReport = {
  announcements: {
    average_per_ann: anncAverage,
    graphArray: makeMonthlyGraphArray(anncTotal, monthRange),
    total: anncTotal,
    unit: 'month'
  },
  community_reached: {
    average_per_ann: communityAverage,
    graphArray: makeMonthlyGraphArray(communityTotal, monthRange),
    total: communityTotal,
    unit: 'month'
  },
  directors: [
    {
      _id: 'christine-smith',
      directorName: 'Christine Smith',
      m: 68
    },
    {
      _id: 'jennifer-smith',
      directorName: 'Jennifer Smith',
      m: 50
    },
    {
      _id: 'theo-smith',
      directorName: 'Theo Smith',
      m: 41
    },
    {
      _id: 'Alicia-smith',
      directorName: 'Alicia Smith',
      m: 19
    },
    {
      _id: 'bryan-smith',
      directorName: 'Bryan Smith',
      m: 2
    }
  ],
  family_members: {
    average_per_ann: familyAverage,
    graphArray: makeMonthlyGraphArray(familyTotal, monthRange),
    total: familyTotal,
    unit: 'quarter'
  },
  integration_count: 0,
  locations: [
    {
      _id: 'smith-funeral-home-north',
      estimated_cases_year: 0.359,
      home: 'Memorial Funeral Home',
      m: 283
    },
    {
      _id: 'smith-funeral-home-south',
      estimated_cases_year: 0.326,
      home: 'Smith Funeral Home South',
      m: 257
    }
  ],
  reviews: {
    allReviews: [
      {
        message:
          'During this difficult time they made everything so, from providing the picture boards to being able to order flowers on site. They think of every detail so you don’t have to. Also making the notifications to social security and life insurance companies was a relief.',
        rating: 1,
        name: { first: 'Susan', last: 'S.' },
        _id: 'first-susan-s-review',
        createdAt: moment(now - daysInMs(3)).format('YYYY-MM-DDTHH:mm:ss.SSSZZ')
      },
      {
        message:
          'Once again Smith Funeral Home did a fabulous job on everything. They were so attentive, kind and caring. Our family has used them for years. Thank you for making this sad time a wonderful one.',
        rating: 1,
        name: { first: 'Kenneth', last: 'M.' },
        _id: 'second-kenneth-m-review',
        createdAt: moment(now - daysInMs(7)).format('YYYY-MM-DDTHH:mm:ss.SSSZZ')
      },
      {
        message:
          'Courteous, polite, anticipated our needs. Everything as perfect. Made us very comfortable from our first visit until the last!',
        rating: 1,
        name: { first: 'Dora', last: 'O.' },
        _id: 'third-dora-o-review',
        createdAt: moment(now - daysInMs(30)).format('YYYY-MM-DDTHH:mm:ss.SSSZZ')
      },
      {
        message:
          'We used Smith Funeral Home for my sister-in-law. They were great in handling our needs especially Sandra and Eric. Thank you. ',
        rating: 1,
        name: { first: 'Mitchell', last: 'E.' },
        _id: 'fourth-mitchell-e-review',
        createdAt: moment(now - daysInMs(45)).format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
        source: 'google'
      }
    ],
    negative_reviews: 0,
    neutral_reviews: 0,
    positive_reviews: 5,
    total_evdy_google_rating: 5,
    total_evdy_google_reviews: 5,
    total_reviews: 5
  },
  to_date: '08/28/2019'
};

export const makeMemorialStatsArray = (total, monthRange) => {
  let array = [];

  for (let i = 0; i < total; i++) {
    const month = getRandomInt(0, monthRange);
    const now = Date.now();
    const monthDate = moment(now)
      .subtract(month, 'months')
      .valueOf();

    const dateObj = { date: new Date(monthDate).toISOString() };
    array.push(dateObj);
  }

  return array.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf());
};

export const demoMemorialStats = makeMemorialStatsArray(anncTotal, monthRange);

export const demoActivities = [
  {
    _id: 'director-care-anniversary',
    type: 'madeByJourney',
    createdAt: now - daysInMs(2),
    sender: {
      name: 'Everdays',
      image: evdySquareLogo
    },
    memorialRef: demoAnnouncements[1],
    message: `The anniversary for ${nameString(
      demoAnnouncements[1].name
    )}'s Announcement is next week, you should send the ${
      demoAnnouncements[1].name.last
    } family a note.`
  },
  {
    _id: 'director-care-birthday',
    type: 'madeByJourney',
    createdAt: now - daysInMs(7),
    sender: {
      name: 'Everdays',
      image: evdySquareLogo
    },
    memorialRef: demoAnnouncements[1],
    message: `The birthday of ${nameString(
      demoAnnouncements[1].name
    )} is next week, you should reach out to the family.`
  },
  {
    _id: 'director-care-100-views',
    type: 'directorAnnViewed100',
    createdAt: now - daysInMs(26),
    sender: {
      name: nameString(demoDirectors[0].name)
    },
    memorialRef: demoAnnouncements[1],
    message: `Great work, ${demoDirectors[0].name.first}, for building a community of support around the Johnson family. The Announcement has received over 100 views!`
  },
  {
    _id: 'director-care-first-annc',
    type: 'directorMemorialFirst',
    createdAt: now - daysInMs(28),
    sender: {
      name: nameString(demoDirectors[0].name)
    },
    memorialRef: demoAnnouncements[1],
    message: `Congratulations to ${nameString(
      demoDirectors[0].name
    )} for creating our first Announcement! You're bringing family and friends together at a time it matters most.`
  }
];
