"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDayRange = exports.monthsAbbr = exports.months = void 0;
var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
exports.months = months;
var monthsAbbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
exports.monthsAbbr = monthsAbbr;

var getDayRange = function getDayRange(dateObj) {
  var dayLimit = 31;

  switch (parseInt(dateObj.month)) {
    case 1:
      dayLimit = dateObj.year % 4 === 0 ? 29 : 28;
      break;

    case 3:
    case 5:
    case 8:
    case 10:
      dayLimit = 30;
      break;

    default:
  }

  return dayLimit;
};

exports.getDayRange = getDayRange;