"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchInboxAction = exports.fetchInboxFailure = exports.fetchInboxSuccess = exports.fetchInboxRequest = void 0;

var types = _interopRequireWildcard(require("../../constants/webAnnouncement/inbox"));

var _constants = require("../../constants");

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var fetchInboxRequest = function fetchInboxRequest() {
  return {
    type: types.FETCH_INBOX_REQUEST
  };
};

exports.fetchInboxRequest = fetchInboxRequest;

var fetchInboxSuccess = function fetchInboxSuccess(_ref) {
  var data = _ref.data;
  return {
    type: types.FETCH_INBOX_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.fetchInboxSuccess = fetchInboxSuccess;

var fetchInboxFailure = function fetchInboxFailure(error) {
  return {
    type: types.FETCH_INBOX_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.fetchInboxFailure = fetchInboxFailure;

var fetchInboxAction = function fetchInboxAction() {
  return function (dispatch) {
    dispatch(fetchInboxRequest());
    return _index.default.get("".concat(_constants.API_URL, "/inbox")).then(function (res) {
      return dispatch(fetchInboxSuccess(res));
    }).catch(function (err) {
      return dispatch(fetchInboxFailure((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.fetchInboxAction = fetchInboxAction;