"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MEDIA_REPORT_FAILURE = exports.MEDIA_REPORT_SUCCESS = exports.MEDIA_REPORT_REQUEST = void 0;
var MEDIA_REPORT_REQUEST = 'MEDIA_REPORT_REQUEST';
exports.MEDIA_REPORT_REQUEST = MEDIA_REPORT_REQUEST;
var MEDIA_REPORT_SUCCESS = 'MEDIA_REPORT_SUCCESS';
exports.MEDIA_REPORT_SUCCESS = MEDIA_REPORT_SUCCESS;
var MEDIA_REPORT_FAILURE = 'MEDIA_REPORT_FAILURE';
exports.MEDIA_REPORT_FAILURE = MEDIA_REPORT_FAILURE;