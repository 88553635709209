"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.array.from.js");

require("core-js/modules/es6.array.slice.js");

require("core-js/modules/es6.function.name.js");

require("core-js/modules/es6.weak-map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.members = exports.denormalize = exports.normalize = exports.member = void 0;

require("core-js/modules/es6.array.map.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.array.find.js");

var _omit = _interopRequireDefault(require("lodash-es/omit"));

var actions = _interopRequireWildcard(require("../../../constants/dash/annc"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var member = {
  name: {
    first: '',
    last: '',
    middle: ''
  },
  integrationMeta: {
    precoa: {
      inSteppingStones: false
    }
  },
  location: {
    isNearby: false,
    zip: ''
  },
  ageRangeTxt: '',
  phone: '',
  email: '',
  relation: '',
  type: 'manager',
  locationNearby: '',
  primarySurvivor: false,
  hideInCondolences: false,
  muteJourney: false
};
exports.member = member;
var omissions = ['__v'];

var normalize = function normalize(d) {
  return (Array.isArray(d) ? d : [d]).filter(Boolean).map(function (x) {
    var newMem = _objectSpread({}, x);

    if (x.locationNearby) {
      newMem.location = _objectSpread(_objectSpread({}, newMem.location), {}, {
        isNearby: x.locationNearby === 'Nearby'
      });
    }

    return (0, _omit.default)(newMem, omissions);
  });
};

exports.normalize = normalize;

var denormalize = function denormalize(x) {
  var newMem = _objectSpread({}, x);

  newMem.locationNearby = x.locationNearby || (x.location.isNearby ? 'Nearby' : 'Unsure');
  return newMem;
};

exports.denormalize = denormalize;

var members = function members() {
  var array = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

  var _ref = arguments.length > 1 ? arguments[1] : undefined,
      data = _ref.data,
      action = _objectWithoutProperties(_ref, ["data"]);

  switch (action.type) {
    case actions.SET_MEMBERS:
      return _toConsumableArray(normalize(data));

    case actions.PUSH_MEMBER:
      return [].concat(_toConsumableArray(array), _toConsumableArray(normalize(data)));

    case actions.EDIT_MEMBER:
      return array.map(function (x, idx) {
        if (idx !== action.idx) return x;
        return _objectSpread(_objectSpread({}, x), normalize(data)[0]);
      });

    case actions.REMOVE_MEMBER:
      return array.filter(function (x, idx) {
        return idx !== action.idx;
      });

    case actions.DEMOTE_MEMBER:
      {
        var demotedOwner = false;
        var newArray = array.map(function (x, idx) {
          if (idx !== action.idx) return x;

          if (x.type === 'owner') {
            demotedOwner = true;
          }

          return _objectSpread(_objectSpread({}, x), {}, {
            type: 'viewer'
          });
        });

        if (demotedOwner) {
          var manager = newArray.find(function (m) {
            return m.type === 'manager';
          });
          manager.type = 'owner';
        }

        return newArray;
      }

    case actions.DEMOTE_MEMBER_BY_ID:
      {
        var _demotedOwner = false;

        var _newArray = array.map(function (x) {
          if (x._id !== action._id) return x;

          if (x.type === 'owner') {
            _demotedOwner = true;
          }

          return _objectSpread(_objectSpread({}, x), {}, {
            type: 'viewer'
          });
        });

        if (_demotedOwner) {
          var _manager = _newArray.find(function (m) {
            return m.type === 'manager';
          });

          _manager.type = 'owner';
        }

        return _newArray;
      }

    case actions.anncReset:
      return [];

    default:
      return array;
  }
};

exports.members = members;