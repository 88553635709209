const port = process.env.API_PORT || 1350;
export const host = process.env.HOST || 'localhost';

export const getAPIUrl = (v = '', passedPort = port) => {
  switch (process.env.DEPLOY_ENV) {
    case 'dev':
      return `https://dev.everdays.com${v}`;
    case 'dev2':
      return `https://dev2.everdays.com${v}`;
    case 'prod':
      return `https://everdays.com${v}`;
    case 'localDns':
      return `https://local.everdays.com:${passedPort}${v}`;
    default:
      return `http://${host}:${passedPort}${v}`;
  }
};

export const API_URL = getAPIUrl();

export const API_URL_V2 = (() => getAPIUrl('/v2'))();

export const CHAT_API_URL = (() => {
  switch (process.env.DEPLOY_ENV) {
    case 'prod':
      return 'https://chat-prod.everdays.com/api/messages';
    case 'localChat':
      return 'http://localhost:8088/api/messages';
    default:
      return 'https://chat-dev.everdays.com/api/messages';
  }
})();

const getVideoUploadUrl = () => {
  switch (process.env.DEPLOY_ENV) {
    case 'dev':
    case 'prod':
      return `http://52.54.92.58:1351`;
    default:
      return `http://${host}:1351`;
  }
};

export const VIDEO_UPLOAD_URL = getVideoUploadUrl();

export const CHAT_HOSTNAME = (() => {
  switch (process.env.DEPLOY_ENV) {
    case 'prod':
      return 'chat-prod.everdays.com';
    case 'localChat':
      return 'localhost';
    default:
      return 'chat-dev.everdays.com';
  }
})();

export const CHAT_BOSH_URL = (() => {
  switch (process.env.DEPLOY_ENV) {
    case 'prod':
      return 'https://bosh-prod.everdays.com/http-bind';
    case 'localChat':
      return 'http://localhost:5280/http-bind';
    default:
      return 'https://bosh-dev.everdays.com/http-bind';
  }
})();

export const LAMBDA_URL =
  'https://z4unjm07yj.execute-api.us-east-1.amazonaws.com/prod/compressImage';

/* LINKS */
export const HOME_LINK = 'https://everdays.com/';
export const APPLE_LINK =
  'https://apps.apple.com/us/app/everdays-funeral-announcements/id1123339295';
export const GOOGLE_LINK =
  'https://play.google.com/store/apps/details?id=com.izimobile.requiem&hl=en_US';

/* Google Maps */
export const GMAPS_API_KEY = (() => {
  switch (process.env.DEPLOY_ENV) {
    case 'dev':
    case 'dev2':
    case 'prod':
      return 'AIzaSyAjB4UVXYJ8emTH2fWmcFq6Gn9wtId3vi4';
    default:
      return 'AIzaSyC3KlUDlnrl5U2wa65hbhe_IocOL9YLg_w';
  }
})();
export const GMAPS_URL = 'https://maps.googleapis.com/maps/api/staticmap';

export const GMAPS_PLACES_URL = `https://maps.googleapis.com/maps/api/js?key=${GMAPS_API_KEY}&libraries=places`;

/* SF */
const sfEnv = process.env.DEPLOY_ENV === 'prod' ? '' : '--torrentdev';
export const SF_BASE_URL = `https://everdays2017${sfEnv}.lightning.force.com/one/one.app#/sObject/`;

export const LT_BASE_URL =
  process.env.DEPLOY_ENV === 'prod'
    ? 'http://www.ltkeepsakes.com/'
    : 'http://chppw.algqj.servertrust.com/';
export const LT_API_URL =
  process.env.DEPLOY_ENV === 'prod'
    ? 'https://api3.legacytouch.com/'
    : 'https://devapi.legacytouch.com/';
