"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getNextWeekDate = void 0;

var getNextWeekDate = function getNextWeekDate() {
  var today = new Date();
  var weekInMs = 1000 * 60 * 60 * 24 * 7;
  var nextWeek = new Date(today.getTime() + weekInMs);
  var nextWeekMonth = nextWeek.getMonth();
  var nextWeekDate = nextWeek.getDate();
  var nextWeekYear = nextWeek.getFullYear();
  return {
    nextWeekMonth: nextWeekMonth,
    nextWeekDate: nextWeekDate,
    nextWeekYear: nextWeekYear
  };
};

exports.getNextWeekDate = getNextWeekDate;