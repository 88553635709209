"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_REQUIRED_FIELDS_FAILURE = exports.GET_REQUIRED_FIELDS_SUCCESS = exports.GET_REQUIRED_FIELDS_REQUEST = void 0;
var GET_REQUIRED_FIELDS_REQUEST = 'GET_REQUIRED_FIELDS_REQUEST';
exports.GET_REQUIRED_FIELDS_REQUEST = GET_REQUIRED_FIELDS_REQUEST;
var GET_REQUIRED_FIELDS_SUCCESS = 'GET_REQUIRED_FIELDS_SUCCESS';
exports.GET_REQUIRED_FIELDS_SUCCESS = GET_REQUIRED_FIELDS_SUCCESS;
var GET_REQUIRED_FIELDS_FAILURE = 'GET_REQUIRED_FIELDS_FAILURE';
exports.GET_REQUIRED_FIELDS_FAILURE = GET_REQUIRED_FIELDS_FAILURE;