"use strict";

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.array.slice.js");

require("core-js/modules/es6.array.from.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePendingAction = void 0;

require("core-js/modules/es6.function.name.js");

var _react = require("react");

var _reactRedux = require("react-redux");

var _pendingAction = require("@evdy/web-redux/dist/lib/pendingAction");

var _invitePage = require("@evdy/web-redux/dist/lib/invitePage");

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var loveComment = _invitePage.invitePageOperations.loveComment,
    unloveComment = _invitePage.invitePageOperations.unloveComment,
    loveMedia = _invitePage.invitePageOperations.loveMedia,
    unloveMedia = _invitePage.invitePageOperations.unloveMedia,
    leaveComment = _invitePage.invitePageOperations.leaveComment,
    leaveCondolence = _invitePage.invitePageOperations.leaveCondolence;
/**
 * Usage:
 * const actionProps = {
    actionType: 'love',
    mediaType: 'condolence',
    authCallback: openShellLoginModal,
    pendingAction,
    userData,
    memorialId
  };
 * const [handleLove] = usePendingAction(actionProps)
 *
 * <button onClick={() => handleLove({ mediaId, commentId, isLiked })}>Love</button>
 *
 */

/**
 * For invitation actions that require authentication, the action
 * needs to pend until the authentication flow can finish. This hook
 * takes the action and media type and return a callback to trigger
 * the page action.
 *
 * Suported actions: love | comment | leaveCondolence
 * Support media types: photo | condolence | familymessage
 *
 * TODO: add support for video and photo actions
 *
 * @param {Object} pendingActionParams
 * @param {String} pendingActionParams.actionType - the type of action to perform
 * @param {String} pendingActionParams.mediaType - the type of action to perform
 * @param {Function} pendingActionParams.authCallback - function that opens the login flow
 * @param {Object} pendingActionParams.pendingAction - action type saved to redux
 * @param {Object} pendingActionParams.userData - current user's data
 * @param {Object} pendingActionParams.memorialId - invitation id
 */

var usePendingAction = function usePendingAction(_ref) {
  var actionType = _ref.actionType,
      mediaType = _ref.mediaType,
      authCallback = _ref.authCallback,
      _ref$pendingAction = _ref.pendingAction,
      pendingAction = _ref$pendingAction === void 0 ? {} : _ref$pendingAction,
      _ref$userData = _ref.userData,
      userData = _ref$userData === void 0 ? {} : _ref$userData,
      memorialId = _ref.memorialId;
  var dispatch = (0, _reactRedux.useDispatch)();

  var _useState = (0, _react.useState)({
    mediaId: '',
    commentId: '',
    messageObj: {},
    isLiked: null
  }),
      _useState2 = _slicedToArray(_useState, 2),
      actionProps = _useState2[0],
      setActionProps = _useState2[1];

  var _ref2 = userData || {},
      userId = _ref2._id,
      userNameUpdatedOnce = _ref2.userNameUpdatedOnce,
      email = _ref2.email,
      shellEmail = _ref2.shellEmail,
      _ref2$name = _ref2.name;

  _ref2$name = _ref2$name === void 0 ? {} : _ref2$name;
  var userLastName = _ref2$name.last;
  var handleAction = (0, _react.useCallback)(function (_ref3) {
    var mediaId = _ref3.mediaId,
        commentId = _ref3.commentId,
        messageObj = _ref3.messageObj,
        isLiked = _ref3.isLiked;

    switch (actionType) {
      case 'love':
        var loveOptions = [memorialId, mediaId, mediaType, commentId];
        var isCommentLove = !!commentId;

        if (isLiked) {
          isCommentLove ? dispatch(unloveComment.apply(void 0, loveOptions)) : dispatch(unloveMedia.apply(void 0, loveOptions));
        } else {
          isCommentLove ? dispatch(loveComment.apply(void 0, loveOptions)) : dispatch(loveMedia.apply(void 0, loveOptions));
        }

        break;

      case 'comment':
        dispatch(leaveComment({
          memorialId: memorialId,
          mediaId: mediaId,
          mediaType: mediaType,
          comment: messageObj
        }));
        break;

      case 'leaveCondolence':
        dispatch(leaveCondolence({
          memorialId: memorialId,
          condolence: messageObj
        }));
        break;

      default:
        break;
    }
  }, [actionType, dispatch, mediaType, memorialId]);

  var callBack = function callBack(_ref4) {
    var mediaId = _ref4.mediaId,
        commentId = _ref4.commentId,
        messageObj = _ref4.messageObj,
        isLiked = _ref4.isLiked;

    if (!userId || !userNameUpdatedOnce) {
      authCallback();
      dispatch((0, _pendingAction.storePendingAction)(actionType));
      setActionProps({
        mediaId: mediaId,
        commentId: commentId,
        messageObj: messageObj,
        isLiked: isLiked
      });
    } else {
      handleAction({
        mediaId: mediaId,
        commentId: commentId,
        messageObj: messageObj,
        isLiked: isLiked
      });
    }
  };

  (0, _react.useEffect)(function () {
    if (userId && (email || shellEmail) && userLastName && userNameUpdatedOnce && pendingAction.type === actionType) {
      handleAction(actionProps);
      (0, _pendingAction.clearPendingAction)();
    }
  }, [actionProps, actionType, email, handleAction, pendingAction.type, shellEmail, userId, userLastName, userNameUpdatedOnce]);
  return [callBack];
};

exports.usePendingAction = usePendingAction;