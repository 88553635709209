import React, { useEffect } from 'react';

import NavBar from '../NavBar';
import FooterSection from '../shared/Footer/FooterSection';
import AsSeenIn from '../AsSeenIn';

import ReadyToPlan from '../ReadyToPlan';
import EverdaysGuaranteeSection from '../EverdaysGuaranteeSection';
import HowEverdaysWorks from '../HowEverdaysWorks';
import Testimonials from '../Testimonials';
import FAQ from '../FAQ';

import { useHideIntercom, useVerifyCookie } from '../../customHooks';

import styles from './Home.module.scss';

import jimK from '../../public/images/jim-k-profile.jpg';
import shannonH from '../../public/images/shannon-h-profile.jpg';
import sallyN from '../../public/images/YA-GIRL-SALLY.jpg';

import heroImage from '../../public/images/home_page_hero.png';
import peaceOfMind from '../../public/images/peace_of_mind.png';

import nationLocation from '../../public/images/icons/nation-location.svg';
import aPlusProtect from '../../public/images/icons/a+-protection.svg';
import supportImg from '../../public/images/icons/support.svg';
import lockedPrice from '../../public/images/icons/locked-price.svg';
import resourcesImg from '../../public/images/icons/resources.svg';
import topNotch from '../../public/images/icons/top-notch.svg';

const {
  home: homeClass,
  'home-hero': homeHeroClass,
  'hero-content': heroContentClass,
  'hero-subtitle': heroSubtitleClass,
  'hero-items': heroItemsClass,
  'hero-img': heroImgClass,
  'hero-ctas': heroDesktopCtasClass,
  'hero-cta-secondary': secondaryHeroCtaClass,
  'peace-of-mind': peaceOfMindClass,
  'peace-reasons': peaceReasonsClass,
  'peace-reason': peaceReasonClass,
  'secure-plan': securePlanClass,
  'secure-cards': secureCardsClass,
  'secure-card': secureCardClass
} = styles;

const Home = ({ location }) => {
  const testimonials = [
    {
      name: 'Sally N.',
      image: sallyN,
      stars: 5,
      copy: 'I kept putting it off, but knew I should get these decisions written down. Everdays made it so easy, and I had a nice plan my kids could access in less than 10 minutes.'
    },
    {
      name: 'Shannon H.',
      image: shannonH,
      stars: 5,
      copy: 'I knew I wanted to get my decisions down so my daughters wouldn’t have to worry about it. I love how simple it was and I feel good knowing my girls have what they need.'
    },
    {
      name: 'Jim K.',
      image: jimK,
      stars: 5,
      copy: 'I’m relieved knowing I’ve got my plan and my family knows what to do... so there won’t be any arguing or disagreements down the road.'
    }
  ];

  //hide intercom
  useHideIntercom();

  // add schema script to head
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify({
      '@context': 'http://schema.org',
      '@id': 'https://everdays.com/#organization',
      '@type': 'Organization',
      name: 'Everdays',
      url: 'https://everdays.com/',
      logo: 'https://v.fastcdn.co/u/337a8a6c/50377295-0-GradLogo.png',
      contactPoint: {
        '@type': 'ContactPoint',
        email: 'support@everdays.com',
        contactType: 'customer support',
        areaServed: 'US'
      },
      sameAs: [
        'https://www.facebook.com/JoinEverdays/',
        'https://www.linkedin.com/company/everdays-inc.'
      ]
    });
    document.head.appendChild(script);
  }, []);

  const ctaLink = useVerifyCookie('rq-sid') ? '/get-my-price' : 'https://try.everdays.com/plan';
  const handleGetStarted = () => (window.location.href = ctaLink);

  return (
    <div className={homeClass}>
      <NavBar {...{ location, ctaLink }} />
      <div className={homeHeroClass}>
        <div className={heroContentClass}>
          <h1>The best way to plan for what comes next</h1>
          <p className={heroSubtitleClass}>
            Part of living over 60 means looking to the future, and we’re here to help you plan
            everything from your retirement to your funeral.
          </p>
          <div className={heroDesktopCtasClass}>
            <button onClick={handleGetStarted}>Start My Free Plan</button>
          </div>
        </div>
        <img className={heroImgClass} src={heroImage} alt="" />
      </div>
      <AsSeenIn />
      <HowEverdaysWorks />
      <div className={peaceOfMindClass}>
        <h2>Peace of mind is part of every Plan</h2>
        <img src={peaceOfMind} alt="" />
        <div className={peaceReasonsClass}>
          <div className={peaceReasonClass}>
            <h3>How, when, & where you want</h3>
            <p>
              With Everdays, you can make your plan, see pricing options, and secure it for your
              family - all without ever having to go into a funeral home or meet with a sales agent.
            </p>
          </div>
          <div className={peaceReasonClass}>
            <h3>Everything is secure and accessible</h3>
            <p>
              Your simple online account gives you 24/7 access to your plan, where you can invite
              loved ones to review the details, make updates, and capture more of your wishes.
            </p>
          </div>
          <div className={peaceReasonClass}>
            <h3>We care about quality</h3>
            <p>
              We fully vet and contract with the very best funeral homes nationwide to ensure our
              plans are fulfilled by trusted funeral professionals who will guide your family when
              the time comes.
            </p>
          </div>
        </div>
      </div>
      <Testimonials {...{ testimonials }} />
      <FAQ />
      <ReadyToPlan />
      <FooterSection />
    </div>
  );
};

export default Home;
