"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.archiveInvite = exports.resetInviteDetails = exports.fetchInviteDetails = void 0;

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

var _actions = require("./actions");

var _constants = require("../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var instance = _index.default.create({
  baseURL: _constants.API_URL
}); // Fetch Invite Details


var fetchInviteDetails = function fetchInviteDetails(_ref) {
  var memorialId = _ref.memorialId,
      userData = _ref.userData,
      showAllEvents = _ref.showAllEvents;
  return function (dispatch) {
    var authType = userData ? 'private' : 'public';
    dispatch((0, _actions.fetchInviteDetailsRequest)());
    return instance.get("/v2/".concat(authType, "/memorial/").concat(memorialId), {
      params: {
        showAllEvents: showAllEvents
      }
    }).then(function (res) {
      return dispatch((0, _actions.fetchInviteDetailsSuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.fetchInviteDetailsFailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.fetchInviteDetails = fetchInviteDetails;

var resetInviteDetails = function resetInviteDetails() {
  return function (dispatch) {
    dispatch((0, _actions.resetInviteDetailsAction)());
  };
}; // Archive Invite


exports.resetInviteDetails = resetInviteDetails;

var archiveInvite = function archiveInvite(_ref2) {
  var memorialId = _ref2.memorialId;
  return function (dispatch) {
    dispatch((0, _actions.archiveInviteRequest)());
    return instance.put("/v2/private/memorial/".concat(memorialId, "/archive")).then(function (res) {
      return dispatch((0, _actions.archiveInviteSuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.archiveInviteFailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.archiveInvite = archiveInvite;