import React from 'react';
import { useSelector } from 'react-redux';

import PagesHeader from '@evdy/web-core/dist/components/PagesHeader2';
import PagesFooter from '@evdy/web-core/dist/components/PagesFooter';
import LegalTextHeader from '../../shared/LegalTextHeader';
import { renderAuthModal } from '@evdy/web-core/dist/lib/utils';

import './TermsAndConditions.scss';

/* Page for CLIENT HLC/Primary Agreement */
const TermsAndConditions = ({ location, children }) => {
  const { user = {} } = useSelector(({ authUser }) => ({
    user: authUser.data
  }));

  return (
    <>
      <PagesHeader currentPage="terms" pathname={location.pathname} queryString={location.search} />
      <div className="terms-and-conditions">
        <LegalTextHeader />
        <div className="terms">
          <h1>Terms and Conditions</h1>
          <p className="date-updated">
            <b>
              Last Modified: <span>July 6, 2020</span>
            </b>
          </p>
          <div className="terms-and-conditions-links">
            <a href="/clientagreement" target="_blank" rel="noopener noreferrer">
              Everdays Client Agreement
            </a>
          </div>
          <h4>HLC Agreement</h4>
          <p>
            <b>
              Please read this Authorization carefully. By clicking a button or checking a box
              marked “I Agree” (or something similar), Funeral Home acknowledges that they have
              read, understand and agree to be bound by each and every provision set forth herein,
              and you signify that you have the authority to enter into this Agreement on behalf of
              Funeral Home. If Funeral Home does not understand any aspect of this Authorization,
              its terms and conditions, Funeral Home is advised to consult with their own legal
              counsel for advice.
            </b>
          </p>
          <p>
            <b>
              Funeral Home may revoke this Authorization Agreement at any time as detailed below.
              The Internal Revenue Service does not require your consent to any provision of this
              document other than the certifications required to avoid backup withholding as noted
              in this Agreement.
            </b>
          </p>
          <p>
            This Funeral Home Authorization Agreement (“Agreement”) is entered into as of the date
            you click a button indicating your agreement with the terms of this Agreement between
            Funeral Home (as noted above, known as “Funeral Home” or “Participant”) and Homesteaders
            Life Company, an Iowa Corporation, and any and all of its subsidiaries and affiliates
            (“Homesteaders”). Each of Funeral Home and Homesteaders are referred to herein as a
            “Party” and collectively referred to as the “Parties.”
          </p>
          <p>
            <b>AUTHORIZATIONS EXTENDED</b> – Products chosen may be changed at any time and any
            changes will be indicated on the funeral home file record provided with each policy.
            Execution of this agreement authorizes Homesteaders Life Company and its agents to act
            on behalf of the signing Funeral Home. Individual agents may act without a specific
            appointment form being executed following execution of this Authorization Agreement.
          </p>
          <p>
            Along with the authorizations extended on the front of this Agreement, by signing this
            Agreement, Funeral Home agrees that the following authorizations are also extended.
          </p>
          <ul>
            <li>
              Products chosen may be changed at any time and any changes will be indicated on the
              Funeral Home file record provided with each policy. The products that do not qualify
              for the loyalty programs offered by Homesteaders can be found on myHomesteaders.com.
            </li>
            <li>
              To utilize the name of Funeral Home, including any of its branches, to create a market
              interest in life insurance and annuity products underwritten by Homesteaders to defray
              future funeral costs and final expenses.
            </li>
            <li>
              To execute on behalf of the Funeral Home binding Prearranged Funeral Agreements. The
              negotiation of a Prearranged Funeral Agreement shall be an activity undertaken by the
              agent as a representative of the Funeral Home and not as a representative of
              Homesteaders. Prior to taking any actions pursuant to this authorization, the agent
              shall contact the Funeral Home and secure the necessary information concerning pricing
              of merchandise and services, and specific forms or agreements in use by the Funeral
              Home. The agent’s authority is limited to only pricing, merchandise and services
              authorized by the funeral home as agreed by the funeral home and the agent, which may
              be by separate agreement.
            </li>
            <li>
              To accept on behalf of the Funeral Home, the ownership of life insurance and annuity
              products underwritten by Homesteaders to fund Prearranged Funeral Agreements and to
              irrevocably assign ownership of such policies to the Homesteaders Life Company Funeral
              Assurance Trust.
            </li>
          </ul>
          <p>
            The agent and the Funeral Home shall also obtain all necessary permits and licenses as
            may be required by applicable state and local statutes. If the authority of the life
            insurance agent is restricted by statute, the life insurance agent shall be authorized
            to act on behalf of the Funeral Home only to the extent authorized and allowed by law.
          </p>
          <p>
            Nothing contained in this Authorization Agreement shall be construed to create a
            guarantee by Homesteaders, or by licensed life insurance agents, to provide funeral
            services and/or merchandise under any Prearranged Funeral Agreement.
          </p>
          <p>
            This Authorization Agreement shall be continuing in nature until revoked by notice by
            the Funeral Home to both the agent and to Homesteaders, in writing, by ordinary mail.
            Homesteaders and its licensed life insurance agent shall be entitled to terminate this
            authorization by written notice to the Funeral Home. The termination provisions as to
            any of the parties shall be effective five (5) business days after mailing.
          </p>
          <h4>USE OF MyHomesteaders</h4>
          <p>
            Funeral Home authorizes the Authorized User referenced on the previous page to be
            granted user credentials to access Funeral Home’s myHomesteaders system, also known as
            the “Funeral Home Dashboard.”
          </p>
          <p>
            <b>Revocation of Access.</b> Either Party may revoke this authorization at any time for
            any reason or no reason at all. Funeral Home may revoke access by providing written
            Notice and Homesteaders may revoke access at its discretion, with or without notice.
            Access to the myHomesteaders dashboard will be revoked by Homesteaders when Homesteaders
            becomes aware that Funeral Home has changed ownership, in whole or in part, or believes
            that Funeral Home may have changed ownership.
          </p>
          <p>
            <b>Data Ownership, Intellectual Property.</b> Funeral Home acknowledges that as between
            Homesteaders and Funeral Home, Homesteaders owns all Data delivered to Funeral Home
            regardless of the means transmitted, this includes Funeral Home’s myHomesteaders system.
            Data shall include all information, including but not limited to, names, addresses,
            telephone numbers, email addresses, information about goods and services that correspond
            to pre-need contracts, information about any insurance policy or certificate assigned to
            Funeral Home or that corresponds to a pre-need contract, reports, lists, formulae,
            prices, algorithms, code, applets, APIs, trademarks, copyrights, and all other
            information without respect to form (collectively, “Homesteaders’ Data”).
          </p>
          <p>
            <b>Data Transmission.</b> Funeral Home agrees to notify Homesteaders of any changes in
            its Authorized User that is to receive the data. Notification will be made in writing to
            Homesteaders.
          </p>
          <p>
            <b>Non-Disclosure and Confidentiality.</b> Funeral Home agrees to treat all Homesteaders
            Data provided as confidential information (“Confidential Information”) and will not,
            without the prior written approval of Homesteaders, use, publish, disclose, copyright or
            authorize anyone else to use, publish, disclose or copyright, any Confidential
            Information during the Term of this Authorization, regardless of the form of the
            Confidential Information; provided, however, that Funeral Home may disclose the
            Confidential Information to (i) those employees, Authorized Users, officers, technical
            personnel, attorneys, and accountants who have a need to know and whose services are
            required and who shall have, prior to the disclosure of the Confidential Information,
            agreed to comply with the terms of these confidentiality provisions; (ii) any applicable
            legal or regulatory authority if so required; or (ii) with the consent of the disclosing
            party. Funeral Home agrees to provide security for the Confidential Information it
            receives in a manner reasonably sufficient to prevent a breach of the confidentiality
            required by this paragraph.
          </p>
          <p>
            Funeral Home agrees that the security it will provide for the data shall be consistent
            with the level of security provided by Homesteaders. Should a breach of data occur,
            Funeral Home will notify Homesteaders of said breach within 24 hours. Funeral Home will
            cooperate with Homesteaders regarding any breach of data including, but not limited to
            reporting of the breach, notification to individuals, providing information regarding
            the breach, and any other items necessary under applicable State or Federal laws or as
            may be required by Homesteaders.
          </p>
          <p>
            Funeral Home agrees to notify Authorized User of this Non-Disclosure and Confidentiality
            paragraph and understands Authorized User will be held to the same standards as Funeral
            Home. Funeral Home understands and agrees that Funeral Home is responsible for the
            actions of Authorized User under this paragraph and will indemnify Homesteaders for any
            violation of this paragraph by Authorized User.
          </p>
          <p>
            <b>Disclosure As Required By Law, Order or Injunction.</b> In the event Funeral Home or
            Authorized User receives a subpoena or other order issued by a court or governmental
            agency or other regulatory authority, or is otherwise required by law to disclose
            Confidential Information, Funeral Home or Authorized User shall provide prompt and
            reasonable notice of any such order, subpoena or required disclosure to Homesteaders
            prior to disclosure so that Homesteaders has the opportunity to obtain a protective
            order or other relief. In the event a protective order or other relief is not obtained,
            Funeral Home or Authorized User will furnish only that portion of the Confidential
            Information that is legally required.
          </p>
          <p>
            <b>Proprietary Rights.</b> All data received, and all copies thereof, shall be and
            remain the sole and exclusive property of Homesteaders. Upon request by Homesteaders,
            Funeral Home agrees to deliver immediately to Homesteaders the originals and all copies
            of any of the foregoing, including those held by Authorized User. The rights and
            obligations under this section shall survive any such return of such information and
            materials. Upon request by Homesteaders, Funeral Home agrees to destroy all data,
            including any back- ups, still in its possession and to provide Homesteaders
            certification of such destruction.
          </p>
          <p>
            <b>Notice.</b> All notices or other communications shall be in writing and mailed to
            Homesteaders at ATTENTION SALES ADMINISTRATION, P.O. Box 1756, Des Moines, IA 50306-1756
            and to Funeral Home or Authorized User at their respective addresses identified in this
            document.
          </p>
          <p>
            <b>Jurisdiction and Venue.</b> Any action, claims, dispute or proceeding arising from or
            relating to this Authorization shall be instituted and prosecuted in the courts located
            in Polk County, Iowa and each Party submits to the jurisdiction and venue of such courts
            and waives any defense relating to such personal jurisdiction and venue. This
            Authorization shall be governed by law of the state of Iowa.
          </p>
          <p>
            <b>Indemnification.</b> Homesteaders and its agents, directors, employees, affiliates,
            and members shall not be liable for any loss or liability incurred in connection with
            any act performed, or failed to be performed, in accordance with the terms of this
            Authorization, except for any loss or liability incurred due to fraud or willful
            misconduct by Homesteaders or someone acting with authority on its behalf. Homesteaders
            will not be liable to Funeral Home for any punitive, indirect, incidental, or
            consequential damages of any nature arising from this Authorization. Funeral Home shall,
            to the fullest extent permitted by law, indemnify and hold harmless Homesteaders and
            Homesteaders’ agents, directors, employees, affiliates and members from and against any
            and all liability, loss, cost, expense, or damage incurred or sustained due to the
            fraud, willful misconduct or negligence of Funeral Home or its Authorized User. Funeral
            Home shall defend and hold Homesteaders harmless from all claims, liabilities, damages,
            or judgments involving a third party, including Homesteaders’ costs and attorney fees,
            which arise as a result of Funeral Home’s failure to meet any of its obligations under
            this Authorization.
          </p>
          <p>
            <b>Assignment.</b> Homesteaders may assign its rights and obligations under this
            Authorization to any subsidiary, affiliate, or successor by merger or consolidation
            without notice to the Funeral Home, or to any other entity after 30 days’ written
            notice. The Funeral Home may not assign this Authorization without Homesteaders’ prior
            written consent of Homesteaders.
          </p>
          <p>
            <b>Waiver.</b> The failure of a party to insist on full compliance with any provision of
            this Agreement in a particular instance shall not preclude it from requiring full
            compliance thereafter.
          </p>
          <h4>CERTIFICATION BY FUNERAL HOME</h4>
          <p>
            <b>Under penalties of perjury, I certify that:</b>
            <br />
            <b>
              (1) The number provided is my correct taxpayer identification number (or I am waiting
              for a number to be issued to me), and (2) I am not subject to backup withholding
              because: (a) I am exempt from backup withholding, or (b) I have not been notified by
              the Internal Revenue Service (IRS) that I am subject to backup withholding as a result
              of a failure to report all interest or dividends, or (c) the IRS has notified Funeral
              Home that it is no longer subject to backup withholding, and (3) I am a U.S. person
              (including a U.S. resident alien).
            </b>
          </p>
          <p>
            <b>eFUNERAL TERMS AND CONDITIONS</b>
          </p>
          <p>
            <b>RECITALS</b>
          </p>
          <p>
            WHEREAS, Homesteaders’ subsidiary, eFuneral Solutions, LLC (“EFS”) owns, develops and
            offers a certain software platform and hosting service to allow funeral homes to
            transact the business of pre-need or pre-arranged funeral plans digitally through an
            internet presence; and
          </p>
          <p>
            WHEREAS, Participant (as defined below) desires to utilize EFS’s software platform and
            hosting services to facilitate digital pre-arrangement and/or at-need funeral plan
            transactions, subject to the terms and conditions set forth in this Agreement; and
          </p>
          <p>
            WHEREAS, EFS and Participant wish to set out the terms and conditions between the
            parties in order to commence EFS’ Web Hosting Services to the Participant.
          </p>
          <b>EFS AGREEMENT</b>
          <p>NOW, THEREFORE, Provider (as defined below) and Participant agree as follows:</p>
          <ul>
            <li>
              <b>1. Definitions:</b>
            </li>
            <ul>
              <li>
                <b>1.1. </b>“Content” refers to all text, pictures, sound, graphics, video, links,
                and other data stored by the Participant on our Server computers.
              </li>
              <li>
                <b>1.2. </b>“Participant” refers to Funeral Home, its agents and authorized
                representatives which are also referred to by using the terms “they”, “them”,
                “their” and “theirs”.
              </li>
              <li>
                <b>1.3. </b>“Provider” refers to EFS, its agents and authorized representatives
                which are also referred to by using the terms “we”, “us”, “our” and “ours”.
              </li>
              <li>
                <b>1.4. </b>“Server computers” are computers owned, leased, or rented by the
                Provider for use by the Participant to host its own website for the purposes set
                forth in this EFS Agreement.
              </li>
              <li>
                <b>1.5. </b>“Web Hosting Services” refers to the services being provided by Provider
                which allow Participant to make its own website, or a webpage provided by Provider,
                accessible via the World Wide Web for the purposes set forth in this EFS Agreement.
              </li>
            </ul>
            <li>
              <b>2. Website Hosting.</b> EFS hereby authorizes Participant to utilize its Web
              Hosting Services to transact the business of pre-arranged funeral planning
              (“Services”) and Participant hereby acknowledges and agrees to utilize the Web Hosting
              Services for said purposes, in each instance in the manner specifically set forth
              herein.
            </li>
            <li>
              <b>3. Term and Termination.</b> The term of this EFS Agreement will be for one year
              commencing on the Effective Date and will be automatically renewed for successive
              one-year terms, unless either party gives notice to the other party of termination at
              least thirty (30) days prior to the end of the then-current term. Notwithstanding the
              foregoing, either party may terminate this EFS Agreement (i) with or without cause
              upon thirty (30) days prior written notice to the other party, or (ii) immediately
              upon the other party’s breach of the terms or conditions of this EFS Agreement.
              Termination or expiration of this EFS Agreement will not relieve any party of any
              obligations or liabilities that are expressly indicated to survive termination or
              expiration of this EFS Agreement and will be without prejudice to any rights that will
              have accrued to the benefit of any party prior to such termination or expiration.
              Sections 4, 5, 6, 7, 10, 11, 13, 14 and 17 will survive the expiration or termination
              of this EFS Agreement. Further, any termination of this EFS Agreement will not affect
              the rights of Consumers that may have effectuated Transactions through the Participant
              eFuneral Page.
            </li>
            <li>
              <b>4. Intellectual Property.</b>
            </li>
            <li>
              <b>(a) No Transfer of Intellectual Property. </b>Any technology, system, application
              tool, process, software, module, standard software application, know-how, methodology,
              copyright, trade secret, patent, trademark or other intellectual property owned or
              used by EFS as of the Effective Date of this EFS Agreement and any such intellectual
              property owned, acquired, developed or used by or for the benefit of EFS on or after
              the Effective Date of this EFS Agreement, and including, without limitation, the Web
              Hosting Services, and any and all data generated from use of the Participant eFuneral
              Page (collectively, “EFS Proprietary Rights”) will, to such extent, be and remain the
              exclusive property of EFS. Except as provided herein, Participant will have no rights,
              title or interests in any EFS Proprietary Rights as a result of this EFS Agreement and
              in no event will Participant obtain any source code from EFS. All use of EFS
              Proprietary Rights, including, without limitation, all use of the Participant eFuneral
              Page, will inure to the benefit of EFS.
            </li>
            <li>
              <b>(b) Permission to Use Consumer Personally Identifiable Information. </b>As between
              EFS and Participant, EFS will own any and all personally identifiable information that
              is provided to EFS regarding Consumers, regardless of how provided (“Consumer PII”).
              EFS may disclose the following Consumer PII to Participant: i) at the time a Consumer
              completes a Transaction through the Participant eFuneral Page; or ii) at the time a
              Consumer fails to complete a Transaction so Participant may contact Consumer to
              continue discussions regarding the Transaction: Consumer name, address, phone number
              and e-mail address. Participant may use Consumer PII only for the following purposes:
              to provide at-need and pre-need funeral arrangements, to provide on-going marketing
              for the purpose of pre-need funeral arrangements, as agreed to by Consumer and as may
              be required by any applicable State or Federal law, or any other business purposes the
              parties may hereafter agree to in writing. Consumer PII is “Confidential Information”
              as that term is defined in Section 5 below and Participant shall treat Consumer PII in
              a manner consistent with that section. Furthermore, Participant will modify or amend
              its privacy policies as necessary to share Consumer PII with EFS.
            </li>
            <li>
              <b>(a) Trademarks. </b>Neither party will have the right to use the trademarks,
              service marks, trade dress, logos and trade names (collectively, “Trademarks”) of the
              other party except as expressly authorized in this Section. During the term of this
              EFS Agreement, each party hereby grants to the other a nonexclusive, non‑assignable
              license to use the other’s Trademarks solely and exclusively for the purposes
              described in this EFS Agreement or any other agreement. In exercising this license,
              each party will comply with the instructions of the other as to the form and manner in
              which the party’s Trademarks will be used, including any direction as to quality,
              style and graphic integrity, and a party will not make any addition to, deletion from
              or other modification to the other’s Trademarks. Prior to distributing any promotional
              or other materials (including website content) bearing a party’s Trademarks, the other
              party will first provide the party a reasonable opportunity to review and approve the
              presentation of its Trademarks. Each party’s use of the Trademarks will inure to the
              other’s benefit.
            </li>
            <li>
              <b>5. Confidentiality. </b>In the course of their activities pursuant to this EFS
              Agreement, the parties anticipate that EFS may disclose Confidential Information to
              Participant. The parties wish to protect such Confidential Information in accordance
              with this Section.
            </li>
            <li>
              <b>(a) </b>For purposes hereof, “<u>Confidential Information</u>” means any trade
              secrets; any confidential, proprietary or competitively sensitive information,
              knowledge, designs, data, or know-how, including Consumer PII; or any other
              information considered reasonably as “confidential” that EFS discloses to Participant.
              Confidential Information does not include information that (i) is or hereafter becomes
              generally available to the public other than as a result of a disclosure by
              Participant (except that Consumer PII shall be considered Confidential Information
              notwithstanding such general availability), (ii) was already known to Participant
              prior to receipt from EFS as evidenced by prior written documents in its possession
              not subject to an existing confidentiality obligation to EFS, (iii) is disclosed to
              Participant on a non‑confidential basis by a person who is not in default of any
              confidentiality obligation to EFS, or (iv) is developed by or on behalf of Participant
              without reliance on Confidential received hereunder.
            </li>
            <li>
              <b>(b) </b>Participant will (i) use such Confidential Information solely in connection
              with the activities contemplated by this EFS Agreement and (ii) not disclose such
              Confidential Information to any person other than those of its agents and
              representatives who need to know such Confidential Information in order to accomplish
              the objectives for which it was disclosed. Participant will inform such agents and
              representatives of the confidential nature of the information and cause them to
              observe the limitations on the use thereof. Notwithstanding the foregoing, Participant
              may disclose Confidential Information to the extent necessary to comply with
              applicable laws or regulations or with an order issued by a court or regulatory body
              with competent jurisdiction; provided that, in connection with such disclosure,
              Participant notifies EFS in advance of such disclosure and uses commercially
              reasonable efforts to obtain confidential treatment or an appropriate protective
              order, to the extent available, with respect to such Confidential Information.
            </li>
            <li>
              <b>(c) </b>Upon request of EFS, Participant will promptly redeliver to EFS all
              Confidential Information provided to Participant in tangible form, and Participant
              will not retain any copies, extracts or other reproductions, in whole or in part, of
              such Confidential Information. Notwithstanding the foregoing, legal counsel to
              Participant will be permitted to retain in its files one copy of all Confidential
              Information to evidence the scope of and to enforce the party’s obligation of
              confidentiality under this Section.
            </li>
            <li>
              <b>6. Data Security. </b>EFS maintains security of Consumer PII at, or above, industry
              standards. Participant agrees that the security it will provide for any Consumer PII
              shall be consistent with the level of security provided by EFS. Should a breach of
              Consumer PII occur, Participant will within twenty-four (24) hours notify EFS of said
              breach. Participant will cooperate with EFS regarding any breach of Consumer PII
              including, but not limited to reporting of the breach, notification to individuals,
              providing information regarding the breach, and any other items necessary under
              applicable State or Federal laws or as may be required by EFS, regulators or law
              enforcement. Participant shall, to the fullest extent permitted by law, indemnify and
              hold harmless EFS and EFS’ parent company, agents, directors, employees, owners,
              affiliates and members from and against any and all liability, loss, cost, expense, or
              damage incurred or sustained due to the fraud, willful misconduct or negligence of
              Participant under this Paragraph. Participant shall defend and hold EFS and EFS’
              parent company, agents, directors, employees, owners, affiliates and members harmless
              from all claims, liabilities, damages, or judgments involving a third party, including
              EFS’ costs and attorney fees, which arise as a result of Participant’s failure to meet
              any of its obligations under this Paragraph.
            </li>
            <li>
              <b>7. Participant’s Obligation to Obtain Consumer Consent. </b>To the extent
              Participant provides Consumer PII to EFS, Participant represents and warrants that it
              has obtained all necessary consents to provide such information to EFS, for use by EFS
              in any manner consistent with the privacy policy posted on the Participant eFuneral
              Page. Participant also represents and warrants that to the extent it provides Consumer
              PII to EFS, such disclosure is consistent with any applicable agreements it has with
              the relevant Consumer and with any disclosures, policies, and notices Participant has
              presented to the relevant Consumer.
            </li>
            <li>
              <b>8. Disclaimer of Warranties. </b>EFS DISCLAIMS ALL WARRANTIES AND CONDITIONS,
              EXPRESS, IMPLIED OR STATUTORY, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES
              OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. EFS
              DOES NOT REPRESENT OR WARRANT THAT THE PARTICIPANT EFUNERAL PAGE WILL OPERATE WITHOUT
              INTERRUPTION. THE PARTICIPANT EFUNERAL PAGE IS PROVIDED “AS IS” AND “AS AVAILABLE.”
              PARTICIPANT ACKNOWLEDGES THAT PARTICIPANT HAS NOT ENTERED INTO THIS EFS AGREEMENT IN
              RELIANCE UPON ANY WARRANTY OR REPRESENTATION EXCEPT THOSE SPECIFICALLY SET FORTH
              HEREIN.
            </li>
            <li>
              <b>9. Remedies. </b>The parties agree that it would be impossible or inadequate to
              measure and calculate damages from breach of the provisions set forth in this EFS
              Agreement. Accordingly, the parties agree that the non-breaching party will have
              available, in addition to any other right or remedy available, the right to obtain an
              injunction from a court of competent jurisdiction restraining such breach or
              threatened breach of this Agreement and to specific performance of any such provision
              of this EFS Agreement.
            </li>
            <li>
              <b>10. Limitation of Liability. </b>IN NO EVENT WILL EFS, ITS AFFILIATES, LICENSORS OR
              SERVICE PROVIDERS OR ITS OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, OWNERS OR
              AGENTS, BE LIABLE TO PARTICIPANT (NOR TO ANY THIRD PARTY CLAIMING THROUGH PARTICIPANT)
              FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL LOSSES OR
              DAMAGES (INCLUDING LOSS OF PROFITS, ANTICIPATED PROFITS, REVENUES, ANTICIPATED
              SAVINGS, OR GOODWILL OR BUSINESS OPPORTUNITY) ARISING OUT OF OR IN CONNECTION WITH
              THIS EFS AGREEMENT. IN NO EVENT WILL EFS, ITS LICENSORS’, ITS SERVICE PROVIDERS’ OR
              ITS OR THEIR DIRECTORS’, OFFICERS’, EMPLOYEES’, OR AGENTS’ COMBINED AGGREGATE
              LIABILITY HEREUNDER TO PARTICIPANT OR ANY THIRD PARTY CLAIMING THROUGH PARTICIPANT FOR
              ANY CAUSE WHATSOEVER ARISING OUT OF OR IN CONNECTION WITH THIS EFS AGREEMENT EXCEED
              $10.00. THE FOREGOING LIMITATIONS OF LIABILITY WILL APPLY TO THE FULLEST EXTENT
              PERMITTED BY LAW, WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, NEGLIGENCE OR
              OTHER TORT, STRICT LIABILITY OR ANY OTHER BASIS, EVEN IF AN AUTHORIZED REPRESENTATIVE
              OF PARTICIPANT RELYING ON THIS LIMITATION OF LIABILITY OR ITS LICENSORS OR SERVICE
              PROVIDERS HAD BEEN ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES,
              AND WITHOUT REGARD TO THE SUCCESS OR EFFECTIVENESS OF OTHER REMEDIES.
            </li>
            <li>
              <b>11. Compliance with Laws. </b>At all times, each party’s performance under this EFS
              Agreement will comply with all applicable laws, rules and regulations, including all
              applicable laws, rules, regulations and best practices concerning data privacy and
              security. In the event either party breaches any of its obligations under this
              Section, the other party may take any action available under law or contract.
            </li>
            <li>
              <b>12. Expenses. </b>Each party will be responsible for any expenses incurred by it in
              connection with the performance of its obligations under this EFS Agreement.
            </li>
            <li>
              <b>13. Governing Law. </b>This EFS Agreement will be governed by the laws of the State
              of Iowa without reference to its rules of conflicts or choice of laws. All disputes
              arising from or relating to this EFS Agreement will be within the exclusive
              jurisdiction of the state and/or federal courts located within the State of Iowa and
              the parties hereby consent to such exclusive jurisdiction and waive objections to
              venue therein.
            </li>
            <li>
              <b>14. Restrictions on use. </b>Participant will not, directly or indirectly, (i)
              redistribute for commercial purposes, reverse engineer, disassemble, transfer,
              distribute or otherwise commercially exploit anything with respect to the Web Hosting
              Services; (ii) modify or make derivative works based upon the Web Hosting Services;
              (iii) access the Web Hosting Services in order to build a competitive product or
              service; (iv) contract with any other person or entity to design or operate similar
              web hosting or marketing services; or (v) use the Web Hosting Services in any manner
              inconsistent with the terms and conditions of this EFS Agreement. Additionally,
              Participant shall not use the Web Hosting Services to: (i) send spam or otherwise
              duplicative or unsolicited messages in violation of applicable laws; (ii) send or
              store infringing, obscene, threatening, libelous, or otherwise unlawful material,
              including material harmful to children or violative of third party privacy rights;
              (iii) send or store material containing software viruses, worms, Trojan horses or
              other harmful computer code, ﬁles, scripts, agents or programs; (iv) interfere with or
              disrupt the integrity or performance of the Services or the data contained therein; or
              (v) attempt to gain unauthorized access to the Web Hosting Services or its related
              data, systems or networks.
            </li>
            <li>
              <b>15. Binding Effect. </b>Participant agrees that this EFS Agreement shall be binding
              upon their respective directors, officers, employees, owners, agents, or any other
              person or entity it authorizes to use the services contemplated herein without further
              agreement or consent.
            </li>
            <li>
              <b>16. Entire Agreement; Amendment. </b>This EFS Agreement constitutes the entire
              agreement between the parties concerning the subject matter hereof and supersedes all
              previous negotiations, agreements and commitments with respect thereto. This EFS
              Agreement will not be amended or modified in any manner except by a written instrument
              signed by duly authorized officers or representatives of each of the parties hereto.
            </li>
            <li>
              <b>17. Assignment. </b>Participant will have no right to assign any of its rights or
              obligations under this EFS Agreement without the prior written consent of EFS.
            </li>
          </ul>
        </div>
      </div>
      <PagesFooter currentPage="terms" pathname={location.pathname} queryString={location.search} />
      {renderAuthModal(user, location, children, { dlHideAuth: true, hasOptions: true })}
    </>
  );
};

export default TermsAndConditions;
