"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetEditPreneedPricesAction = exports.getRawPreneedFailure = exports.getRawPreneedSuccess = exports.getRawPreneedRequest = exports.editPreneedPricesAllFailure = exports.editPreneedPricesAllSuccess = exports.editPreneedPricesAllRequest = exports.editPreneedPricesFailure = exports.editPreneedPricesSuccess = exports.editPreneedPricesRequest = exports.initPreneedFailure = exports.initPreneedSuccess = exports.initPreneedRequest = exports.resetGPLPDFUploadAction = exports.uploadGPLPDFFailure = exports.uploadGPLPDFSuccess = exports.uploadGPLPDFRequest = exports.resetPreneedTOSAction = exports.acceptPreneedTOSFailure = exports.acceptPreneedTOSSuccess = exports.acceptPreneedTOSRequest = exports.resetAccLocationDetailsUpdate = exports.resetFuneralHomeAction = exports.editFuneralHomefailure = exports.editFuneralHomeSuccess = exports.editFuneralHomeRequest = exports.fetchFuneralHomefailure = exports.fetchFuneralHomeSuccess = exports.fetchFuneralHomeRequest = void 0;

var types = _interopRequireWildcard(require("./constants"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

// Fetch funeralhome
var fetchFuneralHomeRequest = function fetchFuneralHomeRequest() {
  return {
    type: types.FETCH_FUNERAL_HOME_REQUEST
  };
};

exports.fetchFuneralHomeRequest = fetchFuneralHomeRequest;

var fetchFuneralHomeSuccess = function fetchFuneralHomeSuccess(_ref) {
  var data = _ref.data;
  return {
    type: types.FETCH_FUNERAL_HOME_SUCCESS,
    payload: data.data,
    receivedAt: Date.now()
  };
};

exports.fetchFuneralHomeSuccess = fetchFuneralHomeSuccess;

var fetchFuneralHomefailure = function fetchFuneralHomefailure(error) {
  return {
    type: types.FETCH_FUNERAL_HOME_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
}; // Edit funeralhome


exports.fetchFuneralHomefailure = fetchFuneralHomefailure;

var editFuneralHomeRequest = function editFuneralHomeRequest() {
  return {
    type: types.EDIT_FUNERAL_HOME_REQUEST
  };
};

exports.editFuneralHomeRequest = editFuneralHomeRequest;

var editFuneralHomeSuccess = function editFuneralHomeSuccess(_ref2) {
  var data = _ref2.data;
  return {
    type: types.EDIT_FUNERAL_HOME_SUCCESS,
    payload: data.data,
    receivedAt: Date.now()
  };
};

exports.editFuneralHomeSuccess = editFuneralHomeSuccess;

var editFuneralHomefailure = function editFuneralHomefailure(error) {
  return {
    type: types.EDIT_FUNERAL_HOME_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
}; // Reset funeralhome


exports.editFuneralHomefailure = editFuneralHomefailure;

var resetFuneralHomeAction = function resetFuneralHomeAction() {
  return {
    type: types.RESET_FUNERAL_HOME
  };
};

exports.resetFuneralHomeAction = resetFuneralHomeAction;

var resetAccLocationDetailsUpdate = function resetAccLocationDetailsUpdate() {
  return {
    type: types.RESET_ACC_LOCATIONS_HOME_UPDATE
  };
}; // Terms


exports.resetAccLocationDetailsUpdate = resetAccLocationDetailsUpdate;

var acceptPreneedTOSRequest = function acceptPreneedTOSRequest() {
  return {
    type: types.ACCEPT_PRENEED_TOS_REQUEST
  };
};

exports.acceptPreneedTOSRequest = acceptPreneedTOSRequest;

var acceptPreneedTOSSuccess = function acceptPreneedTOSSuccess(_ref3) {
  var data = _ref3.data;
  return {
    type: types.ACCEPT_PRENEED_TOS_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.acceptPreneedTOSSuccess = acceptPreneedTOSSuccess;

var acceptPreneedTOSFailure = function acceptPreneedTOSFailure(error) {
  return {
    type: types.ACCEPT_PRENEED_TOS_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.acceptPreneedTOSFailure = acceptPreneedTOSFailure;

var resetPreneedTOSAction = function resetPreneedTOSAction() {
  return {
    type: types.RESET_PRENEED_TOS_ACCEPTED
  };
}; // PDF Upload


exports.resetPreneedTOSAction = resetPreneedTOSAction;

var uploadGPLPDFRequest = function uploadGPLPDFRequest() {
  return {
    type: types.UPLOAD_GPL_PDF_REQUEST
  };
};

exports.uploadGPLPDFRequest = uploadGPLPDFRequest;

var uploadGPLPDFSuccess = function uploadGPLPDFSuccess(_ref4) {
  var data = _ref4.data;
  return {
    type: types.UPLOAD_GPL_PDF_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.uploadGPLPDFSuccess = uploadGPLPDFSuccess;

var uploadGPLPDFFailure = function uploadGPLPDFFailure(error) {
  return {
    type: types.UPLOAD_GPL_PDF_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.uploadGPLPDFFailure = uploadGPLPDFFailure;

var resetGPLPDFUploadAction = function resetGPLPDFUploadAction() {
  return {
    type: types.RESET_UPLOAD_GPL_PDF
  };
}; // Init Preneed


exports.resetGPLPDFUploadAction = resetGPLPDFUploadAction;

var initPreneedRequest = function initPreneedRequest() {
  return {
    type: types.INIT_PRENEED_REQUEST
  };
};

exports.initPreneedRequest = initPreneedRequest;

var initPreneedSuccess = function initPreneedSuccess(_ref5) {
  var data = _ref5.data;
  return {
    type: types.INIT_PRENEED_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.initPreneedSuccess = initPreneedSuccess;

var initPreneedFailure = function initPreneedFailure(error) {
  return {
    type: types.INIT_PRENEED_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
}; // Edit Preneed Package Prices


exports.initPreneedFailure = initPreneedFailure;

var editPreneedPricesRequest = function editPreneedPricesRequest() {
  return {
    type: types.EDIT_PRENEED_PRICES_REQUEST
  };
};

exports.editPreneedPricesRequest = editPreneedPricesRequest;

var editPreneedPricesSuccess = function editPreneedPricesSuccess(_ref6) {
  var data = _ref6.data;
  return {
    type: types.EDIT_PRENEED_PRICES_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.editPreneedPricesSuccess = editPreneedPricesSuccess;

var editPreneedPricesFailure = function editPreneedPricesFailure(error) {
  return {
    type: types.EDIT_PRENEED_PRICES_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
}; // Edit Preneed Package Prices


exports.editPreneedPricesFailure = editPreneedPricesFailure;

var editPreneedPricesAllRequest = function editPreneedPricesAllRequest() {
  return {
    type: types.EDIT_PRENEED_PRICES_ALL_REQUEST
  };
};

exports.editPreneedPricesAllRequest = editPreneedPricesAllRequest;

var editPreneedPricesAllSuccess = function editPreneedPricesAllSuccess(_ref7) {
  var data = _ref7.data;
  return {
    type: types.EDIT_PRENEED_PRICES_ALL_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.editPreneedPricesAllSuccess = editPreneedPricesAllSuccess;

var editPreneedPricesAllFailure = function editPreneedPricesAllFailure(error) {
  return {
    type: types.EDIT_PRENEED_PRICES_ALL_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
}; // GET raw preneed data for PackageRow before preneed is initialized


exports.editPreneedPricesAllFailure = editPreneedPricesAllFailure;

var getRawPreneedRequest = function getRawPreneedRequest() {
  return {
    type: types.GET_RAWPRENEED_DATA_REQUEST
  };
};

exports.getRawPreneedRequest = getRawPreneedRequest;

var getRawPreneedSuccess = function getRawPreneedSuccess(_ref8) {
  var data = _ref8.data;
  return {
    type: types.GET_RAWPRENEED_DATA_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.getRawPreneedSuccess = getRawPreneedSuccess;

var getRawPreneedFailure = function getRawPreneedFailure(error) {
  return {
    type: types.GET_RAWPRENEED_DATA_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.getRawPreneedFailure = getRawPreneedFailure;

var resetEditPreneedPricesAction = function resetEditPreneedPricesAction() {
  return {
    type: types.RESET_EDIT_PRENEED_PRICES
  };
};

exports.resetEditPreneedPricesAction = resetEditPreneedPricesAction;