"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FETCH_COMPANY_FAILURE = exports.FETCH_COMPANY_SUCCESS = exports.FETCH_COMPANY_REQUEST = exports.FETCH_COMPANY_RESET = void 0;
var FETCH_COMPANY_RESET = 'FETCH_COMPANY_RESET';
exports.FETCH_COMPANY_RESET = FETCH_COMPANY_RESET;
var FETCH_COMPANY_REQUEST = 'FETCH_COMPANY_REQUEST';
exports.FETCH_COMPANY_REQUEST = FETCH_COMPANY_REQUEST;
var FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
exports.FETCH_COMPANY_SUCCESS = FETCH_COMPANY_SUCCESS;
var FETCH_COMPANY_FAILURE = 'FETCH_COMPANY_FAILURE';
exports.FETCH_COMPANY_FAILURE = FETCH_COMPANY_FAILURE;