import React, { useState } from 'react';

import ItemSection from '../shared/ItemSection';

import qMarkOutline from '../../public/images/q-mark-outline.svg';

import styles from './FAQ.module.scss';

const { faq: faqClass, header: headerClass, body: bodyClass, 'item-list': itemListClass } = styles;

const questions = [
  {
    name: 'What if I don’t want a “traditional” funeral?',
    description:
      'The great thing about planning ahead is that YOU get to decide what type of services you want to put in place, and it’s the best way to ensure your wishes will be honored when the time comes. If a traditional service isn’t your style, consider planning for a casual memorial service at your go-to brunch spot, or a casual outdoor barbecue celebration of life with a balloon release. You could even decide to have a simple family gathering somewhere meaningful to you, like your family cabin or your favorite beach to catch the sunset. Whatever it is, planning ahead means your wishes will be known, and your family will have the support they need to honor your life the way you wished.'
  },
  {
    name: 'How does my family know what to do?',
    description:
      'When you plan on Everdays, you can easily give loved ones access to your plans and documents so they have the answers they’ll need. Instead of frantically searching for documents filed away somewhere at the time of your passing, your family simply opens the app on their phone to find your plan and all the details of your selections and wishes. And once you’ve funded your plan on Everdays, they’ll also have easy access to the insurance policy, the agreement with the funeral home, and proof of purchase – right in the app. Not only will you give your family the answers and financial support they’ll need to get through a very difficult time – you’ll also give them great peace of mind knowing everything is ready and available whenever that sad day comes.'
  },
  {
    name: 'How do I know my money is safe?',
    description:
      '\n' +
      'With Everdays, your funds are held safely in an insurance policy by 100-year-old, A+ rated insurance company Homesteaders Life to ensure full package delivery when the time comes. Your funds will be paid directly to your chosen funeral home at the time of your passing to deliver the services you’ve put in place. You get the exact same price as the funeral home charges, but also gain access to exclusive Everdays benefits – from free funeral home relocation services, to app storage and special digital features, to 100 days money back – included for you and your family at no additional cost. And that’s not all. The day you choose to fund your plan, your services and price are locked in and protected against inflation and future service price increases – saving you, your family and your estate thousands of dollars in the long run.'
  }
];

const FAQ = () => {
  const [activeItems, setActiveItems] = useState({});

  const handleToggleActive = name => {
    setActiveItems(prev => ({ ...prev, [name]: !prev[name] }));
  };

  return (
    <div className={faqClass}>
      <div className={headerClass}>
        <img src={qMarkOutline} alt="" />
        <h2>Smart questions you might be asking</h2>
      </div>
      <div className={bodyClass}>
        <div className={itemListClass}>
          {questions.map(({ name, description }) => (
            <ItemSection key={name} {...{ activeItems, handleToggleActive, name, description }} />
          ))}
        </div>
        <a href="https://help.everdays.com/en/collections/2674330-everdays-planning-faqs#package-questions">
          View our most common questions
        </a>
      </div>
    </div>
  );
};

export default FAQ;
