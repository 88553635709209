"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: '',
  email: '',
  taxID: '',
  taxClassification: '',
  type: '',
  website: '',
  phone: '',
  logo: '',
  address: {
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: ''
  }
};
exports.default = _default;