"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.INSPIRATION_SIGNUP_FAILURE = exports.INSPIRATION_SIGNUP_SUCCESS = exports.INSPIRATION_SIGNUP_REQUEST = void 0;
var INSPIRATION_SIGNUP_REQUEST = 'INSPIRATION_SIGNUP_REQUEST';
exports.INSPIRATION_SIGNUP_REQUEST = INSPIRATION_SIGNUP_REQUEST;
var INSPIRATION_SIGNUP_SUCCESS = 'INSPIRATION_SIGNUP_SUCCESS';
exports.INSPIRATION_SIGNUP_SUCCESS = INSPIRATION_SIGNUP_SUCCESS;
var INSPIRATION_SIGNUP_FAILURE = 'INSPIRATION_SIGNUP_FAILURE';
exports.INSPIRATION_SIGNUP_FAILURE = INSPIRATION_SIGNUP_FAILURE;