"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_USER_PACKAGE_FAILURE = exports.UPDATE_USER_PACKAGE_SUCCESS = exports.UPDATE_USER_PACKAGE_REQUEST = exports.START_SALE_FAILURE = exports.START_SALE_SUCCESS = exports.START_SALE_REQUEST = exports.RESET_UPDATE_WEBLEAD = exports.UPDATE_WEBLEAD_FAILURE = exports.UPDATE_WEBLEAD_SUCCESS = exports.UPDATE_WEBLEAD_REQUEST = exports.FETCH_WEBLEAD_FAILURE = exports.FETCH_WEBLEAD_SUCCESS = exports.FETCH_WEBLEAD_REQUEST = exports.RESET_PACKAGE_SELECTED = exports.SELECT_NEW_PACKAGE_FAILURE = exports.SELECT_NEW_PACKAGE_SUCCESS = exports.SELECT_NEW_PACKAGE_REQUEST = exports.SELECT_PACKAGE_FAILURE = exports.SELECT_PACKAGE_SUCCESS = exports.SELECT_PACKAGE_REQUEST = void 0;
// for starting purchase flow
var SELECT_PACKAGE_REQUEST = 'SELECT_PACKAGE_REQUEST';
exports.SELECT_PACKAGE_REQUEST = SELECT_PACKAGE_REQUEST;
var SELECT_PACKAGE_SUCCESS = 'SELECT_PACKAGE_SUCCESS';
exports.SELECT_PACKAGE_SUCCESS = SELECT_PACKAGE_SUCCESS;
var SELECT_PACKAGE_FAILURE = 'SELECT_PACKAGE_FAILURE'; // for selecting a new package after having started the purchase flow

exports.SELECT_PACKAGE_FAILURE = SELECT_PACKAGE_FAILURE;
var SELECT_NEW_PACKAGE_REQUEST = 'SELECT_NEW_PACKAGE_REQUEST';
exports.SELECT_NEW_PACKAGE_REQUEST = SELECT_NEW_PACKAGE_REQUEST;
var SELECT_NEW_PACKAGE_SUCCESS = 'SELECT_NEW_PACKAGE_SUCCESS';
exports.SELECT_NEW_PACKAGE_SUCCESS = SELECT_NEW_PACKAGE_SUCCESS;
var SELECT_NEW_PACKAGE_FAILURE = 'SELECT_NEW_PACKAGE_FAILURE';
exports.SELECT_NEW_PACKAGE_FAILURE = SELECT_NEW_PACKAGE_FAILURE;
var RESET_PACKAGE_SELECTED = 'RESET_PACKAGE_SELECTED'; // fetch weblead

exports.RESET_PACKAGE_SELECTED = RESET_PACKAGE_SELECTED;
var FETCH_WEBLEAD_REQUEST = 'FETCH_WEBLEAD_REQUEST';
exports.FETCH_WEBLEAD_REQUEST = FETCH_WEBLEAD_REQUEST;
var FETCH_WEBLEAD_SUCCESS = 'FETCH_WEBLEAD_SUCCESS';
exports.FETCH_WEBLEAD_SUCCESS = FETCH_WEBLEAD_SUCCESS;
var FETCH_WEBLEAD_FAILURE = 'FETCH_WEBLEAD_FAILURE'; // update weblead

exports.FETCH_WEBLEAD_FAILURE = FETCH_WEBLEAD_FAILURE;
var UPDATE_WEBLEAD_REQUEST = 'UPDATE_WEBLEAD_REQUEST';
exports.UPDATE_WEBLEAD_REQUEST = UPDATE_WEBLEAD_REQUEST;
var UPDATE_WEBLEAD_SUCCESS = 'UPDATE_WEBLEAD_SUCCESS';
exports.UPDATE_WEBLEAD_SUCCESS = UPDATE_WEBLEAD_SUCCESS;
var UPDATE_WEBLEAD_FAILURE = 'UPDATE_WEBLEAD_FAILURE';
exports.UPDATE_WEBLEAD_FAILURE = UPDATE_WEBLEAD_FAILURE;
var RESET_UPDATE_WEBLEAD = 'RESET_UPDATE_WEBLEAD'; // start eFuneral sale

exports.RESET_UPDATE_WEBLEAD = RESET_UPDATE_WEBLEAD;
var START_SALE_REQUEST = 'START_SALE_REQUEST';
exports.START_SALE_REQUEST = START_SALE_REQUEST;
var START_SALE_SUCCESS = 'START_SALE_SUCCESS';
exports.START_SALE_SUCCESS = START_SALE_SUCCESS;
var START_SALE_FAILURE = 'START_SALE_FAILURE'; // update package and terms on user

exports.START_SALE_FAILURE = START_SALE_FAILURE;
var UPDATE_USER_PACKAGE_REQUEST = 'UPDATE_USER_PACKAGE_REQUEST';
exports.UPDATE_USER_PACKAGE_REQUEST = UPDATE_USER_PACKAGE_REQUEST;
var UPDATE_USER_PACKAGE_SUCCESS = 'UPDATE_USER_PACKAGE_SUCCESS';
exports.UPDATE_USER_PACKAGE_SUCCESS = UPDATE_USER_PACKAGE_SUCCESS;
var UPDATE_USER_PACKAGE_FAILURE = 'UPDATE_USER_PACKAGE_FAILURE';
exports.UPDATE_USER_PACKAGE_FAILURE = UPDATE_USER_PACKAGE_FAILURE;