"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  address: '',
  city: '',
  state: '',
  country: '',
  zip: '',
  phone: '',
  website: ''
};
exports.default = _default;