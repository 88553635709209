"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EDIT_USER_VISITED_MEMORIALS_FAILURE = exports.EDIT_USER_VISITED_MEMORIALS_SUCCESS = exports.EDIT_USER_VISITED_MEMORIALS_REQUEST = exports.EDIT_USER_TERMS_FAILURE = exports.EDIT_USER_TERMS_SUCCESS = exports.EDIT_USER_TERMS_REQUEST = exports.EDIT_SPECIFIC_USER_RESET = exports.EDIT_USER_RESET = exports.EDIT_USER_FAILURE = exports.EDIT_SPECIFIC_USER_SUCCESS = exports.EDIT_USER_SUCCESS = exports.EDIT_USER_REQUEST = exports.GET_SPECIFIC_USER_SUCCESS = exports.GET_SPECIFIC_USER_FAILURE = exports.GET_SPECIFIC_USER_REQUEST = void 0;
var GET_SPECIFIC_USER_REQUEST = 'GET_SPECIFIC_USER_REQUEST';
exports.GET_SPECIFIC_USER_REQUEST = GET_SPECIFIC_USER_REQUEST;
var GET_SPECIFIC_USER_FAILURE = 'GET_SPECIFIC_USER_FAILURE';
exports.GET_SPECIFIC_USER_FAILURE = GET_SPECIFIC_USER_FAILURE;
var GET_SPECIFIC_USER_SUCCESS = 'GET_SPECIFIC_USER_SUCCESS';
exports.GET_SPECIFIC_USER_SUCCESS = GET_SPECIFIC_USER_SUCCESS;
var EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
exports.EDIT_USER_REQUEST = EDIT_USER_REQUEST;
var EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
exports.EDIT_USER_SUCCESS = EDIT_USER_SUCCESS;
var EDIT_SPECIFIC_USER_SUCCESS = 'EDIT_SPECIFIC_USER_SUCCESS';
exports.EDIT_SPECIFIC_USER_SUCCESS = EDIT_SPECIFIC_USER_SUCCESS;
var EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';
exports.EDIT_USER_FAILURE = EDIT_USER_FAILURE;
var EDIT_USER_RESET = 'EDIT_USER_RESET';
exports.EDIT_USER_RESET = EDIT_USER_RESET;
var EDIT_SPECIFIC_USER_RESET = 'EDIT_SPECIFIC_USER_RESET';
exports.EDIT_SPECIFIC_USER_RESET = EDIT_SPECIFIC_USER_RESET;
var EDIT_USER_TERMS_REQUEST = 'EDIT_USER_TERMS_REQUEST';
exports.EDIT_USER_TERMS_REQUEST = EDIT_USER_TERMS_REQUEST;
var EDIT_USER_TERMS_SUCCESS = 'EDIT_USER_TERMS_SUCCESS';
exports.EDIT_USER_TERMS_SUCCESS = EDIT_USER_TERMS_SUCCESS;
var EDIT_USER_TERMS_FAILURE = 'EDIT_USER_TERMS_FAILURE';
exports.EDIT_USER_TERMS_FAILURE = EDIT_USER_TERMS_FAILURE;
var EDIT_USER_VISITED_MEMORIALS_REQUEST = 'EDIT_USER_VISITED_MEMORIALS_REQUEST';
exports.EDIT_USER_VISITED_MEMORIALS_REQUEST = EDIT_USER_VISITED_MEMORIALS_REQUEST;
var EDIT_USER_VISITED_MEMORIALS_SUCCESS = 'EDIT_USER_VISITED_MEMORIALS_SUCCESS';
exports.EDIT_USER_VISITED_MEMORIALS_SUCCESS = EDIT_USER_VISITED_MEMORIALS_SUCCESS;
var EDIT_USER_VISITED_MEMORIALS_FAILURE = 'EDIT_USER_VISITED_MEMORIALS_FAILURE';
exports.EDIT_USER_VISITED_MEMORIALS_FAILURE = EDIT_USER_VISITED_MEMORIALS_FAILURE;