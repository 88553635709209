"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ARCHIVE_INVITE_FAILURE = exports.ARCHIVE_INVITE_SUCCESS = exports.ARCHIVE_INVITE_REQUEST = exports.INVITE_DETAILS_RESET = exports.FETCH_INVITE_DETAILS_FAILURE = exports.FETCH_INVITE_DETAILS_SUCCESS = exports.FETCH_INVITE_DETAILS_REQUEST = void 0;
var FETCH_INVITE_DETAILS_REQUEST = 'FETCH_INVITE_DETAILS_REQUEST';
exports.FETCH_INVITE_DETAILS_REQUEST = FETCH_INVITE_DETAILS_REQUEST;
var FETCH_INVITE_DETAILS_SUCCESS = 'FETCH_INVITE_DETAILS_SUCCESS';
exports.FETCH_INVITE_DETAILS_SUCCESS = FETCH_INVITE_DETAILS_SUCCESS;
var FETCH_INVITE_DETAILS_FAILURE = 'FETCH_INVITE_DETAILS_FAILURE';
exports.FETCH_INVITE_DETAILS_FAILURE = FETCH_INVITE_DETAILS_FAILURE;
var INVITE_DETAILS_RESET = 'INVITE_DETAILS_RESET'; // Archive Invite

exports.INVITE_DETAILS_RESET = INVITE_DETAILS_RESET;
var ARCHIVE_INVITE_REQUEST = 'ARCHIVE_INVITE_REQUEST';
exports.ARCHIVE_INVITE_REQUEST = ARCHIVE_INVITE_REQUEST;
var ARCHIVE_INVITE_SUCCESS = 'ARCHIVE_INVITE_SUCCESS';
exports.ARCHIVE_INVITE_SUCCESS = ARCHIVE_INVITE_SUCCESS;
var ARCHIVE_INVITE_FAILURE = 'ARCHIVE_INVITE_FAILURE';
exports.ARCHIVE_INVITE_FAILURE = ARCHIVE_INVITE_FAILURE;