"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FAMILY_MESSAGE_COMMENT_FAILURE = exports.FAMILY_MESSAGE_COMMENT_SUCCESS = exports.FAMILY_MESSAGE_COMMENT_REQUEST = void 0;
var FAMILY_MESSAGE_COMMENT_REQUEST = 'FAMILY_MESSAGE_COMMENT_REQUEST';
exports.FAMILY_MESSAGE_COMMENT_REQUEST = FAMILY_MESSAGE_COMMENT_REQUEST;
var FAMILY_MESSAGE_COMMENT_SUCCESS = 'FAMILY_MESSAGE_COMMENT_SUCCESS';
exports.FAMILY_MESSAGE_COMMENT_SUCCESS = FAMILY_MESSAGE_COMMENT_SUCCESS;
var FAMILY_MESSAGE_COMMENT_FAILURE = 'FAMILY_MESSAGE_COMMENT_FAILURE';
exports.FAMILY_MESSAGE_COMMENT_FAILURE = FAMILY_MESSAGE_COMMENT_FAILURE;