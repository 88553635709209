"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stopUpload = exports.startUpload = exports.subscribeToStreamList = exports.closeStreamSocket = exports.openStreamSocket = void 0;

require("core-js/modules/es6.array.slice.js");

require("core-js/modules/es6.function.name.js");

var _socket = _interopRequireDefault(require("socket.io-client"));

var _constants = require("../../constants");

var _actions = require("./actions");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var streamProps = {
  subscribeSocket: undefined,
  uploadSocket: undefined,
  dispatch: undefined,
  file: undefined
};

var handleStreamConnectionChange = function handleStreamConnectionChange() {
  var connectionStatus = {
    subscribeSocket: streamProps.subscribeSocket.connected,
    uploadSocket: streamProps.uploadSocket.connected
  };
  streamProps.dispatch((0, _actions.liveStreamConnectionChange)(connectionStatus));
};

var handleInitalStreamList = function handleInitalStreamList(_ref) {
  var streams = _ref.streams;
  streamProps.dispatch((0, _actions.initalStreamList)({
    streams: streams
  }));
};

var handleStreamListUpdate = function handleStreamListUpdate(_ref2) {
  var stream = _ref2.stream;
  streamProps.dispatch((0, _actions.streamListUpdate)({
    stream: stream
  }));
};

var handleUploadNextSlice = function handleUploadNextSlice(_ref3) {
  var percent = _ref3.percent,
      place = _ref3.place,
      sliceAmt = _ref3.sliceAmt;
  var reader = new FileReader();
  streamProps.dispatch((0, _actions.setUploadProgress)(percent));
  var uploadPosition = place * sliceAmt;
  var newSlice = streamProps.file.slice(uploadPosition, uploadPosition + Math.min(sliceAmt, streamProps.file.size - uploadPosition));
  reader.readAsBinaryString(newSlice);

  reader.onload = function (_ref4) {
    var target = _ref4.target;
    streamProps.uploadSocket.emit('event://upload-new-video-slice', {
      name: streamProps.file.name,
      data: target.result
    });
  };
};

var handleFinishUpload = function handleFinishUpload() {
  streamProps.dispatch((0, _actions.setUploadInProgress)(false));
  streamProps.uploadSocket.removeEventListener('event://ready-for-next-video-slice', handleUploadNextSlice);
  streamProps.uploadSocket.removeEventListener('event://finished-video-upload', handleFinishUpload);
  streamProps.file = undefined;
};

var openStreamSocket = function openStreamSocket() {
  return function (dispatch) {
    if (!streamProps.uploadSocket) {
      streamProps.subscribeSocket = (0, _socket.default)(_constants.API_URL);
      streamProps.uploadSocket = (0, _socket.default)(_constants.VIDEO_UPLOAD_URL);
      streamProps.dispatch = dispatch;
      streamProps.subscribeSocket.on('connect', handleStreamConnectionChange);
      streamProps.subscribeSocket.on('reconnect', handleStreamConnectionChange);
      streamProps.subscribeSocket.on('disconnect', handleStreamConnectionChange);
      streamProps.uploadSocket.on('connect', handleStreamConnectionChange);
      streamProps.uploadSocket.on('reconnect', handleStreamConnectionChange);
      streamProps.uploadSocket.on('disconnect', handleStreamConnectionChange);
    }
  };
};

exports.openStreamSocket = openStreamSocket;

var closeStreamSocket = function closeStreamSocket() {
  return function (dispatch) {
    streamProps.subscribeSocket.removeEventListener('connect', handleStreamConnectionChange);
    streamProps.subscribeSocket.removeEventListener('reconnect', handleStreamConnectionChange);
    streamProps.subscribeSocket.removeEventListener('disconnect', handleStreamConnectionChange);
    streamProps.uploadSocket.removeEventListener('connect', handleStreamConnectionChange);
    streamProps.uploadSocket.removeEventListener('reconnect', handleStreamConnectionChange);
    streamProps.uploadSocket.removeEventListener('disconnect', handleStreamConnectionChange);
    streamProps.subscribeSocket = undefined;
    streamProps.uploadSocket = undefined;
    streamProps.dispatch = undefined;
    dispatch((0, _actions.liveStreamConnectionChange)(false));
  };
};

exports.closeStreamSocket = closeStreamSocket;

var subscribeToStreamList = function subscribeToStreamList(memorialId) {
  return function (dispatch) {
    if (streamProps.uploadSocket) {
      streamProps.subscribeSocket.emit("subscribe://stream-list", {
        memorialId: memorialId
      });
      streamProps.subscribeSocket.on('event://stream-list', handleInitalStreamList);
      streamProps.subscribeSocket.on('event://stream-list-update', handleStreamListUpdate);
    }
  };
};

exports.subscribeToStreamList = subscribeToStreamList;

var startUpload = function startUpload(_ref5) {
  var file = _ref5.file,
      memorialId = _ref5.memorialId;
  return function (dispatch) {
    if (streamProps.uploadSocket) {
      var name = file.name;
      streamProps.file = file;
      dispatch((0, _actions.setUploadInProgress)(true));
      streamProps.uploadSocket.emit('event://start-video-upload', {
        name: name,
        size: file.size,
        memorialId: memorialId
      });
      streamProps.uploadSocket.on('event://ready-for-next-video-slice', handleUploadNextSlice);
      streamProps.uploadSocket.on('event://finished-video-upload', handleFinishUpload);
    }
  };
};

exports.startUpload = startUpload;

var stopUpload = function stopUpload() {
  return function (dispatch) {
    streamProps.uploadSocket.emit('event://stop-video-upload', {
      name: streamProps.file.name
    });
    streamProps.uploadSocket.removeEventListener('event://ready-for-next-video-slice', handleUploadNextSlice);
    streamProps.uploadSocket.removeEventListener('event://finished-video-upload', handleFinishUpload);
    dispatch((0, _actions.setUploadInProgress)(false));
    dispatch((0, _actions.setUploadInProgress)(false));
    streamProps.file = undefined;
  };
};

exports.stopUpload = stopUpload;