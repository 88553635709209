import React from 'react';
import { Router, Route, browserHistory, Redirect, IndexRedirect } from 'react-router';

import ErrorPage from '@evdy/web-core/dist/components/ErrorPage';

import App from './components/App';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import ForHomes from './components/Partners/ForHomes';
import MissingPage from './components/MissingPage';
import Unsub from './components/Unsub';
import Privacy from './components/legal/Privacy';
import ClientPrivacy from './components/legal/Privacy/ClientPrivacy';
import TOS from './components/legal/TOS';
import ClientTOS from './components/legal/ClientTOS';
import TermsAndConditions from './components/legal/TermsAndConditions';
import ClientAgreement from './components/legal/ClientAgreement';
import Apps from './components/Apps';
import ExternalRedirect from './components/ExternalRedirect';
import LogOut from './components/LogOut';
import AllPackages from './components/AllPackages';
import PackageDetails from './components/AllPackages/PackageDetails';
import HowItWorks from './components/HowItWorks';

import WhyEverdays from './components/WhyEverdays';
import Love from './components/WhyEverdays/pages/Love';
import Savings from './components/WhyEverdays/pages/Savings';
import Protection from './components/WhyEverdays/pages/Protection';
import Medicaid from './components/WhyEverdays/pages/Medicaid';
import Estate from './components/WhyEverdays/pages/Estate';
import SetupCall from './components/SetupCall';
import ScheduleCall from './components/ScheduleCall';
import ScheduleSuccess from './components/ScheduleSuccess';

import signUpModalRoutes from '@evdy/web-core/dist/components/SignUpModal/signUpModalRoutes';
import redirectRoute from '@evdy/web-core/dist/components/redirectRoute';

const Routes = (store, history) => {
  const isDev = process.env.NODE_ENV === 'development';
  const homeUrl = isDev ? 'https://dev.everdays.com/' : 'https://everdays.com/';
  return (
    <Router history={history || browserHistory}>
      <Route component={App}>
        <Route path="/" component={Home}>
          {signUpModalRoutes()}
        </Route>

        {/* <Route path="get-my-estimate/setup-call" component={SetupCall} /> */}
        {/* <Route path="schedule-call" component={ScheduleCall} /> */}
        {/* <Route path="schedule-success" component={ScheduleSuccess} /> */}
        {/* <Route path="about-us" component={AboutUs} /> */}
        {/* <Route path="families" component={() => ExternalRedirect('https://www.everdays.com')} /> */}
        {/* <Route path="provider" component={ForHomes}>
          {signUpModalRoutes()}
        </Route> */}
        {/* <Route path="why" component={WhyEverdays} />
        <Route path="why/love" component={Love} />
        <Route path="why/savings" component={Savings} />
        <Route path="why/protection" component={Protection} />
        <Route path="why/medicaid" component={Medicaid} />
        <Route path="why/estate" component={Estate} /> */}
        {/* <Route path="how-it-works" component={HowItWorks} /> */}
        {/* <Route path="invite">
          <IndexRedirect to="/" />
        </Route>
        <Route path="careers">
          <IndexRedirect to="/about-us" />
        </Route>
        <Route path="press">
          <IndexRedirect to="/about-us" />
        </Route>
        <Route path="for-homes">
          <IndexRedirect to="/provider" />
        </Route>
        <Route path="pricing">
          <IndexRedirect to="/provider" />
        </Route>
        <Route path="get-started">
          <IndexRedirect to="/provider" />
        </Route>
        <Route path="demo">
          <IndexRedirect to="/provider" />
        </Route>
        <Route path="get-demo">
          <IndexRedirect to="/provider" />
        </Route> */}
        {/* <Route
          path="funeral-director"
          component={() => ExternalRedirect('https://www.everdays.com')}
        />
        <Route
          path="online-obituaries"
          component={() => ExternalRedirect('https://www.everdays.com')}
        />
        <Route
          path="funeral-program-order-of-service"
          component={() => ExternalRedirect('https://www.everdays.com')}
        />
        <Route path="pet-cemetery" component={() => ExternalRedirect('https://www.everdays.com')} />
        <Route
          path="how-to-plan-a-funeral"
          component={() => ExternalRedirect('https://www.everdays.com')}
        />
        <Route
          path="365-days-of-inspiration"
          component={() => ExternalRedirect('https://www.everdays.com')}
        /> */}
        {/* <Route path="privacy" component={Privacy}>
          {signUpModalRoutes()}
        </Route> */}
        <Route path="termsandconditions" component={TermsAndConditions}>
          {signUpModalRoutes()}
        </Route>
        <Route path="clientprivacy" component={ClientPrivacy}>
          {signUpModalRoutes()}
        </Route>
        <Route path="terms" component={TOS}>
          {signUpModalRoutes()}
        </Route>
        <Route path="clientterms" component={ClientTOS}>
          {signUpModalRoutes()}
        </Route>
        <Route path="clientagreement" component={ClientAgreement}>
          {signUpModalRoutes()}
        </Route>
        {/* optional userId && memorialId for accessor tracking */}
        {/* <Route path="apps(/:userId)(/:memorialId)" component={Apps} />
        <Route path="/packages" component={AllPackages} />
        <Route path="/packages/complete-funeral" component={PackageDetails} />
        <Route path="/packages/heartfelt-memorial" component={PackageDetails} />
        <Route path="/packages/celebration-of-life" component={PackageDetails} />
        <Redirect from="/packages/cremation-essentials" to="/packages/celebration-of-life" />
        <Redirect from="/packages/unique-tribute" to="/packages/celebration-of-life" /> */}
        {/* <Route
          path="smartdirector"
          component={() => ExternalRedirect('https://www.everdays.com')}
        /> */}
        <Route path="404" component={MissingPage} />
        <Route path="500" component={ErrorPage} />
        <Route path="unsub" component={Unsub} />
        <Route path="logout" component={LogOut} />
        {redirectRoute()}
      </Route>
    </Router>
  );
};

export default Routes;
