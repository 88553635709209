"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESET_SEND_MESSAGE = exports.UPDATE_SEND_TO_MANAGER_NAME = exports.UPDATE_SEND_TO_MANAGER_ID = exports.UPDATE_MESSAGE_TEXT = exports.SEND_MESSAGE_FAILURE = exports.SEND_MESSAGE_SUCCESS = exports.SEND_MESSAGE_REQUEST = void 0;
var SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
exports.SEND_MESSAGE_REQUEST = SEND_MESSAGE_REQUEST;
var SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
exports.SEND_MESSAGE_SUCCESS = SEND_MESSAGE_SUCCESS;
var SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';
exports.SEND_MESSAGE_FAILURE = SEND_MESSAGE_FAILURE;
var UPDATE_MESSAGE_TEXT = 'UPDATE_MESSAGE_TEXT';
exports.UPDATE_MESSAGE_TEXT = UPDATE_MESSAGE_TEXT;
var UPDATE_SEND_TO_MANAGER_ID = 'UPDATE_SEND_TO_MANAGER_ID';
exports.UPDATE_SEND_TO_MANAGER_ID = UPDATE_SEND_TO_MANAGER_ID;
var UPDATE_SEND_TO_MANAGER_NAME = 'UPDATE_SEND_TO_MANAGER_NAME';
exports.UPDATE_SEND_TO_MANAGER_NAME = UPDATE_SEND_TO_MANAGER_NAME;
var RESET_SEND_MESSAGE = 'RESET_SEND_MESSAGE';
exports.RESET_SEND_MESSAGE = RESET_SEND_MESSAGE;