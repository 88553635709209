"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _redux = require("redux");

var _contactHome = _interopRequireDefault(require("./contactHome"));

var _fetchHomeSummary = _interopRequireDefault(require("./fetchHomeSummary"));

var _fetchLocales = _interopRequireDefault(require("./fetchLocales"));

var _fetchLocalesAutocomplete = _interopRequireDefault(require("./fetchLocalesAutocomplete"));

var _fetchCityHomes = _interopRequireDefault(require("./fetchCityHomes"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var homeDirectory = (0, _redux.combineReducers)({
  contactHome: _contactHome.default,
  fetchLocales: _fetchLocales.default,
  fetchLocalesAutocomplete: _fetchLocalesAutocomplete.default,
  fetchCityHomes: _fetchCityHomes.default,
  homeSummary: _fetchHomeSummary.default
});
var _default = homeDirectory;
exports.default = _default;