"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FAMILY_MESSAGE_COMMENT_REPORT_FAILURE = exports.FAMILY_MESSAGE_COMMENT_REPORT_SUCCESS = exports.FAMILY_MESSAGE_COMMENT_REPORT_REQUEST = void 0;
var FAMILY_MESSAGE_COMMENT_REPORT_REQUEST = 'FAMILY_MESSAGE_COMMENT_REPORT_REQUEST';
exports.FAMILY_MESSAGE_COMMENT_REPORT_REQUEST = FAMILY_MESSAGE_COMMENT_REPORT_REQUEST;
var FAMILY_MESSAGE_COMMENT_REPORT_SUCCESS = 'FAMILY_MESSAGE_COMMENT_REPORT_SUCCESS';
exports.FAMILY_MESSAGE_COMMENT_REPORT_SUCCESS = FAMILY_MESSAGE_COMMENT_REPORT_SUCCESS;
var FAMILY_MESSAGE_COMMENT_REPORT_FAILURE = 'FAMILY_MESSAGE_COMMENT_REPORT_FAILURE';
exports.FAMILY_MESSAGE_COMMENT_REPORT_FAILURE = FAMILY_MESSAGE_COMMENT_REPORT_FAILURE;