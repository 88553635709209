import React from 'react';
import { connect } from 'react-redux';
import Link from 'react-router/es/Link';

import PagesHeader from '@evdy/web-core/dist/components/PagesHeader2';
import PagesFooter from '@evdy/web-core/dist/components/PagesFooter';
import LegalTextHeader from '../../shared/LegalTextHeader';
import { renderAuthModal } from '@evdy/web-core/dist/lib/utils';

import './ClientPrivacy.scss';

const ClientPrivacy = ({ user, location, children }) => {
  return (
    <>
      <PagesHeader
        currentPage="privacy"
        pathname={location.pathname}
        queryString={location.search}
      />
      <div className="privacy-policy">
        <LegalTextHeader />
        <div className="privacy-body">
          <h1>Client User Privacy Policy</h1>
          <p className="date-updated">
            Last Modified: <span>July 6, 2020</span>
          </p>
          <p>
            Everydays, Inc. <b>(“Everdays,” “we,” “our” or “us”)</b> is committed to protecting your
            privacy. This Client User Privacy Policy explains how Everdays collects, uses and shares
            the personal information we collect from visitors to our website, mobile applications,
            software and any online or mobile services provided on or in connection with the service
            (collectively, the “<b>Service</b>”) on behalf of a participating funeral home (the “
            <b>Client</b>”). By downloading, accessing or using our Service, or by clicking a button
            or checking a box marked “I Agree” (or something similar) or otherwise consenting to
            represent a participating funeral home as part of our Service, you signify that you have
            read, understood and agree to our collection, storage, use and disclosure of your
            personal information as described in this Client User Privacy Policy (this “Agreement”)
            and our{' '}
            <b>
              <a href="/clientterms" target="_blank" rel="noopener noreferrer">
                Terms of Service
              </a>
            </b>{' '}
            (which is expressly incorporated herein). You also signify that you have read,
            understood and agree to be bound by Everdays’{' '}
            <a href="/terms" target="_blank" rel="noopener noreferrer">
              User Terms of Service
            </a>{' '}
            and{' '}
            <a href="/privacy" target="_blank" rel="noopener noreferrer">
              User Privacy Policy
            </a>{' '}
            , which are also expressly incorporated herein. Everdays reserves the right to modify
            these terms and will provide notice of these changes as described below. This Agreement
            applies to all users who access or participate in the Service on behalf of funeral homes
            <b>
              (“Client Users”). If you do not agree to be bound by this Client User Privacy Policy
              and our Client User Terms of Service, you may not access or use this Service.
            </b>
          </p>
          <section>
            <h3>1. WHAT INFORMATION DO WE COLLECT AND FOR WHAT PURPOSE?</h3>
            <p>The categories of information we collect can include:</p>
            <ul>
              <li>
                <b>Information you provide to us.</b> We may collect personal information, such as
                your name, title, job position, employer, phone number, location, and e-mail address
                when you register for our Service or otherwise communicate with us. We may also
                collect personal information you provide relating to a deceased individual for whom
                your Client funeral home is providing services and/or such individual’s family
                members present at the arrangement meeting or otherwise. We may also collect any
                communications between you and Everdays and any other information you provide to
                Everdays.
              </li>
              <li>
                <b>Data collected through the use of the Service.</b> We collect information about
                how you use the Service, your actions on the Service, and content you post to the
                Service, including the groups you belong to, your interaction with your friends and
                with others on the Service, and photos and videos you post to the Service, and any
                content you provide through announcements, in-app messages, or other functionality.
                Some areas of the Service allow Client Users and Users to create, submit, post,
                display, provide, record, or otherwise make available content such as profile
                information, images, photographs, videos, illustrations, graphics, audio recordings,
                posts, comments, questions, data, works, texts and other content or information (any
                such materials a User submits, posts, displays, provides, or otherwise makes
                available on the Service is referred to as <b>"UserContent"</b>). To share photos
                and videos, we will access your device camera roll and camera with your permission.
                If you need to update your permissions, you can do so in the "Settings" app of your
                device. Please remember that Everdays may, but has no obligation to, monitor,
                record, and store User Content in order to protect your safety or the safety of
                other users, to assist with regulatory or law enforcement efforts, or to protect and
                defend our rights and property. By using the Service, you consent to the recording,
                storage and disclosure of such communications you create, send or receive for these
                purposes.
              </li>
              <li>
                <b>Information we collect from social networks.</b> When you interact with our
                Service through various social media, such as when you login through Facebook or
                when you follow Everdays or share Everdays content on Facebook, Twitter, or other
                sites, we may receive information from those social networks including your profile
                information, photos, user ID associated with your social media account, friends
                list, and any other information you permit the social network to share with third
                parties. We may use information about your connections on social media to let you
                know what your friends are doing on our Service and to let your friends know what
                you are doing on the Service. The data we receive is dependent upon your privacy
                settings with the social network. You should always review, and if necessary, adjust
                your privacy settings on third-party websites and services before linking or
                connecting them to our website or Service.
              </li>
              <li>
                <b>Address Book Information.</b> With your permission, Everdays may access your
                contact list available on your mobile device or in your email accounts so that you
                can locate your friends and contacts on the Service and invite your friends and
                contacts to connect with our Service. When we send the invitation to your friends
                and contacts to join the Service, we will include your name and photo to let them
                know that you are the person extending the invitation. After sending these
                invitations, we may also send reminder emails and text messages to your invitees on
                your behalf, which may also include your name and photo. We will store these
                contacts for purposes of alerting you when your contacts join Everdays at a later
                time so that you may connect with them on the Service, and to suggest friends and
                connections to other members of Everdays. Everdays may also use the information
                collected from your contact list and your friends' contact lists to suggest new
                friends you may want to connect with on the Service. We make these recommendations
                based on your address book information as well as your friends' address book
                information and try to find common friendships or connections to introduce you to.
              </li>
              <li>
                <b>Information collected from your browser.</b> We also collect information sent to
                us automatically by your web browser when you navigate the website and mobile
                application. This information typically includes your IP address, usage details,
                operating system information, the date and time of your visit and the pages you
                visit. We collect this data to help us:
                <ul className="sublist">
                  <li>
                    Administer the website and mobile application and improve your user experience;
                  </li>
                  <li>Analyze website and mobile application trends and aggregate usage;</li>
                  <li>Promote and improve services; and </li>
                  <li>Fulfill your requests and contact you.</li>
                </ul>
              </li>
              <li>
                <b>Location Information.</b> We may collect your unique user identifier and your
                location through GPS, WiFi, or wireless network triangulation in order to obtain
                your location for the purposes of providing our Service. We maintain location
                information only so long as is reasonable to provide the Service and then delete
                location data tied to your personal information. We may maintain de-identified
                location data for a longer period of time in order to analyze aggregate trends and
                metrics. If you want to opt-out of the collection of your location data, please
                adjust your settings in your mobile device to limit the app’s access to your
                location data. Please see “Control Over Your Information” below to learn more.
              </li>
              <li>
                <b>Information from other sources.</b>  We may obtain information from other
                sources, such as third-party information providers, or through mergers and
                acquisitions, and combine this with information previously collected. In these
                cases, our Client User Privacy Policy governs the handling of the combined personal
                information. We may also collect information about you that is publicly available.
              </li>
            </ul>
            <p>
              We use this information to operate, maintain, and provide to you the features and
              functionality of the Service, as well as to communicate directly with you, such as to
              send you email messages and push notifications, and permit you to communicate with
              others on the Service or on social media or invite others to join the Service. We may
              also send you Service-related emails or messages (e.g., account verification, change
              or updates to features of the Service, technical and security notices). For more
              information about your communication preferences, see “Control Over Your Information”
              below.
            </p>
          </section>
          <section>
            <h3>2. HOW WE USE COOKIES AND OTHER TRACKING TECHNOLOGY TO COLLECT INFORMATION.</h3>
            <p>
              We, and our third-party partners, automatically collect certain types of usage
              information when you visit our Service, read our texts or emails, or otherwise engage
              with us. We typically collect this information through a variety of tracking
              technologies, including cookies, web beacons, file information and similar technology
              (collectively, <b>"Tracking technologies"</b>). For example, we collect information
              about your device and its software, such as your IP address, browser type, Internet
              service provider, platform type, device type, operating system, date and time stamp, a
              unique ID that allows us to uniquely identify your browser, mobile device or your
              account, and other such information. We also collect information about the way you use
              our Service, for example, the site from which you came and the site to which you are
              going when you leave our website, the pages you visit, the links you click, how
              frequently you access the Service, whether you open emails or click the links
              contained in emails, whether you access the Service from multiple devices, and other
              actions you take on the Service. When you access our Service from a mobile device, we
              may collect unique identification numbers associated with your device or our mobile
              application (including, for example, a UDID, Unique ID for Advertisers (<b>"IDFA"</b>
              ), Google AdID, or Windows Advertising ID), mobile carrier, device type, model and
              manufacturer, mobile device operating system brand and model, phone number, and
              depending on your mobile device settings, your geographical location data, including
              GPS coordinates (e.g., latitude and/or longitude) or similar information regarding the
              location of your mobile device, or we may be able to approximate a device’s location
              by analyzing other information, like an IP address. We may collect analytics data, or
              use third-party analytics tools (including, without limitation, Google Analytics and
              Firebase Analytics), to help us measure traffic and usage trends for the Service and
              to understand more about the demographics of our users. We may also work with
              third-party partners to employ technologies, including the application of statistical
              modeling tools, which attempt to recognize you across multiple devices. Although we do
              our best to honor the privacy preferences of our users, we are unable to respond to Do
              Not Track signals set by your browser at this time.
            </p>
            <p>
              We use or may use the data collected through tracking technologies to: (a) remember
              information so that you will not have to re-enter it during your visit or the next
              time you visit the site; (b) provide custom, personalized content and information,
              including targeted content, advertising and offers; (c) identify you across multiple
              devices; (d) provide and monitor the effectiveness of our Service; (e) monitor
              aggregate metrics such as total number of visitors, traffic, usage, and demographic
              patterns on our website; (f) diagnose or fix technology problems; and (g) otherwise to
              plan for and enhance our service.
            </p>
            <p>
              If you would prefer not to accept cookies, most browsers will allow you to: (i) change
              your browser settings to notify you when you receive a cookie, which lets you choose
              whether or not to accept it; (ii) disable existing cookies; or (iii) set your browser
              to automatically reject cookies. Please note that doing so may negatively impact your
              experience using the Service, as some features and services on our Service may not
              work properly. Depending on your mobile device and operating system, you may not be
              able to delete or block all cookies. You may also set your e-mail options to prevent
              the automatic downloading of images that may contain technologies that would allow us
              to know whether you have accessed our e-mail and performed certain functions with it.
            </p>
            <p>
              We and our third-party partners may also use cookies and tracking technologies for
              advertising purposes. For more information about tracking technologies, please see
              “Third-Party Tracking and Online Advertising” below.
            </p>
          </section>
          <section>
            <h3>3. SHARING OF YOUR INFORMATION.</h3>
            <p>
              We may share your personal information in the instances described below. For further
              information on your choices regarding your information, see the “Control Over Your
              Information” section below.
            </p>
            <p>
              Remember, our Service allows you to connect and interact with others.{' '}
              <b>
                Your profile information, including your name, title, employer, photo, and other
                personal information, will be available publicly to other members of the Service by
                default when you create a profile, interact with others on the Service in public
                groups, and post content to public spaces.
              </b>
            </p>
            <p>We may share your personal information with or for:</p>
            <ul>
              <li>
                <b>Users of the Service:</b> We may share your name, title, employer, photo and/or
                contact information with Users of the Service in connection with texts, emails or
                other communications with Users that our Service generates on behalf of your funeral
                home. These communications may be associated with events, condolences, posts or
                other matters connected with an individual for whom your Client funeral home is
                providing services, or in connection with marketing and selling products and
                services on behalf of your funeral home to consumers.
              </li>
              <li>
                <b>Third Party Support Vendors:</b> when necessary, on our behalf to provide
                specific business support services, including website hosting and management, mobile
                application development, and other support services. We may also enable third-party
                vendors to market and sell products and services to you, including, for example:
                <li>
                  <ul>
                    Funeral homes with whom we’ve arranged to offer you funeral goods and services;
                  </ul>
                  <ul>
                    Insurance carriers with whom we’ve arranged to offer insurance policies; and
                  </ul>
                  <ul>
                    Servicing platforms to process your insurance applications and/or requests to
                    purchase from funeral homes.
                  </ul>
                </li>
              </li>
              <li>
                <b>Website Analytics Companies:</b> We share anonymous aggregated information
                regarding visitors to our website and mobile application with third-party website
                analytics companies. These companies use this aggregate data, which has been
                stripped of any personally identifying information about you, to provide us with
                insight regarding our web usage patterns. As we only share anonymous, aggregate
                data, this information cannot be traced back to you individually by either us or the
                website analytics vendors.We use Google Analytics, Firebase, Amplitude and Hotjar to
                provide us website usage and analytic reports, which necessitates us sharing your
                anonymous, aggregate data. You may choose not to share your data with Google by
                installing the Google Analytics opt-out browser add-on, which instructs your browser
                not to provide your website usage data to Google Analytics. To opt-out of Google
                Analytics, visit{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://tools.google.com/dlpage/gaoptout"
                >
                  https://tools.google.com/dlpage/gaoptout
                </a>{' '}
                to install the browser add-on. For more information on Firebase’s Terms of Service
                and privacy practices, visit{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://firebase.google.com/terms/"
                >
                  https://firebase.google.com/terms/
                </a>{' '}
                and{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://policies.google.com/privacy"
                >
                  https://policies.google.com/privacy
                </a>
                , respectively. For more information on Amplitude’s Terms of Service and privacy
                practices, visit{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://amplitude.com/terms">
                  https://amplitude.com/terms
                </a>{' '}
                and{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://amplitude.com/privacy">
                  https://amplitude.com/privacy
                </a>
                , respectively. For more information on Hotjar’s Terms of Service and privacy
                practices, visit{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://hotjar.com/legal/policies/terms-of-service"
                >
                  https://hotjar.com/legal/policies/terms-of-service
                </a>{' '}
                and{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href=" https://hotjar.com/legal/policies/privacy/"
                >
                  https://hotjar.com/legal/policies/privacy/
                </a>
                , respectively.
              </li>
              <li>
                <b>Third-Party Data Matching Service Providers: </b>We may send information that you
                provide to third-party data providers to obtain more information about you (e.g.,
                age, zip code, gender, etc.). These third-party data providers include, without
                limitation, USADATA and V12 Data. As we contract with additional third-party data
                providers, we will periodically update this section to reflect the same.
              </li>
              <li>
                <b>Public and Other Users: </b>
                <ul className="sublist">
                  <li>when you provide feedback or User Content on our Service;</li>
                  <li>
                    Your profile information and content you post to public areas of the Service,
                    including public groups, will be viewable by others on the Service and the
                    public. Please do not provide personal information you would not want to be
                    public. You may be able to control the visibility of some of your information
                    and actions in your Settings (see "Control Over Your Information" below). We
                    share your information with third parties with whom you communicate with on the
                    Service. You control who you want to communicate with and what information you
                    share
                  </li>
                </ul>
              </li>
              <li>
                <b>Legal Requirements: </b>In certain circumstances, we may be required to disclose
                your personal data as otherwise required by law or legal or regulatory proceeding.
                We will only share the information we are required to disclose by law and only when
                we are required to do so.
              </li>
              <li>
                <b>Security and Fraud Prevention Efforts: </b>When necessary, we will use your
                personal data, including email communications, to preserve the security of our
                website, systems, and personal data in our control. If necessary, we will also use
                your personal data to investigate possible fraud, to identify violations of this
                Client User Privacy Policy and our terms and conditions, and to prevent any
                attempted harm to our users.
              </li>
              <li>
                <b>Sale of Business: </b>other parties in connection with a company transaction,
                such as a merger, sale of company assets or shares, reorganization, financing,
                change of control or acquisition of all or a portion of our business by another
                company or third party, or in the event of a bankruptcy or related or similar
                proceedings.
              </li>
              <li>
                <b>Other Third Parties: </b>
                <ul className="sublist">
                  <li>
                    With other carefully-selected third parties who may have products or services we
                    think you may enjoy.
                  </li>
                  <li>
                    With third parties at your request. For example, among other things, you may
                    have the option to share your activities on the Service with your friends
                    through email, text or on various social media sites, or to plan a funeral
                    through the Service, or to purchase goods or services and/or apply for insurance
                    coverage.
                  </li>
                </ul>
              </li>
              <li>
                <b>Affiliated Companies: </b>other companies and brands owned or controlled by
                Everdays, Inc. and other companies owned by or under common ownership as Everdays,
                which also includes our subsidiaries (i.e., any organization we own or control) or
                our ultimate holding company (i.e., any organization that owns or controls us) and
                any subsidiaries it owns. These companies will use your personal information in the
                same way as we can under this Client User Privacy Policy.
              </li>
              <li>
                <b>Other: </b>We may also share information with others in an aggregated or
                otherwise anonymized form that does not reasonably identify you directly as an
                individual.
              </li>
            </ul>
            <p>The Services are not intended for certain users, including: </p>
            <ul>
              <li>
                <b>Children Under the Age of 18: </b>No one under the age of 18 may provide any
                information to or on the Services. We do not knowingly collect personal information
                from anyone under the age of 18. If you are under the age of 18, do not use the
                Services or provide any information about yourself on the Services. If you believe
                we might have received information from a child under the age of 18, please contact
                us at the postal or email address provided in the “Contact Information” section
                below.
              </li>
              <li>
                <b>Individuals Outside of United States and Canada: </b>The website and mobile
                application are intended for users located in the United States and Canada. In the
                event you visit the website or mobile application from outside the United States,
                your information may be transferred to, stored, and processed in the United States,
                where the privacy laws might not be as comprehensive as those in your country. By
                using the website and/or mobile application, you understand that your information
                may be transferred to and processed in the United States, and in accordance with
                this Client User Privacy Policy, and you consent to such data processing activities.
              </li>
            </ul>
          </section>
          <section>
            <h3>4. CONTROL OVER YOUR INFORMATION.</h3>
            <p>
              <b>Profile and Data Sharing Settings.</b> You may update your profile information,
              such as your user name and profile photo, and may change some of your data sharing
              preferences on your Settings page.
            </p>
            <p>
              <b>Access to your Device Information.</b> You may control the Service’s access to your
              device information through your “Settings” app on your device. For instance, you can
              withdraw permission for the Service to access your address book, location, photo
              stream and camera.
            </p>
            <p>
              <b>How to control your communications preferences:</b> You can stop receiving
              promotional email communications from us by clicking on the “unsubscribe link”
              provided in such communications. We make every effort to promptly process all
              unsubscribe requests. You may not opt out of service-related communications (e.g.,
              account verification, transactional communications, changes/updates to features of the
              Service, technical and security notices). To unsubscribe from text messages at any
              time, reply STOP to any text message you receive from Everdays. You consent that
              following such a request to unsubscribe, you may receive one final text message from
              Everdays confirming your request. For help contact us at{' '}
              <a href="mailto:support@everdays.com">support@everdays.com</a>.
            </p>
            <p>
              <b>Modifying or deleting your information:</b> If you have any questions about
              reviewing, modifying, or deleting your information, or if you want to remove your name
              or comments from our website or publicly displayed content, you can contact us
              directly at <a href="mailto:support@everdays.com">support@everdays.com</a>. We may not
              be able to modify or delete your information in all circumstances.
            </p>
          </section>
          <section>
            <h3>5. YOUR CHOICES AND RIGHTS.</h3>
            <ul>
              <li>
                <b>EU Data Subject Rights:</b> If you are an EU data subject, we provide you with
                choices about the collection, use and disclosure of your personal data. You may
                exercise these rights by contacting us in writing by electronic mail, postal mail,
                or commercial courier at the address or email address provided at the end of this
                Client User Privacy Policy under the heading “Contact Information.” Unless
                explicitly stated otherwise, we will respond to your request as soon as possible,
                but at the latest within one month. Your rights include:
                <ul className="sublist">
                  <li>
                    Accessing your personal data to know what information we have collected about
                    you and how it has been shared;
                  </li>
                  <li>Requesting the deletion of all or some of your personal data;</li>
                  <li>Changing or correcting inaccurate or outdated information;</li>
                  <li>
                    Objecting to, limiting or restricting use of all or some of your personal data;
                    and
                  </li>
                  <li>Requesting a copy of your personal data, including in a portable format.</li>
                </ul>
                You also have the right to lodge a complaint with your supervisory authority if you
                believe we have violated your privacy rights or applicable laws and regulations.
              </li>
              <li>
                <b>California Privacy Rights: </b>If you are a resident of the State of California
                and you have provided your personal data to us, you have the right to request a list
                of all third parties to which we have disclosed your personal data for direct
                marketing purposes. If you exercise your right to submit such a request to us, we
                will send you the following information:
                <ul className="sublist">
                  <li>
                    The categories of information we have disclosed to any third party for any third
                    party’s direct marketing purposes during the preceding year; and
                  </li>
                  <li>
                    The names and addresses of third parties that received such information, or if
                    the nature of their business cannot be determined from the name, then examples
                    of the products or services marketed.
                  </li>
                </ul>
                You may make such a request by contacting us in writing by electronic mail, postal
                mail, or commercial courier at the address or email address provided at the end of
                this Client User Privacy Policy under the heading “Contact Information” with a
                preference on how our response to your request should be sent. California law also
                requires that we disclose how we respond to “do-not-track requests” from our users.
                At this time, we do not currently respond to “do-not-track” requests from our users’
                browsers.
              </li>
            </ul>
          </section>
          <section>
            <h3>6. THIRD PARTY TRACKING AND ONLINE ADVERTISING.</h3>
            <p>
              We may share, or we may permit third-party online advertising networks, social media
              companies and other third-party services, to collect, information about your use of
              our website over time so that they may play or display ads that may be relevant to
              your interests on our Service as well as on other websites or apps, or on other
              devices you may use. Typically, though not always, the information we share is
              provided through cookies or similar tracking technologies, which recognize the device
              you are using and collect information, including hashed data, click stream
              information, browser type, time and date you visited the site, and other information.
              This information is used to display targeted ads on or through our Service or on other
              websites or apps, including on Facebook. We or the online advertising networks use
              this information to make the advertisements you see online more relevant to your
              interests. As noted above, depending on your browser or mobile device, you may be able
              set your browser to delete or notify you of cookies and other tracking technology by
              actively managing the settings on your browser or mobile device. You may also be able
              to limit interest-based advertising through the settings on your mobile device by
              selecting “limit ad tracking” (iOS) or “opt-out of interest-based ads” (Android). To
              learn more about interest-based advertising and how you may be able to opt-out of some
              of this advertising, you may wish to visit the Network Advertising Initiative’s online
              resources, at{' '}
              <a
                href="http://www.networkadvertising.org/choices"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.networkadvertising.org/choices
              </a>
              , and/or the DAA’s resources at{' '}
              <a href="http://www.aboutads.info/choices" target="_blank" rel="noopener noreferrer">
                www.aboutads.info/choices
              </a>
              , and you may also adjust your ad preferences through your Facebook settings. You may
              also be able to opt-out of some – but not all – interest-based ads served by mobile ad
              networks by visiting{' '}
              <a
                href="http://youradchoices.com/appchoices"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://youradchoices.com/appchoices
              </a>{' '}
              and downloading the mobile AppChoices app. If you have any questions about opting out
              of the collection of cookies and other tracking/recording tools, you can contact us
              directly at <a href="mailto:support@everdays.com">support@everdays.com</a>.
            </p>
          </section>
          <section>
            <h3>7. HOW WE STORE AND PROTECT YOUR INFORMATION.</h3>
            <p>
              <b>Data storage and transfer: </b>Your information collected through our website may
              be stored and processed in the United States or any other country in which Everdays or
              its affiliates or service providers maintain facilities. If you are located in the
              European Union or other regions with laws governing data collection and use that may
              differ from U.S. law, please note that we may transfer information, including personal
              information, to a country and jurisdiction that does not have the same data protection
              laws as your jurisdiction, and you consent to the transfer of information to the U.S.
              or any other country in which the Everdays or its parent, subsidiaries, affiliates, or
              service providers maintain facilities and the use and disclosure of information about
              you as described in this Client User Privacy Policy.
            </p>
            <p>
              <b>Keeping your information safe: </b>We care about the security of your information
              and use commercially reasonable physical, administrative, and technological safeguards
              to preserve the integrity and security of all information collected through our
              website. However, no security system is impenetrable, and we cannot guarantee the
              security of our systems. In the event that any information under our control is
              compromised as a result of a breach of security, we will take reasonable steps to
              investigate the situation and, where appropriate, notify those individuals whose
              information may have been compromised and take other steps, in accordance with any
              applicable laws and regulations.
            </p>
          </section>
          <section>
            <h3>8. LINKS TO OTHER WEB SITES AND SERVICES.</h3>
            <p>
              The Services may contain links to and from third-party websites of our business
              partners, advertisers, and social media sites and our users may post links to
              third-party websites. If you follow a link to any of these websites, please note that
              these websites have their own privacy policies and that we do not accept any
              responsibility or liability for their policies. We strongly recommend that you read
              their privacy policies and terms and conditions of use to understand how they collect,
              use, and share information. We are not responsible for the privacy practices or the
              content on the websites of third-party sites.
            </p>
          </section>
          <section>
            <h3>9. HOW TO CONTACT US.</h3>
            <p>
              If you have any questions about this Client User Privacy Policy or the website, please
              contact us at <br />
              <br />
              c/o Privacy
              <br />
              Everydays, Inc.
              <br />
              320 Martin St. Suite 140
              <br />
              Birmingham, MI 48009
              <br />
              <a href="mailto:legal@everdays.com">legal@everdays.com</a>.
            </p>
          </section>
          <section>
            <h3>10. CHANGES TO OUR CLIENT PRIVACY POLICY.</h3>
            <p>
              We may modify or update this Client User Privacy Policy from time to time to reflect
              the changes in our business and practices, and so you should review this page
              periodically. When we change the policy in a material manner, we will let you know and
              update the ‘last modified’ date at the top of this page. The changes will be effective
              as of the date we post the revised Client User Privacy Policy on our website and
              mobile application and the revised policy will only apply to information collected
              thereafter. Your continued use of the Services after we make changes to the Client
              User Privacy Policy signifies your acceptance of those changes. Please check our
              website or mobile application periodically for updates to the Client User Privacy
              Policy. For your convenience, we will post the effective date at the top of any
              revised Client User Privacy Policy.
            </p>
          </section>
        </div>
      </div>
      <PagesFooter
        currentPage={'privacy'}
        pathname={location.pathname}
        queryString={location.search}
      />
      {renderAuthModal(user, location, children, { dlHideAuth: true, hasOptions: true })}
    </>
  );
};

export default connect(({ dash }) => ({ user: dash.user }))(ClientPrivacy);
