import React from 'react';
import classNames from 'classnames';

import styles from './Evelyn.module.scss';

// will likely need a better quality (square) image
import evelyn from '../../../public/images/evelyn.png';

const {
  evelyn: evelynClass,
  'evelyn-image': evelynImageClass,
  'speech-bubbles-container': speechBubblesContainerClass,
  'mobile-evelyn-container': mobileEvelynContainerClass, // this class helps manage the evelyn image next to the first speech bubble on mobile
  'evelyn-mobile-image': evelynMobileImageClass,
  'speech-bubble': speechBubbleClass,
  'align-bubbles': alignBubblesClass
} = styles;

const Evelyn = ({
  customClass = '',
  image = evelyn,
  speechBubbleArray = ["Hi! I'm Evelyn.", 'Welcome to Everdays.'],
  alignBubbles = false
}) => {
  const speechBubblesContainerClasses = classNames(speechBubblesContainerClass, {
    [alignBubblesClass]: alignBubbles
  });
  return (
    <div className={`${evelynClass} ${customClass}`}>
      <div className={evelynImageClass} style={{ backgroundImage: `url('${image}')` }} />
      <div className={speechBubblesContainerClasses}>
        {speechBubbleArray.map((bubble, idx) => {
          if (idx === 0) {
            return (
              <div key={idx} className={mobileEvelynContainerClass}>
                <div
                  className={`${evelynImageClass} ${evelynMobileImageClass}`}
                  style={{ backgroundImage: `url('${image}')` }}
                />
                <div key={idx} className={speechBubbleClass}>
                  <h2>{bubble}</h2>
                </div>
              </div>
            );
          } else {
            return (
              <div key={idx} className={speechBubbleClass}>
                <p>{bubble}</p>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Evelyn;
