"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UNSUBSCRIBE_BOT_FAILURE = exports.UNSUBSCRIBE_BOT_SUCCESS = exports.UNSUBSCRIBE_BOT_REQUEST = exports.STORE_CHAT_ROSTER = exports.STORE_CHAT_OBJ = exports.CHAT_CONNECTED = exports.REMOVE_PENDING_FOLLOW = exports.ADD_PENDING_FOLLOW = exports.REMOVE_PENDING_CHAT_RESPONSE = exports.ADD_PENDING_CHAT_RESPONSE = exports.SUBTRACT_FROM_UNREAD_COUNT = exports.ADD_TO_UNREAD_COUNT = void 0;
var ADD_TO_UNREAD_COUNT = 'ADD_TO_UNREAD_COUNT';
exports.ADD_TO_UNREAD_COUNT = ADD_TO_UNREAD_COUNT;
var SUBTRACT_FROM_UNREAD_COUNT = 'SUBTRACT_FROM_UNREAD_COUNT';
exports.SUBTRACT_FROM_UNREAD_COUNT = SUBTRACT_FROM_UNREAD_COUNT;
var ADD_PENDING_CHAT_RESPONSE = 'ADD_PENDING_CHAT_RESPONSE';
exports.ADD_PENDING_CHAT_RESPONSE = ADD_PENDING_CHAT_RESPONSE;
var REMOVE_PENDING_CHAT_RESPONSE = 'REMOVE_PENDING_CHAT_RESPONSE';
exports.REMOVE_PENDING_CHAT_RESPONSE = REMOVE_PENDING_CHAT_RESPONSE;
var ADD_PENDING_FOLLOW = 'ADD_PENDING_FOLLOW';
exports.ADD_PENDING_FOLLOW = ADD_PENDING_FOLLOW;
var REMOVE_PENDING_FOLLOW = 'REMOVE_PENDING_FOLLOW';
exports.REMOVE_PENDING_FOLLOW = REMOVE_PENDING_FOLLOW;
var CHAT_CONNECTED = 'CHAT_CONNECTED';
exports.CHAT_CONNECTED = CHAT_CONNECTED;
var STORE_CHAT_OBJ = 'STORE_CHAT_OBJ';
exports.STORE_CHAT_OBJ = STORE_CHAT_OBJ;
var STORE_CHAT_ROSTER = 'STORE_CHAT_ROSTER';
exports.STORE_CHAT_ROSTER = STORE_CHAT_ROSTER;
var UNSUBSCRIBE_BOT_REQUEST = 'UNSUBSCRIBE_BOT_REQUEST';
exports.UNSUBSCRIBE_BOT_REQUEST = UNSUBSCRIBE_BOT_REQUEST;
var UNSUBSCRIBE_BOT_SUCCESS = 'UNSUBSCRIBE_BOT_SUCCESS';
exports.UNSUBSCRIBE_BOT_SUCCESS = UNSUBSCRIBE_BOT_SUCCESS;
var UNSUBSCRIBE_BOT_FAILURE = 'UNSUBSCRIBE_BOT_FAILURE';
exports.UNSUBSCRIBE_BOT_FAILURE = UNSUBSCRIBE_BOT_FAILURE;