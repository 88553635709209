"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESET_PROVIDERS = exports.FETCH_PROVIDERS_FAILURE = exports.FETCH_PROVIDERS_SUCCESS = exports.FETCH_PROVIDERS_REQUEST = void 0;
var FETCH_PROVIDERS_REQUEST = 'FETCH_PROVIDERS_REQUEST';
exports.FETCH_PROVIDERS_REQUEST = FETCH_PROVIDERS_REQUEST;
var FETCH_PROVIDERS_SUCCESS = 'FETCH_PROVIDERS_SUCCESS';
exports.FETCH_PROVIDERS_SUCCESS = FETCH_PROVIDERS_SUCCESS;
var FETCH_PROVIDERS_FAILURE = 'FETCH_PROVIDERS_FAILURE';
exports.FETCH_PROVIDERS_FAILURE = FETCH_PROVIDERS_FAILURE;
var RESET_PROVIDERS = 'RESET_PROVIDERS';
exports.RESET_PROVIDERS = RESET_PROVIDERS;