import React from 'react';

import EverdaysGuarantee from '../EverdaysGuarantee';

import familyDinner from '../../public/images/family-dinner.png';

import styles from './EverdaysGuaranteeSection.module.scss';

const { 'insured-protection': insuredProtectionClass } = styles;

const EverdaysGuaranteeSection = ({
  titleCopy = 'Insured protection for your money, plan, and family.',
  bodyCopy = 'We believe in transparency about what it takes to make sure your family will be taken care of, and with us there’s never any hidden fees. Payments are held by 100-year-old, A+ rated insurance company Homesteaders Life to ensure full fund and package delivery when the time comes.',
  buttonCopy = '',
  onClick = () => (window.location.href = 'https://try.everdays.com/plan')
}) => {
  return (
    <div className={insuredProtectionClass}>
      <h2>{titleCopy}</h2>
      <img src={familyDinner} alt="" />
      <p>{bodyCopy}</p>
      <EverdaysGuarantee />
      {buttonCopy && <button onClick={onClick}>{buttonCopy}</button>}
    </div>
  );
};

export default EverdaysGuaranteeSection;
