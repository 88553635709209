import React from 'react';
import classNames from 'classnames';

import styles from './CTAButton.module.scss';

const { button: buttonClass } = styles;

//--Styles: default-style, blue-border, white-border, black
//--Sizes: default-size, nav, full-width

const CTAButton = ({
  CTA = 'Click me',
  href = '#',
  target = '',
  buttonStyle = 'default-style',
  buttonSize = 'default-size'
}) => {
  const classes = classNames(
    buttonClass,
    ...[buttonStyle, buttonSize].map(style => styles[style]).filter(Boolean)
  );

  return (
    <a rel="noopener noreferrer" className={classes} {...{ href, target }}>
      {CTA}
    </a>
  );
};

export default CTAButton;
