"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var loginDetails = {
  name: '',
  email: '',
  password: '',
  phone: '',
  code: ''
};
var dashAnncs = {
  searchText: '',
  filteredSearchText: '',
  inlineSearchTag: '',
  sort: '',
  date: ''
};
var dashUsers = {
  searchText: '',
  sort: ''
};
var dashAccounts = {
  searchText: '',
  sort: ''
};
var dashReports = {
  location: '',
  date: ''
};
var dashLeads = {
  searchText: '',
  sort: ''
};
var tosModal = {
  isAccepted: true
};
var crmLeadDetails = {
  name: {
    first: '',
    last: ''
  },
  email: '',
  phone: '',
  afp: null
};
var _default = {
  loginDetails: loginDetails,
  dashAnncs: dashAnncs,
  dashUsers: dashUsers,
  dashAccounts: dashAccounts,
  dashReports: dashReports,
  dashLeads: dashLeads,
  tosModal: tosModal,
  crmLeadDetails: crmLeadDetails
};
exports.default = _default;