"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: {
    first: '',
    last: '',
    middle: '',
    nickname: '',
    suffix: ''
  },
  phone: '',
  dob: {
    month: '',
    day: '',
    year: ''
  },
  dod: {
    month: '',
    day: '',
    year: ''
  },
  cityOfBirth: '',
  cityOfDeath: '',
  gender: '',
  traumatic: false
};
exports.default = _default;