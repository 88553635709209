import React from 'react';

import shieldOutline from '../../public/images/evdy-shield-outline.svg';

import styles from './EverdaysGuarantee.module.scss';

const {
  'everdays-guarantee': everdaysGuaranteeClass,
  shield: shieldClass,
  body: bodyClass
} = styles;

const EverdaysGuarantee = () => {
  return (
    <div className={everdaysGuaranteeClass}>
      <img className={shieldClass} src={shieldOutline} alt="" />
      <div className={bodyClass}>
        <h3>Everdays Guarantee</h3>
        <p>
          We know life happens, and there’s no buyers remorse with us. You can cancel your purchase
          for a full refund within 100 days, no questions asked.
        </p>
      </div>
    </div>
  );
};

export default EverdaysGuarantee;
