"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.klaviyoTrackNewsletter = exports.klaviyoIdentify = exports.klaviyoNewsletterSignup = void 0;

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

var _actions = require("./actions");

var _constants = require("../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var instance = _index.default.create({
  baseURL: "".concat(_constants.API_URL)
});
/**
 * Assigns identifying properties to the users cookie, used before any tracking can be done
 * @param {{email: String, firstName: String, lastName: String, zip: String, birthdate: String, phone: String, gender: String}} body An object containing all of the required tracking properties
 */


var klaviyoIdentify = function klaviyoIdentify(_ref) {
  var email = _ref.email,
      firstName = _ref.firstName,
      lastName = _ref.lastName,
      zip = _ref.zip,
      birthdate = _ref.birthdate,
      phone = _ref.phone,
      gender = _ref.gender;

  if (global.window && window._learnq) {
    window._learnq.push(['identify', {
      $email: email,
      $first_name: firstName,
      $last_name: lastName,
      $zip: zip,
      $birthdate: birthdate,
      $phone_number: phone,
      $user_zip: zip,
      $gender: gender
    }]);
  }
};
/**
 * Submits tracking event for "Subscribe to Newsletter"
 *
 */


exports.klaviyoIdentify = klaviyoIdentify;

var klaviyoTrackNewsletter = function klaviyoTrackNewsletter() {
  if (global.window && window._learnq) {
    window._learnq.push(['track', 'Request Newsletter Subscribe Email']);
  }
}; // klaviyo newsletter signup


exports.klaviyoTrackNewsletter = klaviyoTrackNewsletter;

var klaviyoNewsletterSignup = function klaviyoNewsletterSignup(_ref2) {
  var email = _ref2.email;
  return function (dispatch) {
    dispatch((0, _actions.klaviyoNewsSubscribeRequest)());
    return instance.post("/v2/public/newsletter-subscribe", {
      klaviyoEmail: email
    }).then(function (res) {
      return dispatch((0, _actions.klaviyoNewsSubscribeSuccess)(res));
    }).catch(function (err) {
      return dispatch((0, _actions.klaviyoNewsSubscribeFailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.klaviyoNewsletterSignup = klaviyoNewsletterSignup;