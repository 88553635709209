"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestAppLink = void 0;

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

var _actions = require("./actions");

var _constants = require("../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var instance = _index.default.create({
  baseURL: _constants.API_URL
});

var requestAppLink = function requestAppLink(data) {
  return function (dispatch) {
    dispatch((0, _actions.requestAppLinkRequest)());
    return instance.post('/v2/public/request-app', data).then(function (res) {
      return dispatch((0, _actions.requestAppLinkSuccess)(res));
    }, function (err) {
      return dispatch((0, _actions.requestAppLinkFailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.requestAppLink = requestAppLink;