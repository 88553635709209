import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { preneedPackagesOperations } from '@evdy/web-redux/dist/lib/webPurchase/preneedPackages';
import { purchaseFlowOperations } from '@evdy/web-redux/dist/lib/webPurchase/purchaseFlow';
import { authUserOperations } from '@evdy/web-redux/dist/lib/authUser';

import { getCookie } from './helperFns';
import { screenNameRoutes } from './components/constants';

const { saveLastScreen } = authUserOperations;

// on mount, check if weblead cookie exists and fetch existing weblead
export const useGetWebleadFromCookie = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const webleadCookie = document?.cookie
      ?.split(';')
      ?.find(cookie => cookie.trim().startsWith('weblead='));
    const webleadId = webleadCookie?.split('=')[1];

    if (webleadId) {
      dispatch(purchaseFlowOperations.fetchWeblead(webleadId));
    }
  }, []);
};

// on mount, check if cookie provided in argument exists
export const useVerifyCookie = cookieName => {
  const [cookieData, setCookieData] = useState(false);

  // get cookie data after component loads
  useEffect(() => {
    setCookieData(getCookie(cookieName));
  }, [cookieName]);

  return !!cookieData;
};

// on mount, if authUser or weblead cookie is not detected navigate back to all packages
export const useDetectWebleadUser = ({ redirectHref = '/packages' }) => {
  const { authUser = {} } = useSelector(({ authUser }) => ({ authUser }));

  useEffect(() => {
    const webleadCookie = getCookie('weblead');

    if (!authUser?.data?._id && !webleadCookie) {
      window.location.href = redirectHref;
    }
  }, []);
};

// on mount, if authUser is not detected navigate to redirectHref
export const useDetectUser = ({ redirectHref = '/get-my-price' }) => {
  const { authUser = {} } = useSelector(({ authUser }) => ({ authUser }));

  useEffect(() => {
    if (!authUser?.data?._id) {
      window.location.href = redirectHref;
    }
  }, []);
};

// hides intercom on mount programatically, can set to hide up until a specific breakpoint
// otherwise hide on all screen sizes
export const useHideIntercom = breakpoint => {
  useEffect(() => {
    if (window?.innerWidth <= (breakpoint || window?.innerWidth)) {
      // eslint-disable-next-line no-undef
      Intercom('update', {
        hide_default_launcher: true
      });
    }
  }, []);
};

//get preneed packages
export const useGetPreneedPackages = () => {
  const dispatch = useDispatch();
  const { preneedPackages = [] } = useSelector(({ webPurchase }) => ({
    preneedPackages: webPurchase.preneedPackages.data?.packages
  }));

  const { getPreneedPackages } = preneedPackagesOperations;

  useEffect(() => {
    if (!preneedPackages?.length) {
      dispatch(getPreneedPackages());
    }
  }, [preneedPackages.length]);
};

// on mount remove canonical html element with undefined url and replace
export const useUpdateCanonicalElement = url => {
  useEffect(() => {
    const linkNodes = [...document.querySelectorAll('link')];
    // remove dupe nodes
    const canonicalNodes = linkNodes.filter(n => n.rel === 'canonical');
    canonicalNodes.forEach(n => n.remove());
    // append correct canonical node
    const howItWorksNode = document.createElement('link');
    howItWorksNode.href = `https://everdays.com${url}/`;
    howItWorksNode.rel = 'canonical';
    document.getElementsByTagName('head')[0].appendChild(howItWorksNode);
  }, [url]);
};
