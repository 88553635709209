"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DOWNLOAD_MEDIA_RESET = exports.DOWNLOAD_MEDIA_FAILURE = exports.DOWNLOAD_MEDIA_SUCCESS = exports.DOWNLOAD_MEDIA_REQUEST = void 0;
var DOWNLOAD_MEDIA_REQUEST = 'DOWNLOAD_MEDIA_REQUEST';
exports.DOWNLOAD_MEDIA_REQUEST = DOWNLOAD_MEDIA_REQUEST;
var DOWNLOAD_MEDIA_SUCCESS = 'DOWNLOAD_MEDIA_SUCCESS';
exports.DOWNLOAD_MEDIA_SUCCESS = DOWNLOAD_MEDIA_SUCCESS;
var DOWNLOAD_MEDIA_FAILURE = 'DOWNLOAD_MEDIA_FAILURE';
exports.DOWNLOAD_MEDIA_FAILURE = DOWNLOAD_MEDIA_FAILURE;
var DOWNLOAD_MEDIA_RESET = 'DOWNLOAD_MEDIA_RESET';
exports.DOWNLOAD_MEDIA_RESET = DOWNLOAD_MEDIA_RESET;