"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetShareEstimates = exports.shareEstimates = void 0;

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

var _actions = require("./actions");

var _constants = require("../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var instance = _index.default.create({
  baseURL: "".concat(_constants.API_URL)
});

var shareEstimates = function shareEstimates(_ref) {
  var recipientName = _ref.recipientName,
      recipientEmail = _ref.recipientEmail,
      notes = _ref.notes,
      screenName = _ref.screenName;
  return function (dispatch) {
    dispatch((0, _actions.shareEstimatesRequest)());
    return instance.post("/v2/private/preneed/sendemail", {
      recipientName: recipientName,
      recipientEmail: recipientEmail,
      notes: notes,
      screenName: screenName
    }).then(function (res) {
      return dispatch((0, _actions.shareEstimatesSuccess)(res));
    }).catch(function (err) {
      return dispatch((0, _actions.shareEstimatesFailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.shareEstimates = shareEstimates;

var resetShareEstimates = function resetShareEstimates() {
  return function (dispatch) {
    dispatch((0, _actions.shareEstimatesReset)());
  };
};

exports.resetShareEstimates = resetShareEstimates;