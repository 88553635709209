import React from 'react';

import styles from './WhyContent.module.scss';

const {
  'why-content': whyContentClass,
  'section-one': sectionOneClass,
  'section-two': sectionTwoClass,
  'graphic-container': graphicContainerClass,
  'section-two-copy': sectionTwoCopyClass
} = styles;

const WhyContent = ({
  sectionCopy = [],
  sectionGraphic,
  ctaButtonCopy,
  ctaButtonHref,
  renderExtraContent
}) => {
  return (
    <div className={whyContentClass}>
      <div className={sectionOneClass}>
        <h4>{sectionCopy[0]?.header}</h4>
        <p>{sectionCopy[0]?.content}</p>
      </div>
      <div className={sectionTwoClass}>
        <div className={graphicContainerClass}>
          <img src={sectionGraphic} />
        </div>
        <div className={sectionTwoCopyClass}>
          <h4>{sectionCopy[1]?.header}</h4>
          <p>{sectionCopy[1]?.content}</p>
          {renderExtraContent}
          <button onClick={() => (window.location.href = ctaButtonHref)}>{ctaButtonCopy}</button>
        </div>
      </div>
    </div>
  );
};

export default WhyContent;
