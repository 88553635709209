"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SET_IS_EMPLOYEE = exports.SET_DASH_MODE = exports.CLOSE_CREATE_ANNC_MODAL = exports.OPEN_CREATE_ANNC_MODAL = void 0;
var OPEN_CREATE_ANNC_MODAL = 'OPEN_CREATE_ANNC_MODAL';
exports.OPEN_CREATE_ANNC_MODAL = OPEN_CREATE_ANNC_MODAL;
var CLOSE_CREATE_ANNC_MODAL = 'CLOSE_CREATE_ANN_MODAL';
exports.CLOSE_CREATE_ANNC_MODAL = CLOSE_CREATE_ANNC_MODAL;
var SET_DASH_MODE = 'SET_DASH_MODE';
exports.SET_DASH_MODE = SET_DASH_MODE;
var SET_IS_EMPLOYEE = 'SET_IS_EMPLOYEE';
exports.SET_IS_EMPLOYEE = SET_IS_EMPLOYEE;