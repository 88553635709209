"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetEditPreneedPrices = exports.getRawPreneedData = exports.editPreneedPricesAll = exports.editPreneedPrices = exports.initPreneed = exports.resetGPLPDFUpload = exports.uploadGPLPDF = exports.resetPreneedTOS = exports.acceptPreneedTOS = exports.resetAccLocUpdate = exports.resetFuneralHome = exports.editFuneralHome = exports.fetchFuneralHome = void 0;

require("core-js/modules/es6.array.map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.promise.js");

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

var _actions = require("./actions");

var _constants = require("../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var instance = _index.default.create({
  baseURL: "".concat(_constants.API_URL)
});

var fetchFuneralHome = function fetchFuneralHome(funeralhomeId) {
  return function (dispatch) {
    dispatch((0, _actions.fetchFuneralHomeRequest)());
    return instance.get("/v2/private/funeralhome/".concat(funeralhomeId)).then(function (res) {
      return dispatch((0, _actions.fetchFuneralHomeSuccess)(res));
    }).catch(function (err) {
      return dispatch((0, _actions.fetchFuneralHomefailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.fetchFuneralHome = fetchFuneralHome;

var editFuneralHome = function editFuneralHome(funeralhomeId, location) {
  return function (dispatch) {
    dispatch((0, _actions.editFuneralHomeRequest)());
    return instance.put("/v2/private/funeralhome/".concat(funeralhomeId), location).then(function (res) {
      return dispatch((0, _actions.editFuneralHomeSuccess)(res));
    }).catch(function (err) {
      return dispatch((0, _actions.editFuneralHomefailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.editFuneralHome = editFuneralHome;

var resetFuneralHome = function resetFuneralHome() {
  return function (dispatch) {
    dispatch((0, _actions.resetFuneralHomeAction)());
  };
};

exports.resetFuneralHome = resetFuneralHome;

var resetAccLocUpdate = function resetAccLocUpdate() {
  return function (dispatch) {
    dispatch((0, _actions.resetAccLocationDetailsUpdate)());
  };
}; // Terms


exports.resetAccLocUpdate = resetAccLocUpdate;

var acceptPreneedTOS = function acceptPreneedTOS(companyId) {
  return function (dispatch) {
    dispatch((0, _actions.acceptPreneedTOSRequest)());
    return instance.post("/v2/private/company/".concat(companyId, "/preneedtos")).then(function (res) {
      return dispatch((0, _actions.acceptPreneedTOSSuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.acceptPreneedTOSFailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.acceptPreneedTOS = acceptPreneedTOS;

var resetPreneedTOS = function resetPreneedTOS() {
  return function (dispatch) {
    dispatch((0, _actions.resetPreneedTOSAction)());
  };
}; // PDF Upload


exports.resetPreneedTOS = resetPreneedTOS;

var uploadGPLPDF = function uploadGPLPDF(_ref) {
  var funeralHomeId = _ref.funeralHomeId,
      pdf = _ref.pdf;
  return function (dispatch) {
    dispatch((0, _actions.uploadGPLPDFRequest)());
    return instance.post("/v2/private/funeralhome/".concat(funeralHomeId, "/uploadpricegpl"), pdf).then(function (res) {
      return dispatch((0, _actions.uploadGPLPDFSuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.uploadGPLPDFFailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.uploadGPLPDF = uploadGPLPDF;

var resetGPLPDFUpload = function resetGPLPDFUpload() {
  return function (dispatch) {
    dispatch((0, _actions.resetGPLPDFUploadAction)());
  };
}; // Init Preneed


exports.resetGPLPDFUpload = resetGPLPDFUpload;

var initPreneed = function initPreneed(_ref2) {
  var funeralHomeId = _ref2.funeralHomeId;
  return function (dispatch) {
    dispatch((0, _actions.initPreneedRequest)());
    return instance.post("/v2/private/funeralhome/".concat(funeralHomeId, "/initPreneed")).then(function (res) {
      return dispatch((0, _actions.initPreneedSuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.initPreneedFailure)((0, _transformAxiosError.default)(err)));
    });
  };
}; // Edit Preneed Package Prices


exports.initPreneed = initPreneed;

var editPreneedPrices = function editPreneedPrices(_ref3) {
  var funeralHomeId = _ref3.funeralHomeId,
      updatedPrices = _ref3.updatedPrices;
  return function (dispatch) {
    dispatch((0, _actions.editPreneedPricesRequest)());
    var data = {
      priceStructure: updatedPrices
    };
    return instance.post("/v2/private/funeralhome/".concat(funeralHomeId, "/preneedpackages"), data).then(function (res) {
      return dispatch((0, _actions.editPreneedPricesSuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.editPreneedPricesFailure)((0, _transformAxiosError.default)(err)));
    });
  };
}; // Edit Preneed Packages Prices on all company locations


exports.editPreneedPrices = editPreneedPrices;

var editPreneedPricesAll = function editPreneedPricesAll(_ref4) {
  var funeralHomeId = _ref4.funeralHomeId,
      updatedPrices = _ref4.updatedPrices,
      otherFuneralHomeIds = _ref4.otherFuneralHomeIds;
  return function (dispatch) {
    dispatch((0, _actions.editPreneedPricesRequest)());
    var data = {
      priceStructure: updatedPrices
    };
    var updateOtherHomes = otherFuneralHomeIds.map(function (_id) {
      return instance.post("/v2/private/funeralhome/".concat(_id, "/preneedpackages"), data);
    });
    return Promise.all(updateOtherHomes).then(function () {
      return instance.post("/v2/private/funeralhome/".concat(funeralHomeId, "/preneedpackages"), data);
    }).then(function (res) {
      return dispatch((0, _actions.editPreneedPricesSuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.editPreneedPricesFailure)((0, _transformAxiosError.default)(err)));
    });
  };
}; // get Raw Preneed Data


exports.editPreneedPricesAll = editPreneedPricesAll;

var getRawPreneedData = function getRawPreneedData() {
  return function (dispatch) {
    dispatch((0, _actions.getRawPreneedRequest)());
    return instance.get("/v2/public/preneedpackages/rawpreneed").then(function (res) {
      return dispatch((0, _actions.getRawPreneedSuccess)(res.data));
    }).catch(function (err) {
      return dispatch((0, _actions.getRawPreneedFailure)(err));
    });
  };
};

exports.getRawPreneedData = getRawPreneedData;

var resetEditPreneedPrices = function resetEditPreneedPrices() {
  return function (dispatch) {
    dispatch((0, _actions.resetEditPreneedPricesAction)());
  };
};

exports.resetEditPreneedPrices = resetEditPreneedPrices;