"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculatePrice = void 0;

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

var _actions = require("./actions");

var _constants = require("../../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var instance = _index.default.create({
  baseURL: _constants.API_URL
});

var calculatePrice = function calculatePrice(_ref, userId, isLoggedIn) {
  var birthDay = _ref.birthDay,
      birthMonth = _ref.birthMonth,
      birthYear = _ref.birthYear,
      email = _ref.email,
      selectedPackage = _ref.selectedPackage,
      zipcode = _ref.zipcode,
      firstName = _ref.firstName,
      lastName = _ref.lastName;
  return function (dispatch) {
    dispatch((0, _actions.calculatePriceRequest)());
    var body = {
      userId: userId,
      dateOfBirth: new Date(birthYear, birthMonth - 1, birthDay).toISOString(),
      zip: "".concat(zipcode),
      email: email,
      firstName: firstName,
      lastName: lastName,
      defaultPackageName: selectedPackage
    };
    return instance.post("".concat(_constants.API_URL, "/v2/").concat(isLoggedIn ? 'private' : 'public', "/preneed/getestimates"), body).then(function (res) {
      return dispatch((0, _actions.calculatePriceSucess)(res.data));
    }).catch(function (err) {
      return (0, _actions.calculatePriceFailure)((0, _transformAxiosError.default)(err));
    });
  };
};

exports.calculatePrice = calculatePrice;