"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LT_API_URL = exports.LT_BASE_URL = exports.SF_BASE_URL = exports.GMAPS_PLACES_URL = exports.GMAPS_URL = exports.GMAPS_API_KEY = exports.GOOGLE_LINK = exports.APPLE_LINK = exports.HOME_LINK = exports.LAMBDA_URL = exports.CHAT_BOSH_URL = exports.CHAT_HOSTNAME = exports.VIDEO_UPLOAD_URL = exports.CHAT_API_URL = exports.API_URL_V2 = exports.API_URL = exports.getAPIUrl = exports.host = void 0;
var port = process.env.API_PORT || 1350;
var host = process.env.HOST || 'localhost';
exports.host = host;

var getAPIUrl = function getAPIUrl() {
  var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var passedPort = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : port;

  switch (process.env.DEPLOY_ENV) {
    case 'dev':
      return "https://dev.everdays.com".concat(v);

    case 'dev2':
      return "https://dev2.everdays.com".concat(v);

    case 'prod':
      return "https://everdays.com".concat(v);

    case 'localDns':
      return "https://local.everdays.com:".concat(passedPort).concat(v);

    default:
      return "http://".concat(host, ":").concat(passedPort).concat(v);
  }
};

exports.getAPIUrl = getAPIUrl;
var API_URL = getAPIUrl();
exports.API_URL = API_URL;

var API_URL_V2 = function () {
  return getAPIUrl('/v2');
}();

exports.API_URL_V2 = API_URL_V2;

var CHAT_API_URL = function () {
  switch (process.env.DEPLOY_ENV) {
    case 'prod':
      return 'https://chat-prod.everdays.com/api/messages';

    case 'localChat':
      return 'http://localhost:8088/api/messages';

    default:
      return 'https://chat-dev.everdays.com/api/messages';
  }
}();

exports.CHAT_API_URL = CHAT_API_URL;

var getVideoUploadUrl = function getVideoUploadUrl() {
  switch (process.env.DEPLOY_ENV) {
    case 'dev':
    case 'prod':
      return "http://52.54.92.58:1351";

    default:
      return "http://".concat(host, ":1351");
  }
};

var VIDEO_UPLOAD_URL = getVideoUploadUrl();
exports.VIDEO_UPLOAD_URL = VIDEO_UPLOAD_URL;

var CHAT_HOSTNAME = function () {
  switch (process.env.DEPLOY_ENV) {
    case 'prod':
      return 'chat-prod.everdays.com';

    case 'localChat':
      return 'localhost';

    default:
      return 'chat-dev.everdays.com';
  }
}();

exports.CHAT_HOSTNAME = CHAT_HOSTNAME;

var CHAT_BOSH_URL = function () {
  switch (process.env.DEPLOY_ENV) {
    case 'prod':
      return 'https://bosh-prod.everdays.com/http-bind';

    case 'localChat':
      return 'http://localhost:5280/http-bind';

    default:
      return 'https://bosh-dev.everdays.com/http-bind';
  }
}();

exports.CHAT_BOSH_URL = CHAT_BOSH_URL;
var LAMBDA_URL = 'https://z4unjm07yj.execute-api.us-east-1.amazonaws.com/prod/compressImage';
/* LINKS */

exports.LAMBDA_URL = LAMBDA_URL;
var HOME_LINK = 'https://everdays.com/';
exports.HOME_LINK = HOME_LINK;
var APPLE_LINK = 'https://apps.apple.com/us/app/everdays-funeral-announcements/id1123339295';
exports.APPLE_LINK = APPLE_LINK;
var GOOGLE_LINK = 'https://play.google.com/store/apps/details?id=com.izimobile.requiem&hl=en_US';
/* Google Maps */

exports.GOOGLE_LINK = GOOGLE_LINK;

var GMAPS_API_KEY = function () {
  switch (process.env.DEPLOY_ENV) {
    case 'dev':
    case 'dev2':
    case 'prod':
      return 'AIzaSyAjB4UVXYJ8emTH2fWmcFq6Gn9wtId3vi4';

    default:
      return 'AIzaSyC3KlUDlnrl5U2wa65hbhe_IocOL9YLg_w';
  }
}();

exports.GMAPS_API_KEY = GMAPS_API_KEY;
var GMAPS_URL = 'https://maps.googleapis.com/maps/api/staticmap';
exports.GMAPS_URL = GMAPS_URL;
var GMAPS_PLACES_URL = "https://maps.googleapis.com/maps/api/js?key=".concat(GMAPS_API_KEY, "&libraries=places");
/* SF */

exports.GMAPS_PLACES_URL = GMAPS_PLACES_URL;
var sfEnv = process.env.DEPLOY_ENV === 'prod' ? '' : '--torrentdev';
var SF_BASE_URL = "https://everdays2017".concat(sfEnv, ".lightning.force.com/one/one.app#/sObject/");
exports.SF_BASE_URL = SF_BASE_URL;
var LT_BASE_URL = process.env.DEPLOY_ENV === 'prod' ? 'http://www.ltkeepsakes.com/' : 'http://chppw.algqj.servertrust.com/';
exports.LT_BASE_URL = LT_BASE_URL;
var LT_API_URL = process.env.DEPLOY_ENV === 'prod' ? 'https://api3.legacytouch.com/' : 'https://devapi.legacytouch.com/';
exports.LT_API_URL = LT_API_URL;