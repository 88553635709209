"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NEWSLETTER_SIGNUP_FAILURE = exports.NEWSLETTER_SIGNUP_SUCCESS = exports.NEWSLETTER_SIGNUP_REQUEST = void 0;
var NEWSLETTER_SIGNUP_REQUEST = 'NEWSLETTER_SIGNUP_REQUEST';
exports.NEWSLETTER_SIGNUP_REQUEST = NEWSLETTER_SIGNUP_REQUEST;
var NEWSLETTER_SIGNUP_SUCCESS = 'NEWSLETTER_SIGNUP_SUCCESS';
exports.NEWSLETTER_SIGNUP_SUCCESS = NEWSLETTER_SIGNUP_SUCCESS;
var NEWSLETTER_SIGNUP_FAILURE = 'NEWSLETTER_SIGNUP_FAILURE';
exports.NEWSLETTER_SIGNUP_FAILURE = NEWSLETTER_SIGNUP_FAILURE;