"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.obituary = exports.accessor = exports.decedentPhone = exports.decedentInfo = exports.eventInfo = exports.funeralInfo = void 0;
var funeralInfo = {
  funeralHome: '',
  director: '',
  noServices: null
};
exports.funeralInfo = funeralInfo;
var eventInfo = {
  eventName: '',
  location: {
    name: '',
    city: '',
    street: '',
    zip: ''
  },
  eventDate: null,
  startTime: '',
  endTime: '',
  description: '',
  noServices: null,
  notifyCommunity: false,
  videoStreamUrl: ''
};
exports.eventInfo = eventInfo;
var decedentInfo = {
  image: '',
  name: {
    first: '',
    last: ''
  },
  displayName: '',
  decedentPhone: '',
  dateOfBirth: null,
  dateOfDeath: null,
  traumatic: false
};
exports.decedentInfo = decedentInfo;
var decedentPhone = {
  decedentPhone: ''
};
exports.decedentPhone = decedentPhone;
var accessor = {
  name: {
    first: '',
    last: ''
  },
  phone: '',
  email: '',
  relation: '',
  primarySurvivor: false
};
exports.accessor = accessor;
var obituary = {
  message: ''
};
exports.obituary = obituary;