"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FETCH_RECENT_BOT_MESSAGE_FAILURE = exports.FETCH_RECENT_BOT_MESSAGE_SUCCESS = exports.FETCH_RECENT_BOT_MESSAGE_REQUEST = exports.FETCH_SENT_MESSAGES_FAILURE = exports.FETCH_SENT_MESSAGES_SUCCESS = exports.FETCH_SENT_MESSAGES_REQUEST = exports.FETCH_MESSAGES_FAILURE = exports.FETCH_MESSAGES_SUCCESS = exports.FETCH_MESSAGES_REQUEST = void 0;
var FETCH_MESSAGES_REQUEST = 'FETCH_MESSAGES_REQUEST';
exports.FETCH_MESSAGES_REQUEST = FETCH_MESSAGES_REQUEST;
var FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
exports.FETCH_MESSAGES_SUCCESS = FETCH_MESSAGES_SUCCESS;
var FETCH_MESSAGES_FAILURE = 'FETCH_MESSAGES_FAILURE';
exports.FETCH_MESSAGES_FAILURE = FETCH_MESSAGES_FAILURE;
var FETCH_SENT_MESSAGES_REQUEST = 'FETCH_SENT_MESSAGES_REQUEST';
exports.FETCH_SENT_MESSAGES_REQUEST = FETCH_SENT_MESSAGES_REQUEST;
var FETCH_SENT_MESSAGES_SUCCESS = 'FETCH_SENT_MESSAGES_SUCCESS';
exports.FETCH_SENT_MESSAGES_SUCCESS = FETCH_SENT_MESSAGES_SUCCESS;
var FETCH_SENT_MESSAGES_FAILURE = 'FETCH_SENT_MESSAGES_FAILURE';
exports.FETCH_SENT_MESSAGES_FAILURE = FETCH_SENT_MESSAGES_FAILURE;
var FETCH_RECENT_BOT_MESSAGE_REQUEST = 'FETCH_RECENT_BOT_MESSAGE_REQUEST';
exports.FETCH_RECENT_BOT_MESSAGE_REQUEST = FETCH_RECENT_BOT_MESSAGE_REQUEST;
var FETCH_RECENT_BOT_MESSAGE_SUCCESS = 'FETCH_RECENT_BOT_MESSAGE_SUCCESS';
exports.FETCH_RECENT_BOT_MESSAGE_SUCCESS = FETCH_RECENT_BOT_MESSAGE_SUCCESS;
var FETCH_RECENT_BOT_MESSAGE_FAILURE = 'FETCH_RECENT_BOT_MESSAGE_FAILURE';
exports.FETCH_RECENT_BOT_MESSAGE_FAILURE = FETCH_RECENT_BOT_MESSAGE_FAILURE;