"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EDIT_JOURNEY_FAILURE = exports.EDIT_JOURNEY_SUCCESS = exports.EDIT_JOURNEY_REQUEST = exports.ADD_JOURNEY_FAILURE = exports.ADD_JOURNEY_SUCCESS = exports.ADD_JOURNEY_REQUEST = exports.FETCH_JOURNEY_CONFIG_FAILURE = exports.FETCH_JOURNEY_CONFIG_SUCCESS = exports.FETCH_JOURNEY_CONFIG_REQUEST = exports.FETCH_JOURNEYS_FAILURE = exports.FETCH_JOURNEYS_SUCCESS = exports.FETCH_JOURNEYS_REQUEST = void 0;
var FETCH_JOURNEYS_REQUEST = 'FETCH_JOURNEYS_REQUEST';
exports.FETCH_JOURNEYS_REQUEST = FETCH_JOURNEYS_REQUEST;
var FETCH_JOURNEYS_SUCCESS = 'FETCH_JOURNEYS_SUCCESS';
exports.FETCH_JOURNEYS_SUCCESS = FETCH_JOURNEYS_SUCCESS;
var FETCH_JOURNEYS_FAILURE = 'FETCH_JOURNEYS_FAILURE';
exports.FETCH_JOURNEYS_FAILURE = FETCH_JOURNEYS_FAILURE;
var FETCH_JOURNEY_CONFIG_REQUEST = 'FETCH_JOURNEY_CONFIG_REQUEST';
exports.FETCH_JOURNEY_CONFIG_REQUEST = FETCH_JOURNEY_CONFIG_REQUEST;
var FETCH_JOURNEY_CONFIG_SUCCESS = 'FETCH_JOURNEY_CONFIG_SUCCESS';
exports.FETCH_JOURNEY_CONFIG_SUCCESS = FETCH_JOURNEY_CONFIG_SUCCESS;
var FETCH_JOURNEY_CONFIG_FAILURE = 'FETCH_JOURNEY_CONFIG_FAILURE';
exports.FETCH_JOURNEY_CONFIG_FAILURE = FETCH_JOURNEY_CONFIG_FAILURE;
var ADD_JOURNEY_REQUEST = 'ADD_JOURNEY_REQUEST';
exports.ADD_JOURNEY_REQUEST = ADD_JOURNEY_REQUEST;
var ADD_JOURNEY_SUCCESS = 'ADD_JOURNEY_SUCCESS';
exports.ADD_JOURNEY_SUCCESS = ADD_JOURNEY_SUCCESS;
var ADD_JOURNEY_FAILURE = 'ADD_JOURNEY_FAILURE';
exports.ADD_JOURNEY_FAILURE = ADD_JOURNEY_FAILURE;
var EDIT_JOURNEY_REQUEST = 'EDIT_JOURNEY_REQUEST';
exports.EDIT_JOURNEY_REQUEST = EDIT_JOURNEY_REQUEST;
var EDIT_JOURNEY_SUCCESS = 'EDIT_JOURNEY_SUCCESS';
exports.EDIT_JOURNEY_SUCCESS = EDIT_JOURNEY_SUCCESS;
var EDIT_JOURNEY_FAILURE = 'EDIT_JOURNEY_FAILURE';
exports.EDIT_JOURNEY_FAILURE = EDIT_JOURNEY_FAILURE;