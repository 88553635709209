import React, { useState } from 'react';

import SiteNav from '../shared/SiteNav';
import EstimateCTA from '../shared/SiteNav/EstimateCTA';
import SlideOutMenu from '../shared/SlideOutMenu';
import FooterSection from '../shared/Footer/FooterSection';
import GeneralContent from '../shared/GeneralContent';
import HelpSection from '../shared/HelpSection';

import { useHideIntercom } from '../../customHooks';

import techcrunchLogo from '../../public/images/logos/techcrunch.svg';
import usatodayLogo from '../../public/images/logos/usatoday.svg';
import freepressLogo from '../../public/images/logos/freepress.svg';
import pitchbookLogo from '../../public/images/logos/pitchbook.svg';
import heroImage from '../../public/images/comfortable_afternoon.jpg';

import styles from './AboutUs.module.scss';

const {
  'about-us': aboutUsClass,
  'about-us-content': aboutUsContentClass,
  'about-us-paired-content': aboutUsPairedContentClass,
  'about-us-content-pair': aboutUsPairClass,
  'dark-pair': aboutUsDarkPairClass,
  press: pitchSectionClass,
  'general-content': generalContentClass,
  'general-content-icon': generalContentIconClass,
  'general-content-title': generalContentTitleClass,
  'general-content-description': generalContentDescriptionClass,
  'general-content-button': generalContentButtonClass
} = styles;

const AboutUs = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setMenuOpen(prev => !prev);
  };

  //hide intercome
  useHideIntercom();

  return (
    <div className={aboutUsClass}>
      <SiteNav rightNavContent={() => <EstimateCTA />} />
      <SlideOutMenu {...{ isMenuOpen }} closeMenu={handleToggleMenu} />
      <div className={aboutUsContentClass}>
        <div className={`${aboutUsPairedContentClass} ${aboutUsDarkPairClass}`}>
          <div className={aboutUsPairClass}>
            <h2>
              We started Everdays to inspire people to give their families what they need to say
              goodbye with peace
            </h2>
          </div>
          <div className={aboutUsPairClass}>
            <img src={heroImage} alt="" />
          </div>
        </div>
        <div className={aboutUsPairedContentClass}>
          <div className={aboutUsPairClass}>
            <h3>
              We believe family and friends need to come together after losing someone they love
            </h3>
          </div>
          <div className={aboutUsPairClass}>
            <p>
              Saying goodbye together helps everyone through a difficult time. Whether it’s a full
              funeral service, spreading ashes at the family cabin, or anything in between – the
              ways to honor life are endless.
            </p>
            <p>
              But <b>your</b> life deserves to be honored, and people deserve a chance to honor it.{' '}
            </p>
          </div>
        </div>
        <div className={pitchSectionClass}>
          <img src={techcrunchLogo} alt="techcrunch" />
          <img src={usatodayLogo} alt="usatoday" />
          <img src={freepressLogo} alt="freepress" />
          <img src={pitchbookLogo} alt="pitchbook" />
        </div>
        <div className={aboutUsPairedContentClass}>
          <div className={aboutUsPairClass}>
            <h3>We want you to feel confident planning your funeral long before you die</h3>
          </div>
          <div className={aboutUsPairClass}>
            <p>
              Our funeral packages are easy to understand and we offer transparent pricing, so you
              can make your own decisions without ever talking to a sales agent or walking into a
              funeral home.
            </p>
            <p>
              We’re digital and online – because it’s important for you to be able to buy whenever,
              wherever, and however it feels right.
            </p>
            <p>
              And we partner with trusted providers across the country so you have the freedom to
              pick the best funeral home fit for your family.
            </p>
            <p>
              Buying your own funeral is an act of love, and we want you to feel great about that.
            </p>
          </div>
        </div>
        <GeneralContent
          value={{
            title: 'Check out our packages',
            subtitle:
              'We’ve worked hard to create thoughtful funeral experiences, and we’re sure we’ve got the package that fits you and your family.',
            cta: { href: '/packages', text: 'Learn more' },
            image: 'https://everdays-site.s3.amazonaws.com/home-11-20/pitch-1.jpg'
          }}
        />
        <div className={aboutUsPairedContentClass}>
          <div className={aboutUsPairClass}>
            <h3>Our family roots</h3>
          </div>
          <div className={aboutUsPairClass}>
            <p>
              Our founder, Mark Alhermizi, started Everdays because of his parents – both of their
              lives and passings helped shape the company into what we are today. Losing his parents
              and participating in their services affirmed for Mark the importance of funerals and
              having professionals there to guide everyone through the difficult time after the
              passing of someone they love.
            </p>
            <button
              onClick={() => (window.location.href = 'https://learn.everdays.com/originstory')}
            >
              Read Mark's story
            </button>
          </div>
        </div>
        <div className={generalContentClass}>
          <div className={generalContentIconClass} />
          <h1 className={generalContentTitleClass}>Want to join our team?</h1>
          <p className={generalContentDescriptionClass}>Help us build the future of the funerals</p>
          <button
            className={generalContentButtonClass}
            onClick={() => (window.location.href = 'https://everdays.applicantpro.com/jobs/')}
          >
            See open roles
          </button>
        </div>
        <HelpSection />
        <FooterSection />
      </div>
    </div>
  );
};

export default AboutUs;
