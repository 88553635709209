"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.APP_SIGNUP_SUCCESS = exports.USER_COMPLETE_RESET = exports.FB_LOGIN_SUCCESS = exports.EDIT_USER_SUCCESS = exports.VERIFY_PHONE_FAILURE = exports.VERIFY_PHONE_SUCCESS = exports.VERIFY_PHONE_REQUEST = exports.CHECK_PHONE_FAILURE = exports.CHECK_PHONE_SUCCESS = exports.CHECK_PHONE_REQUEST = void 0;
var CHECK_PHONE_REQUEST = 'CHECK_PHONE_REQUEST';
exports.CHECK_PHONE_REQUEST = CHECK_PHONE_REQUEST;
var CHECK_PHONE_SUCCESS = 'CHECK_PHONE_SUCCESS';
exports.CHECK_PHONE_SUCCESS = CHECK_PHONE_SUCCESS;
var CHECK_PHONE_FAILURE = 'CHECK_PHONE_FAILURE';
exports.CHECK_PHONE_FAILURE = CHECK_PHONE_FAILURE;
var VERIFY_PHONE_REQUEST = 'VERIFY_PHONE_REQUEST';
exports.VERIFY_PHONE_REQUEST = VERIFY_PHONE_REQUEST;
var VERIFY_PHONE_SUCCESS = 'VERIFY_PHONE_SUCCESS';
exports.VERIFY_PHONE_SUCCESS = VERIFY_PHONE_SUCCESS;
var VERIFY_PHONE_FAILURE = 'VERIFY_PHONE_FAILURE';
exports.VERIFY_PHONE_FAILURE = VERIFY_PHONE_FAILURE;
var EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
exports.EDIT_USER_SUCCESS = EDIT_USER_SUCCESS;
var FB_LOGIN_SUCCESS = 'FB_LOGIN_SUCCESS';
exports.FB_LOGIN_SUCCESS = FB_LOGIN_SUCCESS;
var USER_COMPLETE_RESET = 'USER_COMPLETE_RESET';
exports.USER_COMPLETE_RESET = USER_COMPLETE_RESET;
var APP_SIGNUP_SUCCESS = 'APP_SIGNUP_SUCCESS';
exports.APP_SIGNUP_SUCCESS = APP_SIGNUP_SUCCESS;