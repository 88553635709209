"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.inviteCommentInput = exports.inviteCondolenceInput = void 0;

var _reactReduxForm = require("react-redux-form");

var _dashForms = _interopRequireDefault(require("./dashForms"));

var _demoForms = _interopRequireDefault(require("./demoForms"));

var _homeDirectoryForms = _interopRequireDefault(require("./homeDirectoryForms"));

var _sharePageForms = _interopRequireDefault(require("./sharePageForms"));

var _siteForms = _interopRequireDefault(require("./siteForms"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// for form demo page
var sample = {
  name: '',
  age: '',
  hairColor: '',
  petType: '',
  cereal: '',
  isCold: false,
  notes: '',
  full: '',
  small: '',
  mini: '',
  custom: '',
  mood: '',
  pie: {
    display: '',
    save: ''
  },
  time: ''
}; // shared forms can live in index for now

var eventAddress = {
  customAddress: {
    name: '',
    address: '',
    city: '',
    state: '',
    zip: ''
  },
  location: {
    name: '',
    address: '',
    city: '',
    state: '',
    zip: ''
  },
  display: '',
  coords: [],
  locationSearchText: ''
};
var location = {
  casesPerYear: ''
};
var signupDetails = {
  nameFirst: '',
  nameLast: '',
  company: '',
  title: '',
  email: '',
  phone: '',
  websiteUrl: '',
  referral: '',
  terms: true
};
var anncSearch = {
  searchText: ''
};
var webAnnc = {
  login: {
    email: '',
    password: ''
  },
  signup: {
    name: '',
    email: '',
    password: '',
    tosAgree: true
  },
  missingData: {
    missingFirstName: '',
    missingLastName: '',
    missingPhone: '',
    missingPhoneCode: '',
    missingEmail: ''
  },
  setPassword: {
    password: '',
    confirmPassword: ''
  },
  journey: {
    tosAgree: true
  }
};
var passwordReset = {
  email: '',
  password: '',
  passwordConfirm: ''
};
var inviteCondolenceInput = {
  text: ''
};
exports.inviteCondolenceInput = inviteCondolenceInput;
var inviteCommentInput = {
  text: ''
};
exports.inviteCommentInput = inviteCommentInput;
var forms = (0, _reactReduxForm.combineForms)(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
  eventAddress: eventAddress,
  sample: sample,
  signupDetails: signupDetails,
  anncSearch: anncSearch,
  webAnnc: webAnnc,
  location: location,
  passwordReset: passwordReset,
  inviteCondolenceInput: inviteCondolenceInput,
  inviteCommentInput: inviteCommentInput
}, _dashForms.default), _demoForms.default), _homeDirectoryForms.default), _sharePageForms.default), _siteForms.default), 'forms', {
  key: 'formsMeta'
});
var _default = forms;
exports.default = _default;