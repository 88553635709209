"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EDIT_AFP_SUCCESS = exports.EDIT_AFP_FAILURE = exports.EDIT_AFP_REQUEST = void 0;
var EDIT_AFP_REQUEST = 'EDIT_AFP_REQUEST';
exports.EDIT_AFP_REQUEST = EDIT_AFP_REQUEST;
var EDIT_AFP_FAILURE = 'EDIT_AFP_FAILURE';
exports.EDIT_AFP_FAILURE = EDIT_AFP_FAILURE;
var EDIT_AFP_SUCCESS = 'EDIT_AFP_SUCCESS';
exports.EDIT_AFP_SUCCESS = EDIT_AFP_SUCCESS;