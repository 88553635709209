"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRequiredFields = void 0;

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

var _actions = require("./actions");

var _constants = require("../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var instance = _index.default.create({
  baseURL: _constants.API_URL
});

var getRequiredFields = function getRequiredFields(_ref) {
  var funeralHomeId = _ref.funeralHomeId;
  return function (dispatch) {
    dispatch((0, _actions.requestRequiredFields)());
    return instance.get("/v2/private/funeralHome/".concat(funeralHomeId, "/preneedrequired")).then(function (res) {
      return dispatch((0, _actions.requestRequiredFieldsSuccess)(res));
    }).catch(function (err) {
      return dispatch((0, _actions.requestRequiredFieldsFailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.getRequiredFields = getRequiredFields;