"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.filter.js");

var actions = _interopRequireWildcard(require("../../constants/HomeDirectory/fetchHomeSummary"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialState = {
  data: {},
  isFetching: false,
  lastUpdated: 0,
  error: {}
};

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actions.UPDATE_REVIEW_REQUEST:
    case actions.LEAVE_REVIEW_REQUEST:
    case actions.FETCH_HOME_SUMMARY_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true,
        error: {}
      });

    case actions.FETCH_HOME_SUMMARY_SUCCESS:
      {
        var _ref = action.payload.data && action.payload.data.home,
            _ref$ratingsInfo = _ref.ratingsInfo,
            _ratingsInfo = _ref$ratingsInfo === void 0 ? {} : _ref$ratingsInfo;

        var userRating = _ratingsInfo.userRating;
        if (userRating && _ratingsInfo.ratings) _ratingsInfo.ratings = _ratingsInfo.ratings.filter(function (_ref2) {
          var _id = _ref2._id;
          return _id !== userRating._id;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          isFetching: false,
          lastUpdated: action.receivedAt,
          data: _objectSpread({}, action.payload.data),
          error: {}
        });
      }

    case actions.FETCH_HOME_SUMMARY_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: action.error
      });

    case actions.LEAVE_REVIEW_SUCCESS:
    case actions.UPDATE_REVIEW_SUCCESS:
      var data = action.payload.data;
      var average = data.average,
          count = data.count,
          rating = data.rating,
          combinedAvg = data.combinedAvg,
          combinedCount = data.combinedCount;
      var ratingsInfo = {
        ratings: state.data.home.ratingsInfo.ratings,
        average: average,
        combinedAvg: combinedAvg,
        count: count,
        combinedCount: combinedCount,
        userRating: rating
      };
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        data: _objectSpread(_objectSpread({}, state.data), {}, {
          home: _objectSpread(_objectSpread({}, state.data.home), {}, {
            ratingsInfo: ratingsInfo
          })
        })
      });

    case actions.DELETE_REVIEW_SUCCESS:
      {
        var _data = action.payload.data;
        var _average = _data.average,
            _count = _data.count,
            _combinedAvg = _data.combinedAvg,
            _combinedCount = _data.combinedCount;
        var _ratingsInfo2 = {
          ratings: state.data.home.ratingsInfo.ratings,
          average: _average,
          combinedAvg: _combinedAvg,
          count: _count,
          combinedCount: _combinedCount
        };
        return _objectSpread(_objectSpread({}, state), {}, {
          data: _objectSpread(_objectSpread({}, state.data), {}, {
            home: _objectSpread(_objectSpread({}, state.data.home), {}, {
              ratingsInfo: _ratingsInfo2
            })
          })
        });
      }

    case actions.RESET_HOME_SUMMARY:
      return _objectSpread({}, initialState);

    default:
      return state;
  }
};

exports.default = _default;