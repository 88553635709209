import React from 'react';

import styles from './HelpSection.module.scss';

import chatIcon from '../../../public/images/icons/contact-chat.svg';
import phoneIcon from '../../../public/images/icons/contact-phone.svg';
import emailIcon from '../../../public/images/icons/contact-email.svg';

const {
  support: supportSectionClass,
  'methods-of-support': methodsOfSupportClass,
  'method-of-support': methodOfSupportClass,
  'support-icon': supportIconClass,
  'support-details': supportDetailsClass,
  'mobile-hidden': mobileHidden,
  'always-display': alwaysDisplay
} = styles;

const HelpSection = () => (
  <div className={supportSectionClass}>
    <h3>Our care team is here to help</h3>
    <div className={methodsOfSupportClass}>
      <a
        className={`${methodOfSupportClass} live-chat intercom-chat`}
        href="mailto:wdj13bk6@intercom-mail.com"
      >
        <div className={supportIconClass}>
          <img src={chatIcon} alt="message a member of our team" />
        </div>
        <div className={supportDetailsClass}>
          <h3>Live Chat</h3>
          <p className={mobileHidden}>Weekdays 9am-6pm EST</p>
          <p className={mobileHidden}>Weekends – Emergency Support</p>
        </div>
      </a>
      <a className={methodOfSupportClass} href="tel:8774003297">
        <div className={supportIconClass}>
          <img src={phoneIcon} alt="message a member of our team" />
        </div>
        <div className={supportDetailsClass}>
          <h3>Call</h3>
          <p className={alwaysDisplay}>+1 (877) 400 - 3297 (DAYS)</p>
        </div>
      </a>
      <a className={methodOfSupportClass} href="mailto:care@everdays.com">
        <div className={supportIconClass}>
          <img src={emailIcon} alt="message a member of our team" />
        </div>
        <div className={supportDetailsClass}>
          <h3>Email</h3>
          <p className={alwaysDisplay} style={{ textDecoration: 'underline' }}>
            care@everdays.com
          </p>
        </div>
      </a>
    </div>
  </div>
);

export default HelpSection;
