"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SEND_EMAIL_CONFIRMATION_RESET = exports.SEND_EMAIL_CONFIRMATION_FAILURE = exports.SEND_EMAIL_CONFIRMATION_SUCCESS = exports.SEND_EMAIL_CONFIRMATION_REQUEST = void 0;
var SEND_EMAIL_CONFIRMATION_REQUEST = 'SEND_EMAIL_CONFIRMATION_REQUEST';
exports.SEND_EMAIL_CONFIRMATION_REQUEST = SEND_EMAIL_CONFIRMATION_REQUEST;
var SEND_EMAIL_CONFIRMATION_SUCCESS = 'SEND_EMAIL_CONFIRMATION_SUCCESS';
exports.SEND_EMAIL_CONFIRMATION_SUCCESS = SEND_EMAIL_CONFIRMATION_SUCCESS;
var SEND_EMAIL_CONFIRMATION_FAILURE = 'SEND_EMAIL_CONFIRMATION_FAILURE';
exports.SEND_EMAIL_CONFIRMATION_FAILURE = SEND_EMAIL_CONFIRMATION_FAILURE;
var SEND_EMAIL_CONFIRMATION_RESET = 'SEND_EMAIL_CONFIRMATION_RESET';
exports.SEND_EMAIL_CONFIRMATION_RESET = SEND_EMAIL_CONFIRMATION_RESET;