"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = transAxiosErr;

function transAxiosErr(error) {
  if (!error.response) {
    return {
      message: error.message
    };
  }

  return error.response.data;
}