"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_PRICES_FAILURE = exports.GET_PRICES_SUCCESS = exports.GET_PRICES_REQUEST = void 0;
var GET_PRICES_REQUEST = 'GET_PRICES_REQUEST';
exports.GET_PRICES_REQUEST = GET_PRICES_REQUEST;
var GET_PRICES_SUCCESS = 'GET_PRICES_SUCCESS';
exports.GET_PRICES_SUCCESS = GET_PRICES_SUCCESS;
var GET_PRICES_FAILURE = 'GET_PRICES_FAILURE';
exports.GET_PRICES_FAILURE = GET_PRICES_FAILURE;