"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DELETE_FAMILY_UPDATE_FAILURE = exports.DELETE_FAMILY_UPDATE_SUCCESS = exports.DELETE_FAMILY_UPDATE_REQUEST = exports.EDIT_FAMILY_UPDATE_FAILURE = exports.EDIT_FAMILY_UPDATE_SUCCESS = exports.EDIT_FAMILY_UPDATE_REQUEST = exports.ADD_FAMILY_UPDATE_FAILURE = exports.ADD_FAMILY_UPDATE_SUCCESS = exports.ADD_FAMILY_UPDATE_REQUEST = void 0;
var ADD_FAMILY_UPDATE_REQUEST = 'ADD_FAMILY_UPDATE_REQUEST';
exports.ADD_FAMILY_UPDATE_REQUEST = ADD_FAMILY_UPDATE_REQUEST;
var ADD_FAMILY_UPDATE_SUCCESS = 'ADD_FAMILY_UPDATE_SUCCESS';
exports.ADD_FAMILY_UPDATE_SUCCESS = ADD_FAMILY_UPDATE_SUCCESS;
var ADD_FAMILY_UPDATE_FAILURE = 'ADD_FAMILY_UPDATE_FAILURE';
exports.ADD_FAMILY_UPDATE_FAILURE = ADD_FAMILY_UPDATE_FAILURE;
var EDIT_FAMILY_UPDATE_REQUEST = 'EDIT_FAMILY_UPDATE_REQUEST';
exports.EDIT_FAMILY_UPDATE_REQUEST = EDIT_FAMILY_UPDATE_REQUEST;
var EDIT_FAMILY_UPDATE_SUCCESS = 'EDIT_FAMILY_UPDATE_SUCCESS';
exports.EDIT_FAMILY_UPDATE_SUCCESS = EDIT_FAMILY_UPDATE_SUCCESS;
var EDIT_FAMILY_UPDATE_FAILURE = 'EDIT_FAMILY_UPDATE_FAILURE';
exports.EDIT_FAMILY_UPDATE_FAILURE = EDIT_FAMILY_UPDATE_FAILURE;
var DELETE_FAMILY_UPDATE_REQUEST = 'DELETE_FAMILY_UPDATE_REQUEST';
exports.DELETE_FAMILY_UPDATE_REQUEST = DELETE_FAMILY_UPDATE_REQUEST;
var DELETE_FAMILY_UPDATE_SUCCESS = 'DELETE_FAMILY_UPDATE_SUCCESS';
exports.DELETE_FAMILY_UPDATE_SUCCESS = DELETE_FAMILY_UPDATE_SUCCESS;
var DELETE_FAMILY_UPDATE_FAILURE = 'DELETE_FAMILY_UPDATE_FAILURE';
exports.DELETE_FAMILY_UPDATE_FAILURE = DELETE_FAMILY_UPDATE_FAILURE;