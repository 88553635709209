import React from 'react';
import classNames from 'classnames';

import styles from './ItemSection.module.scss';

const {
  'item-section': itemSectionClass,
  'item-header': itemHeaderClass,
  'item-accoridian-status': accordianStatusClass,
  'item-body': itemBodyClass
} = styles;

const ItemSection = ({ activeItems, handleToggleActive, name, description }) => {
  const itemSectionClasses = name => classNames(itemSectionClass, { active: activeItems[name] });
  return (
    <div className={itemSectionClasses(name)}>
      <button className={itemHeaderClass} onClick={() => handleToggleActive(name)}>
        <h4>{name}</h4>
        <div className={accordianStatusClass} />
      </button>
      <div className={itemBodyClass}>
        {description.split('\n').map((paragraph, idx) => (
          <p key={idx}>{paragraph}</p>
        ))}
      </div>
    </div>
  );
};

export default ItemSection;
