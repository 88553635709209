"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MEDIA_UNLIKE_FAILURE = exports.MEDIA_UNLIKE_SUCCESS = exports.MEDIA_UNLIKE_REQUEST = exports.MEDIA_LIKE_FAILURE = exports.MEDIA_LIKE_SUCCESS = exports.MEDIA_LIKE_REQUEST = void 0;
var MEDIA_LIKE_REQUEST = 'MEDIA_LIKE_REQUEST';
exports.MEDIA_LIKE_REQUEST = MEDIA_LIKE_REQUEST;
var MEDIA_LIKE_SUCCESS = 'MEDIA_LIKE_SUCCESS';
exports.MEDIA_LIKE_SUCCESS = MEDIA_LIKE_SUCCESS;
var MEDIA_LIKE_FAILURE = 'MEDIA_LIKE_FAILURE';
exports.MEDIA_LIKE_FAILURE = MEDIA_LIKE_FAILURE;
var MEDIA_UNLIKE_REQUEST = 'MEDIA_UNLIKE_REQUEST';
exports.MEDIA_UNLIKE_REQUEST = MEDIA_UNLIKE_REQUEST;
var MEDIA_UNLIKE_SUCCESS = 'MEDIA_UNLIKE_SUCCESS';
exports.MEDIA_UNLIKE_SUCCESS = MEDIA_UNLIKE_SUCCESS;
var MEDIA_UNLIKE_FAILURE = 'MEDIA_UNLIKE_FAILURE';
exports.MEDIA_UNLIKE_FAILURE = MEDIA_UNLIKE_FAILURE;