"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unfollowInvitation = exports.followInvitation = exports.resetVideoModalClosed = exports.setVideoModalClosed = exports.clearVideoProgress = exports.saveVideoProgress = exports.unloveComment = exports.loveComment = exports.resetComment = exports.leaveComment = exports.unloveMedia = exports.loveMedia = exports.resetCondolence = exports.leaveCondolence = exports.resetVideoEncoded = exports.resetVideoUploaded = exports.uploadVideoLocal = exports.uploadVideo = exports.uploadPhoto = exports.fetchAnnouncement = void 0;

var _actions = require("./actions");

var _constants = require("../../constants");

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var instance = _index.default.create({
  baseURL: "".concat(_constants.API_URL)
}); // Fetch Invite
// TODO: will likely need to update this action to fetch based on slug rather than memorialId


var fetchAnnouncement = function fetchAnnouncement(memorialId, userData) {
  return function (dispatch) {
    var authType = userData ? 'private' : 'public';
    dispatch((0, _actions.fetchMemorialNoticeRequest)());
    return instance.get("/v2/".concat(authType, "/memorial/").concat(memorialId)).then(function (res) {
      return dispatch((0, _actions.fetchMemorialNoticeSuccess)(res));
    }, function (err) {
      return dispatch((0, _actions.fetchMemorialNoticeFailure)((0, _transformAxiosError.default)(err)));
    });
  };
}; // Photo Upload


exports.fetchAnnouncement = fetchAnnouncement;

var uploadPhoto = function uploadPhoto(_ref) {
  var memorialId = _ref.memorialId,
      photo = _ref.photo,
      photoName = _ref.photoName,
      blobUrl = _ref.blobUrl;
  return function (dispatch) {
    var config = {
      onUploadProgress: function onUploadProgress(progressEvent) {
        var percentComplete = Math.floor(progressEvent.loaded * 100 / progressEvent.total);
        dispatch((0, _actions.pendingPhotoProgressUpdate)({
          progress: percentComplete,
          photoName: photoName
        }));
      }
    };
    dispatch((0, _actions.uploadPhotosRequest)({
      photoName: photoName,
      blobUrl: blobUrl
    }));
    return instance.post("/v2/private/memorial/".concat(memorialId, "/photo"), photo, config).then(function (res) {
      return dispatch((0, _actions.uploadPhotosSuccess)({
        data: res.data.data,
        photoName: photoName
      }));
    }).catch(function (err) {
      return dispatch((0, _actions.uploadPhotosFailure)((0, _transformAxiosError.default)(err)));
    });
  };
}; // Video Upload


exports.uploadPhoto = uploadPhoto;

var uploadVideo = function uploadVideo(_ref2) {
  var memorialId = _ref2.memorialId,
      video = _ref2.video,
      videoName = _ref2.videoName,
      blobUrl = _ref2.blobUrl,
      userData = _ref2.userData;
  return function (dispatch) {
    // first POST to /web-video to upload the video to S3
    var pendingVideoId = "pending-video-".concat(Date.now());
    var config = {
      onUploadProgress: function onUploadProgress(progressEvent) {
        var percentComplete = Math.floor(progressEvent.loaded * 100 / progressEvent.total);
        dispatch((0, _actions.pendingVideoProgressUpdate)({
          progress: percentComplete,
          videoId: 'test'
        }));
      }
    };
    dispatch((0, _actions.uploadVideoRequest)({
      videoName: videoName,
      blobUrl: blobUrl,
      userData: userData,
      memorialId: memorialId,
      pendingVideoId: pendingVideoId
    }));
    return instance.post("/v2/private/memorial/".concat(memorialId, "/condolence/web-video"), video, config).then(function (res) {
      dispatch((0, _actions.uploadVideoSuccess)({
        data: res.data.data
      })); // now, take fields from un-encoded video on S3 and pass to encode-mp4 lambda fn
      // fields include: mediaId, mediaUrl, Key, Bucket, height, width, duration

      dispatch((0, _actions.encodeVideoRequest)());
      return instance.post("/v2/private/memorial/".concat(memorialId, "/condolence/encode"), res.data.data).then(function (res) {
        return dispatch((0, _actions.encodeVideoSuccess)({
          data: res.data.data,
          pendingVideoId: pendingVideoId
        }));
      }).catch(function (err) {
        return dispatch((0, _actions.encodeVideoFailure)((0, _transformAxiosError.default)(err)));
      });
    }).catch(function (err) {
      return dispatch((0, _actions.uploadVideoFailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.uploadVideo = uploadVideo;

var resetVideoUploaded = function resetVideoUploaded() {
  return function (dispatch) {
    return dispatch((0, _actions.resetVideoUploadedAction)());
  };
};

exports.resetVideoUploaded = resetVideoUploaded;

var resetVideoEncoded = function resetVideoEncoded() {
  return function (dispatch) {
    return dispatch((0, _actions.resetVideoEncodedAction)());
  };
};

exports.resetVideoEncoded = resetVideoEncoded;

var uploadVideoLocal = function uploadVideoLocal(_ref3) {
  var memorialId = _ref3.memorialId,
      video = _ref3.video,
      videoName = _ref3.videoName,
      blobUrl = _ref3.blobUrl,
      userData = _ref3.userData,
      delayLengthMs = _ref3.delayLengthMs;
  return function (dispatch) {
    // first POST to /web-video to upload the video to S3
    var pendingVideoId = "pending-video-".concat(Date.now());
    dispatch((0, _actions.uploadVideoRequest)({
      videoName: videoName,
      blobUrl: blobUrl,
      userData: userData,
      memorialId: memorialId,
      pendingVideoId: pendingVideoId
    }));
    return instance.post("/v2/private/memorial/".concat(memorialId, "/condolence/web-video"), video).then(function (res) {
      dispatch((0, _actions.uploadVideoSuccess)({
        data: res.data.data
      })); // now, take fields from un-encoded video on S3 and pass to encode-mp4 lambda fn
      // fields include: mediaId, mediaUrl, Key, Bucket, height, width, duration

      dispatch((0, _actions.encodeVideoRequest)());

      var encodeObj = _objectSpread(_objectSpread({}, res.data.data), {}, {
        userId: userData._id,
        memorialId: memorialId
      });

      return _index.default.post("https://u3asf95jlj.execute-api.us-east-1.amazonaws.com/default/encode-mp4-local", encodeObj);
    }).then(function (res) {
      // then pass the returned data to save the encoded video to the memorial
      return instance.post('/v2/public/save-encoded-video', res.data);
    }).then(function (res) {
      console.log('returned from save');
      dispatch((0, _actions.encodeVideoSuccess)({
        data: res.data.data,
        pendingVideoId: pendingVideoId
      }));
    }).catch(function (err) {
      return dispatch((0, _actions.uploadVideoFailure)((0, _transformAxiosError.default)(err)));
    });
  };
}; // Condolences


exports.uploadVideoLocal = uploadVideoLocal;

var leaveCondolence = function leaveCondolence(_ref4) {
  var memorialId = _ref4.memorialId,
      condolence = _ref4.condolence;
  return function (dispatch) {
    dispatch((0, _actions.leaveCondolenceRequest)());
    return instance.post("/v2/private/memorial/".concat(memorialId, "/condolence"), condolence).then(function (res) {
      return dispatch((0, _actions.leaveCondolenceSuccess)({
        data: res.data.data
      }));
    }, function (err) {
      return dispatch((0, _actions.leaveCondolenceFailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.leaveCondolence = leaveCondolence;

var resetCondolence = function resetCondolence() {
  return function (dispatch) {
    return dispatch((0, _actions.resetCondolenceAction)());
  };
}; // Love/Unlove Media


exports.resetCondolence = resetCondolence;

var loveMedia = function loveMedia(memorialId, entityId, mediaType) {
  return function (dispatch) {
    dispatch((0, _actions.loveMediaRequest)({
      mediaType: mediaType
    })); // mediaType: {photo|condolence}

    return instance.post("/v2/private/memorial/".concat(memorialId, "/").concat(mediaType, "/").concat(entityId, "/like")).then(function (res) {
      return dispatch((0, _actions.loveMediaSuccess)({
        data: res.data.data,
        mediaType: mediaType,
        entityId: entityId
      }));
    }, function (err) {
      return dispatch((0, _actions.loveMediaFailure)({
        error: (0, _transformAxiosError.default)(err),
        mediaType: mediaType
      }));
    });
  };
};

exports.loveMedia = loveMedia;

var unloveMedia = function unloveMedia(memorialId, entityId, mediaType) {
  return function (dispatch) {
    dispatch((0, _actions.unloveMediaRequest)({
      mediaType: mediaType
    })); // mediaType: {photo|condolence}

    return instance.delete("/v2/private/memorial/".concat(memorialId, "/").concat(mediaType, "/").concat(entityId, "/like")).then(function (res) {
      return dispatch((0, _actions.unloveMediaSuccess)({
        data: res.data.data,
        mediaType: mediaType
      }), function (err) {
        return dispatch((0, _actions.unloveMediaFailure)({
          error: (0, _transformAxiosError.default)(err),
          mediaType: mediaType
        }));
      });
    });
  };
}; // Comment


exports.unloveMedia = unloveMedia;

var leaveComment = function leaveComment(_ref5) {
  var memorialId = _ref5.memorialId,
      mediaType = _ref5.mediaType,
      mediaId = _ref5.mediaId,
      comment = _ref5.comment;
  return function (dispatch) {
    dispatch((0, _actions.leaveCommentRequest)({
      mediaType: mediaType
    }));
    return instance.post("/v2/private/memorial/".concat(memorialId, "/").concat(mediaType, "/").concat(mediaId, "/comment"), comment).then(function (res) {
      return dispatch((0, _actions.leaveCommentSuccess)({
        data: res.data.data,
        mediaType: mediaType,
        mediaId: mediaId
      }));
    }, function (err) {
      return dispatch((0, _actions.leaveCommentFailure)({
        error: (0, _transformAxiosError.default)(err),
        mediaType: mediaType
      }));
    });
  };
};

exports.leaveComment = leaveComment;

var resetComment = function resetComment() {
  return function (dispatch) {
    return dispatch((0, _actions.resetCommentAction)());
  };
}; // Love/Unlove Comment


exports.resetComment = resetComment;

var loveComment = function loveComment(memorialId, entityId, mediaType, commentId) {
  return function (dispatch) {
    dispatch((0, _actions.loveCommentRequest)({
      mediaType: mediaType
    })); // mediaType: {photo|condolence}

    return instance.post("/v2/private/memorial/".concat(memorialId, "/").concat(mediaType, "/").concat(entityId, "/comment/").concat(commentId, "/like")).then(function (res) {
      return dispatch((0, _actions.loveCommentSuccess)({
        data: res.data.data,
        mediaType: mediaType,
        entityId: entityId,
        commentId: commentId
      }));
    }, function (err) {
      return dispatch((0, _actions.loveCommentFailure)({
        error: (0, _transformAxiosError.default)(err),
        mediaType: mediaType
      }));
    });
  };
};

exports.loveComment = loveComment;

var unloveComment = function unloveComment(memorialId, entityId, mediaType, commentId) {
  return function (dispatch) {
    dispatch((0, _actions.unloveCommentRequest)({
      mediaType: mediaType
    })); // mediaType: {photo|condolence}

    return instance.delete("/v2/private/memorial/".concat(memorialId, "/").concat(mediaType, "/").concat(entityId, "/comment/").concat(commentId, "/like")).then(function (res) {
      return dispatch((0, _actions.unloveCommentSuccess)({
        data: res.data.data,
        mediaType: mediaType
      }), function (err) {
        return dispatch((0, _actions.unloveCommentFailure)({
          error: (0, _transformAxiosError.default)(err),
          mediaType: mediaType
        }));
      });
    });
  };
}; // Video Progress Tracking


exports.unloveComment = unloveComment;

var saveVideoProgress = function saveVideoProgress(_ref6) {
  var videoId = _ref6.videoId,
      videoOptions = _ref6.videoOptions;
  return function (dispatch) {
    return dispatch((0, _actions.saveVideoProgressAction)({
      videoId: videoId,
      videoOptions: videoOptions
    }));
  };
};

exports.saveVideoProgress = saveVideoProgress;

var clearVideoProgress = function clearVideoProgress(_ref7) {
  var videoId = _ref7.videoId;
  return function (dispatch) {
    return dispatch((0, _actions.clearVideoProgressAction)({
      videoId: videoId
    }));
  };
};

exports.clearVideoProgress = clearVideoProgress;

var setVideoModalClosed = function setVideoModalClosed() {
  return function (dispatch) {
    return dispatch((0, _actions.setVideoModalClosedAction)());
  };
};

exports.setVideoModalClosed = setVideoModalClosed;

var resetVideoModalClosed = function resetVideoModalClosed() {
  return function (dispatch) {
    return dispatch((0, _actions.resetVideoModalClosedAction)());
  };
}; // Follow and unfollow invitation


exports.resetVideoModalClosed = resetVideoModalClosed;

var followInvitation = function followInvitation(_ref8) {
  var memorialId = _ref8.memorialId;
  return function (dispatch) {
    dispatch((0, _actions.followInviteRequest)());
    return instance.post("/v2/private/memorial/".concat(memorialId, "/follow")).then(function (res) {
      return dispatch((0, _actions.followInviteSuccess)(res));
    }, function (err) {
      return dispatch((0, _actions.followInviteFailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.followInvitation = followInvitation;

var unfollowInvitation = function unfollowInvitation(_ref9) {
  var memorialId = _ref9.memorialId;
  return function (dispatch) {
    dispatch((0, _actions.unfollowInviteRequest)());
    return instance.delete("/v2/private/memorial/".concat(memorialId, "/follow")).then(function (res) {
      return dispatch((0, _actions.unfollowInviteSuccess)(res));
    }, function (err) {
      return dispatch((0, _actions.unfollowInviteFailure)((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.unfollowInvitation = unfollowInvitation;