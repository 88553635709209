import React from 'react';
import classNames from 'classnames';

import CTAButton from '../CTAButton';

import styles from './GeneralContent.module.scss';

const { general: generalClass, graphic: graphicClass, image: imageClass } = styles;

const GeneralContent = ({
  value: { graphic, title, subtitle, cta, image } = {},
  imagePosition = 'right',
  background = 'default'
}) => {
  const { href, text } = cta ?? {};
  const generalClasses = classNames(
    generalClass,
    styles[`background-${background}`],
    styles[`image-${imagePosition}`]
  );

  return (
    <div className={generalClasses}>
      <div>
        {graphic && <img className={graphicClass} src={graphic} alt="" />}
        <h5>{title}</h5>
        {subtitle && subtitle.split('\n').map(s => <p>{s}</p>)}
        {text && <CTAButton CTA={text} buttonSize="full-width" {...{ href }} />}
      </div>
      {image && <img className={imageClass} src={image} alt="" />}
    </div>
  );
};

export default GeneralContent;
