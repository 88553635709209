"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _redux = require("redux");

var _reactReduxForm = require("react-redux-form");

var _utils = require("@evdy/web-core/dist/lib/utils");

var _forms = require("./forms");

var types = _interopRequireWildcard(require("./constants"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialState = {
  data: {},
  isFetching: false,
  lastUpdated: null,
  error: {},
  pdfUploaded: false,
  pricesUpdated: false,
  accLocationDetailsUpdated: false
};

var funeralhomeReducer = function funeralhomeReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.FETCH_FUNERAL_HOME_REQUEST:
    case types.EDIT_FUNERAL_HOME_REQUEST:
    case types.EDIT_PRENEED_PRICES_REQUEST:
    case types.UPLOAD_GPL_PDF_REQUEST:
    case types.INIT_PRENEED_REQUEST:
    case types.GET_RAWPRENEED_DATA_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true
      });

    case types.FETCH_FUNERAL_HOME_FAILURE:
    case types.EDIT_FUNERAL_HOME_FAILURE:
    case types.EDIT_PRENEED_PRICES_FAILURE:
    case types.UPLOAD_GPL_PDF_FAILURE:
    case types.INIT_PRENEED_FAILURE:
    case types.GET_RAWPRENEED_DATA_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: action.error,
        lastUpdated: action.receivedAt
      });

    case types.FETCH_FUNERAL_HOME_SUCCESS:
    case types.EDIT_FUNERAL_HOME_SUCCESS:
    case types.EDIT_PRENEED_PRICES_SUCCESS:
    case types.INIT_PRENEED_SUCCESS:
      var funeralHome = action.payload;

      var _ref = funeralHome || {},
          _ref$preneedPackages = _ref.preneedPackages,
          preneedPackages = _ref$preneedPackages === void 0 ? {} : _ref$preneedPackages;

      var _processPackageServic = (0, _utils.processPackageServices)(preneedPackages),
          _packages = _processPackageServic.packages,
          services = _processPackageServic.services;

      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        data: _objectSpread(_objectSpread({}, funeralHome), {}, {
          preneedPackages: _objectSpread(_objectSpread({}, preneedPackages), {}, {
            packages: _packages,
            services: services
          }),
          rawPreneedData: _objectSpread({}, state.data.rawPreneedData)
        }),
        lastUpdated: action.receivedAt,
        pricesUpdated: action.type === types.EDIT_PRENEED_PRICES_SUCCESS,
        accountLocationDetailsUpdated: action.type === types.EDIT_FUNERAL_HOME_SUCCESS
      });

    case types.UPLOAD_GPL_PDF_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        pdfUploaded: true,
        data: _objectSpread(_objectSpread({}, state.data), {}, {
          priceListingGPLUrl: action.payload.priceListingGPLUrl,
          packageSetupComplete: action.payload.packageSetupComplete
        })
      });

    case types.RESET_FUNERAL_HOME:
      return _objectSpread({}, initialState);

    case types.RESET_ACC_LOCATIONS_HOME_UPDATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        accountLocationDetailsUpdated: false,
        lastUpdated: null
      });

    case types.GET_RAWPRENEED_DATA_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        data: _objectSpread(_objectSpread({}, state.data), {}, {
          rawPreneedData: action.payload
        })
      });

    case types.RESET_EDIT_PRENEED_PRICES:
      return _objectSpread(_objectSpread({}, state), {}, {
        pricesUpdated: false
      });

    default:
      return state;
  }
};

var _default = (0, _redux.combineReducers)({
  home: funeralhomeReducer,
  forms: (0, _reactReduxForm.combineForms)({
    homeDetails: _forms.homeDetails,
    packages: _forms.packages
  }, 'funeralHome.forms', {
    key: 'formsMeta'
  })
});

exports.default = _default;